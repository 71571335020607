import gql from 'graphql-tag';

export const createAbsence = gql`
  mutation($absenceInput: AbsenceInputCreate!) {
    createAbsence(absenceInput: $absenceInput)
  }
`;

export const updateAbsence = gql`
  mutation($absenceInput: AbsenceInputUpdate!) {
    updateAbsence(absenceInput: $absenceInput) {
      id
      absenceReason {
        id
        name
        document
        attendance
      }
      absenceDetail {
        id
        postulation
        student
        specialty
      }
      state {
        id
        name
      }
      document
      days
      since
      until
      delete
      edit
    }
  }
`;

export const deleteAbsence = gql`
  mutation($id: Int!) {
    deleteAbsence(id: $id)
  }
`;

export const updateStateAbsence = gql`
  mutation($id: Int!) {
    updateStateAbsence(id: $id)
  }
`;
