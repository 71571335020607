import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import classes from './ContestInformation.module.scss';

const itemBox = (name, until, color) => {
  return (
    <div className={classes.box} key={name}>
      <label className={classes.label}>
        {`${name}: postulaciones abiertas hasta ${until}`}{' '}
      </label>
    </div>
  );
};

const ContestInformation = props => {
  const { className, loading, ...rest } = props;
  const competitions = props.competitions;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <h3 className={classes.title}>Información de Concursos</h3>
        {competitions && competitions.length > 0 ? (
          competitions.map(detail => {
            return itemBox(detail.name, detail.until);
          })
        ) : loading ? (
          <p>Cargando...</p>
        ) : (
          <p>No existe documentación solicitada.</p>
        )}
      </CardContent>
    </Card>
  );
};

ContestInformation.propTypes = {
  className: PropTypes.string
};

export default ContestInformation;
