import React, { useState, useEffect } from 'react';
import {
  SearchBasicTable,
  DataTableWidgetNotLazy,
  DialogDelete,
  DialogState,
  IconActionTableDelete,
  IconActionTableEdit,
  IconActionTableDetail,
  IconActionTableState,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget
} from '../../../components';
import { listAbsences } from '../../../graphql/queries/absence';
import { deleteAbsence } from '../../../graphql/mutation/absence';
import { updateStateAbsence } from '../../../graphql/mutation/absence';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  handleActionEditObj,
  handleActionState,
  handleActionDelete,
  concatObjectConfigDatatable
} from '../../../helpers';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/list.scss';
import AbsenceEdit from '../AbsenceEdit';
import { useActions } from '../../../store/actions';

const AbsenceList = () => {
  const { year: yearReducer } = useActions();
  const [
    listAbsencesHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listAbsences, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    deleteAbsenceHandler,
    { loading: loadingDeleteGraph, data: dataDeleteGraph }
  ] = useMutation(deleteAbsence, { errorPolicy: 'all' });

  const [
    stateAbsenceHandler,
    { loading: loadingChangeStateGraph, data: dataChangeStateGraph }
  ] = useMutation(updateStateAbsence, { errorPolicy: 'all' });

  const [absenceDataState, setAbsenceDataState] = useState([]);
  const [onFilterDataState, setOnFilterDataState] = useState([]);
  const [filterTextState, setFilterTextState] = useState('');
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [openDialogStateState, setOpenDialogStateState] = useState(false);

  const [sortState] = useState({ id: 'asc' });

  const [editionState, setEditionState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [rowToEditState, setRowToEditState] = useState('');

  const [modalState, setModalState] = useState({
    openDetail: false,
    detailModal: ''
  });

  useEffect(() => {
    setOnFilterDataState(absenceDataState);
  }, [absenceDataState]);

  useEffect(() => {
    if (dataListGraph && dataListGraph.listAbsences) {
      setAbsenceDataState(dataListGraph.listAbsences);
    }
  }, [dataListGraph]);

  useEffect(() => {
    async function queryData() {
      try {
        await listAbsencesHandler({
          variables: { sort: sortState }
        });
      } catch (err) {}
    }
    queryData();
  }, [listAbsencesHandler, sortState]);

  const searchFunc = async filterText => {
    let filter = {};
    if (filterText !== '') {
      filter = {
        filter: {
          absenceReasonString: {
            contains: filterText
          }
        }
      };
    }
    try {
      await listAbsencesHandler({
        variables: filter
      });
    } catch (err) {}
  };

  const subHeaderComponentMemo = (
    <SearchBasicTable
      resetFunc={() => {
        searchFunc('');
        setFilterTextState('');
      }}
      valuetext={filterTextState}
      placeholder="Buscar motivo"
      filterFunc={() => searchFunc(filterTextState)}
      onFilter={value => setFilterTextState(value)}
    />
  );

  const columnsConfig = [
    {
      name: 'Desde',
      selector: 'since',
      sortable: true
    },
    {
      name: 'Hasta',
      selector: 'until',
      sortable: true
    },
    {
      name: 'Motivo',
      selector: 'absenceReason.name',
      sortable: true
    },
    {
      name: 'Adjunto',
      selector: 'document',
      cell: row =>
        row.absenceReason.document ? (
          <a href={row.document} target="_blank" rel="noopener noreferrer">
            Descargar
          </a>
        ) : null,
      sortable: true
    },
    {
      name: 'Días',
      selector: 'days',
      sortable: true
    },
    {
      name: 'Cant. Alumnos',
      selector: 'absenceDetail',
      cell: row => row.absenceDetail.length,
      sortable: true
    }
  ];

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '190px',
      cell: row => (
        <div className="actionColumn">
          {row.edit && (
            <IconActionTableEdit
              handleActionEdit={() => {
                setRowToEditState(row);
                setEditionState(true);
              }}
            />
          )}
          <IconActionTableState
            rowState={row.state.id}
            handleActionState={() => DialogStateHandler(row)}
          />
          {row.delete && (
            <IconActionTableDelete
              handleActionDelete={() => DialogDeleteHandler(row)}
            />
          )}
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setEditionState(false);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  const deleteActionDialogHandler = async () => {
    try {
      await deleteAbsenceHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(rowToHandleState, absenceDataState);
    setAbsenceDataState(newData);
  };

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = newObject => {
    const newData = handleActionEditObj(newObject, absenceDataState);
    setAbsenceDataState(newData);
  };

  const DialogStateHandler = row => {
    setEditionState(false);
    setRowToHandleState(row);
    setOpenDialogStateState(!openDialogStateState);
  };

  const stateActionDialogHandler = async row => {
    try {
      await stateAbsenceHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionState(row, absenceDataState);
    setAbsenceDataState(newData);
  };

  return (
    <div className="list-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <AbsenceEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card>
              <CardHeaderWidget
                title={`Inasistencias Registradas en año ${yearReducer}`}
                subheader={
                  <BreadCrumbs subTitle="Registro de las inasistencias." />
                }
              />
              <Divider />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <div>
                  <DataTableWidgetNotLazy
                    columns={columns}
                    sortField="name"
                    filteredItems={onFilterDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingListGraph}
                  />
                </div>
                <DialogState
                  openDialog={openDialogStateState}
                  rowState={rowToHandleState}
                  actionState={stateActionDialogHandler}
                  variant="success"
                  loading={loadingChangeStateGraph}
                  success={dataChangeStateGraph}
                  openState={setOpenDialogStateState}
                />
                <DialogDelete
                  openDialog={openDialogDeleteState}
                  rowDelete={rowToHandleState}
                  actionDelete={deleteActionDialogHandler}
                  variant="success"
                  loading={loadingDeleteGraph}
                  success={dataDeleteGraph}
                  openState={setOpenDialogDeleteState}
                />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default AbsenceList;
