import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { getPostulationStudent } from '../../../../graphql/queries/PostulationStudent';
import CardActions from '@material-ui/core/CardActions';
import classes from './CurriculumReport.module.scss';
import CircularProgressComponent from '../../../../components/Progress/CircularProgressComponent';
import { decimalAdjust, printOrder } from '../../../../helpers';
import { BsContainer, BsRow, BsCol } from '../../../../layouts/components';

function useQueryRoute() {
  return new URLSearchParams(useLocation().search);
}

const Cvformat = props => {
  const [userIdSelectedState, setUserIdSelectedState] = useState(null);

  const [dataStudentState, setDataStudentState] = useState('');

  // const profileSelectedState = [3,4];
  const [
    GetPostulationStudentHandler,
    {
      loading: loadingGetPostulationStudentGraph,
      data: dataGetPostulationStudentGraph
    }
  ] = useLazyQuery(getPostulationStudent, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // cuando llega la data del postulante
  useEffect(() => {
    if (
      dataGetPostulationStudentGraph &&
      dataGetPostulationStudentGraph.getPostulationStudent
    ) {
      setDataStudentState(dataGetPostulationStudentGraph.getPostulationStudent);
    }
  }, [dataGetPostulationStudentGraph]);

  // Ejecuta Carga la data del postulante
  useEffect(() => {
    async function queryDataGet(idGet) {
      const filter = {
        id: parseInt(idGet)
      };
      try {
        await GetPostulationStudentHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (userIdSelectedState) {
      queryDataGet(userIdSelectedState);
    } else {
      setDataStudentState([]);
    }
  }, [GetPostulationStudentHandler, userIdSelectedState]);

  let query = useQueryRoute();
  let userIdQuery = null;

  // Cuando le llega un ID por get
  userIdQuery = query.get('idUser');
  useEffect(() => {
    if (userIdQuery) {
      setUserIdSelectedState(userIdQuery);
    }
  }, [userIdQuery]);

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12 mb-4">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <div>
                  {/* <Button size="small" color="primary">
										Excel
									</Button> */}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => printOrder('ifmcontentstoprint')}>
                    Imprimir
                  </Button>
                </div>
              </CardActions>
              <Divider />
              <CardContent className={classes.cardContent}>
                <div>
                  <div
                    id="ifmcontentstoprint"
                    style={{ backgroundColor: 'white' }}>
                    {loadingGetPostulationStudentGraph && (
                      <BsRow class="mt-4">
                        <BsCol class="col-md-12">
                          <CircularProgressComponent />
                        </BsCol>
                      </BsRow>
                    )}
                    {userIdSelectedState && (
                      <React.Fragment>
                        <BsRow class="mt-4">
                          <BsCol class="col-md-3">
                            <div
                              style={{
                                width: '100%',
                                height: '250px',
                                padding: '20px',
                                border: '2px solid #cccccc',
                                boxSizing: 'border-box',
                                backgroundColor: 'white',
                                marginBottom: '30px'
                              }}></div>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" /> Datos
                                    Personales
                                  </td>
                                </tr>
                                <tr>
                                  <td>Fecha Nacimiento</td>
                                  {dataStudentState.birthDate ? (
                                    <td>{dataStudentState.birthDate}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nacionalidad</td>
                                  {dataStudentState.nationality ? (
                                    <td>{dataStudentState.nationality.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Región / Comuna</td>
                                  {dataStudentState.region &&
                                  dataStudentState.commune ? (
                                    <td>
                                      {dataStudentState.region.name} /
                                      {dataStudentState.commune.name}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Dirección</td>
                                  {dataStudentState.address ? (
                                    <td>{dataStudentState.address}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  {dataStudentState.email ? (
                                    <td>{dataStudentState.email}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Teléfono</td>
                                  {dataStudentState.phone ? (
                                    <td>{dataStudentState.phone}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol class="col-md-9">
                            <h3>
                              {dataStudentState.fullname &&
                                dataStudentState.fullname}
                            </h3>
                            <h6 className="mb-5">
                              {dataStudentState.rut && dataStudentState.rut}
                            </h6>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Especialidad
                                  </td>
                                </tr>
                                <tr>
                                  <td>Especialidad</td>
                                  {dataStudentState.specialty ? (
                                    <td>{dataStudentState.specialty.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Concurso</td>
                                  {dataStudentState.competition ? (
                                    <td>{dataStudentState.competition.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Información Formativa
                                  </td>
                                </tr>
                                <tr>
                                  <td>Título Profesional</td>
                                  {dataStudentState.jobTitle ? (
                                    <td>{dataStudentState.jobTitle}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Año Titulación</td>
                                  {dataStudentState.yearTitle ? (
                                    <td>{dataStudentState.yearTitle}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Universidad</td>
                                  {dataStudentState.studentUniversity ? (
                                    <td>
                                      {dataStudentState.studentUniversity.name}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Pediatría</td>
                                  {dataStudentState.noteInternPediatrics ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternPediatrics,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Ginecología</td>
                                  {dataStudentState.noteInternGynecology ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternGynecology,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Cirugía</td>
                                  {dataStudentState.noteInternSurgery ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternSurgery,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Médico Nacional</td>
                                  {dataStudentState.noteNationalMedical ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteNationalMedical,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>EUNACOM</td>
                                  {dataStudentState.eunacomScore ? (
                                    <td>
                                      Nota:{' '}
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.eunacomScore,
                                        -1
                                      )}{' '}
                                      | Año: {dataStudentState.eunacomYear}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nivel Inglés</td>
                                  {dataStudentState.studentLanguageLevel ? (
                                    <td>
                                      {
                                        dataStudentState.studentLanguageLevel
                                          .name
                                      }
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Información de Salud
                                  </td>
                                </tr>
                                <tr>
                                  <td>Inscripción Superintendencia</td>
                                  {dataStudentState.healthSuperintendenceRegistration &&
                                  dataStudentState.healthSuperintendenceRegistration ===
                                    1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.healthSuperintendenceRegistration ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Vacuna contra Influenza</td>
                                  {dataStudentState.influenzaVaccine &&
                                  dataStudentState.influenzaVaccine === 1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.influenzaVaccine ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Vacuna contra Hepatitis B</td>
                                  {dataStudentState.hepatitisbVaccine &&
                                  dataStudentState.hepatitisbVaccine === 1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.hepatitisbVaccine ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>
                                    Póliza de Seguros de Responsabilidad
                                    Profesional
                                  </td>
                                  {dataStudentState.policyProfessionalLiabilityNumber ? (
                                    <td>
                                      {
                                        dataStudentState.policyProfessionalLiabilityNumber
                                      }
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Formación Adicional
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <BsRow class="mt-3">
                              {dataStudentState.postulationCurricularBackground &&
                                dataStudentState.postulationCurricularBackground
                                  .length > 0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Antecedentes Curriculares
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Nombre Estudio</th>
                                          <th>Fecha/Período</th>
                                          <th>Calificación</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationCurricularBackground &&
                                        dataStudentState
                                          .postulationCurricularBackground
                                          .length > 0 ? (
                                          dataStudentState.postulationCurricularBackground.map(
                                            cb => {
                                              return (
                                                <tr key={cb.id}>
                                                  <td>{cb.name}</td>
                                                  <td>{cb.date}</td>
                                                  <td>{cb.qualification}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay antecedentes curriculares.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationPublication &&
                                dataStudentState.postulationPublication.length >
                                  0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Publicaciones Científicas de forma
                                      individual
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Publicación</th>
                                          <th>Autores</th>
                                          <th>Revista</th>
                                          <th>Fecha/Período</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationPublication &&
                                        dataStudentState.postulationPublication
                                          .length > 0 ? (
                                          dataStudentState.postulationPublication.map(
                                            publication => {
                                              return (
                                                <tr key={publication.id}>
                                                  <td>{publication.name}</td>
                                                  <td>{publication.author}</td>
                                                  <td>
                                                    {publication.magazine}
                                                  </td>
                                                  <td>{publication.date}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay publicaciones.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationPresentation &&
                                dataStudentState.postulationPresentation
                                  .length > 0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Presentaciones en Congresos de forma
                                      individual
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Presentación</th>
                                          <th>Autor</th>
                                          <th>Congreso</th>
                                          <th>Fecha/Período</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationPresentation &&
                                        dataStudentState.postulationPresentation
                                          .length > 0 ? (
                                          dataStudentState.postulationPresentation.map(
                                            presentation => {
                                              return (
                                                <tr key={presentation.id}>
                                                  <td>{presentation.name}</td>
                                                  <td>{presentation.author}</td>
                                                  <td>
                                                    {presentation.congress}
                                                  </td>
                                                  <td>{presentation.date}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay presentaciones.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationAssistantShip &&
                                dataStudentState.postulationAssistantShip
                                  .length > 0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Ayudantías a Alumnos
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Asignatura</th>
                                          <th>Universidad</th>
                                          <th>País</th>
                                          <th>Año</th>
                                          <th>Horas</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationAssistantShip &&
                                        dataStudentState
                                          .postulationAssistantShip.length >
                                          0 ? (
                                          dataStudentState.postulationAssistantShip.map(
                                            as => {
                                              return (
                                                <tr key={as.id}>
                                                  <td>{as.course}</td>
                                                  <td>{as.university}</td>
                                                  <td>
                                                    {as.country
                                                      ? as.country.name
                                                      : ''}
                                                  </td>
                                                  <td>{as.year}</td>
                                                  <td>{as.hour}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay ayudantías a alumnos.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationDiplomat &&
                                dataStudentState.postulationDiplomat.length >
                                  0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Diplomados
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Diplomado</th>
                                          <th>Universidad</th>
                                          <th>País</th>
                                          <th>Fecha/Período</th>
                                          <th>Horas</th>
                                          <th>Calificación</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationDiplomat &&
                                        dataStudentState.postulationDiplomat
                                          .length > 0 ? (
                                          dataStudentState.postulationDiplomat.map(
                                            diplomat => {
                                              return (
                                                <tr key={diplomat.id}>
                                                  <td>{diplomat.name}</td>
                                                  <td>{diplomat.university}</td>
                                                  <td>
                                                    {diplomat.country.name}
                                                  </td>
                                                  <td>{diplomat.date}</td>
                                                  <td>{diplomat.hour}</td>
                                                  <td>
                                                    {diplomat.qualification}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay diplomados.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationPerformance &&
                                dataStudentState.postulationPerformance.length >
                                  0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Desempeño Laboral
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Nombre</th>
                                          <th>Lugar</th>
                                          <th>Horas</th>
                                          <th>Fecha/Período</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationPerformance &&
                                        dataStudentState.postulationPerformance
                                          .length > 0 ? (
                                          dataStudentState.postulationPerformance.map(
                                            performance => {
                                              return (
                                                <tr key={performance.id}>
                                                  <td>{performance.name}</td>
                                                  <td>{performance.place}</td>
                                                  <td>{performance.hour}</td>
                                                  <td>{performance.date}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay registros de su desempeño
                                              laboral.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                              {dataStudentState.postulationDistinction &&
                                dataStudentState.postulationDistinction.length >
                                  0 && (
                                  <BsCol
                                    class={clsx(
                                      'col-md-12',
                                      classes.tableRegisters
                                    )}>
                                    <h6>
                                      <ArrowRightIcon fontSize="small" />{' '}
                                      Distinciones
                                    </h6>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Nombre</th>
                                          <th>Institución</th>
                                          <th>Fecha/Período</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dataStudentState.postulationDistinction &&
                                        dataStudentState.postulationDistinction
                                          .length > 0 ? (
                                          dataStudentState.postulationDistinction.map(
                                            distinction => {
                                              return (
                                                <tr key={distinction.id}>
                                                  <td>{distinction.name}</td>
                                                  <td>
                                                    {distinction.institution}
                                                  </td>
                                                  <td>{distinction.date}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={3}>
                                              No hay distinciones.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </BsCol>
                                )}
                            </BsRow>
                          </BsCol>
                        </BsRow>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

// const CurriculumReport = props => {
// 	return(
// 		<PDFViewer width="90%" height="500px">
// 			<PdfMaker />
// 		</PDFViewer>
// 	)
// }

export default withRouter(Cvformat);
