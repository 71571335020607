import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import useForm from 'react-hook-form';

import { recoverPassword } from '../../graphql/queries/recoverPassword';
import { useLazyQuery, resetApolloContext } from '@apollo/react-hooks';
import { Button, Typography, Link, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextFieldWidgetTextBig } from '../../components';
import classes from './RecoveryPassword.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import Snackbar from '@material-ui/core/Snackbar';
import SnackBarsComponent from '../../components/SnackBarsComponent';
import snackBarsMessages from '../../common/snackBarsMessages.json';

const RecoveryPassword = props => {
  const { history } = props;
  const [successState, setSuccessState] = useState(false);
  const { handleSubmit, register, errors, reset } = useForm();
  const snackBarMessage = snackBarsMessages.message['recoverPassword'];

  const [
    recoverPasswordHandler,
    { loading: loadingRecoverPasswordGraph, data: dataRecoverPasswordGraph }
  ] = useLazyQuery(recoverPassword, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (dataRecoverPasswordGraph) {
      setSuccessState(true);
    }
  }, [dataRecoverPasswordGraph]);

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  const onSubmit = async event => {
    const { email } = event;
    try {
      await recoverPasswordHandler({
        variables: { email: email }
      });
      reset();
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class={['col-lg-5 px-md-0', classes.quoteContainer].join(' ')}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Especialidades Médicas
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    UCN | Facultad de Medicina
                  </Typography>
                </div>
              </div>
            </div>
          </BsCol>
          <BsCol class={['col-lg-7', classes.content].join(' ')}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}>
                  <Typography className={classes.title} variant="h2">
                    Recuperar Contraseña
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    Ingrese su email registrado
                  </Typography>
                  <TextFieldWidgetTextBig
                    name="email"
                    className="mt-3"
                    label="E-mail"
                    req
                    register={register}
                    errors={errors.email}
                    autoComplete="email"
                  />
                  <div className={classes.wrapper}>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      fullWidth
                      disabled={loadingRecoverPasswordGraph}
                      size="large"
                      type="submit"
                      variant="contained">
                      Recuperar
                    </Button>
                    {loadingRecoverPasswordGraph && (
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                      />
                    )}
                  </div>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    style={{ marginTop: '10px' }}>
                    Ya tienes una cuenta?{' '}
                    <Link component={RouterLink} to="/sign-in" variant="h6">
                      Ingresar
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successState}
        autoHideDuration={6000}>
        <SnackBarsComponent
          variant="success"
          message={snackBarMessage}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </div>
  );
};

export default withRouter(RecoveryPassword);
