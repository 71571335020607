import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useForm from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Card, CardContent, Divider } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import classes from './CurriculumAdd.module.scss';
import { Context } from '../../../context/Context';
import { useActions } from '../../../store/actions';
import {
  SaveAction,
  TextFieldWidgetSelect,
  CardHeaderWidget,
  BreadCrumbs,
  CurriculumCourseTable
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { listSpecialities } from '../../../graphql/queries/specialty';
import { createAndUpdateCurriculumMesh } from '../../../graphql/mutation/curriculumMesh';
import { listCurriculumMesh } from '../../../graphql/queries/curriculumMesh';
import { CircularProgressComponent } from '../../../components/Progress';

const ApplicationLevelOne = props => {
  const context = useContext(Context);
  const { year: yearReducer } = useActions();

  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [specialtyDataState, setSpecialtyDataState] = useState([]);
  const [curriculumAreaState, setCurriculumAreaState] = useState(false);
  const [blocksDataState, setBlocksDataState] = useState(null);
  const [specialtyPeriodsState, setSpecialtyPeriodsState] = useState(null);

  const { handleSubmit, register, errors } = useForm();

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listCurriculumMeshHandler,
    { loading: loadingCurriculumMeshGraph, data: dataCurriculumMeshGraph }
  ] = useLazyQuery(listCurriculumMesh, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    createAndUpdateCurriculumMeshHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createAndUpdateCurriculumMesh);

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    async function queryData() {
      const filter = {
        filter: {
          specialty: {
            eq: parseInt(specialtySelectedState)
          },
          year: {
            eq: parseInt(yearReducer)
          }
        }
      };
      try {
        await listCurriculumMeshHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (specialtySelectedState) {
      queryData();
    } else {
    }
  }, [listCurriculumMeshHandler, specialtySelectedState]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  useEffect(() => {
    if (specialtySelectedState) {
      setCurriculumAreaState(1);
    } else {
      setCurriculumAreaState(null);
    }
  }, [specialtySelectedState]);

  useEffect(() => {
    if (dataCurriculumMeshGraph && dataCurriculumMeshGraph.listCurriculumMesh) {
      setSpecialtyPeriodsState(
        dataCurriculumMeshGraph.listCurriculumMesh.meshHeader
      );

      const newArrayEdit = dataCurriculumMeshGraph.listCurriculumMesh.rowBlock.map(
        blo => {
          const newPeriods = blo.periods.map(per => {
            const newPer = {
              idAcademicPeriod: per.idAcademicPeriod,
              course: {
                id: parseInt(per.course.id),
                name: per.course.name,
                code: per.course.code
                // __typename: 'CurriculumMeshCourse'
              }
            };
            return newPer;
          });
          // const checkNew = { ...check, assigned: e.target.checked };
          return {
            // ...blo,
            blockNumber: blo.blockNumber,
            periods: newPeriods
          };
        }
      );
      setBlocksDataState(newArrayEdit);
    }
  }, [dataCurriculumMeshGraph]);

  useEffect(() => {
    console.log(dataSubmitGraph, 'llego la data inserción');
  }, [dataSubmitGraph]);

  const handleCourseSelect = (e, block, perId) => {
    const newArrayEdit = blocksDataState.map(blo => {
      if (blo.blockNumber !== parseInt(block)) {
        return blo;
      } else {
        const newPeriods = blo.periods.map(per => {
          if (per.idAcademicPeriod !== perId) {
            return per;
          } else {
            const newPer = {
              idAcademicPeriod: perId,
              course: {
                // ...per.course,
                // ...e
                id: parseInt(e.id),
                name: e.name,
                code: e.code
                // __typename: 'CurriculumMeshCourse'
              }
            };
            return newPer;
          }
        });
        return {
          // ...blo,
          blockNumber: block,
          periods: newPeriods
        };
      }
    });
    setBlocksDataState(newArrayEdit);
  };

  const handleCourseClear = (block, perId) => {
    const newArrayEdit = blocksDataState.map(blo => {
      if (blo.blockNumber !== parseInt(block)) {
        return blo;
      } else {
        const newPeriods = blo.periods.map(per => {
          if (per.idAcademicPeriod !== perId) {
            return per;
          } else {
            const newPer = {
              idAcademicPeriod: perId,
              course: {
                id: null,
                name: null,
                code: null
                // __typename: 'CurriculumMeshCourse'
              }
            };
            return newPer;
          }
        });
        return {
          // ...blo,
          blockNumber: block,
          periods: newPeriods
        };
      }
    });
    setBlocksDataState(newArrayEdit);
  };

  const onSubmit = async values => {
    console.log(values, 'los valores, a guardar-->', blocksDataState);
    console.log('ESPECIALTY ', specialtySelectedState);
    const query = {
      CurriculumMesh: {
        specialty: parseInt(specialtySelectedState),
        year: parseInt(yearReducer),
        rowBlock: blocksDataState
      }
    };

    try {
      await createAndUpdateCurriculumMeshHandler({
        variables: query
      });
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
              </CardActions>
              <CardHeaderWidget
                title="Definir Malla Curricular"
                subheader={
                  <BreadCrumbs subTitle="Módulo para cargar la malla." />
                }
              />
              <Divider />
              <CardContent className={classes.cardContent}>
                <div style={{ minHeight: '40vh' }}>
                  <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                    <BsRow>
                      <BsCol class="col-md-4">
                        <TextFieldWidgetSelect
                          options={specialtyDataState}
                          name="specialty"
                          req={true}
                          selected={specialtySelectedState}
                          onChange={e => setSpecialtySelectedState(e)}
                          loading={loadingSpecialitiesGraph}
                          withDefault={true}
                          InputLabelProps={{ shrink: true }}
                          labeltext="Especialidad"
                          register={register}
                          errors={errors.specialty}
                        />
                      </BsCol>
                    </BsRow>
                    {curriculumAreaState ? (
                      <CurriculumCourseTable
                        resetFunc={handleCourseClear}
                        blocksData={blocksDataState}
                        tableHeaders={specialtyPeriodsState}
                        actionSelect={handleCourseSelect}
                        register={register}
                        errors={errors}
                      />
                    ) : loadingCurriculumMeshGraph ? (
                      <CircularProgressComponent />
                    ) : (
                      ''
                    )}
                    <SaveAction
                      messageType="add"
                      mutationLoading={loadingSubmitGraph}
                      success={dataSubmitGraph}
                    />
                  </form>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(ApplicationLevelOne);
