import gql from 'graphql-tag';

export const sendPostulation = gql`
  mutation(
      $idUser: Int!
      $idPostulationActive: Int!
    ) {
    sendPostulation(idUser: $idUser, idPostulationActive: $idPostulationActive)
  }
`;
