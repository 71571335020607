import gql from 'graphql-tag';

export const listStudentUniversity = gql`
  query {
    listStudentUniversity {
      id
      name
    }
  }
`;
