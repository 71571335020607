import gql from 'graphql-tag';

export const listNotifications = gql`
  query($filter: NotificationFilterInput, $sort: NotificationSortingInput) {
    listNotifications(filter: $filter, sort: $sort) {
      id
      state {
        id
        name
      }
      user {
        id
        email
        rut
        name
        paternalLastname
        maternalLastname
        fullname
      }
      date
      name
      message
    }
  }
`;

export const listNotificationsComplex = gql`
  query($filter: NotificationFilterInput) {
    listNotificationsComplex(filter: $filter) {
      notificationAll {
        id
        state {
          id
          name
        }
        user {
          id
          email
          rut
          name
          paternalLastname
          maternalLastname
          fullname
        }
        date
        name
        message
      }
      notificationRead {
        id
        state {
          id
          name
        }
        user {
          id
          email
          rut
          name
          paternalLastname
          maternalLastname
          fullname
        }
        date
        name
        message
      }
      notificationNotRead {
        id
        state {
          id
          name
        }
        user {
          id
          email
          rut
          name
          paternalLastname
          maternalLastname
          fullname
        }
        date
        name
        message
      }
    }
  }
`;
