import gql from 'graphql-tag';

export const createAndUpdatePostulationHealthInformation = gql`
  mutation(
    $id: Int!
    $healthSuperintendenceRegistration: Int!
    $registerNumber: String!
    $influenzaVaccine: Int!
    $hepatitisbVaccine: Int!
    $doseHepatitisb: Float
    $policyProfessionalLiabilityNumber: String!
    $policyProfessionalLiabilityName: String!
    $documentSuperintendencyRegistrationCertificate: Upload
    $documentHepatitisCertificate: Upload
    $documentSecureAffiliationCertificate: Upload
    $documentInfluenzaCertificate: Upload
    $documentHealthAffiliationCertificate: Upload
    $documentMobilityPassCertificate: Upload
    $idPostulationActive: Int
  ) {
    createAndUpdatePostulationHealthInformation(
      formInput: {
        id: $id
        healthSuperintendenceRegistration: $healthSuperintendenceRegistration
        registerNumber: $registerNumber
        influenzaVaccine: $influenzaVaccine
        hepatitisbVaccine: $hepatitisbVaccine
        doseHepatitisb: $doseHepatitisb
        policyProfessionalLiabilityNumber: $policyProfessionalLiabilityNumber
        policyProfessionalLiabilityName: $policyProfessionalLiabilityName
        documentSuperintendencyRegistrationCertificate: $documentSuperintendencyRegistrationCertificate
        documentHepatitisCertificate: $documentHepatitisCertificate
        documentSecureAffiliationCertificate: $documentSecureAffiliationCertificate
        documentInfluenzaCertificate: $documentInfluenzaCertificate
        documentHealthAffiliationCertificate: $documentHealthAffiliationCertificate
        documentMobilityPassCertificate: $documentMobilityPassCertificate
        idPostulationActive: $idPostulationActive
      }
    )
  }
`;
