import gql from 'graphql-tag';

export const listPostulationStudents = gql`
  query(
    $filter: PostulationStudentFilterInput
    $limit: Int
    $page: Int
    $sort: PostulationStudentSortingInput
    $year: Int
  ) {
    listPostulationStudents(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      year: $year
    ) {
      id
      idUser
      idPostulation
      postulationDate
      rut
      name
      paternalLastname
      maternalLastname
      fullname
      finalNote
      competition {
        id
        name
      }
      specialty {
        id
        name
      }
      state
      statePostulation {
        id
        name
      }
      stateEvaluationPostulation {
        id
        name
      }
      stateInstitutionPostulation {
        id
        name
      }
      stateValidationPostulation {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const listPostulationStudentsCount = gql`
  query($filter: PostulationStudentFilterInput) {
    listPostulationStudentsCount(filter: $filter)
  }
`;
