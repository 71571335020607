import gql from 'graphql-tag';

export const getPostulationDiplomatList = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationDiplomatList(id: $id, idPostulationActive: $idPostulationActive) {
      id
      country {
        id
        name
      }
      name
      hour
      date
      university
      qualification
      certificate
    }
  }
`;
