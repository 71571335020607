import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetTextBig.module.scss';
import { validateRut } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';

const TextFieldWidgetTextBig = props => {
	const { register, errors, req, ...others } = props;
	
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        case 'pattern':
          setStateMessage(errorMessage.message[errors.ref.name].pattern);
          break;
        case 'maxLength':
          setStateMessage(errorMessage.message[errors.ref.name].maxLength);
          break;
        case 'minLength':
          setStateMessage(errorMessage.message[errors.ref.name].minLength);
          break;
        case 'menorQue':
          setStateMessage(errorMessage.message[errors.ref.name].menorQue);
          break;
        case 'mayorQue':
          setStateMessage(errorMessage.message[errors.ref.name].mayorQue);
          break;
        case 'rutValido':
          setStateMessage(errorMessage.message[errors.ref.name].rutValido);
          break;
        case 'matchesPreviousPassword':
          setStateMessage(
            errorMessage.message[errors.ref.name].matchesPreviousPassword
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  //   validaciones
  let pattern = null;
  let validate = null;
  if (props.name === 'email') {
    pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  }

  if (props.name === 'rut') {
    validate = {
      rutValido: value => validateRut(value)
    };
  }

  if (props.name === 'password2') {
    validate = {
      matchesPreviousPassword: value => props.equalto === value
    };
  }

  return (
    <div className={classes.inputContainerStyle}>
      <TextField
        variant="outlined"
        {...others}
        fullWidth
        inputRef={register({
          required: props.req,
          maxLength: props.maxLength,
          minLength: props.minLength,
          pattern: pattern,
          validate: validate
        })}
        error={stateError}
        helperText={stateMessage}
      />
    </div>
  );
};

TextFieldWidgetTextBig.propTypes = {
	name: PropTypes.string.isRequired,
	req: PropTypes.bool,
	label: PropTypes.string.isRequired,
};

export default TextFieldWidgetTextBig;
