import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import classes from './SidebarNav.module.scss';

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const [openState, setOpenState] = useState({
    values: {}
  });

  const handleClick2 = event => {
    setOpenState(openState => ({
      values: {
        ...openState.values,
        [event.target.name]: !openState.values[event.target.name]
      }
    }));
  };

  const handleClick = id => {
    setOpenState(openState => ({
      values: {
        ...!openState.values,
        [id]: !openState.values[id]
      }
    }));
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
			{/* <ListItem
				button
				activeClassName={classes.active}
				className={classes.item}
				key={1000}
				selected={false}
				component={NavLink}
				to="/"
				onClick={() => handleClick(1000)}
				name={1000}>
				<ListItemIcon className={classes.icon}><DashboardIcon /></ListItemIcon>
				<ListItemText primary="Inicio" />
			</ListItem> */}
      {/* <ListItem
				button
				activeClassName={classes.active}
				className={classes.item}
				key={1000}
				selected={false}
				component={NavLink}
				to="/postulation-docs"
				onClick={() => handleClick(1200)}
				name={1000}>
				<ListItemText primary="Inicio222" />
			</ListItem> */}
      {pages.map(page => (
        <Fragment key={page.id + 'fragment'}>
          <ListItem
            button
            activeClassName={classes.active}
            className={classes.item}
            key={page.id}
            component={NavLink}
            to={page.href ? page.href : '#'}
            onClick={() => handleClick(page.id)}
            name={page.id}>
            <ListItemIcon className={classes.icon}>{page.icon}</ListItemIcon>
            <ListItemText primary={page.title} />
            {page.submenu ? (
              openState.values[page.id] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
          {page.submenu && (
            <Collapse
              className={classes.Collapse}
              in={openState.values[page.id]}
              timeout="auto"
              unmountOnExit
              key={page.id + 'submenu'}>
              <List component="div" disablePadding>
                {page.submenu.map(submenu => (
                  <ListItem
                    button
                    activeClassName={classes.active}
                    className={classes.subitem}
                    key={submenu.id}
                    component={NavLink}
                    to={submenu.href}
                    onClick={handleClick2}
                    name={submenu.id}>
                    <ListItemIcon className={classes.icon}>
                      {submenu.icon}
                    </ListItemIcon>
                    <ListItemText primary={submenu.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </Fragment>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
