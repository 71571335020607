import gql from 'graphql-tag';

export const getPostulationHealthInformation = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationHealthInformation(id: $id, idPostulationActive: $idPostulationActive) {
      id
      healthSuperintendenceRegistration
      registerNumber
      influenzaVaccine
      hepatitisbVaccine
      doseHepatitisb
      policyProfessionalLiabilityNumber
      policyProfessionalLiabilityName
      documentSuperintendencyRegistrationCertificateHref
      documentHepatitisCertificateHref
      documentSecureAffiliationCertificateHref
      documentInfluenzaCertificateHref
      documentHealthAffiliationCertificateHref
      documentMobilityPassCertificateHref
    }
  }
`;
