import React, { useState, useEffect } from 'react';
import {
  SearchBasicTable,
  DataTableWidgetNotLazy,
  DialogDelete,
  IconActionTableDelete,
  IconActionTableEdit,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget
} from '../../../components';
import { listAbsenceReasons } from '../../../graphql/queries/absenceReason';
import { deleteAbsenceReason } from '../../../graphql/mutation/absenceReason';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  handleActionEditObj,
  handleActionDelete,
  concatObjectConfigDatatable
} from '../../../helpers';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/list.scss';
import AbsenceReasonEdit from '../AbsenceReasonEdit';
import { useActions } from '../../../store/actions';

const AbsenceReasonList = () => {
  const { year: yearReducer } = useActions();
  const [
    listAbsenceReasonsHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listAbsenceReasons, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    deleteAbsenceReasonHandler,
    { loading: loadingDeleteGraph, data: dataDeleteGraph }
  ] = useMutation(deleteAbsenceReason, { errorPolicy: 'all' });

  const [absenceReasonDataState, setAbsenceReasonDataState] = useState([]);
  const [onFilterDataState, setOnFilterDataState] = useState([]);
  const [filterTextState, setFilterTextState] = useState('');
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);

  const [sortState] = useState({ name: 'asc' });

  const [editionState, setEditionState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [rowToEditState, setRowToEditState] = useState('');

  useEffect(() => {
    setOnFilterDataState(absenceReasonDataState);
  }, [absenceReasonDataState]);

  useEffect(() => {
    if (dataListGraph && dataListGraph.listAbsenceReasons) {
      setAbsenceReasonDataState(dataListGraph.listAbsenceReasons);
    }
  }, [dataListGraph]);

  useEffect(() => {
    async function queryData() {
      try {
        await listAbsenceReasonsHandler({
          variables: { sort: sortState }
        });
      } catch (err) {}
    }
    queryData();
  }, [listAbsenceReasonsHandler, sortState]);

  const searchFunc = async filterText => {
    const filteredItems = absenceReasonDataState.filter(item =>
      item.name.toLowerCase().includes(filterText)
    );
    await setOnFilterDataState(filteredItems);
  };

  /* const filteredItems = absenceReasonDataState.filter(item => {
    console.log(item.name);
    return item.name.toLowerCase().includes(filterTextState);
  });

  console.log(filteredItems); */

  const subHeaderComponentMemo = (
    <SearchBasicTable
      resetFunc={() => {
        searchFunc('');
        setFilterTextState('');
      }}
      valuetext={filterTextState}
      placeholder="Buscar Nombre"
      filterFunc={() => searchFunc(filterTextState)}
      onFilter={value => setFilterTextState(value)}
    />
  );

  const columnsConfig = [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Tiene adjunto',
      selector: 'documentSee',
      sortable: true
    },
    {
      name: 'Afecta inasistencia',
      selector: 'attendanceSee',
      sortable: true
    }
  ];

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '190px',
      cell: row => (
        <div className="actionColumn">
          {row.edit && (
            <IconActionTableEdit
              handleActionEdit={() => {
                setRowToEditState(row);
                setEditionState(true);
              }}
            />
          )}
          {row.delete && (
            <IconActionTableDelete
              handleActionDelete={() => DialogDeleteHandler(row)}
            />
          )}
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setEditionState(false);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  const deleteActionDialogHandler = async () => {
    try {
      await deleteAbsenceReasonHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(
      rowToHandleState,
      absenceReasonDataState
    );
    setAbsenceReasonDataState(newData);
  };

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = newObject => {
    const newData = handleActionEditObj(newObject, absenceReasonDataState);
    setAbsenceReasonDataState(newData);
  };

  return (
    <div className="list-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <AbsenceReasonEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card>
              <CardHeaderWidget
                title={`Listar Motivos de Inasistencia de año ${yearReducer}`}
                subheader={
                  <BreadCrumbs subTitle="Listado de todos los motivos de inasistencia del sistema." />
                }
              />
              <Divider />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <div>
                  <DataTableWidgetNotLazy
                    columns={columns}
                    sortField="name"
                    filteredItems={onFilterDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingListGraph}
                  />
                </div>
                <DialogDelete
                  openDialog={openDialogDeleteState}
                  rowDelete={rowToHandleState}
                  actionDelete={deleteActionDialogHandler}
                  variant="success"
                  loading={loadingDeleteGraph}
                  success={dataDeleteGraph}
                  openState={setOpenDialogDeleteState}
                />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default AbsenceReasonList;
