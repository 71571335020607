import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import {
  Card,
  CardContent,
  Divider,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { listAbsenceReasons } from '../../../graphql/queries/absenceReason';
import { listPostulationStudents } from '../../../graphql/queries/student';
import { listSpecialities } from '../../../graphql/queries/specialty';
import { createAbsence } from '../../../graphql/mutation/absence';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  TextFieldWidgetDateRange,
  BreadCrumbs,
  CheckboxWidget
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';
import errorMessage from '../../../common/errorMessages.json';
import classes from './AbsenceAdd.module.scss';

import { dateFormatIso8601 } from '../../../helpers';

const require = true;

const AbsenceAdd = () => {
  const [reasonDataState, setReasonDataState] = useState([]);
  const [reasonSelectedState, setReasonSelectedState] = useState('');

  const [withDocState, setWithDocState] = useState(false);

  const [beginRangeState, setBeginRangeState] = useState(false);
  const [endRangeState, setEndRangeState] = useState(false);
  const [clearRangeState, setClearRangeState] = useState(false);

  const [limitState] = useState(200);
  const [sortState] = useState({
    paternalLastname: 'asc',
    maternalLastname: 'asc'
  });
  const [pageState] = useState(1);
  const [studentsDataState, setStudentsDataState] = useState([]);
  const [checkboxesStundentsState, setCheckboxesStundentsState] = useState(
    null
  );
  const [specialtyDataState, setSpecialtyDataState] = useState([]);
  const [specialtySelectedState, setSpecialtySelectedState] = useState('');

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    createAbsenceHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createAbsence);

  const {
    loading: loadingAbsenceReasonsGraph,
    data: dataAbsenceReasonsGraph
  } = useQuery(listAbsenceReasons, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listStudentsHandler,
    { loading: loadingListStudentsGraph, data: dataListStudentsGraph }
  ] = useLazyQuery(listPostulationStudents, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  useEffect(() => {
    if (dataAbsenceReasonsGraph && dataAbsenceReasonsGraph.listAbsenceReasons) {
      setReasonDataState(dataAbsenceReasonsGraph.listAbsenceReasons);
    }
  }, [dataAbsenceReasonsGraph]);

  // aca recibe data de los alumnos
  useEffect(() => {
    if (
      dataListStudentsGraph &&
      dataListStudentsGraph.listPostulationStudents
    ) {
      setStudentsDataState(dataListStudentsGraph.listPostulationStudents);
    }
  }, [dataListStudentsGraph]);

  // cada cambio de motivo
  useEffect(() => {
    const reasonSelArray = reasonDataState.filter(
      reson => reson.id === parseInt(reasonSelectedState)
    );

    if (reasonSelArray[0] && reasonSelArray[0].document) {
      setWithDocState(true);
    } else {
      setWithDocState(false);
    }
  }, [reasonSelectedState, reasonDataState]);

  // aquí enviará el filtro para traer alumnos segun especialty
  useEffect(() => {
    async function queryData() {
      const filter = {
        filter: {
          specialty: {
            eq: parseInt(specialtySelectedState)
					},
					statePostulation: {
            eq: 5
          }
        },
        limit: limitState,
        sort: sortState,
        page: pageState
      };
      try {
        await listStudentsHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (specialtySelectedState) {
      queryData(specialtySelectedState);
    } else {
      setCheckboxesStundentsState(null);
    }
  }, [
    listStudentsHandler,
    specialtySelectedState,
    limitState,
    pageState,
    sortState
  ]);

  useEffect(() => {
    if (specialtySelectedState) {
      setCheckboxesStundentsState(studentsDataState);
    }
  }, [specialtySelectedState, studentsDataState]);

  const handleSelectStudents = e => {
    // const newArrayStudents = checkboxesStundentsState.map(check => {
    //   if (check.id !== parseInt(e.target.value)) {
    //     return check;
    //   } else {
    //     const checkNew = { ...check, assigned: e.target.checked };
    //     return checkNew;
    //   }
    // });
    // setCheckboxesStundentsState(newArrayStudents);
  };

  const renderCheckboxes = () => {
    if (!checkboxesStundentsState) {
      return (
        <p style={{ marginTop: '15px' }}>
          Seleccione Especialidad para cargar Alumnos.
        </p>
      );
    } else if (
      checkboxesStundentsState &&
      checkboxesStundentsState.length === 0
    ) {
      return <p style={{ marginTop: '15px' }}>Especialidad sin Alumnos.</p>;
    } else {
      return checkboxesStundentsState.map(aCheckbox => (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidget
            name="absenceUsers"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleSelectStudents}
            // checked={false}
            register={register}
            req={true}
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.fullname}
          </Typography>
        </label>
      ));
    }
  };

  const renderStudents = () => {
    return (
      <div className={classes.inputContainerStyle}>
        <div className={classes.labelDesktopStyle}>Alumnos</div>
        <div>
          <div className={classes.groupLabel}>{renderCheckboxes()}</div>
          {errors && errors.absenceUsers && (
            <FormHelperText className={classes.errorText} error>
              {errorMessage.message['absenceUsers']}
            </FormHelperText>
          )}
        </div>
      </div>
    );
  };

  const renderAttachment = () =>
    withDocState && (
      <TextFieldWidgetText
        name="attached"
				type="file"
				disabled={false}
        labeltext="Documento Adjunto"
        InputLabelProps={{ shrink: true }}
        req={require}
        register={register}
        errors={errors.attached}
      />
    );

  const onSubmit = async values => {
    let beginRange = dateFormatIso8601(beginRangeState);
    let endRange = dateFormatIso8601(endRangeState);
    let arrayStudent = values.absenceUsers;
    if (typeof values.absenceUsers === 'string') {
      arrayStudent = Array.from(values.absenceUsers);
    }
    let documentSend = '';
    if (values.attached) {
      documentSend = values.attached;
    }

    // mapeo para armar objeto students
    const newArrayStudent = arrayStudent.map(student => {
      const newStudents = checkboxesStundentsState.map(dataStu => {
        if (parseInt(student) === parseInt(dataStu.id)) {
          const studentWithAbsence = {
            // idUser: dataStu.idUser,
            postulation: dataStu.idPostulation,
            student: parseInt(student),
            specialty: dataStu.specialty.id
          };
          return studentWithAbsence;
        } else {
          return null;
        }
      });
      return newStudents;
    });
    // elimino un nivel y elementos null
    let cleanArrayStudent1 = _.flatten(newArrayStudent);
    let cleanArrayStudent2 = _.compact(cleanArrayStudent1);

    const query = {
      absenceReason: +values.reason,
      absenceDetail: cleanArrayStudent2,
      document: documentSend,
      days: +values.absenceDays,
      // period: values.period,
      since: beginRange,
      until: endRange
    };
    try {
      await createAbsenceHandler({
        variables: {
          absenceInput: query
        }
      });
    } catch (err) {}
    setReasonSelectedState('');
    setSpecialtySelectedState('');
    setBeginRangeState(false);
    setEndRangeState(false);
    setClearRangeState(true);
    reset();
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Agregar Inasistencia"
                subheader={
                  <BreadCrumbs subTitle="Módulo para ingresar inasistencias de alumnos." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetSelect
                        options={reasonDataState}
                        name="reason"
                        req={require}
                        selected={reasonSelectedState}
                        onChange={e => setReasonSelectedState(e)}
                        loading={loadingAbsenceReasonsGraph}
                        withDefault={true}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Motivo"
                        register={register}
                        errors={errors.reason}
                      />
                      <TextFieldWidgetSelect
                        options={specialtyDataState}
                        name="specialty"
                        req={require}
                        selected={specialtySelectedState}
                        onChange={e => setSpecialtySelectedState(e)}
                        withDefault={true}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Especialidad"
                        register={register}
                        errors={errors.specialty}
                        loading={loadingSpecialitiesGraph}
                      />
                      {renderStudents()}
                      {renderAttachment()}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TextFieldWidgetDateRange
                          value={[]}
                          name="period"
                          emptyLabel={clearRangeState}
                          labeltext="Período Inasistencia"
                          register={register}
                          errors={errors.period}
                          placeholder="Seleccione rango de fechas"
                          format="dd/MM/yyyy"
                          onChange={values => {
                            setBeginRangeState(values.begin);
                            setEndRangeState(values.end);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <TextFieldWidgetText
                        name="absenceDays"
                        type="number"
                        labeltext="Cant. de días académicos de inasistencia"
                        placeholder="1"
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        register={register}
                        autoComplete="days"
                        errors={errors.absenceDays}
                      />
                      {checkboxesStundentsState &&
                      checkboxesStundentsState.length === 0 ? (
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>
                          Sin Alumnos
                        </p>
                      ) : (
                        <SaveAction
                          messageType="add"
                          mutationLoading={loadingSubmitGraph}
                          success={dataSubmitGraph}
                        />
                      )}
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default AbsenceAdd;
