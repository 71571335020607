import React from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import classes from './BreadCrumbs.module.scss';
import breadCrumbsNames from '../../common/breadCrumbsNames.json';

const BreadCrumbs = props => {
  const pathNames = props.match.url.split('/');
  const moduleName = pathNames[1].split('-');

  const pathToList = moduleName[0] + '-list';
  let subModule = null;
  switch (moduleName[1]) {
    case 'add':
      subModule = 'Agregar';
      break;
    case 'edit':
      subModule = 'Editar';
      break;
    case 'privilege':
      subModule = 'Privilegios';
      break;
    case 'docs':
      subModule = 'Documentos Adjuntos';
      break;
    default:
      subModule = 'Listar';
      break;
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        className={classes.BreadCrumbs}
        aria-label="breadcrumb"
        component="div">
        <Link to="/">Home</Link>
        <Link to={pathToList}>
          {breadCrumbsNames.modules[moduleName[0]].name}
        </Link>
        <Typography color="textPrimary">{subModule}</Typography>
      </Breadcrumbs>
      <p className={classes.description}>{props.subTitle}</p>
    </React.Fragment>
  );
};

export default withRouter(BreadCrumbs);
