import React from 'react';
import clsx from 'clsx';
import classes from './PopOverContent.module.scss';

const PopOverContent = props => (
  <ul className={clsx('list-group', 'list-group-flush', classes.listStyle)}>
    {props.notReadNot &&
      props.notReadNot.length > 0 &&
      props.notReadNot.map(notifi => (
        <li className="list-group-item list-group-item-action">
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{notifi.name}</h5>
            <small>{notifi.date}</small>
          </div>
          <p className="mb-1">{notifi.message}</p>
        </li>
      ))}
    {props.readNot &&
      props.readNot.length > 0 &&
      props.readNot.map(notifi => (
        <li
          className={clsx(
            'list-group-item',
            'list-group-item-action',
            'list-group-item-light',
            classes.listInactiveStyle
          )}>
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{notifi.name}</h5>
            <small>{notifi.date}</small>
          </div>
          <p className="mb-1">{notifi.message}</p>
        </li>
      ))}
  </ul>
);

export default PopOverContent;
