import gql from 'graphql-tag';

export const listCountry = gql`
  query {
    listCountry {
      id
      name
    }
  }
`;
