import React, {  useEffect } from 'react';
import useForm from 'react-hook-form';
import {  useMutation } from '@apollo/react-hooks';
import { updateFinalNote } from '../../../graphql/mutation/finalNote';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import '../../../assets/scss/form.scss';
import { decimalAdjust } from '../../../helpers';

const FinalNoteEdit = props => {
	const { idPostulation, fullname, finalNote } = props.rowEdit;

  const pattern = /^\d*$/;
  const require = true;

  const { handleSubmit, register, errors, reset } = useForm();


  const [
    updateFinalNoteHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateFinalNote);


  useEffect(() => {
    reset({
      idPostulation: parseInt(idPostulation),
      finalNote: decimalAdjust('round',parseFloat(finalNote),-1)
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [idPostulation, finalNote]);

  useEffect(() => {
		console.log("llego lo que vuelve-->", dataSubmitGraph);
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateFinalNote) {
        const newObj = dataSubmitGraph.updateFinalNote;
        props.editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async values => {
    const query = {
      idPostulation: parseInt(values.idPostulation),
      finalNote: parseFloat(values.finalNote)
    };
    try {
      await updateFinalNoteHandler({
        variables: query
      });
    } catch (err) {}
  };

  return (
    <Card>
      <CardHeaderWidget title={`Editar Nota de: ${fullname}`} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="idPostulation"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />
              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="finalNote"
                labeltext="Nota Final"
                req={require}
                type="number"
								inputProps={{ step: '.1' }}
								minLength={1}
								maxLength={3}
                register={register}
                errors={errors.finalNote}
              />

              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default FinalNoteEdit;
