import React, { useState, useEffect, useContext } from 'react';
import useForm from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Context } from './../../context/Context';
import { updateUser } from '../../graphql/mutation/users';
import { listUsersProfile, getUser } from '../../graphql/queries/users';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  BreadCrumbs
} from '../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { formatRut, formatOnChangeRut } from '../../helpers';
import '../../assets/scss/form.scss';
import { Redirect } from 'react-router-dom';

const Account = props => {
  const context = useContext(Context);
  const userId = context.userId;
  const userFullname = context.userFullname;
  const require = true;
  const [newFullnameState, setNewFullnameState] = useState(false);
  const [profileListDataState, setProfileListDataState] = useState([]);
  const [userDataState, setuserDataState] = useState({
    id: null,
    email: '',
    rut: '',
    name: '',
    paternalLastname: '',
    maternalLastname: '',
    profile: '',
    phone: ''
  });
  const [
    updateUserHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateUser);

  const { handleSubmit, register, errors, getValues, reset } = useForm();

  const [
    listUsersProfileHandler,
    { loading: loadingProfileGraph, data: dataProfileGraph }
  ] = useLazyQuery(listUsersProfile, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [getUserHandler, { data: dataUserGraph }] = useLazyQuery(getUser, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  

  useEffect(() => {
    async function queryData() {
      let filter = {};
      filter = {
        id: parseInt(userId)
      };
      try {
        await getUserHandler({
          variables: filter
        });
        await listUsersProfileHandler();
      } catch (err) {}
    }
    queryData();
  }, [getUserHandler, listUsersProfileHandler, userId]);

  useEffect(() => {
    if (dataUserGraph && dataUserGraph.getUser) {
      const {
        id,
        email,
        rut,
        name,
        paternalLastname,
        maternalLastname,
        profile,
        phone
      } = dataUserGraph.getUser;

      setuserDataState({
        id,
        email,
        rut,
        name,
        paternalLastname,
        maternalLastname,
        profile,
        phone
      });
    }
  }, [dataUserGraph]);

  useEffect(() => {
    if (dataProfileGraph && dataProfileGraph.listUsersProfile) {
      setProfileListDataState(dataProfileGraph.listUsersProfile);
    }
  }, [dataProfileGraph]);

  useEffect(() => {
    const {
      id,
      email,
      rut,
      name,
      paternalLastname,
      maternalLastname,
      profile,
      phone
    } = userDataState;

    reset({
      id: parseInt(id),
      profile: parseInt(profile.id),
      rut: rut,
      name: name,
      paternalLastname: paternalLastname,
      maternalLastname: maternalLastname,
      email: email,
      phone: phone
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [userDataState, reset]);

  useEffect(() => {
    if (dataSubmitGraph && dataSubmitGraph.updateUser) {
      context.setUserFullname(dataSubmitGraph.updateUser.name);

      setNewFullnameState(true);
    }
  }, [loadingSubmitGraph, dataSubmitGraph, context]);

  const onSubmit = async values => {
    const query = {
      id: parseInt(values.id),
      email: values.email,
      rut: formatRut(values.rut),
      name: values.name,
      paternalLastname: values.paternalLastname,
      maternalLastname: values.maternalLastname,
      phone: values.phone,
      password: values.password,
      specialty: []
    };
    try {
      await updateUserHandler({
        variables: query
      });
    } catch (err) {}
  };

  // TOMA EL VALOR DE PASSWORD
  const { password } = getValues();

  const fullnameAlert = () => {
    if (
      !userFullname ||
      userFullname === 'null' ||
      userFullname.trim() === ''
    ) {
      return (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert">
          <p className="mb-1">
            <strong>Bienvenido a la Plataforma de Postulación.</strong>
          </p>
          <p className="mb-1">
            Le solicitamos complete su información personal, antes de comenzar
            con el proceso de postulación.
          </p>
        </div>
      );
    } else {
      if (newFullnameState) {
        return <Redirect to="/" />;
      }
    }
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {fullnameAlert()}
            <Card>
              <CardHeaderWidget
                title="Editar Datos Personales"
                subheader={
                  <BreadCrumbs subTitle="Módulo para editar datos personales." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetSelect
                        loading={loadingProfileGraph}
                        selected={userDataState.profile.id}
                        options={profileListDataState}
                        name="profile"
                        labeltext="Tipo de Perfil"
                        register={register}
                        errors={errors.profile}
                        disabled
                      />
                      <TextFieldWidgetText
                        InputLabelProps={{ shrink: true }}
                        name="rut"
                        autoComplete="dni"
                        onChange={formatOnChangeRut}
                        labeltext="Rut"
                        req={require}
                        register={register}
                        placeholder="11.111.111-1"
                        errors={errors.rut}
                      />
                      <TextField
                        name="id"
                        inputRef={register}
                        type="number"
                        style={{ display: 'none' }}
                      />
                      <TextFieldWidgetText
                        name="name"
                        labeltext="Nombres"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                        autoComplete="given-name"
                      />
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="paternalLastname"
                            labeltext="Apellido paterno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="family-name"
                            errors={errors.paternalLastname}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="maternalLastname"
                            labeltext="Apellido materno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="family-name"
                            errors={errors.maternalLastname}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="email"
                            labeltext="E-mail"
                            req={require}
                            register={register}
                            errors={errors.email}
                            autoComplete="email"
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="phone"
                            type="number"
                            labeltext="Teléfono"
                            req={require}
                            minLength={8}
                            maxLength={10}
                            register={register}
                            placeholder="56999999999"
                            autoComplete="phone"
                            errors={errors.phone}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="institutionalMail"
                            labeltext="Correo Institucional"
                            req={false}
                            register={register}
                            errors={errors.institutionalMail}
                            autoComplete="institutionalMail"
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          {/* <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="photo"
                            type="file"
                            labeltext="Foto"
                            req={false}
                            minLength={8}
                            maxLength={10}
                            register={register}
                            errors={errors.photo}
                          /> */}
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="password"
                            type="password"
                            minLength={6}
                            maxLength={10}
                            labeltext="Contraseña"
                            register={register}
                            errors={errors.password}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="password2"
                            type="password"
                            equalto={password}
                            minLength={6}
                            maxLength={10}
                            labeltext="Confirmar Contraseña"
                            register={register}
                            errors={errors.password2}
                          />
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="edit"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                        actionClose={props.closeFunc}></SaveAction>
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default Account;
