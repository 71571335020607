import gql from 'graphql-tag';

export const createPostulationPublication = gql`
  mutation(
    $id: Int!
    $name: String!
    $author: String!
    $magazine: String!
    $date: String!
    $idPostulationActive: Int
  ) {
    createPostulationPublication(
      formInput: {
        id: $id
        name: $name
        author: $author
        magazine: $magazine
        date: $date
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      name
      author
      magazine
      date
    }
  }
`;

export const deletePostulationPublication = gql`
  mutation($id: Int!) {
    deletePostulationPublication(id: $id)
  }
`;
