import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import classes from './TextFieldWidgetSelect.module.scss';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';

const TextFieldWidgetSelect = props => {
  const {
    register,
    errors,
    options,
    onChange,
    req,
    loading,
    selected,
    disabled,
    withDefault,
    shortLabel = false,
    ...others
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  // console.log(selected, "------------")

  const [selectedValueState, setSelectedValueState] = useState('');

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  useEffect(() => {
		setSelectedValueState(selected);
  }, [selected]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  // console.log(selectedValueState, "---------selectedValueState")

  //   validaciones
  return (
    <div className={classes.inputContainerStyle}>
      <div className={clsx(
        classes.labelDesktopStyle, 
        shortLabel ? classes.labelDesktopShortStyle : ''
        )}>{props.labeltext}</div>
      {props.loading && <p>cargando</p>}
      <TextField
        variant="outlined"
        {...others}
        select
        margin="dense"
        label={labelText}
        disabled={disabled}
        value={selectedValueState}
        onChange={e => onChange(e.target.value)}
        fullWidth
        SelectProps={{
          native: true
        }}
        inputRef={register({
          required: props.req
        })}
        error={stateError}
        helperText={stateMessage}
        // value={props.selected !== null ? props.selected : '0'}
      >
        {withDefault ? (
          props.selected === 0 ? (
            <option key="0" value="">
              Seleccione...
            </option>
          ) : (
            <option key="0" value="">
              Seleccione...
            </option>
          )
        ) : null}
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </TextField>
    </div>
  );
};

TextFieldWidgetSelect.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  loading: PropTypes.bool
};

export default TextFieldWidgetSelect;
