import gql from 'graphql-tag';

export const getPostulationStateOptionButtons = gql`
  query($id: Int!, $idUserFilter: Int!, $idPostulationActive: Int) {
    getPostulationStateOptionButtons(idUser: $id, idUserFilter: $idUserFilter, idPostulationActive: $idPostulationActive) {
      buttonDocuments
      buttonApprove
      buttonReject
      buttonObservation
      message
      messageApprove
    }
  }
`;

export const getPostulationDocumentApprobation = gql`
  query($idUser: Int!, $idUserFilter: Int!, $idPostulationActive: Int) {
    getPostulationDocumentApprobation(
      idUser: $idUser
      idUserFilter: $idUserFilter
      idPostulationActive: $idPostulationActive
    ) {
      id
      name
      documentHref
      documentLoad
      delete
      edit
    }
  }
`;

export const getPostulationStatePostulation = gql`
  query(
    $idUser: Int!
    $idPostulationActive: Int
    ) {
    getPostulationStatePostulation(
      idUser: $idUser, idPostulationActive: $idPostulationActive
    ) {
      postulationStatePostulation
    }
  }
`;
