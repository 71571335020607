import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createCourse } from '../../../graphql/mutation/course';
import { listCoursesType } from '../../../graphql/queries/course';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  BreadCrumbs
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';

const require = true;

const CourseAdd = () => {
	const [courseTypeDataState, setCourseTypeDataState] = useState([]);
	
	const [typeSelectedState, setTypeSelectedState] = useState('');

  const { handleSubmit, register, errors, reset } = useForm();
  const {
    loading: loadingCourseTypeGraph,
    data: dataCourseTypeGraph
  } = useQuery(listCoursesType, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [
    createCourseHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createCourse);

  useEffect(() => {
    if (dataCourseTypeGraph && dataCourseTypeGraph.listCoursesType) {
      setCourseTypeDataState(dataCourseTypeGraph.listCoursesType);
    }
  }, [dataCourseTypeGraph]);

  const onSubmit = async values => {
    const query = {
      type: +values.type,
      name: values.name,
      code: values.code
    };
    try {
      await createCourseHandler({
        variables: query
			});
			setTypeSelectedState('');
      reset();
    } catch (err) {}
  };

  // toma el valor password

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Agregar Asignatura"
                subheader={
                  <BreadCrumbs subTitle="Módulo para crear Asignatura." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetSelect
                        options={courseTypeDataState}
                        name="type"
                        req={require}
                        loading={loadingCourseTypeGraph}
                        withDefault={true}
												selected={typeSelectedState}
												onChange={setTypeSelectedState}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Tipo de Asignatura"
                        register={register}
                        errors={errors.type}
                      />
                      <BsRow>
                        <BsCol class="col-md-12">
                          <TextFieldWidgetText
                            name="name"
                            labeltext="Nombre"
                            req={require}
                            InputLabelProps={{ shrink: true }}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            errors={errors.name}
                            autoComplete="nombre"
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-12">
                          <TextFieldWidgetText
                            name="code"
                            labeltext="Código"
                            req={require}
                            InputLabelProps={{ shrink: true }}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            errors={errors.code}
                            autoComplete="nombre"
                          />
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default CourseAdd;
