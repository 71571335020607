import React, { useState, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import errorMessage from '../../../common/errorMessages.json';
import classes from './RadioGroupWidget.module.scss';

const RadioGroupWidget = props => {
  const { errors, name, checked, handleradiocheck } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(errorMessage.message.minimum);
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  return (
    <span>
      <RadioGroup
        aria-label={name}
        name={name}
        value={checked}
        onChange={e => handleradiocheck(e.target)}
        classes={{
          root: classes.horizontalStyle
        }}>
        {props.children}
      </RadioGroup>
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
    </span>
  );
};

RadioGroupWidget.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object
};

export default RadioGroupWidget;
