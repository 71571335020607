import React, { useState, useEffect, useContext } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider, InputLabel, FormControl, Select, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Context } from '../../context/Context';
import { Context as ActivePostulationContext } from '../../context/ActivePostulationContext';
import { createAndUpdatePostulationDocument } from '../../graphql/mutation/postulationDocument';
import { listPostulationDocument } from '../../graphql/queries/postulationDocument';
import { getEditPostulation } from '../../graphql/queries/editPostulation';
import { listUsers } from '../../graphql/queries/users';
import { listPostulationSpecialty } from '../../graphql/queries/postulationSpecialty';

import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  DocumentsDetailTable,
  FilterAction,
  AutoCompleteWidget,
  BreadCrumbs
} from '../../components';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import '../../assets/scss/form.scss';
import { listPostulationStudents } from '../../graphql/queries/student';
import { useActions } from 'store/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const require = false;
const PostulationDocsAdd = props => {
  const context = useContext(Context);
  const userId = context.userId;
  const userProfile = context.userProfile;

  const {
		state: { activePostulation },
    changeActive
	} = useContext(ActivePostulationContext);

  const { year: yearReducer } = useActions();

  const [activePostulationState, setActivePostulationState] = useState(1);
// solo para el select Change
  const [activePostulationSelectState, setActivePostulationSelectState] = useState('');

  const [dataActivePostulationsState, setDataActivePostulationsState] = useState([]);

  const [disabledForSecretary, setDisabledForSecretary] = useState(false);

  const classesUI = useStyles();

  // para la secretaria
  const [userSelectedState, setUserSelectedState] = useState(null);
  const [usersDataState, setUsersDataState] = useState([]);

  const [limitState] = useState(200);
  const [sortState] = useState({
    paternalLastname: 'asc',
    maternalLastname: 'asc'
  });

  let userCadidateId = userId;
  if (userProfile === 'Secretaria') {
    userCadidateId = userSelectedState
      ? userSelectedState.idUser
      : userSelectedState;
  }

  // console.log(userCadidateId, "-----------")

  // const { loading: loadingUsersGraph, data: dataUsersGraph } = useQuery(
  //   listUsers,
  //   {
  //     variables: {
  //       filter: {
  //         profile: {
  //           in: [3, 4]
  //         }
  //       },
  //       limit: limitState,
  //       sort: sortState
  //     },
  //     errorPolicy: 'all',
  //     fetchPolicy: 'cache-first'
  //   }
  // );

  let filterStudents = {
    year: { eq: parseInt(yearReducer) },
        // agregar solo enviadas
    statePostulation: { gt: 0 },
    
  }

  const { loading: loadingStudentsGraph, data: dataStudentsGraph } = useQuery(
    listPostulationStudents,
    {
      variables: {
        filter: filterStudents,
        limit: limitState,
        sort: sortState
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  // console.log(userProfile, 'el contexto');
  // console.log(userId, 'el userID');
  // console.log(userCadidateId, 'el candidate');
  // console.log(userSelectedState, 'el state seleccionado');

  const [documentsState, setDocumentsState] = useState('');
  const [values, setValues] = useState(false);

  const { handleSubmit, register, errors, reset } = useForm();

  // const { data: getEditPostulationGraph } = useQuery(getEditPostulation, {
  //   variables: { idUser: parseInt(userId) },
  //   errorPolicy: 'all',
  //   fetchPolicy: 'cache-first'
  // });

  const [
    getEditPostulationHandler,
    { loading: loadingGetEditPostulationGraph, data: getEditPostulationGraph }
  ] = useLazyQuery(getEditPostulation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });


  // nuevo get edit con postulation
  useEffect(() => {
    console.log("<-----ejecuta dentro del get edit")
    async function queryData() {
      try {
        await getEditPostulationHandler({
          variables: { idUser: parseInt(userId), idPostulationActive: activePostulationState !== '0' ? parseInt(activePostulationState) : null  }
        });
      } catch (err) {}
    }
    if (userCadidateId) {
      // console.log("-------------------> se ejecuta getedit, idUser: ", parseInt(userCadidateId), "------Z postulationActive: ", parseInt(activePostulationState) )
      queryData();
    }
    
  }, [userId, activePostulationState]);

  useEffect(() => {
    async function queryData(userId, idPost) {
      try {
        await getEditPostulationHandler({
          variables: { idUser: parseInt(userId), idPostulationActive: idPost  }
        });
      } catch (err) {
        console.log(err)
      }
    }
    console.log(activePostulationState, "<------activePostulationState")
    if(userProfile === 'Secretaria' && userSelectedState){
      console.log(userSelectedState, "----para hacer getedit----")
      setDisabledForSecretary(false);
      queryData(userSelectedState.idUser, userSelectedState.idPostulation);
    }
    if(userProfile === 'Secretaria' && !userSelectedState) {
      setDisabledForSecretary(true);
    }
  }, [userSelectedState]);

  const [
    listPostulationSpecialtyHandler,
    { data: dataListPostulationSpecialtyGraph, loading: loadingdataListPostulationSpecialtyGraph, }
  ] = useLazyQuery(listPostulationSpecialty, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!userSelectedState && userProfile === 'Secretaria') {
      setDocumentsState('');
    }
  }, [userSelectedState, documentsState, userProfile]);

  // Cuando recibe el listado de postulantes
  // useEffect(() => {
  //   if (dataUsersGraph && dataUsersGraph.listUsers) {
  //     const usersFiltered = dataUsersGraph.listUsers.filter(
  //       student => !student.delete
  //     );
  //     // delete false filtrar array

  //     setUsersDataState(usersFiltered);
  //   }
  // }, [dataUsersGraph]);

  // solo carga postulantes con postulación
  useEffect(() => {
    if (dataStudentsGraph && dataStudentsGraph.listPostulationStudents) {

      setUsersDataState(dataStudentsGraph.listPostulationStudents);
    }
  }, [dataStudentsGraph]);

  let disableForm = !Boolean(getEditPostulationGraph);



  useEffect(() => {
    if (getEditPostulationGraph) {
      console.log('------>edit, ', disableForm, "-----", getEditPostulationGraph);
    }
  }, [getEditPostulationGraph]);

  const [
    listPostulationDocumentHandler,
    {
      loading: loadingListPostulationDocumentGraph,
      data: dataListPostulationDocumentGraph
    }
  ] = useLazyQuery(listPostulationDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // este ue effect tiene que ser el user del select cuando es secretaria

  useEffect(() => {
    async function queryData() {
      try {
        await listPostulationDocumentHandler({
          variables: {
            id: parseInt(userCadidateId),
            idPostulationActive: activePostulation.idPostulationActive ? parseInt(activePostulation.idPostulationActive) : userSelectedState.idPostulation
          }
        });
      } catch (err) {}
    }
    if (values) {
      console.log("--------------AFTER---->", activePostulation, "----values", values)
      queryData();
      setValues(false);
    } else {
      if (userCadidateId && activePostulation.idPostulationActive) {
        queryData();
      }
    }
  }, [listPostulationDocumentHandler, values, userCadidateId, activePostulation]);

  useEffect(() => {
    async function queryData(activePostulation) {
      try {
        await listPostulationDocumentHandler({
          variables: {
            id: parseInt(userCadidateId),
            idPostulationActive: activePostulation
          }
        });
      } catch (err) {}
    }
    if(userProfile === 'Secretaria' && userSelectedState){
      queryData(userSelectedState.idPostulation);
    }
    
  }, [userSelectedState]);

  useEffect(() => {
    console.log(dataListPostulationDocumentGraph, " el graph de los docs");
    if (
      dataListPostulationDocumentGraph &&
      dataListPostulationDocumentGraph.listPostulationDocument
    ) {
      
      setDocumentsState(
        dataListPostulationDocumentGraph.listPostulationDocument
      );
    }
  }, [dataListPostulationDocumentGraph]);

  const [
    createAndUpdatePostulationDocumentHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createAndUpdatePostulationDocument);

  const onSubmit = async values => {

    console.log(activePostulation,"<------activePostulation", userCadidateId)

    const query = {
      idUser: parseInt(userCadidateId),
      documentNationalMedicalQualification:
        values.documentNationalMedicalRating,
      documentProfessionCertificate: values.certificateOfTitle,
      documentPresentationLetter: values.presentationLetter,
      documentSecureAffiliationCertificate: values.secureAffiliationCertificate,
      documentSimpleCopyCNI: values.CNIsimpleCopy,
      documentUpdatedResume: values.updatedCurriculumVitae,
      documentEunacomCertificate: values.eunacomAproved,
      documentHepatitisCertificate: values.hepatitisCertificate,
      documentSuperintendencyRegistrationCertificate:
        values.superintendencyRegistrationCertificate,
      documentProfilePhoto: values.profilePhoto,
      documentRecommendationLetter: values.recommendationLetter,
      documentInfluenzaCertificate: values.influenzaCertificate,
      documentHealthAffiliationCertificate: values.healthAffiliationCertificate,
      documentMobilityPassCertificate: values.mobilityPassCertificate,
      documentBirthCertificate: values.birthCertificate,
      documentDefinitiveResidence: values.residence,
      idPostulationActive: activePostulation.idPostulationActive ? parseInt(activePostulation.idPostulationActive) : userSelectedState.idPostulation
    };
    try {
      await createAndUpdatePostulationDocumentHandler({
        variables: query
      });
      setValues(true);
      reset();
      setDocumentsState(
        dataListPostulationDocumentGraph.listPostulationDocument
      );
    } catch (err) {}
  };

  // useEffect(() => {
  //   if (disableForm && disableForm === false) {
  //     const elems = document.getElementsByClassName('EditPostulation');
  //     for (let i = 0; i < elems.length; i += 1) {
  //       elems[i].style.display = 'none';
  //     }
  //   }
  // });

  // selector de postulante para secretaria
  const getCandidateFilter = () => {
    if (userProfile !== 'Secretaria') {
      return null;
    }

     const onClear = () => {
      console.log("------")
    }

    return (
      <BsCol class="col-md-10">
        <BsRow>
          <BsCol class="col-md-7">
            <AutoCompleteWidget
              label="Postulante / Alumno"
              require={true}
              data={usersDataState}
              dataOptionLabel="fullname"
              inicialValue="Seleccione Perfil..."
              valuecombo={userSelectedState}
              checkId
              onClear={onClear}
              loadingComboState={loadingStudentsGraph}
              onSelectState={setUserSelectedState}
              name="usercombo"
              register={register}
              errors={errors.usercombo}
            />
          </BsCol>
          {/* <BsCol class="col-md-5">
            <FilterAction mutationLoading={false} success={false} />
          </BsCol> */}
        </BsRow>
      </BsCol>
    );
  };


  const handleChangePostulation = (e) => {
    let post = e.target.value;
    console.log(post, "<---------onchange")
    // setActivePostulationState(post);
    setActivePostulationSelectState(post);
  }

  const postulationHandler = () => {
    console.log("seleccionó-------------->", activePostulationSelectState)
    let activeValue = activePostulationSelectState;
    changeActive(activeValue);
    // necesario para recargar en cuaqluier pestaña
    // window.location.reload(false)
  }

  useEffect(() => {
    // para traer postulaciones activas
    async function queryData() {
      try {
        await listPostulationSpecialtyHandler({
          variables: { id: parseInt(userCadidateId) }
        });
      } catch (err) {}
    }
    if (userCadidateId) {
      queryData();
    }
  }, []);

  useEffect(() => {
    if (
      dataListPostulationSpecialtyGraph &&
      dataListPostulationSpecialtyGraph.listPostulationSpecialty
    ) {
      // console.log("-------------", dataListPostulationSpecialtyGraph.listPostulationSpecialty)
      setDataActivePostulationsState(dataListPostulationSpecialtyGraph.listPostulationSpecialty);
      if(dataListPostulationSpecialtyGraph.listPostulationSpecialty.length){
        setActivePostulationSelectState(dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].idPostulation);
      }
    }
  }, [dataListPostulationSpecialtyGraph]);

  // cuando se setea el context, selecciona el select
  useEffect(() => {
    setActivePostulationState(activePostulation.idPostulationActive ? activePostulation.idPostulationActive : '');
    // console.log("..............>", activePostulation.idPostulationActive)
  }, [activePostulation]);



  // selector de postulación para postulante
  const PostulationSelector = () => {
    if (userProfile !== 'Postulante') {
      return null;
    }
    // console.log("-------------->dataActivePostulationsState", dataActivePostulationsState, activePostulationState)
    return (
       <BsContainer  style={{backgroundColor: 'rgba(98,134,165, 0.1)', borderRadius: 20, paddingTop: 10, paddingBottom: 5}}>
        <BsRow class="justify-content-md-center align-items-center mt-2">
          <BsCol class="col-md-12 text-center">
            <Typography variant="h5" gutterBottom>
              Aquí selecciona la postulación activa
            </Typography>
          </BsCol>
          {!loadingdataListPostulationSpecialtyGraph && (
            <BsCol class="col-md-7 text-center">
              <FormControl className={classesUI.formControl}>
                <InputLabel htmlFor="postulation-native-simple">Postulación</InputLabel>
                <Select
                  native
                  value={activePostulationSelectState}
                  onChange={handleChangePostulation}
                  inputProps={{
                    name: 'postulation',
                    id: 'postulation-native-simple',
                  }}
                >
                  {
                    dataActivePostulationsState.length && dataActivePostulationsState.map((postulation, index) => (
                      <option key={index} value={postulation.idPostulation}>Post. {index + 1} ({postulation.nameCompetition}/{postulation.nameSpecialty})</option>
                    ))
                  }
                  {/* {
                    dataActivePostulationsState.length < 2 && (
                      <option value="0">CREAR NUEVA</option>
                    )
                  } */}
                  {/* <option value="435">Postulación 1 (Concurso/Especialidad)</option>
                  <option value="438">Postulación 2/Si no hay dirá NUEVA</option> */}
                </Select>
              </FormControl>
            </BsCol>
          )}
          <BsCol class="col-md-2">
            <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={postulationHandler}
            disabled={loadingdataListPostulationSpecialtyGraph}>
              Seleccionar
            </Button>
          </BsCol>
        </BsRow>
       </BsContainer>
    )
  }

  // console.log("-------------postulación activa de postulante", activePostulation)

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Documentos Postulación"
                subheader={
                  <BreadCrumbs subTitle="Modifique e ingrese los documentos de la postulación seleccionada." />
                }
              />
              <Divider />
              {userProfile !== 'Secretaria' && (<PostulationSelector />)}
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <BsRow>
                        {getCandidateFilter()}
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="documentNationalMedicalRating"
                            labeltext="Calificación Médica Nacional"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.documentNationalMedicalRating}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="presentationLetter"
                            labeltext="Carta de Presentación"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.presentationLetter}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="recommendationLetter"
                            labeltext="Carta de Recomendación"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.recommendationLetter}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="mobilityPassCertificate"
                            labeltext="Pase de Movilidad"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.mobilityPassCertificate}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="secureAffiliationCertificate"
                            labeltext="Certificado Afiliación Seguro"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.secureAffiliationCertificate}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="healthAffiliationCertificate"
                            labeltext="Certificado Afiliación Isapre / Fonasa"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.healthAffiliationCertificate}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="hepatitisCertificate"
                            labeltext="Certificado Vacuna Hepatitis"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.hepatitisCertificate}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="influenzaCertificate"
                            labeltext="Certificado Vacuna Influenza"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.influenzaCertificate}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="superintendencyRegistrationCertificate"
                            labeltext="Certificado Inscripción Registro Prestadores"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={
                              errors.superintendencyRegistrationCertificate
                            }
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="eunacomAproved"
                            labeltext="Certificado EUNACOM"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.eunacomAproved}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="CNIsimpleCopy"
                            labeltext="Copia Simple CNI"
                            type="file"
                            disabled={disableForm || disabledForSecretary}
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.CNIsimpleCopy}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="updatedCurriculumVitae"
                            labeltext="Curriculum Vitae Actualizado"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            req={require}
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.updatedCurriculumVitae}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="certificateOfTitle"
                            labeltext="Certificado de título"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.certificateOfTitle}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="profilePhoto"
                            labeltext="Foto de Perfil"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.profilePhoto}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="birthCertificate"
                            labeltext="Certificado de Nacimiento"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.birthCertificate}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="residence"
                            labeltext="Residencia Definitiva"
                            disabled={disableForm || disabledForSecretary}
                            type="file"
                            InputLabelProps={{
                              shrink: true
                            }}
                            register={register}
                            errors={errors.residence}
                          />
                        </BsCol>
                      </BsRow>
                      <DocumentsDetailTable
                        documents={documentsState}
                        loading={loadingListPostulationDocumentGraph}
                      />
                      <div className="EditPostulation">
                        <SaveAction
                          messageType="add"
                          mutationLoading={loadingSubmitGraph}
                          success={dataSubmitGraph}
                        />
                      </div>
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default PostulationDocsAdd;
