import gql from 'graphql-tag';

export const createAndUpdatePostulationSpecialty = gql`
  mutation(
		$id: Int!
		$idCompetition: Int!
  	$idSpecialty: Int!
    $idPostulationActive: Int
){
  createAndUpdatePostulationSpecialty(
    formInput:{
      id: $id
      idCompetition: $idCompetition
      idSpecialty: $idSpecialty
      idPostulationActive: $idPostulationActive
    }
  )
}
`;