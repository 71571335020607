import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Divider } from '@material-ui/core';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText
} from '../../../../components';
import { BsRow, BsCol } from '../../../../layouts/components';
import classes from './MeetingStudent.module.scss';
import '../../../../assets/scss/form.scss';

const MeetingStudent = React.memo(props => {
  console.log('las props en Update', props);
  const [titleAreaState, setTitleAreaState] = useState('');
  const [typeFormState, setTypeFormState] = useState(null);

  const require = true;

  const {
    actionStage,
    closeFunc,
    editFunc,
    actionAfterSuccess,
    loadingSubmitPostulationMeeting,
    successSubmitPostulationMeeting,
    meetingTime,
    meetingDate
  } = props;

  const { handleSubmit, register, errors, reset } = useForm();

  useEffect(() => {
    if (actionStage === 1) {
      setTitleAreaState('Fecha reunión');
      setTypeFormState(actionStage);
    }
  }, [actionStage]);

  useEffect(() => {
    reset({
      meetingDate: meetingDate,
      meetingTime: meetingTime
    });
  }, [meetingDate, meetingTime, reset]);

  const renderFormType = type => {
    if (type === 1) {
      return (
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <BsRow ml={5}>
            <BsCol class="col-md-6">
              <TextFieldWidgetText
                name="meetingDate"
                labeltext="Fecha"
                type="text"
                disabled={false}
                req={require}
                InputLabelProps={{ shrink: true }}
                register={register}
                errors={errors.meetingDate}
                placeholder="00-00-0000"
              />
            </BsCol>
            <BsCol class="col-md-6">
              <TextFieldWidgetText
                name="meetingTime"
                labeltext="Hora"
                type="text"
                disabled={false}
                req={require}
                InputLabelProps={{ shrink: true }}
                register={register}
                errors={errors.meetingTime}
                placeholder="00:00"
              />
            </BsCol>
          </BsRow>
          <div className="EditPostulation">
            <SaveAction
              messageType="add"
              mutationLoading={loadingSubmitPostulationMeeting}
              success={successSubmitPostulationMeeting}
              actionClose={actionAfterSuccess}
            />
          </div>
        </form>
      );
    }
  };

  const onSubmit = async values => {
    console.log(values, 'enviado en el componente');
    await editFunc(values);
  };

  return (
    <div className={classes.StageContainer}>
      <CardHeaderWidget title={titleAreaState} />
      <IconButton
        className={classes.CloseButton}
        size="medium"
        onClick={closeFunc}
        aria-label="cerrar"
        component="span">
        <CloseIcon fontSize="small" />
      </IconButton>
      <Divider />
      <div>
        <BsRow>
          <BsCol class="col-md-6">{renderFormType(typeFormState)}</BsCol>
        </BsRow>
      </div>
    </div>
  );
});

export default MeetingStudent;
