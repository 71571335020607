import gql from 'graphql-tag';

export const createAndUpdatePostulationPersonalData = gql`
  mutation(
    $id: Int!
    $rut: String!
    $name: String!
    $paternalLastname: String!
    $maternalLastname: String!
    $birthDate: String!
    $address: String!
    $email: String!
    $phone: String!
    $commune: Int!
    $nationality: Int!
    $documentSimpleCopyCNI: Upload
    $documentUpdatedResume: Upload
    $documentPresentationLetter: Upload
    $documentProfilePhoto: Upload
    $documentRecommendationLetter: Upload
    $documentBirthCertificate: Upload
    $documentDefinitiveResidence: Upload
    $idPostulationActive: Int
  ) {
    createAndUpdatePostulationPersonalData(
      formInput: {
        id: $id
        rut: $rut
        name: $name
        paternalLastname: $paternalLastname
        maternalLastname: $maternalLastname
        birthDate: $birthDate
        address: $address
        email: $email
        phone: $phone
        commune: $commune
        nationality: $nationality
        documentSimpleCopyCNI: $documentSimpleCopyCNI
        documentUpdatedResume: $documentUpdatedResume
        documentPresentationLetter: $documentPresentationLetter
        documentProfilePhoto: $documentProfilePhoto
        documentRecommendationLetter: $documentRecommendationLetter
        documentBirthCertificate: $documentBirthCertificate
        documentDefinitiveResidence: $documentDefinitiveResidence
        idPostulationActive: $idPostulationActive
      }
    )
  }
`;
