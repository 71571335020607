import gql from 'graphql-tag';

export const updateApprobationAndRejectPostulationState = gql`
  mutation(
    $idUser: Int!
    $idUserFilter: Int!
    $option: OptionsChangeState!
    $message: String
    $idPostulationActive: Int
  ) {
    updateApprobationAndRejectPostulationState(
      idUser: $idUser
      idUserFilter: $idUserFilter
      option: $option
      message: $message
      idPostulationActive: $idPostulationActive
    )
  }
`;

export const createAndUpdatePostulationDocumentApprobation = gql`
  mutation($idUser: Int!, $idUserFilter: Int!, $documentRequired: Upload!, $documentType: Int!, $idPostulationActive: Int) {
    createAndUpdatePostulationDocumentApprobation(
      postulationDocumentInput: {
        idUser: $idUser
        idUserFilter: $idUserFilter
        documentRequired: $documentRequired
        documentType: $documentType
        idPostulationActive: $idPostulationActive
      }
    )
  }
`;

export const deletePostulationDocumentApprobation = gql`
  mutation($idUser: Int!) {
    deletePostulationDocumentApprobation(idUser: $idUser)
  }
`;
