import gql from 'graphql-tag';

export const listParameter = gql`
  query {
    listParameter {
      id
      name
      nameInput
      value
      type
    }
  }
`;

export const getParameter = gql`
  query($id: Int!) {
    getParameter(id: $id) {
      id
      name
      nameInput
      value
      type
    }
  }
`;
