import gql from 'graphql-tag';

export const createPostulationAssistantShip = gql`
  mutation(
    $id: Int!
    $idCountry: Int!
    $course: String!
    $hour: String!
    $year: String!
    $university: String!
    $certificate: Upload
    $idPostulationActive: Int
  ) {
    createPostulationAssistantShip(
      formInput: {
        id: $id
        idCountry: $idCountry
        course: $course
        hour: $hour
        year: $year
        university: $university
        certificate: $certificate
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      country {
        id
        name
      }
      course
      hour
      year
      university
      certificate
    }
  }
`;

export const deletePostulationAssistantShip = gql`
  mutation($id: Int!) {
    deletePostulationAssistantShip(id: $id)
  }
`;
