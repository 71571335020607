import createDataContext from './createDataPersistContext';

const activePostulation = {
	idPostulationActive: null,
	nameCompetition: null,
	nameSpecialty: null,
};

const postulationReducer = (state, action) => {
	switch (action.type) {
		case 'add_feature':
			let newObj = {};

			Object.entries(action.payload).forEach(([key, value]) => {
				return (newObj = {
					...newObj,
					[key]: value,
				});
			});

			return {
				activePostulation: {
					...state.activePostulation,
					...newObj,
				},
			};

		case 'clear_postulation':
			return {
				activePostulation,
			};

		case 'change_active':
			return {
				activePostulation: {
					idPostulationActive: action.payload,
				},
			};

		default:
			return state;
	}
};

const addFeature = (dispatch) => {
	return async (features, callback) => {
		console.log(features, 'asi llega el feature');
		await dispatch({ type: 'add_feature', payload: features });
		if (callback) {
			console.log('se ejecuta el callback');
			callback();
		}
	};
};

const changeActive = (dispatch) => {
	return (id, callback) => {
		dispatch({
			type: 'change_active',
			payload: id,
		});
		if (callback) {
			callback();
		}
	};
};


const clearPostulation = (dispatch) => {
	return (features, callback) => {
		dispatch({
			type: 'clear_postulation',
		});
		if (callback) {
			callback();
		}
	};
};

export const { Provider, Context } = createDataContext(
	postulationReducer,
	{ addFeature, clearPostulation, changeActive },
	{ activePostulation },
	'@activePostulation'
);
