import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Avatar } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import BlockIcon from '@material-ui/icons/Block';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import classes from './KpiBox.module.scss';

const KpiBox = props => {
  const { idState, value, title, type, ...rest } = props;

  let icon = null;
  let colorName = null;
  if (type === 1) {
    switch (idState) {
      case 0:
        icon = <AssignmentIndIcon className={classes.icon} />;
        // titleKpi = 'Registrados';
        colorName = 'cyan';
        break;
      case 100:
        icon = <PeopleIcon className={classes.icon} />;
        // titleKpi = 'Total Postulaciones';
        colorName = 'blue';
        break;
      case 1:
        icon = <HowToRegIcon className={classes.icon} />;
        // titleKpi = 'Aprobadas';
        colorName = 'green';
        break;
      case 2:
        icon = <AssignmentLateIcon className={classes.icon} />;
        // titleKpi = 'Pendientes Revisión';
        colorName = 'orange';
        break;
      case 3:
        icon = <HowToRegIcon className={classes.icon} />;
        // titleKpi = 'Aprobadas';
        colorName = 'green';
        break;
      case 4:
        icon = <BlockIcon className={classes.icon} />;
        // titleKpi = 'Rechazadas';
        colorName = 'red';
        break;
      case 5:
        icon = <AccountBalanceIcon className={classes.icon} />;
        // titleKpi = 'Alumnos';
        colorName = 'blue';
        break;
      default:
        break;
    }
  }

  if (type === 2) {
    switch (idState) {
      case 0:
        icon = <AssignmentIndIcon className={classes.icon} />;
        // titleKpi = 'Registrados';
        colorName = 'cyan';
        break;
      case 100:
        icon = <PeopleIcon className={classes.icon} />;
        // titleKpi = 'Total Postulaciones';
        colorName = 'blue';
        break;
      case 1:
        icon = <HowToRegIcon className={classes.icon} />;
        // titleKpi = 'Aprobadas';
        colorName = 'green';
        break;
      case 2:
        icon = <HowToRegIcon className={classes.icon} />;
        // titleKpi = 'Aprobadas';
        colorName = 'green';
        break;
      case 3:
        icon = <BlockIcon className={classes.icon} />;
        // titleKpi = 'Rechazadas';
        colorName = 'red';
        break;
      case 4:
        icon = <BlockIcon className={classes.icon} />;
        // titleKpi = 'Rechazadas';
        colorName = 'red';
        break;
      case 5:
        icon = <AssignmentLateIcon className={classes.icon} />;
        // titleKpi = 'Alumnos';
        colorName = 'orange';
        break;
      case 6:
        icon = <AssignmentLateIcon className={classes.icon} />;
        // titleKpi = 'Alumnos';
        colorName = 'orange';
        break;
      default:
        break;
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, classes[colorName])}>
      <CardContent>
        <div className={classes.container}>
          <div className={classes.item}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2">
              {title}
            </Typography>
            <Typography variant="h3">{value}</Typography>
          </div>
          <div className={classes.item}>
            <Avatar className={classes.avatar}>{icon}</Avatar>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

KpiBox.propTypes = {
  idState: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
};

export default KpiBox;
