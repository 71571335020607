import gql from 'graphql-tag';

export const getPostulationSpecialty = gql`
query(
		$id: Int!
    $idPostulationActive: Int
){
  getPostulationSpecialty(id: $id, idPostulationActive: $idPostulationActive){
		id
    competition{
      id
      name
      code
      since
      until
      description
      state{
        id
        name
      }
      delete
      edit
      
    }
    specialty{
      id
      user{
        id
        rut
        name
        paternalLastname
        maternalLastname
        fullname
      }
      name
      code
      description
      state{
        id
        name
      }
      delete
      edit
    }
  }
}
`;

export const listPostulationSpecialty = gql`
query(
		$id: Int!
){
  listPostulationSpecialty(id: $id){
		idPostulation 
    idPostulationSpecialty 
    nameSpecialty 
    idPostulationCompetition 
    nameCompetition
  }
}
`;