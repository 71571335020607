import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import classes from './Topbar.module.scss';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { listCompetitionsComplex } from '../../../../graphql/queries/competition';
import { listNotificationsComplex } from '../../../../graphql/queries/notification';
import { updateNotification } from '../../../../graphql/mutation/notification';
import { listYears } from '../../../../graphql/queries/year';

// import './SelectStyle.scss';
import PopOverContent from './PopOver/PopOverContent';
import { Context } from '../../../../context/Context';
import { Context as ActivePostulationContext } from '../../../../context/ActivePostulationContext';
import { useActions } from 'store/actions';

const Topbar = props => {
  const {
    className,
    onSidebarOpen,
    onChangeYear,
    yearSelected,
    ...rest
  } = props;

  const context = useContext(Context);
  const userIdProfile = context.userIdProfile;
  const userId = context.userId;

  const { changeYear, changeCompetition, year: yearReducer, competition: competitionReducer } = useActions();

  const {
		clearPostulation
	} = useContext(ActivePostulationContext);

  const [loadingFiltersTopState, setLoadingFiltersTopState] = useState(true);

  const { loading: loadingListYearsGraph, data: dataListYearsGraph } = useQuery(
    listYears,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const {
    loading: loadingListCompetitionsComplexGraph,
    data: dataListCompetitionsComplexGraph
  } = useQuery(listCompetitionsComplex, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listNotificationsComplexHandler,
    { data: dataListNotificationsComplexGraph }
  ] = useLazyQuery(listNotificationsComplex, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    competitionsComplexDataState,
    setCompetitionsComplexDataState
  ] = useState([]);

  const [yearsState, setYearsState] = useState([]);

  const [competition, setCompetition] = useState(competitionReducer);

  useEffect(() => {
    if (dataListYearsGraph && dataListYearsGraph.listYears) {
      setYearsState(dataListYearsGraph.listYears);
    }
  }, [dataListYearsGraph]);

  useEffect(() => {
    if (yearsState && yearsState.length > 0) {
      yearsState.map(yearDefault => {
        if (yearDefault.defaultValue === true) {
          changeYear(yearDefault.year);
        }
      });
    }
  }, [yearsState]);

  const [
    updateNotificationHandler,
    { data: dataSubmitGraphUpdateNotification }
  ] = useMutation(updateNotification);

  useEffect(() => {
    if (competitionsComplexDataState.length > 0) {
      changeCompetition(competitionsComplexDataState[0].id);
    }
  }, [competitionsComplexDataState, changeCompetition]);

  useEffect(() => {
    // console.log(dataListCompetitionsComplexGraph, "----dataListCompetitionsComplexGraph")
    if (
      dataListCompetitionsComplexGraph &&
      dataListCompetitionsComplexGraph.listCompetitionsComplex
    ) {
      setCompetitionsComplexDataState(
        dataListCompetitionsComplexGraph.listCompetitionsComplex
      );
      if (
        dataListCompetitionsComplexGraph.listCompetitionsComplex
          .activeCompetition.length > 0 && !competitionReducer
      ) {
        setCompetition(
          dataListCompetitionsComplexGraph.listCompetitionsComplex
            .activeCompetition[0].id
        );
      } else if (dataListCompetitionsComplexGraph.listCompetitionsComplex
          .activeCompetition.length === 0 && !competitionReducer) {
        setCompetition(
          dataListCompetitionsComplexGraph.listCompetitionsComplex
            .allCompetition[0].id
        );
      }
    }
  }, [dataListCompetitionsComplexGraph]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileIdState, setProfileIdState] = useState(null);

  const [notificationsNotReadState, setNotificationsNotReadState] = useState(
    []
  );
  const [notificationsReadState, setNotificationsReadState] = useState([]);

  const handleClickNotifications = event => {
    if (
      notificationsNotReadState.length > 0 ||
      notificationsReadState.length > 0
    ) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
    async function queryData(idNoti) {
      try {
        await updateNotificationHandler({
          variables: { id: parseInt(idNoti) }
        });
      } catch (err) {}
    }
    if (userId) {
      // queryData(userId); Acá ejecuto el update
      notificationsNotReadState.map(notifi => {
        return queryData(notifi.id);
      });
    }
  };

  const openNotifications = Boolean(anchorEl);
  const idNotifications = openNotifications ? 'simple-popover' : undefined;

  // const [age, setAge] = useState('');

  const handleChange = event => {
    if (event.target.name === 'competition_select') {
      setCompetition(event.target.value);
      changeCompetition(event.target.value);
    } else {
      // setAge(event.target.value);
    }
  };

  useEffect(() => {
    setProfileIdState(String(userIdProfile));
    async function queryData() {
      try {
        await listNotificationsComplexHandler({
          variables: {
            filter: {
              user: {
                eq: parseInt(userId)
              }
            }
          }
        });
      } catch (err) {}
    }
    if (userId) {
      queryData();
    }
  }, [context, listNotificationsComplexHandler, userId, userIdProfile]);

  useEffect(() => {
    if (
      dataListNotificationsComplexGraph &&
      dataListNotificationsComplexGraph.listNotificationsComplex
    ) {
      setNotificationsNotReadState(
        dataListNotificationsComplexGraph.listNotificationsComplex
          .notificationNotRead
      );
      setNotificationsReadState(
        dataListNotificationsComplexGraph.listNotificationsComplex
          .notificationRead
      );
    }
  }, [dataListNotificationsComplexGraph]);

  useEffect(() => {
    async function queryData() {
      // console.log('ACA ACA ACA ACA');
      try {
        await listNotificationsComplexHandler({
          variables: {
            filter: {
              user: {
                eq: parseInt(userId)
              }
            }
          }
        });
      } catch (err) {}
    }

    queryData();
  }, []);

  useEffect(() => {
    // console.log(yearsState.length, competitionsComplexDataState)
    if(yearsState.length && competitionsComplexDataState && competitionsComplexDataState.allCompetition){
      setLoadingFiltersTopState(false);
    }
    
  }, [yearsState, competitionsComplexDataState]);

  // console.log(competition,"<----competition selected")

  const logOut = () => {
    clearPostulation();
    context.logout();
  }

  const renderSelects = () => {
    if (profileIdState) {
      if (
        profileIdState === '1' ||
        profileIdState === '2' ||
        profileIdState === '5' ||
        profileIdState === '6'
      ) {
        return (
          <React.Fragment>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label} id="competition_selector">
                Concurso
              </InputLabel>
              <Select
                labelId="competition_selector"
                inputProps={{ name: 'competition_select' }}
                classes={{
                  icon: classes.selectIcon,
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu
                }}
                id="select-helper"
                value={competition}
                onChange={handleChange}>
                <ListSubheader>Vigentes</ListSubheader>
                {competitionsComplexDataState.activeCompetition &&
                competitionsComplexDataState.activeCompetition.length > 0 ? (
                  competitionsComplexDataState.activeCompetition.map(
                    competition => (
                      <MenuItem key={competition.id} value={competition.id}>
                        {competition.name}
                      </MenuItem>
                    )
                  )
                ) : loadingListCompetitionsComplexGraph ? (
                  <MenuItem value="0">Cargando...</MenuItem>
                ) : (
                  <MenuItem value="0">No hay concursos vigentes</MenuItem>
                )}
                <ListSubheader>Inactivos</ListSubheader>
                {competitionsComplexDataState.inactiveCompetition &&
                competitionsComplexDataState.inactiveCompetition.length > 0 ? (
                  competitionsComplexDataState.inactiveCompetition.map(
                    competition => (
                      <MenuItem key={competition.id} value={competition.id}>
                        {competition.name}
                      </MenuItem>
                    )
                  )
                ) : loadingListCompetitionsComplexGraph ? (
                  <MenuItem value="0">Cargando...</MenuItem>
                ) : (
                  <MenuItem value="0">No hay concursos inactivos</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label} id="age_selector">
                Año Activo {yearReducer}
              </InputLabel>

              <Select
                labelId="age_selector"
                inputProps={{ name: 'age_select' }}
                classes={{
                  icon: classes.selectIcon,
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu
                }}
                id="select-helper"
                value={yearSelected}
                onChange={e => changeYear(e.target.value)}>
                {yearsState && yearsState.length > 0 ? (
                  yearsState.map(({ year }) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))
                ) : loadingListYearsGraph ? (
                  <MenuItem value="0">Cargando...</MenuItem>
                ) : (
                  <MenuItem value="0">No hay año activo</MenuItem>
                )}
              </Select>
            </FormControl>
          </React.Fragment>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <AppBar
      {...rest}
      classes={{
        colorPrimary: classes.colorprimary
      }}
      className={clsx('toolbar_root', className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            className={classes.logoTop}
            alt="Logo"
            src="/images/logos/logo-top-w.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          {!loadingFiltersTopState && renderSelects()}
          <IconButton color="inherit" onClick={handleClickNotifications}>
            <Badge
              badgeContent={notificationsNotReadState.length}
               overlap="rectangular"
              color="secondary"
              classes={{
                colorSecondary: classes.colorSecondary
              }}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id={idNotifications}
            open={openNotifications}
            anchorEl={anchorEl}
            classes={{
              paper: classes.popOverStyle
            }}
            onClose={handleCloseNotifications}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <PopOverContent
              readNot={notificationsReadState}
              notReadNot={notificationsNotReadState}
            />
          </Popover>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={logOut}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
