import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactHTMLTableToExcel from 'mb-react-html-table-to-excel';
import useForm from 'react-hook-form';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Card, CardContent, Divider } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import classes from './CohorteReport.module.scss';
import { FilterAction } from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';

const CohorteReport = props => {
  const { handleSubmit } = useForm();

  const onSubmit = async values => {
    console.log(values, 'enviado');
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12 mb-4">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <div>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className={classes.table_xls_button}
                    table="table-cohorte"
                    filename="tablexls"
                    sheet="LA Página"
                    buttonText="EXPORTAR EXCEL"
                  />
                </div>
              </CardActions>

              <Divider />
              <CardContent className={classes.cardContent}>
                <div>
                  <BsRow>
                    <BsCol class="col-md-6">
                      <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                        <BsRow>
                          <BsCol class="col-md-7"></BsCol>
                          <BsCol class="col-md-5">
                            <FilterAction
                              mutationLoading={false}
                              success={false}
                            />
                          </BsCol>
                        </BsRow>
                      </form>
                    </BsCol>
                    <BsCol
                      class={clsx('col-md-6', classes.buttonsActions)}></BsCol>
                  </BsRow>
                  <div>
                    <table id="table-cohorte">
                      <thead>
                        <tr>
                          <th colSpan="22">
                            Informe Académico Cohorte 2020 (Período Académico
                            Abril 2020 a Marzo 2021)
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="22">
                            En orden a dar cumplimiento a lo establecido en la
                            cláusula del convenio: condiciones para el pago;
                            quien suscribe certifica que los antecedentes
                            contenidos en el presente instrumento dan fe del
                            cumplimiento de las obligaciones contraidas por
                            parte de los profesionales que se individualizan a
                            continuación.{' '}
                          </th>
                        </tr>
                        <tr>
                          <th rowSpan="2">N°</th>
                          <th colSpan="3">Nombre Profesional en Formación</th>
                          <th rowSpan="2">Cédula de Identidad N°</th>
                          <th rowSpan="2">Servicio de Salud</th>
                          <th rowSpan="2">Universidad</th>
                          <th rowSpan="2">Facultad</th>
                          <th rowSpan="2">
                            Especialidad (Nombre Programa de Formación)
                          </th>
                          <th rowSpan="2">
                            Nombre tutor responsable del programa
                          </th>
                          <th rowSpan="2">Cohorte de Ingreso AÑO</th>
                          <th rowSpan="2">
                            Año académico cursado en el período
                          </th>
                          <th rowSpan="2">
                            Promedio de calificaciones en el período
                          </th>
                          <th rowSpan="2">
                            Porcentaje de asistencia en el período
                          </th>
                          <th rowSpan="2">
                            CONTINUA RENUNCIA ELIMINACIÓN SUSPENSIÓN
                          </th>
                          <th colSpan="2">Licencias médicas en el período</th>
                          <th colSpan="2">Solicitó SUSPENSIÓN en el período</th>
                          <th colSpan="2">
                            Prorroga la fecha de término del programa
                          </th>
                          <th>
                            Mantiene CONTROL HORARIO en el Centro Formador
                          </th>
                        </tr>
                        <tr>
                          <th>Ap. Paterno</th>
                          <th>Ap. Materno</th>
                          <th>Nombres</th>
                          <th>SI / NO</th>
                          <th>N° días</th>
                          <th>SI / NO</th>
                          <th>N° días</th>
                          <th>SI / NO</th>
                          <th>N° días</th>
                          <th>SI / NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Guiterrez</td>
                          <td>Zambrano</td>
                          <td>Germán</td>
                          <td>12.234.234-2</td>
                          <td>Coquimbo</td>
                          <td>UCN</td>
                          <td>Facultad Medicina</td>
                          <td>Radiología</td>
                          <td>Rodrigo Araya</td>
                          <td>2020</td>
                          <td>Segundo</td>
                          <td>6,5</td>
                          <td>100%</td>
                          <td></td>
                          <td>NO</td>
                          <td></td>
                          <td>NO</td>
                          <td></td>
                          <td>NO</td>
                          <td></td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="22"></td>
                        </tr>
                        <tr>
                          <td colSpan="7">
                            (*) Prorroga por 3 (tres) meses Dra. Anamaría
                            Lagunas por reprobación de asignatura.
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">
                            _____________________________________
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">FIRMA</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">NOMBRE: DR. FERNANDO BONACIC</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">CEDULA IDENTIDAD: 7.970.190-0</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">
                            CARGO: JEFE DE POSTGRADO Y POSTÍTULO
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="8">FACULTAD DE MEDICINA: UCN</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(CohorteReport);
