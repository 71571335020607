import gql from 'graphql-tag';

export const createAbsenceReason = gql`
  mutation($name: String!, $document: Boolean!, $attendance: Boolean!) {
    createAbsenceReason(
      absenceReasonInput: {
        name: $name
        document: $document
        attendance: $attendance
      }
    )
  }
`;

export const updateAbsenceReason = gql`
  mutation(
    $id: Int!
    $name: String!
    $document: Boolean!
    $attendance: Boolean!
  ) {
    updateAbsenceReason(
      absenceReasonInput: {
        id: $id
        name: $name
        document: $document
        attendance: $attendance
      }
    ) {
      id
      name
      document
      documentSee
      attendance
      attendanceSee
      delete
      edit
    }
  }
`;

export const deleteAbsenceReason = gql`
  mutation($id: Int!) {
    deleteAbsenceReason(id: $id)
  }
`;
