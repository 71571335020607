import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Context } from './../../../context/Context';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgressComponent } from '../../Progress';

import {
  TextFieldWidgetText,
  DialogDelete,
  AutoCompleteWidget
} from '../../../components';
import { BsRow, BsCol } from '../../../layouts/components';
import classes from '../AddRegisterPanels.module.scss';

import { handleActionDelete } from '../../../helpers';
import { listCountry } from '../../../graphql/queries/country';

import {
  createPostulationDiplomat,
  deletePostulationDiplomat
} from '../../../graphql/mutation/postulationDiplomat';

import { getPostulationDiplomatList } from '../../../graphql/queries/postulationDiplomat';

import { Context as ActivePostulationContext } from '../../../context/ActivePostulationContext';

const AddRegisterPanelGR = React.memo(props => {
  const { registeredItems, disableForm, loadingGR } = props;
  const context = useContext(Context);
  const userId = context.userId;

  const [fieldsUncompletedState, setFieldsUncompletedState] = useState(null);
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [postulationDiplomatState, setPostulationDiplomatState] = useState('');

  const [countryDataState, setCountryDataState] = useState([]);

  const [countrySelectedState, setCountrySelectedState] = useState(null);

  const [values, setValues] = useState(false);

  const {
		state: { activePostulation }
	} = useContext(ActivePostulationContext);

  const {
    loading: loadingListCountryGraph,
    data: dataListCountryGraph
  } = useQuery(listCountry, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [
    getPostulationDiplomatHandler,
    {
      loading: loadingPostulationDiplomatGraph,
      data: dataPostulationDiplomatGraph
    }
  ] = useLazyQuery(getPostulationDiplomatList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationDiplomatHandler({
          variables: { id: parseInt(userId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (values) {
      queryData();
      setValues(false);
    }
  }, [getPostulationDiplomatHandler, values, userId]);

  const [
    deletePostulationDiplomatHandler,
    {
      loading: loadingDeletePostulationDiplomatGraph,
      data: dataDeletePostulationDiplomatGraph
    }
  ] = useMutation(deletePostulationDiplomat, {
    errorPolicy: 'all'
  });

  const deleteActionDialogHandler = async () => {
    try {
      await deletePostulationDiplomatHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(
      rowToHandleState,
      postulationDiplomatState
    );
    setPostulationDiplomatState(newData);
  };

  const [
    createPostulationDiplomatHandler,
    { loading: loadingSubmitGraph }
  ] = useMutation(createPostulationDiplomat);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  useEffect(() => {
    if (dataListCountryGraph && dataListCountryGraph.listCountry) {
      setCountryDataState(dataListCountryGraph.listCountry);
    }
  }, [dataListCountryGraph]);

  useEffect(() => {
    if (registeredItems) {
      setPostulationDiplomatState(registeredItems);
    }
  }, [registeredItems]);

  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    reset: resetItem,
    watch: watchItem
  } = useForm();

  let watchAllFields = watchItem([
    'nameGraduate',
    'universityGraduate',
    'periodGraduate',
    'hoursGraduate',
    'qualificationGraduate',
    'certificateGraduate'
  ]);

  useEffect(() => {
    if (
      watchAllFields.nameGraduate === '' &&
      watchAllFields.universityGraduate === '' &&
      watchAllFields.periodGraduate === '' &&
      watchAllFields.hoursGraduate === '' &&
      watchAllFields.qualificationGraduate === '' &&
      watchAllFields.certificateGraduate &&
      watchAllFields.certificateGraduate.length === 0 &&
      countrySelectedState === null
    ) {
      setFieldsUncompletedState(true);
    } else if (
      watchAllFields.nameGraduate === '' ||
      watchAllFields.universityGraduate === '' ||
      watchAllFields.periodGraduate === '' ||
      watchAllFields.hoursGraduate === '' ||
      watchAllFields.qualificationGraduate === '' ||
      (watchAllFields.certificateGraduate &&
        watchAllFields.certificateGraduate.length === 0) ||
      countrySelectedState === null
    ) {
      setFieldsUncompletedState('Debe llenar todos los campos');
    } else {
      setFieldsUncompletedState(null);
    }
  }, [watchAllFields, countrySelectedState]);

  const onSubmitItem = async values => {
    const query = {
      id: parseInt(userId),
      idCountry: countrySelectedState.id,
      name: values.nameGraduate,
      hour: values.hoursGraduate,
      date: values.periodGraduate,
      university: values.universityGraduate,
      qualification: values.qualificationGraduate,
      certificate: values.certificateGraduate,
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await createPostulationDiplomatHandler({
        variables: query
      });
      if (
        (values &&
          values.nameGraduate &&
          values.universityGraduate &&
          values.countryGraduate &&
          values.periodGraduate &&
          values.hoursGraduate &&
          values.qualificationGraduate,
        values.certificateGraduate)
      ) {
        setValues(true);
        setFieldsUncompletedState(null);
        resetItem();
        // setPostulationDiplomatState(
        //   dataPostulationDiplomatGraph.getPostulationDiplomatList
        // );
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (disableForm && disableForm !== true) {
      const elems = document.getElementsByClassName('EditPostulation');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].style.display = 'none';
      }
    }
  });

   useEffect(() => {
    // console.log(dataPostulationPublicationGraph, "<-----------llega la data")
   if(dataPostulationDiplomatGraph && dataPostulationDiplomatGraph.getPostulationDiplomatList) {
     setPostulationDiplomatState(
          dataPostulationDiplomatGraph.getPostulationDiplomatList
        );
   }
  }, [dataPostulationDiplomatGraph]);

  return (
    <form onSubmit={handleSubmitItem(onSubmitItem)}>
      <div className={classes.addRegisterContainer}>
        <div className={classes.addTitleAdditional}>
          <AddIcon /> Registre sus Diplomados
        </div>
        <BsRow class="align-items-center">
          <BsCol class="col-md-10">
            <BsRow>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="nameGraduate"
                  labeltext="Nombre"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={200}
                  errors={errorsItem.nameGraduate}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="universityGraduate"
                  labeltext="Universidad"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={300}
                  errors={errorsItem.universityGraduate}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="certificateGraduate"
                  labeltext="Certificado"
                  disabled={disableForm}
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  errors={errorsItem.certificateGraduate}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <AutoCompleteWidget
                  label="País"
                  require={true}
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  data={countryDataState}
                  inicialValue="Seleccione País..."
                  valuecombo={countrySelectedState}
                  loadingComboState={false}
                  onSelectState={setCountrySelectedState}
                  name="countryGraduate"
                  loading={loadingListCountryGraph}
                  register={registerItem}
                  errors={errorsItem.countryGraduate}
                />
              </BsCol>
              <BsCol class="col-md-4">
                <TextFieldWidgetText
                  name="periodGraduate"
                  labeltext="Fecha / Período"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={100}
                  typenote={true}
                  errors={errorsItem.periodGraduate}
                />
              </BsCol>
              <BsCol class="col-md-4">
                <TextFieldWidgetText
                  name="hoursGraduate"
                  labeltext="Horas"
                  type="number"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={6}
                  typenote={true}
                  errors={errorsItem.hoursGraduate}
                />
              </BsCol>
              <BsCol class="col-md-4">
                <TextFieldWidgetText
                  name="qualificationGraduate"
                  labeltext="Calificación"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={40}
                  typenote={true}
                  errors={errorsItem.qualificationGraduate}
                />
              </BsCol>
            </BsRow>
          </BsCol>
          <BsCol class="col-md-2">
            <div className={clsx(classes.wrapper, 'EditPostulation')}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={Boolean(fieldsUncompletedState)}
                size="small"
                className={classes.buttonAdd}
                startIcon={<AddIcon />}>
                Agregar
              </Button>
              {fieldsUncompletedState && (
                <p className={classes.errorField}>{fieldsUncompletedState}</p>
              )}
              {loadingSubmitGraph && <CircularProgressComponent />}
            </div>
          </BsCol>
        </BsRow>
        {/* listado agregados */}
        <div className={classes.registerAddedContainer}>
          <div className={classes.addTitleAdditional}>
            Diplomados Registrados
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Universidad</th>
                <th scope="col">Certificado</th>
                <th scope="col">País</th>
                <th scope="col">Período</th>
                <th scope="col">Horas</th>
                <th scope="col">Nota</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
            {postulationDiplomatState && postulationDiplomatState.length > 0 ? (
              postulationDiplomatState.map(row => (
                <tr key={row.name}>
                  <td>{row.name}</td>
                  <td>{row.university}</td>
                  <td>
                    <a
                      href={row.certificate}
                      target="_blank"
                      rel="noopener noreferrer">
                      Ver Aquí
                    </a>
                  </td>
                  <td>{row.country.name}</td>
                  <td>{row.date}</td>
                  <td>{row.hour}</td>
                  <td>{row.qualification}</td>
                  <td>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      disabled={disableForm}
                      onClick={() => DialogDeleteHandler(row)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : loadingPostulationDiplomatGraph || loadingGR ? (
              <tr>
                <td colSpan={7}><CircularProgressComponent /></td>
              </tr>
            ) : (
              
              <tr>
                <td colSpan={7}><p> No hay diplomados.</p></td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <DialogDelete
          openDialog={openDialogDeleteState}
          rowDelete={rowToHandleState}
          actionDelete={deleteActionDialogHandler}
          variant="success"
          loading={loadingDeletePostulationDiplomatGraph}
          success={dataDeletePostulationDiplomatGraph}
          openState={setOpenDialogDeleteState}
        />
      </div>
    </form>
  );
});

AddRegisterPanelGR.propTypes = {
  registeredItems: PropTypes.array.isRequired
};

export default AddRegisterPanelGR;
