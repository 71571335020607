import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button } from '@material-ui/core';
import useForm from 'react-hook-form';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HomeIcon from '@material-ui/icons/Home';
import { Card, CardContent, Divider, ButtonGroup } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import classes from './ReportApplication.module.scss';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { FilterAction, FormHelpArea, TextFieldWidgetSelect } from '../../../components';
import CircularProgressComponent from '../../../components/Progress/CircularProgressComponent';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getReportBySpecialty } from '../../../graphql/queries/reports';
import { listYears } from '../../../graphql/queries/year';
import { listCompetitions } from '../../../graphql/queries/competition';
import { useActions } from '../../../store/actions';
import { alterArrayForSelectYear, arrayIntersectionElements, printOrder } from '../../../helpers';
import _ from 'lodash';

const ApplicationLevelOne = props => {

  const context = useContext(Context);
  const { userId, userIdProfile, userFullname, userProfile, specialtyUser } = context;

  const { year: yearReducer, competition: competitionReducer, changeYear, changeCompetition } = useActions();

  const { handleSubmit, register, errors } = useForm();

  const [reportBySpecialtyState, setReportBySpecialtyState] = useState('');
  const [excelExportState, setExcelExportState] = useState(false);

  const [yearDataState, setYearDataState] = useState([]);
  const [competitionDataState, setCompetitionDataState] = useState([]);
  const [yearSelectedState, setYearSelectedState] = useState(yearReducer);
  const [competitionSelectedState, setCompetitionSelectedState] = useState(competitionReducer);

  const { loading: loadingListYearsGraph, data: dataListYearsGraph } = useQuery(
    listYears,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const { loading: loadingListCompetitionsGraph, data: dataListCompetitionsGraph } = useQuery(
    listCompetitions,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const [
    GetReportBySpecialtyHandler,
    {
      loading: loadingGetReportBySpecialtyGraph,
      data: dataGetReportBySpecialtyGraph
    }
  ] = useLazyQuery(getReportBySpecialty, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await GetReportBySpecialtyHandler({
          variables: {
            filter: {
              year: parseInt(yearReducer),
              competition: parseInt(competitionReducer)
            }
          }
        });
      } catch (err) {}
    }
    queryData();
  }, [GetReportBySpecialtyHandler, yearReducer, competitionReducer]);

  useEffect(() => {
    if (
      dataGetReportBySpecialtyGraph &&
      dataGetReportBySpecialtyGraph.getReportBySpecialty
    ) {
      console.log("-----lleg´a la data", userIdProfile, specialtyUser, dataGetReportBySpecialtyGraph.getReportBySpecialty)
      if(userIdProfile === "6" || userIdProfile === 6){
        let newArray2 = arrayIntersectionElements(dataGetReportBySpecialtyGraph.getReportBySpecialty, specialtyUser);
        setReportBySpecialtyState(
          newArray2
        );
      } else {
        setReportBySpecialtyState(
          dataGetReportBySpecialtyGraph.getReportBySpecialty
        );
      }
    }
  }, [dataGetReportBySpecialtyGraph]);

  useEffect(() => {
    if (reportBySpecialtyState) {
      console.log('ESTADO LISTO: ', reportBySpecialtyState);
    }
  }, [reportBySpecialtyState]);

  // filters

  useEffect(() => {
    if (dataListYearsGraph && dataListYearsGraph.listYears) {
      console.log(dataListYearsGraph.listYears, "años")
      setYearDataState(alterArrayForSelectYear(dataListYearsGraph.listYears));
    }
  }, [dataListYearsGraph]);

  useEffect(() => {
    if (dataListCompetitionsGraph && dataListCompetitionsGraph.listCompetitions) {
      setCompetitionDataState(dataListCompetitionsGraph.listCompetitions);
    }
  }, [dataListCompetitionsGraph]);

  const onSubmit = async values => {
    console.log(values, "<---form")
    changeYear(values.year);
    changeCompetition(values.competition);
  };

  console.log("-----", competitionReducer, yearReducer)

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.push('/dashboard')}>
                  <HomeIcon fontSize="small" /> Ir al Escritorio
                </Button>
                <div>
                  {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className={classes.exportButtonStyle}
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Excel"
                  /> */}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => printOrder('ifmcontentstoprint')}>
                    Imprimir
                  </Button>
                </div>
              </CardActions>
              <div className={classes.buttonContainer}>
                <ButtonGroup
                  color="default"
                  classes={{
                    root: classes.rootGroup
                  }}
                  aria-label="outlined primary button group">
                  <Button
                    color="primary"
                    classes={{ outlinedPrimary: classes.outlinedPrimary }}>
                    Estado Postulaciones por Especialidad
                  </Button>
                  <Button
                    onClick={() =>
                      props.history.push('/specialty-application-report')
                    }>
                    Postulantes por Especialidad
                  </Button>
                  <Button onClick={() => props.history.push('/profile-report')}>
                    Ficha Perfil
                  </Button>
                </ButtonGroup>
              </div>
              <Divider />
              <CardContent
                className={classes.cardContent}
                id="ifmcontentstoprint">
                <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                    <BsRow>
                      <BsCol class="col-md-3">
                        <TextFieldWidgetSelect
                          options={yearDataState}
                          name="year"
                          req={false}
                          shortLabel={true}
                          selected={yearSelectedState}
                          onChange={e => setYearSelectedState(e)}
                          loading={loadingListYearsGraph}
                          withDefault={true}
                          InputLabelProps={{ shrink: true }}
                          labeltext="Año"
                          register={register}
                          errors={errors.year}
                        />
                      </BsCol>
                      <BsCol class="col-md-3">
                        <TextFieldWidgetSelect
                          options={competitionDataState}
                          name="competition"
                          req={false}
                          shortLabel={true}
                          loading={loadingListCompetitionsGraph}
                          withDefault={true}
                          selected={competitionSelectedState}
                          disabled={false}
                          onChange={e => setCompetitionSelectedState(e)}
                          InputLabelProps={{
                            shrink: true
                          }}
                          labeltext="Concurso"
                          register={register}
                          errors={errors.competition}
                        />
                      </BsCol>
                      <BsCol class="col-md-3">
                        <FilterAction mutationLoading={false} success={false} />
                      </BsCol>
                    </BsRow>
                  </form>
                <div>
                  <FormHelpArea title="Leyenda">
                    <ul>
                      <li>
                        <b>PT:</b> Postulaciones Totales
                      </li>
                      <li>
                        <b>PA:</b> Pendientes Aprobación
                      </li>
                      <li>
                        <b>PC:</b> Pendientes Comisión
                      </li>
                      <li>
                        <b>PI:</b> Pendientes Instituciones
                      </li>
                      <li>
                        <b>PA:</b> Postulaciones Aprobadas
                      </li>
                      <li>
                        <b>AM:</b> Alumnos Matriculados
                      </li>
                    </ul>
                  </FormHelpArea>
                  <table className="table table-striped" id="table-to-xls">
                    <thead>
                      <tr>
                        <th scope="col">Especialidad</th>
                        <th scope="col">PT</th>
                        <th scope="col">PA</th>
                        <th scope="col">PC</th>
                        <th scope="col">PI</th>
                        <th scope="col">PA</th>
                        <th scope="col">AM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportBySpecialtyState &&
                      reportBySpecialtyState.length > 0 ? (
                        reportBySpecialtyState.map(specialty => (
                          <tr key={specialty.id}>
                            <td>
                              <RouterLink
                                to={`/specialty-application-report?idSpecialty=${specialty.id}`}>
                                {specialty.name}
                              </RouterLink>
                            </td>
                            <td>{specialty.postulationTotal}</td>
                            <td>{specialty.postulationPendingValidation}</td>
                            <td>{specialty.postulationPendingEvaluation}</td>
                            <td>{specialty.postulationPendingInstitution}</td>
                            <td>{specialty.postulationApproved}</td>
                            <td>{specialty.postulationEnrollment}</td>
                          </tr>
                        ))
                      ) : loadingGetReportBySpecialtyGraph ? (
                        <tr>
                          <td colSpan={8}>
                            <CircularProgressComponent />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <p> No hay postulaciones por especialidad.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(ApplicationLevelOne);
