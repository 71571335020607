import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider, FormGroup, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createCompetition } from '../../../graphql/mutation/competition';
import { listSpecialities } from '../../../graphql/queries/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetDate,
  BreadCrumbs,
  CheckboxWidget,
} from '../../../components';
import CircularProgressComponent from '../../../components/Progress/CircularProgressComponent';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';
import classes from '../CompetitionEdit/CompetitionEdit.module.scss';
import { alterArrayForSpecialtyAssign } from 'helpers';

const require = true;
const CompetitionAdd = () => {
  const { handleSubmit, register, errors, reset } = useForm();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [checkboxesState, setCheckboxesState] = useState([]);
  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const { loading: loadingSpecialtiesGraph, data: dataSpecialtiesGraph } = useQuery(
    listSpecialities,
    {
      variables: {
        filter: {
          id: {
            gt: 0
          }
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const [
    createCompetitionHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createCompetition);

  const onSubmit = async values => {

    let arrayQuery = [];

    if(values.specialtyUser) {
      arrayQuery = values.specialtyUser.map((id) => {
        return parseInt(id);
      });
    }

    const query = {
      name: values.name,
      code: values.code,
      since: values.since,
      until: values.until,
      description: values.description,
      dayStart: values.daystart,
      specialty: arrayQuery,
    };

    try {
      await createCompetitionHandler({
        variables: query
      });
      setSinceSelectedState(null);
      setUntilSelectedState(null);
      reset();
      setCheckboxesState(alterArrayForSpecialtyAssign(specialtyDataState))
    } catch (err) {}
  };

  useEffect(() => {
    if (dataSpecialtiesGraph && dataSpecialtiesGraph.listSpecialities) {
      console.log(dataSpecialtiesGraph.listSpecialities, "----data specialty")
      setSpecialtyDataState(dataSpecialtiesGraph.listSpecialities);
    }
  }, [dataSpecialtiesGraph]);

  useEffect(() => {
    if(specialtyDataState.length){
     setCheckboxesState(alterArrayForSpecialtyAssign(specialtyDataState))
    }
  }, [specialtyDataState]);

  const handleChange = e => {
    const newArrayEdit = checkboxesState.map(check => {
      if (check.id !== parseInt(e.target.value)) {
        return check;
      } else {
        const checkNew = { ...check, assigned: e.target.checked };
        return checkNew;
      }
    });
    setCheckboxesState(newArrayEdit);
  };

  const renderCheckboxes = () => {

    if(loadingSpecialtiesGraph){
      return <CircularProgressComponent />
    }

    return checkboxesState.map((aCheckbox, index) => {
      // console.log("--------------------------_>", aCheckbox)
      return (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidget
            name="specialtyUser"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleChange}
            checked={aCheckbox.assigned}
            register={register}
            errors="specialtyUser"
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.name}
          </Typography>
        </label>
      );
    });
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Nuevo Concurso"
                subheader={
                  <BreadCrumbs subTitle="Módulo para crear nuevo concurso" />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetText
                        name="name"
                        labeltext="Nombre"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                        autoComplete="nombre"
                      />
                      <TextFieldWidgetText
                        name="code"
                        labeltext="Codigo"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.code}
                        autoComplete="codigo"
                      />
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetDate
                            name="since"
                            labeltext="Desde"
                            value={sinceSelectedState}
                            onChange={date => setSinceSelectedState(date)}
                            register={register}
                            errors={errors.since}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetDate
                            name="until"
                            labeltext="Hasta"
                            value={untilSelectedState}
                            onChange={date => setUntilSelectedState(date)}
                            req={require}
                            register={register}
                            errors={errors.until}
                          />
                        </BsCol>
                      </BsRow>
                      {(<BsRow class="mt-4">
                        <BsCol class="col-md-12">
                          <Typography variant="h6" gutterBottom>
                            Seleccione especialidades del concurso
                          </Typography>
                          <div style={{ display: 'flex' }}>
                            <FormGroup className={classes.groupLabel}>
                              {renderCheckboxes()}
                            </FormGroup>
                          </div>
                        </BsCol>
                      </BsRow>)}
                      <TextFieldWidgetText
                        name="description"
                        labeltext="Descripción"
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        minRows="3"
                        multiline
                        minLength={5}
                        maxLength={500}
                        register={register}
                        errors={errors.description}
                        autoComplete="description"
                      />
                      <TextFieldWidgetText
                        name="daystart"
                        labeltext="Fecha inicio (para certificado)"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.daystart}
                      />
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default CompetitionAdd;
