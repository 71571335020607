import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { BsRow, BsCol } from '../../layouts/components';
import classes from './ModalWidget.module.scss';
const ModalWidget = props => {
	const { openDetail, detailModal, moduleName, size } = props;
	const [openStateDetail, setOpenStateDetail] = useState(openDetail);

  // console.log(props, "<------------")
	
	const handleClose = () => {
    setOpenStateDetail(false);
  };

  useEffect(() => {
    if (detailModal !== '') {
      setOpenStateDetail(true);
    }
	}, [detailModal]);
	
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openStateDetail}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={openStateDetail}>
        <div className={[classes.paper, classes[size]].join(' ')}>
          <BsRow>
            <BsCol class="col-11 pt-2">
              <h2 id="modal-title">Detalle {moduleName}</h2>
            </BsCol>
            <BsCol class="col-1">
              <IconButton
                size="medium"
                onClick={handleClose}
                aria-label="cerrar"
                component="span">
                <CloseIcon fontSize="small" />
              </IconButton>
            </BsCol>
          </BsRow>
          <BsRow>
            <BsCol class="col-12">{detailModal}</BsCol>
          </BsRow>
        </div>
      </Fade>
    </Modal>
  );
};
export default ModalWidget;
