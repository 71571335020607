import { CHANGE_YEAR, CHANGE_COMPETITION } from '../actions/actionTypes';

const d = new Date();

const initialState = {
  year: d.getFullYear(),
  competition: 1
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_YEAR:
      return {
        ...state,
        year: action.year
      };
    case CHANGE_COMPETITION:
      return {
        ...state,
        competition: action.competition
      };
    default:
      return state;
  }
};

export { reducer, initialState };
