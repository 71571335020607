import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { createAbsenceReason } from '../../../graphql/mutation/absenceReason';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  RadioGroupWidget,
  RadioWidget,
  BreadCrumbs
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';

const require = true;

const initialRadioState = {
	document: '',
	attendance: '',
}

const AbsenceReasonAdd = () => {
	const [stateRadioButtons, setStateRadioButtons] = useState(initialRadioState);

  const { handleSubmit, register, errors, reset } = useForm();
  const [
    createAbsenceReasonHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
	] = useMutation(createAbsenceReason);

	const handleRadioCheck = (value) => {
		setStateRadioButtons({
			...stateRadioButtons,
			[value.name]: value.value,
		});
	}
	
  const onSubmit = async ({ name, document, attendance }) => {
    let valueDocument = true;
    let valueAttendance = true;
    if (document !== '1') {
      valueDocument = false;
    }
    if (attendance !== '1') {
      valueAttendance = false;
    }
    const query = {
      name: name,
      document: valueDocument,
      attendance: valueAttendance
		};
    try {
      await createAbsenceReasonHandler({
        variables: query
      });
			reset();
			setStateRadioButtons(initialRadioState);
    } catch (err) {}
	};

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Motivo Inasistencia"
                subheader={
                  <BreadCrumbs subTitle="Módulo para añadir motivo de inasistencia." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetText
                        name="name"
                        autoComplete="Nombre"
                        labeltext="Nombre"
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                      />
                      <BsRow class="mt-5">
                        <BsCol class="col-md-6">
                          Tiene adjunto
                          <RadioGroupWidget
                            name="document"
                            errors={errors.document}
														checked={stateRadioButtons.document}
														handleradiocheck={handleRadioCheck}
														>
                            <RadioWidget
                              register={register}
                              req
                              value="1"
                              label="Sí"
                            />
                            <RadioWidget
                              register={register}
                              req
                              value="2"
                              label="No"
                            />
                          </RadioGroupWidget>
                        </BsCol>
                        <BsCol class="col-md-6">
                          Afecta Asistencia
                          <RadioGroupWidget
                            name="attendance"
                            errors={errors.attendance}
														checked={stateRadioButtons.attendance}
														handleradiocheck={handleRadioCheck}
														>
                            <RadioWidget
                              register={register}
                              req
                              value="1"
                              label="Sí"
                            />
                            <RadioWidget
                              register={register}
                              req
                              value="2"
                              label="No"
                            />
                          </RadioGroupWidget>
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default AbsenceReasonAdd;
