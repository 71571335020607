import _ from 'lodash';
import React from 'react';

const getText = (propName, stateProp) => {
  switch (propName) {
    case 'attendanceSee':
      if (stateProp === 'true') {
        return 'Sí';
      } else {
        return 'No';
      }
    case 'documentSee':
      if (stateProp === 'true') {
        return 'Sí';
      } else {
        return 'No';
      }
    default:
      return null;
  }
};

const listArray = (array) => {
  array.map((item) => <p>{item.name}</p>)
}

export const handleObservationStage = stage => {
  const detail = [];
  detail.push({
    label: 'Observación',
    value: stage
  });
  const detailList = detail.map(it => {
    if (it.value !== null) {
    }
    return (
      <span
        key={it.label}
        style={{
          display: 'block',
          margin: '8px',
          padding: '3px'
        }}>
        {it.value}
      </span>
    );
  });
  return detailList;
};

export const handleActionDetail = row => {
  const detail = [];
  for (let fieldName in row) {
    switch (fieldName) {
      case 'name':
        detail.push({
          label: 'nombres',
          value: row[fieldName],
          order: 1
        });
        break;
      case 'lastname':
        detail.push({
          label: 'apellidos',
          value: row[fieldName],
          order: 2
        });
        break;
      case 'email':
        detail.push({
          label: fieldName,
          value: row[fieldName],
          order: 4
        });
        break;
      case 'rut':
        detail.push({
          label: fieldName,
          value: row[fieldName],
          order: 3
        });
        break;
      case 'phone':
        detail.push({
          label: 'teléfono',
          value: row[fieldName],
          order: 5
        });
        break;
      case 'user':
        detail.push({
          label: 'Nombre usuario',
          value: row.user.fullname,
          order: 6
        });
        break;
      case 'description':
        detail.push({
          label: 'Descripción',
          value: row[fieldName],
          order: 7
        });
        break;
      case 'dayStart':
        detail.push({
          label: 'Fecha inicio (certificado)',
          value: row[fieldName],
          order: 8
        });
        break;
      case 'specialty':
      detail.push({
        label: 'Especialidades',
        map: true,
        value: row[fieldName],
        order: 9
      });
      break;
      
      default:
        break;
    }
  }

  function dynamicSort(property) {
    return function(a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }

  detail.sort(dynamicSort('order'));

  const detailList = detail.map(it => {
    if (it.value !== null) {
    }
    // si es map
    if(it.map) {
      return (
        <ul key={it.label} style={{ listStyle: 'inside'}}>
        {it.value.map(
          (
            ele
          ) => {
            return (
              <li
                key={
                  ele.id
                }
              >
                {
                  ele.name
                }
              </li>
            );
          }
        )}
      </ul>
      )
    }
    return (
      <span
        key={it.label}
        style={{
          display: 'block',
          margin: '8px',
          padding: '3px',
          whiteSpace: 'pre-line'
        }}>
        <b style={{ textTransform: 'capitalize' }}>{it.label}:</b> {it.value}
      </span>
    );
  });
  return detailList;
};



export const handleActionEditObj = (newObject, allObjects) => {
  const newArrayEdit = allObjects.map(obj => {
    return obj.id === parseInt(newObject.id) ? newObject : obj;
  });
  return newArrayEdit;
};

export const handleActionEdit = (newValuesToEdit, users) => {
  const newArrayEdit = users.map(user => {
    if (user.id !== parseInt(newValuesToEdit.id)) {
      return user;
    } else {
      for (let fieldName in user) {
        if (
          fieldName === 'email' ||
          fieldName === 'code' ||
          fieldName === 'name' ||
          fieldName === 'lastname' ||
          fieldName === 'phone' ||
          fieldName === 'rut' ||
          fieldName === 'businessDays' ||
          fieldName === 'since' ||
          fieldName === 'until' ||
          fieldName === 'year' ||
          fieldName === 'description' ||
          fieldName === 'maternalLastname' ||
          fieldName === 'paternalLastname' ||
          fieldName === 'nameuser' ||
          fieldName === 'dayStart'
        ) {
          _.set(user, fieldName, newValuesToEdit[fieldName]);
        } else if (fieldName === 'profile') {
          _.set(user.profile, fieldName, newValuesToEdit[fieldName]);
          // ACA DEBERÍA CAMBIAR EL PROFILE NAME--------------------
        } else if (fieldName === 'type') {
          _.set(user.type, ['id'], newValuesToEdit[fieldName]);
          // ACA DEBERÍA CAMBIAR EL TYPE NAME--------------------
        } else if (fieldName === 'attendance' || fieldName === 'document') {
          _.set(user, fieldName, newValuesToEdit[fieldName]);
          const propName = fieldName + 'See';
          const stateProp = newValuesToEdit[fieldName].toString();
          let valueProp = getText(propName, stateProp);
          _.set(user, propName, valueProp);
        } else if (fieldName === 'specialty') {
          _.set(user.specialty, ['id'], newValuesToEdit[fieldName]);
          // ACA HAY QUE BUSCAR NUEVO NOMBRE
          let valueProp = 'especialidad';
          _.set(user.specialty, ['name'], valueProp);
        }
      }
      return user;
    }
  });
  return newArrayEdit;
};

export const handleActionDelete = (row, users) => {
  const difArray = _.differenceWith(users, [row], _.isEqual);
  return difArray;
};

export const handleActionState = (row, users) => {
  let newIdState = null;
  let newNameState = null;
  let newState = {};
  if (row.state.id === 1) {
    newIdState = 2;
    newNameState = 'Inactivo';
  } else {
    newIdState = 1;
    newNameState = 'Activo';
  }
  newState = { id: newIdState, name: newNameState };

  const newArrayState = users.map(user => {
    if (user.id !== row.id) {
      return user;
    } else {
      user.state = newState;
      return user;
    }
  });

  return newArrayState;
};
