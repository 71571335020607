import React from 'react';
import PropTypes from 'prop-types';
import './Minimal.scss';

import { Topbar } from './components';

const Minimal = props => {
  const { children } = props;

  return (
    <div className="root">
      <Topbar />
      <main className="content">{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
