import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import palette from '../../../theme/palette';
import classes from './PieChart.module.scss';

let data = {
  //   datasets: [
  //     {
  //       //   data: [63, 45, 22, 60],
  //       data: '',
  //       backgroundColor: [
  //         palette.primary.main,
  //         palette.error.main,
  //         palette.warning.main
  //       ],
  //       borderWidth: 4,
  //       borderColor: palette.white,
  //       hoverBorderColor: '#cccccc'
  //     }
  //   ],
  //   labels: ['Medicina Interna', 'Cirugía', 'Pediatría', 'Radología']
};

const options = {
  legend: {
    display: true,
    fullWidth: true
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
};

const PieChart = props => {
  const { className, postulationBySpecialty, ...rest } = props;

  if (postulationBySpecialty) {
    let percentage = [];
    let label = [];
    postulationBySpecialty.map(data => {
      if (data.percentage === null) {
        percentage.push(0);
      } else {
        percentage.push(data.percentage);
      }

      if (data.name) {
        label.push(data.name);
			}
			return percentage;
    });
    // data.push()
    data = {
      datasets: [
        {
          data: percentage,
          backgroundColor: [
            palette.primary.main,
            palette.error.main,
            palette.warning.main
          ],
          borderWidth: 4,
          borderColor: palette.white,
          hoverBorderColor: '#cccccc'
        }
      ],
      labels: label
    };
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Postulaciones por Especialidad" />
      <Divider />
      <CardContent className={classes.content}>
        <div style={{ height: '40vh' }}>
          <Pie
            // width={300}
            // height={300}
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

PieChart.propTypes = {
  className: PropTypes.string
};

export default PieChart;
