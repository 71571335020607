import React, { useState, useEffect } from 'react';
import { Checkbox, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './CheckboxWidget.module.scss';
import errorMessage from '../../common/errorMessages.json';

const CheckboxWidget = props => {
  const { register, errors, req, onChangeHandler, loading, ...others } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  return (
    <span>
      <Checkbox
        className={classes.Checkbox}
        {...others}
        onChange={e => props.onChangeHandler(e)}
        inputRef={register({ required: props.req })}
      />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
    </span>
  );
};

CheckboxWidget.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired
};

export default CheckboxWidget;
