import gql from 'graphql-tag';

export const createPostulationPerformance = gql`
  mutation(
    $id: Int!
    $name: String!
    $place: String!
    $hour: String!
    $date: String!
    $idPostulationActive: Int
  ) {
    createPostulationPerformance(
      formInput: {
        id: $id
        name: $name
        place: $place
        hour: $hour
        date: $date
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      name
      place
      hour
      date
    }
  }
`;

export const deletePostulationPerformance = gql`
  mutation($id: Int!) {
    deletePostulationPerformance(id: $id)
  }
`;
