import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import classes from './ButtonBox.module.scss';

const ButtonBox = props => {
  const { className, title, link, ...rest } = props;

  let history = useHistory();

  const redirect = () => {
    history.push(link);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          onClick={redirect}
          style={{ cursor: 'pointer' }}>
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            {/* <Link to={link}> */}
            <Avatar className={classes.avatar}>
              <ArrowForwardIosIcon className={classes.icon} />
            </Avatar>
            {/* </Link> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ButtonBox.propTypes = {
  className: PropTypes.string
};

export default ButtonBox;
