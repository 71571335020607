import React, { useState, useEffect, useContext } from 'react';
import useForm from 'react-hook-form';
import { Context } from './../../context/Context';
import { Card, CardContent, Divider, Typography, Button, ButtonGroup, InputLabel, FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { listSpecialities } from '../../graphql/queries/specialty';
import { listCompetitions } from '../../graphql/queries/competition';
import { listNationality } from '../../graphql/queries/nationality';
import { listRegion } from '../../graphql/queries/region';
import { listCommuneByRegion } from '../../graphql/queries/commune';
import { listStudentUniversity } from '../../graphql/queries/university';
import { listCountry } from '../../graphql/queries/country';
import { getEditPostulation } from '../../graphql/queries/editPostulation';
import { getPostulationPersonalData } from '../../graphql/queries/postulationPersonalData';
import { getPostulationSpecialty, listPostulationSpecialty } from '../../graphql/queries/postulationSpecialty';
import { getPostulationTrainingInformation } from '../../graphql/queries/postulationTrainingInformation';
import { getPostulationHealthInformation } from '../../graphql/queries/postulationHealthInformation';
import { getPostulationCurricularBackgroundList } from '../../graphql/queries/postulationCurricularBackground';
import { getPostulationPublicationList } from '../../graphql/queries/postulationPublication';
import { getPostulationPresentationList } from '../../graphql/queries/postulationPresentation';
import { getPostulationAssistantShipList } from '../../graphql/queries/postulationAssistantShip';
import { getPostulationDiplomatList } from '../../graphql/queries/postulationDiplomat';
import { getPostulationPerformanceList } from '../../graphql/queries/postulationPerformance';
import { getPostulationDistinctionList } from '../../graphql/queries/postulationDistinction';
import { createAndUpdatePostulationSpecialty } from '../../graphql/mutation/postulationSpecialty';
import { createAndUpdatePostulationPersonalData } from '../../graphql/mutation/postulationPersonalData';
import { createAndUpdatePostulationTrainingInformation } from '../../graphql/mutation/postulationTrainingInformation';
import { createAndUpdatePostulationHealthInformation } from '../../graphql/mutation/postulationHealthInformation';
import { sendPostulation } from '../../graphql/mutation/sendPostulation';
import { getPostulationStatePostulation } from '../../graphql/queries/postulationState';

import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetSelect,
  TextFieldWidgetText,
  TextFieldWidgetDate,
  PostulationHeader,
  RadioGroupWidget,
  RadioWidget,
  AutoCompleteWidget,
  AddRegisterPanelAC,
  AddRegisterPanelSP,
  AddRegisterPanelCP,
  AddRegisterPanelSA,
  AddRegisterPanelGR,
  AddRegisterPanelJP,
  AddRegisterPanelAR
} from '../../components';
import { SimpleTabs, TabPanel } from '../../components/TabPanel';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { formatRut, formatOnChangeRut, dateConvert, dateFormatIso2 } from '../../helpers';
import classes from './Postulation.module.scss';
import { decimalAdjust } from '../../helpers';
import { DialogConfirm } from '../../components';
import { listUsers } from '../../graphql/queries/users';
import { getParameter } from '../../graphql/queries/parameters';
import { Context as ActivePostulationContext } from '../../context/ActivePostulationContext';

const require = true;

// Referencias TABS
// Especialidad lin: 455
// Personales lin: 723
// Formativa lin: 868
// Salud lin: 1162
// Adiconal lin: 1215

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Postulation = () => {
  const context = useContext(Context);
  const userId = context.userId;
  const userProfile = context.userProfile;

  const {
		state: { activePostulation },
    changeActive,
    addFeature,
    clearPostulation
	} = useContext(ActivePostulationContext);

  const classesUI = useStyles();

  // selector de postulación
  const [activePostulationState, setActivePostulationState] = useState('');
  // solo para el select Change
  const [activePostulationSelectState, setActivePostulationSelectState] = useState('');

  // para secretaria

  // console.log("------------> cotext active", activePostulation);

  const [loadingActivePostulationState, setLoadingActivePostulationState] = useState(true);
  const [dataActivePostulationsState, setDataActivePostulationsState] = useState([]);

  const [userSelectedState, setUserSelectedState] = useState(null);
  const [usersDataState, setUsersDataState] = useState([]);

  const [maxPostulationLimitState, setMaxPostulationLimitState] = useState(2);

  const [limitState] = useState(1000);
  const [sortState] = useState({
    paternalLastname: 'asc'
  });

  let userCadidateId = userId;
  if (userProfile === 'Secretaria') {
    userCadidateId = userSelectedState
      ? userSelectedState.id
      : userSelectedState;
  }

  const { loading: loadingUsersGraph, data: dataUsersGraph } = useQuery(
    listUsers,
    {
      variables: {
        filter: {
          profile: {
            in: [3, 4]
          },
          state: {
            eq: 1
          }
        },
        limit: limitState,
        sort: sortState
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    }
  );

  const { loading: loadingParameterGraph, data: dataParameterGraph } = useQuery(
    getParameter,
    {
      variables: {
        id: 3
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  // console.log(userProfile, 'el contexto');
  // console.log(userId, 'el userID');
  // console.log(userCadidateId, 'el candidate');
  // console.log(userSelectedState, 'el state seleccionado');

  // state tabs actived
  const [valueTabState, setValueTabState] = useState(0);

  const [specialtyDataState, setSpecialtyDataState] = useState([]);
  const [competitionDataState, setCompetitionDataState] = useState([]);
  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [competitionSelectedState, setCompetitionSelectedState] = useState('');

  const [nationalitiesDataState, setNationalitiesDataState] = useState([]);
  const [nationalitySelectedState, setNationalitySelectedState] = useState('');

  const [birthdateSelectedState, setBirthdateSelectedState] = useState(null);

  const [regionsDataState, setRegionsDataState] = useState([]);
  const [regionSelectedState, setRegionSelectedState] = useState('');

  const [communesDataState, setCommunesDataState] = useState([]);
  const [communeSelectedState, setCommuneSelectedState] = useState('');

  const [universitiesDataState, setUniversitiesDataState] = useState([]);
  const [universitySelectedState, setUniversitySelectedState] = useState(null);

  const [universityCountryDataState, setUniversityCountrDataState] = useState(
    []
  );
  const [
    universityCountrySelectedState,
    setUniversityCountrySelectedState
  ] = useState(null);

  const [exportButtonState] = useState(false);

  const [editPostulationState, setEditPostulationState] = useState(true);

  const [activeSaveForm, setActivateSaveForm] = useState(false);

  let initialRadioState = {
    eunacom: '',
    englishLevel: '',
    superintendencyRegistration: '',
    influenzaVaccine: '',
    hepatitisBVaccine: ''
  };
  const [stateRadioButtons, setStateRadioButtons] = useState(initialRadioState);

  //STATE SUBMIT
  const [submitSpecialtyState, setSubmitSpecialtyState] = useState(null);

  const [submitPersonalDataState, setSubmitPersonalDataState] = useState(null);

  const [
    submitTrainingInformationState,
    setSubmitTrainingInformationState
  ] = useState(null);

  const [
    submitHealthInformationState,
    setSubmitHealthInformationState
  ] = useState(null);
  // FIN STATE SUBMIT

  const [
    postulationSpecialtyDataState,
    setPostulationSpecialtyDataState
  ] = useState([]);
  const [
    postulationCurricularBackgroundDataState,
    setPostulationCurricularBackgroundDataState
  ] = useState([]);

  const [
    postulationPublicationDataState,
    setPostulationPublicationDataState
  ] = useState([]);

  const [
    postulationPresentationDataState,
    setPostulationPresentationDataState
  ] = useState([]);

  const [
    postulationAssistantShipDataState,
    setPostulationAssistantShipDataState
  ] = useState([]);

  const [
    postulationDiplomatDataState,
    setPostulationDiplomatDataState
  ] = useState([]);

  const [
    postulationPerformanceDataState,
    setPostulationPerformanceDataState
  ] = useState([]);

  const [
    postulationDistinctionDataState,
    setPostulationDistinctionDataState
  ] = useState([]);

  const [personalDataState, setPersonalDataState] = useState({
    id: null,
    rut: '',
    name: '',
    paternalLastname: '',
    maternalLastname: '',
    birthdate: null,
    address: '',
    email: '',
    phone: '',
    region: '',
    commune: '',
    nationality: '',
    documentSimpleCopyCNI: '',
    documentUpdatedResume: '',
    documentPresentationLetter: '',
    documentDefinitiveResidence: ''
  });

  const [
    trainingInformationDataState,
    setTrainingInformationDataState
  ] = useState({
    id: null,
    jobTitle: '',
    yearTitle: '',
    studentUniversity: '',
    otherUniversity: '',
    otherUniversityCountry: '',
    noteInternPediatrics: '',
    noteInternGynecology: '',
    noteInternSurgery: '',
    noteInternalMedicine: '',
    noteNationalMedical: '',
    eunacomApproved: '',
    eunacomYear: '',
    eunacomScore: '',
    studentLanguageLevel: '',
    documentProfessionCertificate: '',
    documentNationalMedicalQualification: '',
    documentEunacomCertificate: ''
  });

  const [healthInformationDataState, setHealthInformationDataState] = useState({
    id: null,
    healthSuperintendenceRegistration: '',
    registerNumber: '',
    influenzaVaccine: '',
    hepatitisbVaccine: '',
    doseHepatitisb: 0,
    policyProfessionalLiabilityNumber: '',
    policyProfessionalLiabilityName: '',
    documentSuperintendencyRegistrationCertificate: '',
    documentInfluenzaCertificate: '',
    documentHepatitisCertificate: '',
    documentSecureAffiliationCertificate: '',
    documentHealthAffiliationCertificate: '',
    documentMobilityPassCertificate: ''
  });

  const tabItems = [
    'Especialidad',
    'Datos Personales',
    'Información Formativa',
    'Información de Salud',
    'Formación Adicional'
  ];

  const [insuranceActiveState, setInsuranceActiveState] = useState(
    localStorage.getItem('insuranceActive')
  );

  const [openDialogConfirmState, setOpenDialogConfirmState] = useState(false);
  const [rowToHandleState] = useState('');
  const [
    activeButtonSendPostulation,
    setActiveButtonSendPostulation
  ] = useState(false);

  const { handleSubmit, register, errors, reset, setValue, watch } = useForm();

  //  const watchCompetition = watch("competition");

  //*************************** QUERIES HANDLER AND USEQUERY SELECT IN ALL TABS  *******************************

  // const { data: getEditPostulationGraph } = useQuery(getEditPostulation, {
  //   variables: { idUser: parseInt(userCadidateId) },
  //   errorPolicy: 'all',
  //   fetchPolicy: 'network-only',
  //   skip: userCadidateId ? false : true
  // });

  const [
    getEditPostulationHandler,
    { loading: loadingGetEditPostulationGraph, data: getEditPostulationGraph }
  ] = useLazyQuery(getEditPostulation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });


  // nuevo get edit con postulation
  useEffect(() => {
    async function queryData() {
      try {
        await getEditPostulationHandler({
          variables: { idUser: parseInt(userCadidateId), idPostulationActive: activePostulationState !== '0' ? parseInt(activePostulationState) : null  }
        });
      } catch (err) {}
    }
    if (userCadidateId) {
      console.log("-------------------> se ejecuta getedit, idUser: ", parseInt(userCadidateId), "------Z postulationActive: ", parseInt(activePostulationState) )
      queryData();
    }
    
  }, [userCadidateId, activePostulationState]);

  useEffect(() => {
    if (getEditPostulationGraph && userProfile !== 'Secretaria') {
      console.log('pasa el get edit--------->', getEditPostulationGraph);
      setEditPostulationState(getEditPostulationGraph.getEditPostulation);
    }
  }, [getEditPostulationGraph, editPostulationState]);

  useEffect(() => {
    if (!userCadidateId) {
      setValueTabState(0);
    }
  }, [userCadidateId]);

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listCompetitionsHandler,
    { loading: loadingCompetitionsGraph, data: dataCompetitionsGraph }
  ] = useLazyQuery(listCompetitions, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const {
    loading: loadingListNationalityGraph,
    data: dataListNationalityGraph
  } = useQuery(listNationality, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const {
    loading: loadingListRegionGraph,
    data: dataListRegionGraph
  } = useQuery(listRegion, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const {
    loading: loadingListCountryGraph,
    data: dataListCountryGraph
  } = useQuery(listCountry, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const {
    loading: loadinglistStudentUniversityGraph,
    data: datalistStudentUniversityGraph
  } = useQuery(listStudentUniversity, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [
    listCommuneHandler,
    { loading: loadingListComuneGraph, data: dataListComuneGraph }
  ] = useLazyQuery(listCommuneByRegion, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // ***********************
  const [
    getPostulationStateHandler,
    {
      loading: loadingGetPostulationStateGraph,
      data: dataGetPostulationStateGraph
    }
  ] = useLazyQuery(getPostulationStatePostulation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  //*************************** CREATE HANDLER  *******************************
  // create specialty
  const [
    createAndUpdatePostulationSpecialtyHandler,
    { loading: loadingSubmitGraphSpecialty, data: dataSubmitGraphSpecialty }
  ] = useMutation(createAndUpdatePostulationSpecialty);

  // create personal data
  const [
    createAndUpdatePostulationPersonalDataHandler,
    {
      loading: loadingSubmitGraphPersonalData,
      data: dataSubmitGraphPersonalData
    }
  ] = useMutation(createAndUpdatePostulationPersonalData);

  // create training information
  const [
    createAndUpdatePostulationTrainingInformationHandler,
    {
      loading: loadingSubmitGraphTrainingInformation,
      data: dataSubmitGraphTrainingInformation
    }
  ] = useMutation(createAndUpdatePostulationTrainingInformation);

  // create health information
  const [
    createAndUpdatePostulationHealthInformationHandler,
    {
      loading: loadingSubmitGraphHealthInformation,
      data: dataSubmitGraphHealthInformation
    }
  ] = useMutation(createAndUpdatePostulationHealthInformation);

  // send postulation
  const [
    sendPostulationHandler,
    { loading: loadingSendPostulationGraph, data: dataSendPostulationGraph }
  ] = useMutation(sendPostulation);

  /*CONTROLADORES SNACKBAR DE EXITO SUBMIT**/
  useEffect(() => {
    if (
      dataSubmitGraphSpecialty &&
      dataSubmitGraphSpecialty.createAndUpdatePostulationSpecialty
    ) {
      setSubmitSpecialtyState(
        dataSubmitGraphSpecialty.createAndUpdatePostulationSpecialty
      );
      // para que se puedan guardar las otras pestañas
      // console.log("PASA POR ACA Y ACTIVA FORM");
      setActivateSaveForm(true);
    }
  }, [dataSubmitGraphSpecialty]);

  useEffect(() => {
    if (dataSubmitGraphPersonalData) {
      // console.log("------------", dataSubmitGraphPersonalData)
      setSubmitPersonalDataState(dataSubmitGraphPersonalData);
    }
  }, [dataSubmitGraphPersonalData]);

  useEffect(() => {
    if (dataSubmitGraphTrainingInformation) {
      setSubmitTrainingInformationState(dataSubmitGraphTrainingInformation);
    }
  }, [dataSubmitGraphTrainingInformation]);

  useEffect(() => {
    if (dataSubmitGraphHealthInformation) {
      setSubmitHealthInformationState(dataSubmitGraphHealthInformation);
    }
  }, [dataSubmitGraphHealthInformation]);

  useEffect(() => {
    setSubmitSpecialtyState(null);
    setSubmitPersonalDataState(null);
    setSubmitTrainingInformationState(null);
    setSubmitHealthInformationState(null);
  }, [valueTabState]);

  //*************************** QUERIES HANDLER LOAD DATA  *******************************

  const [
    listPostulationSpecialtyHandler,
    { data: dataListPostulationSpecialtyGraph, loading: loadingdataListPostulationSpecialtyGraph, }
  ] = useLazyQuery(listPostulationSpecialty, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationSpecialtyHandler,
    { data: dataPostulationSpecialtyGraph }
  ] = useLazyQuery(getPostulationSpecialty, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPersonalDataHandler,
    {
      loading: loadingPostulationPersonalGraph,
      data: dataPostulationPersonalGraph
    }
  ] = useLazyQuery(getPostulationPersonalData, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getTrainingInformationHandler,
    {
      loading: loadingPostulationTrainingInformationGraph,
      data: dataPostulationTrainingInformationGraph
    }
  ] = useLazyQuery(getPostulationTrainingInformation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationHealthInformationHandler,
    {
      loading: loadingPostulationHealthInformationGraph,
      data: dataPostulationHealthInformationGraph
    }
  ] = useLazyQuery(getPostulationHealthInformation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // --------- TAB 5 --------
  const [
    getCurricularBackgroundHandler,
    {
      loading: loadingCurricularBackgroundGraph,
      data: dataCurricularBackgroundGraph
    }
  ] = useLazyQuery(getPostulationCurricularBackgroundList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationPublicationHandler,
    {
      loading: loadingPostulationPublicationGraph,
      data: dataPostulationPublicationGraph
    }
  ] = useLazyQuery(getPostulationPublicationList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationPresentationHandler,
    {
      loading: loadingPostulationPresentationGraph,
      data: dataPostulationPresentationGraph
    }
  ] = useLazyQuery(getPostulationPresentationList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationAssistantShipHandler,
    {
      loading: loadingPostulationAssistantShipGraph,
      data: dataPostulationAssistantShipGraph
    }
  ] = useLazyQuery(getPostulationAssistantShipList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationDiplomatHandler,
    {
      loading: loadingPostulationDiplomatGraph,
      data: dataPostulationDiplomatGraph
    }
  ] = useLazyQuery(getPostulationDiplomatList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationPerformanceHandler,
    {
      loading: loadingPostulationPerformanceGraph,
      data: dataPostulationPerformanceGraph
    }
  ] = useLazyQuery(getPostulationPerformanceList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    getPostulationDistinctionHandler,
    {
      loading: loadingPostulationDistinctionGraph,
      data: dataPostulationDistinctionGraph
    }
  ] = useLazyQuery(getPostulationDistinctionList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataCurricularBackgroundGraph &&
      dataCurricularBackgroundGraph.getPostulationCurricularBackgroundList
    ) {
      setPostulationCurricularBackgroundDataState(
        dataCurricularBackgroundGraph.getPostulationCurricularBackgroundList
      );
    }
  }, [dataCurricularBackgroundGraph /*, valueTabState*/]);

  useEffect(() => {
    // console.log("----------------------->postulationPublicationDataState", dataPostulationPublicationGraph)
    if (
      /*valueTabState === 1 &&*/
      dataPostulationPublicationGraph &&
      dataPostulationPublicationGraph.getPostulationPublicationList
    ) {
      setPostulationPublicationDataState(
        dataPostulationPublicationGraph.getPostulationPublicationList
      );
    }
  }, [dataPostulationPublicationGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataPostulationPresentationGraph &&
      dataPostulationPresentationGraph.getPostulationPresentationList
    ) {
      setPostulationPresentationDataState(
        dataPostulationPresentationGraph.getPostulationPresentationList
      );
    }
  }, [dataPostulationPresentationGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataPostulationAssistantShipGraph &&
      dataPostulationAssistantShipGraph.getPostulationAssistantShipList
    ) {
      setPostulationAssistantShipDataState(
        dataPostulationAssistantShipGraph.getPostulationAssistantShipList
      );
    }
  }, [dataPostulationAssistantShipGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataPostulationDiplomatGraph &&
      dataPostulationDiplomatGraph.getPostulationDiplomatList
    ) {
      setPostulationDiplomatDataState(
        dataPostulationDiplomatGraph.getPostulationDiplomatList
      );
    }
  }, [dataPostulationDiplomatGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataPostulationPerformanceGraph &&
      dataPostulationPerformanceGraph.getPostulationPerformanceList
    ) {
      setPostulationPerformanceDataState(
        dataPostulationPerformanceGraph.getPostulationPerformanceList
      );
    }
  }, [dataPostulationPerformanceGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      /*valueTabState === 1 &&*/
      dataPostulationDistinctionGraph &&
      dataPostulationDistinctionGraph.getPostulationDistinctionList
    ) {
      setPostulationDistinctionDataState(
        dataPostulationDistinctionGraph.getPostulationDistinctionList
      );
    }
  }, [dataPostulationDistinctionGraph /*, valueTabState*/]);

  useEffect(() => {
    if (
      valueTabState === 0 &&
      dataPostulationSpecialtyGraph &&
      dataPostulationSpecialtyGraph.getPostulationSpecialty
    ) {
      console.log(dataPostulationSpecialtyGraph, "----------------------------------------dataPostulationSpecialtyGraph")
      setPostulationSpecialtyDataState(
        dataPostulationSpecialtyGraph.getPostulationSpecialty
      );
    }
  }, [dataPostulationSpecialtyGraph, valueTabState]);

  // useEffect(() => {
  //   console.log(watchCompetition, "-----watchCompetition")
  //   if(watchCompetition){
  //     const arraySpecialty = competitionDataState.filter(ele => ele.id === parseInt(watchCompetition));

  //     console.log(arraySpecialty);
  //     setSpecialtyDataState(arraySpecialty[0].specialty);
  //   }
  // }, [watchCompetition]);

  // para secretaria limpia context cuando cambia alumno
  useEffect(() => {
    if(userProfile === 'Secretaria') {
      clearPostulation(); 
    }
       
  }, [userSelectedState]);

  //CARGA DE SELECT QUE SE DEBEN MOSTRAR EN TAB ESPECIALIDAD
  useEffect(() => {
    if (valueTabState === 0) {
      // console.log('--------------->aqui busca valores concurso y especialidad', postulationSpecialtyDataState);
      const specialtyUser = postulationSpecialtyDataState.specialty;
      const competitionUser = postulationSpecialtyDataState.competition;
      console.log(specialtyUser, "-------postulationSpecialtyDataState", competitionUser)
      if (specialtyUser && specialtyUser.id && competitionUser && competitionUser.id) {
        console.log(
          '--------------->ADENTRO aqui busca valores concurso y especialidad, ',specialtyUser
        );
        setSpecialtySelectedState(specialtyUser.id);
        setCompetitionSelectedState(competitionUser.id);
        if(specialtyUser.id){
          setActivateSaveForm(true);
        }
      } else {
        setSpecialtySelectedState('');
        setCompetitionSelectedState('');
      }
    }
  }, [postulationSpecialtyDataState, valueTabState, userCadidateId]);

  useEffect(() => {
    if (dataCompetitionsGraph && dataCompetitionsGraph.listCompetitions) {

      // const dateNow = new Date();

			// let formatter = new Intl.DateTimeFormat('en-US', {
			// 	timeZone: 'America/Santiago',
			// });

      // console.log(dateFormatIso2(formatter.format(dateNow)), dateConvert(dataCompetitionsGraph.listCompetitions[0].until));

      // console.log(dataCompetitionsGraph.listCompetitions, "hhhhhhhh");

      // let result = dataCompetitionsGraph.listCompetitions.filter(ele => dateFormatIso2(dateConvert(ele.until)) >= dateFormatIso2(formatter.format(dateNow)));

      // solo carga concursos que no terminaron el fecha y activos

      // setCompetitionDataState(result);
      setCompetitionDataState(dataCompetitionsGraph.listCompetitions);
      // console.log(dataCompetitionsGraph, '<---dataCompetitionsGraph-----');

      

    }
  }, [dataCompetitionsGraph, userCadidateId]);

  // ya no consulta, las toma del concurso
  // useEffect(() => {
  //   if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
  //     setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
  //     // console.log(specialtyDataState, '<--------');
  //   }
  // }, [dataSpecialitiesGraph, userCadidateId]);

  useEffect(() => {
    if (
      valueTabState === 1 &&
      dataPostulationPersonalGraph &&
      dataPostulationPersonalGraph.getPostulationPersonalData
    ) {

      // console.log(dataPostulationPersonalGraph, "--------------------------------dataPostulationPersonalGraph")

      setPersonalDataState({
        ...dataPostulationPersonalGraph.getPostulationPersonalData
      });
    }
  }, [dataPostulationPersonalGraph, valueTabState]);
  // CARGA
  useEffect(() => {
    if (
      valueTabState === 2 &&
      dataPostulationTrainingInformationGraph &&
      dataPostulationTrainingInformationGraph.getPostulationTrainingInformation
    ) {
      setTrainingInformationDataState({
        ...dataPostulationTrainingInformationGraph.getPostulationTrainingInformation
      });
    }
  }, [dataPostulationTrainingInformationGraph, valueTabState]);

  useEffect(() => {
    if (
      valueTabState === 3 &&
      dataPostulationHealthInformationGraph &&
      dataPostulationHealthInformationGraph.getPostulationHealthInformation
    ) {
      setHealthInformationDataState({
        ...dataPostulationHealthInformationGraph.getPostulationHealthInformation
      });
    }
  }, [dataPostulationHealthInformationGraph, valueTabState]);

  useEffect(() => {
    async function queryData() {
      try {
        await listCompetitionsHandler({
          variables: { 
            sort: { name: "asc"},
            filter: { 
              state: { eq: 1 }, 
              id: { gt: 0 } 
            } 
          }
        });
      } catch (err) {}
    }
    if (valueTabState === 0 && userCadidateId) {
      queryData();
    }
  }, [listCompetitionsHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { 
            sort: { name: "asc"},
            filter: { id: { gt: 0 } } 
          }
        });
      } catch (err) {}
    }
    if (valueTabState === 0 && userCadidateId) {
      queryData();
    }
  }, [listSpecialitiesHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPersonalDataHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 1 && userCadidateId) {
      queryData();
    }
    if (submitPersonalDataState && userCadidateId) {
      queryData();
    }
  }, [
    getPersonalDataHandler,
    submitPersonalDataState,
    valueTabState,
    userCadidateId
  ]);

  useEffect(() => {
    async function queryData() {
      try {
        await getTrainingInformationHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 2 && userCadidateId) {
      queryData();
    }

    if (submitTrainingInformationState && userCadidateId) {
      queryData();
    }
  }, [
    getTrainingInformationHandler,
    submitTrainingInformationState,
    valueTabState,
    userCadidateId
  ]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationHealthInformationHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 3 && userCadidateId) {
      queryData();
    }
    if (submitHealthInformationState) {
      queryData();
    }
  }, [
    getPostulationHealthInformationHandler,
    submitHealthInformationState,
    valueTabState,
    userCadidateId
  ]);


  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationSpecialtyHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive)  }
        });
      } catch (err) {}
    }
    if (valueTabState === 0 && userCadidateId) {
      console.log(activePostulation.idPostulationActive, "-----dentro de queryData getPostulation")
      queryData();
    }
  }, [getPostulationSpecialtyHandler, valueTabState, userCadidateId, activePostulation]);

  useEffect(() => {
    async function queryData() {
      try {
        await getCurricularBackgroundHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getCurricularBackgroundHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationPublicationHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationPublicationHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationPresentationHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationPresentationHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationAssistantShipHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationAssistantShipHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationDiplomatHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationDiplomatHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationPerformanceHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationPerformanceHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationDistinctionHandler({
          variables: { id: parseInt(userCadidateId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (valueTabState === 4 && userCadidateId) {
      queryData();
    }
  }, [getPostulationDistinctionHandler, valueTabState, userCadidateId]);

  useEffect(() => {
    // console.log(dataListNationalityGraph, "----------dataListNationalityGraph")
    if (dataListNationalityGraph && dataListNationalityGraph.listNationality) {
      setNationalitiesDataState(dataListNationalityGraph.listNationality);
      setValueTabState(0);
    }
  }, [dataListNationalityGraph]);

  useEffect(() => {
    if (dataListRegionGraph && dataListRegionGraph.listRegion) {
      setRegionsDataState(dataListRegionGraph.listRegion);
    }
  }, [dataListRegionGraph]);

  useEffect(() => {
    if (dataListComuneGraph && dataListComuneGraph.listCommuneByRegion) {
      setCommunesDataState(dataListComuneGraph.listCommuneByRegion);
    }
  }, [dataListComuneGraph]);

  useEffect(() => {
    if (
      datalistStudentUniversityGraph &&
      datalistStudentUniversityGraph.listStudentUniversity
    ) {
      setUniversitiesDataState(
        datalistStudentUniversityGraph.listStudentUniversity
      );
    }
  }, [datalistStudentUniversityGraph]);

  useEffect(() => {
    if (dataListCountryGraph && dataListCountryGraph.listCountry) {
      setUniversityCountrDataState(dataListCountryGraph.listCountry);
    }
  }, [dataListCountryGraph]);

  useEffect(() => {
    async function queryData(id) {
      try {
        await listCommuneHandler({
          variables: { id: parseInt(id) }
        });
      } catch (err) {}
    }

    if (regionSelectedState) {
      queryData(regionSelectedState.id);
    }

    if (!regionSelectedState) {
      setCommuneSelectedState('');
    }
  }, [listCommuneHandler, regionSelectedState]);

  useEffect(() => {
    // console.log(personalDataState, "<-----------------------------------personalDataState");
    if (valueTabState === 1) {
      const {
        id,
        rut,
        name,
        paternalLastname,
        maternalLastname,
        birthDate,
        address,
        email,
        phone,
        region,
        commune,
        nationality
      } = personalDataState;

      if (
        nationalitySelectedState !== '' ||
        regionSelectedState !== '' ||
        communeSelectedState !== ''
      ) {
      } else if (
        nationality.id !== null &&
        nationality.id !== 0 &&
        region.id !== null &&
        region.id !== 0 &&
        commune.id !== null &&
        commune.id !== 0
      ) {
        setNationalitySelectedState(nationality);
        setRegionSelectedState(region);
        setCommuneSelectedState(commune);
      } else {
        setNationalitySelectedState('');
        setRegionSelectedState('');
        setCommuneSelectedState('');
      }
      if (birthDate) {
        let birthformat = dateConvert(birthDate);
        // console.log("------Cuando ya llegó------>", birthDate,"----",birthformat);
        setBirthdateSelectedState(birthformat);
      }
      reset({
        id: parseInt(id),
        rut: rut,
        name: name,
        paternalLastname: paternalLastname,
        maternalLastname: maternalLastname,
        // birthdate: birthdate,
        address: address,
        email: email,
        phone: phone
      });
    }
  }, [personalDataState, reset, valueTabState]);

  // useEffect();

  useEffect(() => {
    if (valueTabState === 2) {
      const {
        id,
        jobTitle,
        yearTitle,
        studentUniversity,
        otherUniversity,
        otherUniversityCountry,
        noteInternPediatrics,
        noteInternGynecology,
        noteInternSurgery,
        noteInternalMedicine,
        noteNationalMedical,
        eunacomApproved,
        eunacomYear,
        eunacomScore,
        studentLanguageLevel
      } = trainingInformationDataState;

      // if (studentUniversity && studentUniversity.id !== 0) {
      if (studentUniversity) {
        setUniversitySelectedState(studentUniversity);
      }

      if (otherUniversityCountry) {
        setUniversityCountrySelectedState(otherUniversityCountry);
      }
      let valueLenguageLevel = '';
      if (studentLanguageLevel !== null) {
        switch (studentLanguageLevel.id) {
          case 1:
            valueLenguageLevel = '1';
            break;
          case 2:
            valueLenguageLevel = '2';
            break;
          case 3:
            valueLenguageLevel = '3';
            break;
          case 4:
            valueLenguageLevel = '4';
            break;
          default:
            break;
        }
      }

      initialRadioState = {
        ...initialRadioState,
        eunacom:
          eunacomApproved !== ''
            ? eunacomApproved === 1
              ? '1'
              : eunacomApproved === 2
              ? '2'
              : ''
            : '',
        englishLevel: valueLenguageLevel
      };

      setStateRadioButtons(initialRadioState);

      reset({
        id: parseInt(id),
        careerTitle: jobTitle,
        yearDegree: yearTitle,
        otherUniversity: otherUniversity !== '0' ? otherUniversity : '',

        pediatricInternship: decimalAdjust(
          'round',
          parseFloat(noteInternPediatrics),
          -1
        ),
        gynecologyInternship: decimalAdjust(
          'round',
          parseFloat(noteInternGynecology),
          -1
        ),
        surgeryInternship: decimalAdjust(
          'round',
          parseFloat(noteInternSurgery),
          -1
        ),
        internalMedicineRating: decimalAdjust(
          'round',
          parseFloat(noteInternalMedicine),
          -1
        ),
        nationalMedicalRating: decimalAdjust(
          'round',
          parseFloat(noteNationalMedical),
          -1
        ),
        yearEunacom: eunacomYear,
        // punctuationEunacom: eunacomScore
        punctuationEunacom: decimalAdjust('round', parseFloat(eunacomScore), -2)
      });
    }
  }, [trainingInformationDataState, reset, valueTabState]);

  useEffect(() => {
    if (valueTabState === 3) {
      const {
        id,
        healthSuperintendenceRegistration,
        registerNumber,
        influenzaVaccine,
        hepatitisbVaccine,
        doseHepatitisb,
        policyProfessionalLiabilityNumber,
        policyProfessionalLiabilityName
      } = healthInformationDataState;

      initialRadioState = {
        ...initialRadioState,
        superintendencyRegistration:
          healthSuperintendenceRegistration !== '' &&
          healthSuperintendenceRegistration !== null
            ? healthSuperintendenceRegistration === 1
              ? '1'
              : '2'
            : '',

        influenzaVaccine:
          influenzaVaccine !== '' && influenzaVaccine !== null
            ? influenzaVaccine === 1
              ? '1'
              : '2'
            : '',

        hepatitisBVaccine:
          hepatitisbVaccine !== '' && hepatitisbVaccine !== null
            ? hepatitisbVaccine === 1
              ? '1'
              : '2'
            : ''
      };

      setStateRadioButtons(initialRadioState);

      reset({
        id: parseInt(id),
        superintendencyRegistrationNumber: registerNumber,
        hepatitisBVaccineDose: doseHepatitisb,
        responsibilityPolicyNumber: policyProfessionalLiabilityNumber,
        insuranceName: policyProfessionalLiabilityName
      });
    }
  }, [healthInformationDataState, reset, valueTabState]);

  // recibe estado para el botón
  useEffect(() => {
    if (
      dataGetPostulationStateGraph &&
      dataGetPostulationStateGraph.getPostulationStatePostulation
    ) {
      setActiveButtonSendPostulation(
        dataGetPostulationStateGraph.getPostulationStatePostulation
          .postulationStatePostulation
      );
      // console.log(
      //   'llega la data del botón--------------->POSTULACION',
      //   // ACA ESTA EL PROBLEMA ESTE DEBEWRÌA VENIR FALSE PARA CONCURSO TERMINADO
      //   dataGetPostulationStateGraph.getPostulationStatePostulation
      //     .postulationStatePostulation
      // );
    }
  }, [dataGetPostulationStateGraph]);

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationStateHandler({
          variables: { 
            idUser: parseInt(userCadidateId),
            idPostulationActive: parseInt(activePostulation.idPostulationActive),
          }
        });
      } catch (err) {}
    }
    if (userCadidateId && activePostulation.idPostulationActive) {
      // console.log('consulta estado al empezar');
      queryData();
    }
  }, [getPostulationStateHandler, userCadidateId, activePostulation]);

  const handleChangeTab = (event, newValue) => {
    if(activePostulation.idPostulationActive){
      setValueTabState(newValue);
    }
    
  };

  const handleRadioCheck = value => {
    setStateRadioButtons({
      ...stateRadioButtons,
      [value.name]: value.value
    });
    if (value.name === 'superintendencyRegistration' && value.value === '2') {
      setValue('superintendencyRegistrationNumber', '');
    }
    if (value.name === 'hepatitisBVaccine' && value.value === '2') {
      setValue('hepatitisBVaccineDose', '');
    }
  };

  const handleRadioActiveInsuranceCheck = value => {
    setInsuranceActiveState(value.value);
    localStorage.setItem('insuranceActive', value.value);
  };

  const onSubmit = async values => {
    let variable;
    let idNationality = '';
    let idRegion = '';
    let idCommune = '';
    let idUniversity = '';
    let idUniversityCountry = '';

     console.log("----enviados a create", userCadidateId, activePostulation.idPostulationActive, values.competition);

    switch (valueTabState) {
     
      case 0:
        variable = {
          id: parseInt(userCadidateId),
          idPostulationActive: parseInt(activePostulation.idPostulationActive),
          idCompetition: parseInt(values.competition),
          idSpecialty: parseInt(values.specialty)
        };
        try {
          await createAndUpdatePostulationSpecialtyHandler({
            variables: variable
          });
        } catch (err) {}
        break;
      case 1:
        if (nationalitySelectedState) {
          idNationality = nationalitySelectedState.id;
        }
        if (regionSelectedState) {
          idRegion = regionSelectedState.id;
        }
        if (communeSelectedState) {
          idCommune = communeSelectedState.id;
        }
        variable = {
          id: parseInt(userCadidateId),
          rut: formatRut(values.rut),
          name: values.name,
          paternalLastname: values.paternalLastname,
          maternalLastname: values.maternalLastname,
          birthDate: values.birthdate,
          nationality: idNationality,
          region: idRegion,
          commune: idCommune,
          address: values.address,
          phone: values.phone,
          email: values.email,
          documentSimpleCopyCNI: values.CNIsimpleCopy,
          documentUpdatedResume: values.updatedCurriculumVitae,
          documentPresentationLetter: values.presentationLetter,
          documentProfilePhoto: values.profilePhoto,
          documentRecommendationLetter: values.recommendationLetter,
          documentBirthCertificate: values.birthCertificate,
          documentDefinitiveResidence: values.residence,
          idPostulationActive: parseInt(activePostulation.idPostulationActive)
        };
        try {
          await createAndUpdatePostulationPersonalDataHandler({
            variables: variable
          });
        } catch (err) {}
        break;
      case 2:
        if (universitySelectedState) {
          idUniversity = universitySelectedState.id;
        }
        if (universityCountrySelectedState) {
          idUniversityCountry = universityCountrySelectedState.id;
        }
        variable = {
          id: parseInt(userCadidateId),
          jobTitle: values.careerTitle,
          yearTitle: parseInt(values.yearDegree),
          idStudentUniversity: idUniversity,
          otherUniversity: idUniversity === 0 ? values.otherUniversity : '',
          idOtherUniversityCountry: idUniversityCountry,
          noteInternPediatrics: parseFloat(values.pediatricInternship),
          noteInternGynecology: parseFloat(values.gynecologyInternship),
          noteInternSurgery: parseFloat(values.surgeryInternship),
          noteNationalMedical: parseFloat(values.nationalMedicalRating),
          eunacomApproved: parseInt(values.eunacom),
          eunacomYear: parseInt(values.yearEunacom),
          eunacomScore: parseFloat(values.punctuationEunacom),
          idStudentLanguageLevel: parseInt(values.englishLevel),
          documentProfessionCertificate: values.certificateOfTitle,
          documentNationalMedicalQualification:
            values.documentNationalMedicalRating,
          documentEunacomCertificate: values.eunacomAproved,
          noteInternalMedicine: parseFloat(values.internalMedicineRating),
          idPostulationActive: parseInt(activePostulation.idPostulationActive)
        };
        try {
          await createAndUpdatePostulationTrainingInformationHandler({
            variables: variable
          });
        } catch (err) {}
        break;
      case 3:
        let doseHepatitisbValue = '';
        if (values.hepatitisBVaccineDose !== '') {
          doseHepatitisbValue = parseFloat(values.hepatitisBVaccineDose);
        } else {
          doseHepatitisbValue = parseFloat(0);
        }
        variable = {
          id: parseInt(userCadidateId),
          healthSuperintendenceRegistration: parseInt(
            values.superintendencyRegistration
          ),
          registerNumber: values.superintendencyRegistrationNumber,
          influenzaVaccine: parseInt(values.influenzaVaccine),
          hepatitisbVaccine: parseInt(values.hepatitisBVaccine),
          doseHepatitisb: doseHepatitisbValue,
          policyProfessionalLiabilityNumber: values.responsibilityPolicyNumber,
          policyProfessionalLiabilityName: values.insuranceName,
          documentSuperintendencyRegistrationCertificate:
            values.superintendencyRegistrationCertificate,
          documentHepatitisCertificate: values.hepatitisCertificate,
          documentSecureAffiliationCertificate:
            values.secureAffiliationCertificate,
          documentInfluenzaCertificate: values.influenzaCertificate,
          documentHealthAffiliationCertificate:
            values.healthAffiliationCertificate,
          documentMobilityPassCertificate:
            values.mobilityPassCertificate,
            idPostulationActive: parseInt(activePostulation.idPostulationActive)
        };
        try {
          await createAndUpdatePostulationHealthInformationHandler({
            variables: variable
          });
        } catch (err) {}
        break;
      default:
        break;
    }

    // en tab 0, al guardar debería reload si la selección es 0
    // console.log(activePostulationState, "--------");
    // if(valueTabState === 0 && (activePostulationState === '0' || activePostulationState === '')) {
    //   window.location.reload(false);
    // }

    async function queryData() {
      try {
        await getPostulationStateHandler({
          variables: { 
            idUser: parseInt(userCadidateId),
            idPostulationActive: parseInt(activePostulation.idPostulationActive),
          }
        });
      } catch (err) {}
    }
    if (valueTabState === 1 || valueTabState === 2 || valueTabState === 3 && activePostulation.idPostulationActive) {
      // console.log('consulta estado');
      queryData();
    }
  };

  //DESAPARECE BOTON GUARDAR
  // useEffect(() => {
  //   if (editPostulationState === false) {
  //     const elems = document.getElementsByClassName('EditPostulation');
  //     for (let i = 0; i < elems.length; i += 1) {
  //       elems[i].style.display = 'none';
  //     }
  //   }
  // }, [editPostulationState]);

  // Botón del submit Postulation
  useEffect(() => {
    if (dataSendPostulationGraph) {
      // console.log("---------permitir envio postulacion", dataSendPostulationGraph);
      setActiveButtonSendPostulation(dataSendPostulationGraph.sendPostulation);
    }
  }, [dataSendPostulationGraph]);

  const submitPostulationHandler = async () => {
    setOpenDialogConfirmState(!openDialogConfirmState);
  };

  const stateActionDialogHandler = async () => {
    console.log(parseInt(activePostulation.idPostulationActive), "<------")
    let variable;
    variable = {
      idUser: parseInt(userCadidateId),
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await sendPostulationHandler({
        variables: variable
      });
    } catch (err) {}
  };

  const disabledValidationOtherUniversity = () => {
    let disabledvalue = 0;
    if (!editPostulationState) {
      disabledvalue = 1;
    }
    // if (universitySelectedState) {
    //   disabledvalue = 1;
    // }
    if (universitySelectedState && universitySelectedState.id !== 0) {
      disabledvalue = 1;
    }
    if (disabledvalue === 1) {
      return true;
    }
    return false;
  };

  //---------- para la secretaria

  // Cuando recibe el listado de postulantes
  useEffect(() => {
    if (dataUsersGraph && dataUsersGraph.listUsers) {
      console.log(dataUsersGraph.listUsers, "---------dataUsersGraph.listUsers")
      // const usersFiltered = dataUsersGraph.listUsers.filter(
      //   student => !student.delete
      // );
      // delete false filtrar array

      // setUsersDataState(usersFiltered);
      setUsersDataState(dataUsersGraph.listUsers);
    }
  }, [dataUsersGraph]);

  useEffect(() => {
    // para traer postulaciones activas
    async function queryData() {
      try {
        await listPostulationSpecialtyHandler({
          variables: { id: parseInt(userCadidateId) }
        });
      } catch (err) {}
    }
    if (userCadidateId) {
      queryData();
    }
  }, [userCadidateId]);

  useEffect(() => {
    if (
      dataListPostulationSpecialtyGraph &&
      dataListPostulationSpecialtyGraph.listPostulationSpecialty
    ) {
      // console.log(dataListPostulationSpecialtyGraph, "-----------------------------dataListPostulationSpecialtyGraph", userCadidateId)
      // si tiene una postulación al menos, la manda al context
      if(dataListPostulationSpecialtyGraph.listPostulationSpecialty.length > 0 && activePostulation.idPostulationActive === null){

        // console.log("entro y ", dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].idPostulation)

        addFeature({
          idPostulationActive: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].idPostulation,
          nameCompetition: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].nameCompetition,
	        nameSpecialty: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].nameSpecialty,
        });
      }
      setDataActivePostulationsState(dataListPostulationSpecialtyGraph.listPostulationSpecialty);
      // va a setear la default con la [0] o null
      // changeActive(dataListPostulationSpecialtyGraph.listPostulationSpecialty[0]);
      setLoadingActivePostulationState(false);
    }
  }, [dataListPostulationSpecialtyGraph]);

  // cuando se setea el context, selecciona el select
  useEffect(() => {
    // console.log("--------activePostulation------>",activePostulation)
    if(activePostulation.idPostulationActive) {
      setActivePostulationState(activePostulation.idPostulationActive);
      setActivePostulationSelectState(activePostulation.idPostulationActive);
    }
    
  }, [activePostulation]);

  const getCandidateFilter = () => {
    if (userProfile !== 'Secretaria') {
      return null;
    }

    const onClear = () => {
      console.log("------")
    }

    return (
      <BsRow class="justify-content-md-center">
        <BsCol class="col-md-8 text-center">
          <BsRow>
            <BsCol class="col-md-10">
              <AutoCompleteWidget
                label="Seleccione Postulante"
                require={true}
                data={usersDataState}
                type="user"
                dataOptionLabel="fullname"
                inicialValue="Seleccione Perfil..."
                valuecombo={userSelectedState}
                checkId
                onClear={onClear}
                loadingComboState={loadingUsersGraph}
                onSelectState={setUserSelectedState}
                name="usercombo"
                register={register}
                errors={errors.usercombo}
              />
            </BsCol>
            {/* <BsCol class="col-md-5">
            <FilterAction mutationLoading={false} success={false} />
          </BsCol> */}
          </BsRow>
        </BsCol>
      </BsRow>
    );
  };

  // console.log(editPostulationState, 'estado postula');
  // console.log(valueTabState, 'tab activa');
  // console.log(activeButtonSendPostulation, "-------------------------activeButtonSendPostulation");

  const postulationHandler = () => {
    console.log("seleccionó------------>", activePostulationSelectState)
    setActivePostulationState(activePostulationSelectState);
    let activeValue = activePostulationSelectState === '0' ? null : activePostulationSelectState;
    let activePostulation = [];
    if(activeValue){
      activePostulation = dataActivePostulationsState.filter(ele => ele.idPostulation === parseInt(activeValue));
    }
    // changeActive(activeValue);
    addFeature({
          idPostulationActive: activeValue,
          nameCompetition: activePostulation[0]?.nameCompetition,
	        nameSpecialty: activePostulation[0]?.nameSpecialty,
        });
    // console.log(activePostulation, "<-------------activeValue")
    // necesario para recargar en cuaqluier pestaña
    // window.location.reload(false)
  }

  const handleChangePostulation = (e) => {
    const post = e.target.value;
    // console.log("------------>", post);
    // setActivePostulationState(post);
    setActivePostulationSelectState(post);
  }

  useEffect(() => {
    // console.log(dataParameterGraph, "--------dataParameterGraph")
    if (dataParameterGraph && dataParameterGraph.getParameter) {
      setMaxPostulationLimitState(parseInt(dataParameterGraph.getParameter.value));
    }
  }, [dataParameterGraph]);

  const PostulationSelector = () => {
    if (userProfile !== 'Postulante') {
      return null;
    }
    // console.log("-------------->dataActivePostulationsState", dataActivePostulationsState, activePostulationState)

    if(dataActivePostulationsState.length < 1) {
      return null;
    }

    return (
       <BsContainer  style={{backgroundColor: 'rgba(98,134,165, 0.1)', borderRadius: 20, paddingTop: 10, paddingBottom: 5}}>
        <BsRow class="justify-content-md-center align-items-center mt-2">
          <BsCol class="col-md-12 text-center">
            <Typography variant="h5" gutterBottom>
              Selecciona aquí la postulación activa
            </Typography>
          </BsCol>
          {!loadingdataListPostulationSpecialtyGraph && (
            <BsCol class="col-md-7 text-center">
              <FormControl className={classesUI.formControl}>
                <InputLabel htmlFor="postulation-native-simple" style={{fontSize: 20}}>Postulación</InputLabel>
                <Select
                  native
                  value={activePostulationSelectState}
                  onChange={handleChangePostulation}
                  inputProps={{
                    name: 'postulation',
                    id: 'postulation-native-simple',
                  }}
                >
                  {
                    dataActivePostulationsState.length && dataActivePostulationsState.map((postulation, index) => (
                      <option key={index} value={postulation.idPostulation}>Post. {index + 1} ({postulation.nameCompetition}/{postulation.nameSpecialty})</option>
                    ))
                  }
                  {
                    dataActivePostulationsState.length < maxPostulationLimitState && (
                      <option value="0">CREAR NUEVA</option>
                    )
                  }
                  {/* <option value="435">Postulación 1 (Concurso/Especialidad)</option>
                  <option value="438">Postulación 2/Si no hay dirá NUEVA</option> */}
                </Select>
              </FormControl>
            </BsCol>
          )}
          <BsCol class="col-md-2">
            <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={postulationHandler}
            disabled={loadingdataListPostulationSpecialtyGraph}>
              Seleccionar
            </Button>
          </BsCol>
        </BsRow>
       </BsContainer>
    )
  }

  const competitionHandler = (e) => {
    // console.log("--------------AAAAAAAA----------------", e);
    setCompetitionSelectedState(e);
    let competitionData = [
      ...competitionDataState
    ];
    const arraySpecialty = competitionData.filter(ele => ele.id === parseInt(e));

    // console.log(arraySpecialty);
     setSpecialtyDataState(arraySpecialty[0].specialty);
  }

  useEffect(() => {
     if (valueTabState === 0 && specialtySelectedState !== '') {

        let competitionData = [
          ...competitionDataState
        ];

        // console.log(competitionData, 'specialtySelectedState',competitionSelectedState)
        const arraySpecialty = competitionData.filter(ele => ele.id === parseInt(competitionSelectedState));
         setSpecialtyDataState(arraySpecialty[0]?.specialty);
      
     }
  }, [competitionSelectedState,specialtySelectedState]);

  console.log(userCadidateId,"<---------------userCadidateId", specialtyDataState)

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Formulario Postulación"
                subheader={
                  <PostulationHeader
                    disableExport={exportButtonState}
                    buttonPostulationState={activeButtonSendPostulation}
                    submitHandler={submitPostulationHandler}
                    userCadidateId={userCadidateId}
                  />
                }
              />
              <Divider />
              {getCandidateFilter()}
              {valueTabState === 0 && (<PostulationSelector />)}
              {valueTabState !== 0 && (
                <div style={{marginTop: 20}}>
                  <BsRow>
                    <BsCol class="col-md-12 text-center">
                      <Typography variant="h5">Postulación: {activePostulation.nameCompetition} / {activePostulation.nameSpecialty}</Typography>
                    </BsCol>
                  </BsRow>
                </div>
              )}
              <CardContent className={classes.postulation_class}>
                <BsRow>
                  {!loadingActivePostulationState ? (
                    <BsCol class="col-md-12 pt-3">
                    {/* {(userProfile !== 'Secretaria' || userCadidateId) && ( */}
                    {(userCadidateId) && (
                      <SimpleTabs
                        tabitems={tabItems}
                        value={valueTabState}
                        toggler={handleChangeTab}>
                        <TabPanel value={valueTabState} index={0}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Typography variant="h6" gutterBottom>
                              Seleccione Concurso y Especialidad a la que
                              postula.
                            </Typography>
                            <p>
                              En caso de haber más de un Concurso abierto deberá
                              seleccionar por qué concurso postula, sino vendrá
                              seleccionado.
                            </p>
                            <TextFieldWidgetSelect
                              options={competitionDataState}
                              name="competition"
                              req={require}
                              loading={loadingCompetitionsGraph}
                              withDefault={true}
                              selected={competitionSelectedState}
                              disabled={!Boolean(editPostulationState)}
                              onChange={competitionHandler}
                              InputLabelProps={{
                                shrink: true
                              }}
                              labeltext="Concurso"
                              register={register}
                              errors={errors.competition}
                            />
                            <TextFieldWidgetSelect
                              options={specialtyDataState?.length ? specialtyDataState : []}
                              // options={specialtyDataState}
                              name="specialty"
                              req={require}
                              loading={loadingSpecialitiesGraph}
                              withDefault={true}
                              selected={specialtySelectedState}
                              disabled={!Boolean(editPostulationState)}
                              onChange={e => setSpecialtySelectedState(e)}
                              InputLabelProps={{
                                shrink: true
                              }}
                              labeltext="Especialidad"
                              register={register}
                              errors={errors.specialty}
                            />
                           {editPostulationState && ( <div className="EditPostulation">
                              <SaveAction
                                messageType="add"
                                actionClose={valueTabState === 0 && (activePostulationState === '0' || activePostulationState === '') ? () => window.location.reload(false) : () => {}}
                                mutationLoading={loadingSubmitGraphSpecialty}
                                success={submitSpecialtyState}
                              />
                            </div>)}
                          </form>
                        </TabPanel>

                        {/* -------------->Personales */}
                        <TabPanel value={valueTabState} index={1}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Typography variant="h6" gutterBottom>
                              Ingrese datos personales
                            </Typography>
                            <Typography gutterBottom>
                              <b>Recuerde GUARDAR en cada pestaña.</b> En el escritorio puede ver los pasos del formulario que ya tiene completados.
                            </Typography>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="rut"
                                  autoComplete="rut"
                                  labeltext="Rut"
                                  placeholder="11.111.111-1"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  onChange={formatOnChangeRut}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  register={register}
                                  errors={errors.rut}
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="CNIsimpleCopy"
                                  labeltext="Copia Simple CNI"
                                  download={
                                    personalDataState.documentSimpleCopyCNIHref &&
                                    personalDataState.documentSimpleCopyCNIHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentSimpleCopyCNIHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.CNIsimpleCopy}
                                  autoComplete="Copia Simple CNI"
                                />
                              </BsCol>
                            </BsRow>

                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="name"
                                  labeltext="Nombres"
                                  loading={loadingPostulationPersonalGraph}
                                  disabled={!Boolean(editPostulationState)}
                                  req={require}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  errors={errors.name}
                                  autoComplete="nombre"
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="profilePhoto"
                                  labeltext="Foto de Perfil"
                                  download={
                                    personalDataState.documentProfilePhotoHref &&
                                    personalDataState.documentProfilePhotoHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentProfilePhotoHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.profilePhoto}
                                  // autoComplete="Copia Simple CNI"
                                />
                              </BsCol>
                            </BsRow>

                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="paternalLastname"
                                  labeltext="Apellido paterno"
                                  loading={loadingPostulationPersonalGraph}
                                  disabled={!Boolean(editPostulationState)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  autoComplete="apellido"
                                  errors={errors.paternalLastname}
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="maternalLastname"
                                  labeltext="Apellido materno"
                                  loading={loadingPostulationPersonalGraph}
                                  disabled={!Boolean(editPostulationState)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  autoComplete="apellido materno"
                                  errors={errors.maternalLastname}
                                />
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetDate
                              name="birthdate"
                              labeltext="Fecha de nacimiento"
                              value={
                                birthdateSelectedState
                                  ? birthdateSelectedState
                                  : null
                              }
                              maxDate={new Date()}
                              openTo="year"
                              // onChange={date => updateBirthdate(date)}
                              onChange={date => setBirthdateSelectedState(date)}
                              disabled={!Boolean(editPostulationState)}
                              // loading={loadingPostulationPersonalGraph}
                              register={register}
                              errors={errors.birthdate}
                            />
                            <AutoCompleteWidget
                              label="Nacionalidad"
                              require={true}
                              data={nationalitiesDataState}
                              dataOptionLabel="name"
                              inicialValue="Seleccione Nacionalidad..."
                              checkId
                              valuecombo={nationalitySelectedState}
                              loadingComboState={false}
                              loading={
                                loadingPostulationPersonalGraph ||
                                loadingListNationalityGraph
                              }
                              disabled={!Boolean(editPostulationState)}
                              onSelectState={setNationalitySelectedState}
                              name="nationalityCombo"
                              register={register}
                              errors={errors.nationalityCombo}
                            />
                            <BsRow>
                              <BsCol class="col-md-6">
                                {editPostulationState === false ? (
                                  <AutoCompleteWidget
                                    label="Región"
                                    require={true}
                                    disabled={true}
                                    data={regionsDataState}
                                    dataOptionLabel="name"
                                    checkId
                                    inicialValue="Seleccione Región..."
                                    valuecombo={regionSelectedState}
                                    loading={
                                      loadingPostulationPersonalGraph ||
                                      loadingListRegionGraph
                                    }
                                    loadingComboState={false}
                                    onSelectState={setRegionSelectedState}
                                    name="regionCombo"
                                    register={register}
                                    errors={errors.regionCombo}
                                  />
                                ) : (
                                  <AutoCompleteWidget
                                    label="Región"
                                    require={true}
                                    disabled={
                                      !Boolean(nationalitySelectedState)
                                    }
                                    data={regionsDataState}
                                    dataOptionLabel="name"
                                    checkId
                                    inicialValue="Seleccione Región..."
                                    valuecombo={regionSelectedState}
                                    loading={
                                      loadingPostulationPersonalGraph ||
                                      loadingListRegionGraph
                                    }
                                    loadingComboState={false}
                                    onSelectState={setRegionSelectedState}
                                    name="regionCombo"
                                    register={register}
                                    errors={errors.regionCombo}
                                  />
                                )}
                              </BsCol>
                              <BsCol class="col-md-6">
                                {editPostulationState === false ? (
                                  <AutoCompleteWidget
                                    label="Comuna"
                                    require={true}
                                    disabled={true}
                                    data={communesDataState}
                                    dataOptionLabel="name"
                                    checkId
                                    inicialValue="Seleccione Comuna..."
                                    valuecombo={communeSelectedState}
                                    loading={
                                      loadingPostulationPersonalGraph ||
                                      loadingListComuneGraph
                                    }
                                    loadingComboState={false}
                                    onSelectState={setCommuneSelectedState}
                                    name="communeCombo"
                                    register={register}
                                    errors={errors.communeCombo}
                                  />
                                ) : (
                                  <AutoCompleteWidget
                                    label="Comuna"
                                    require={true}
                                    disabled={!Boolean(regionSelectedState)}
                                    data={communesDataState}
                                    inicialValue="Seleccione Comuna..."
                                    checkId
                                    valuecombo={communeSelectedState}
                                    loading={
                                      loadingPostulationPersonalGraph ||
                                      loadingListComuneGraph
                                    }
                                    loadingComboState={false}
                                    onSelectState={setCommuneSelectedState}
                                    name="communeCombo"
                                    register={register}
                                    errors={errors.communeCombo}
                                  />
                                )}
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetText
                              name="address"
                              labeltext="Dirección"
                              loading={loadingPostulationPersonalGraph}
                              req={require}
                              disabled={!Boolean(editPostulationState)}
                              InputLabelProps={{
                                shrink: true
                              }}
                              minLength={3}
                              maxLength={100}
                              register={register}
                              errors={errors.address}
                              autoComplete="address"
                            />
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="email"
                                  labeltext="E-mail"
                                  loading={loadingPostulationPersonalGraph}
                                  disabled={!Boolean(editPostulationState)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  register={register}
                                  errors={errors.email}
                                  autoComplete="email"
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="phone"
                                  type="number"
                                  labeltext="Teléfono"
                                  loading={loadingPostulationPersonalGraph}
                                  disabled={!Boolean(editPostulationState)}
                                  placeholder="56999999999"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  minLength={8}
                                  maxLength={10}
                                  register={register}
                                  autoComplete="phone"
                                  errors={errors.phone}
                                />
                              </BsCol>
                            </BsRow>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="updatedCurriculumVitae"
                                  labeltext="Curriculum Vitae Actualizado"
                                  download={
                                    personalDataState.documentUpdatedResumeHref &&
                                    personalDataState.documentUpdatedResumeHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentUpdatedResumeHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.updatedCurriculumVitae}
                                  autoComplete="Curriculum Vitae Actualizado"
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="presentationLetter"
                                  labeltext="Carta de Presentación"
                                  download={
                                    personalDataState.documentPresentationLetterHref &&
                                    personalDataState.documentPresentationLetterHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentPresentationLetterHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.presentationLetter}
                                  autoComplete="Carta de Presentación"
                                />
                              </BsCol>
                            </BsRow>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="birthCertificate"
                                  labeltext="Certificado de Nacimiento"
                                  download={
                                    personalDataState.documentBirthCertificateHref &&
                                    personalDataState.documentBirthCertificateHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentBirthCertificateHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.birthCertificate}
                                  // autoComplete="Carta de Presentación"
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="residence"
                                  labeltext="Residencia definitiva"
                                  download={
                                    personalDataState.documentDefinitiveResidenceHref &&
                                    personalDataState.documentDefinitiveResidenceHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentDefinitiveResidenceHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.residence}
                                  // autoComplete="Carta de Presentación"
                                />
                              </BsCol>
                            </BsRow>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="recommendationLetter"
                                  labeltext="Carta de Recomendación"
                                  download={
                                    personalDataState.documentRecommendationLetterHref &&
                                    personalDataState.documentRecommendationLetterHref !==
                                      null ? (
                                      <a
                                        href={
                                          personalDataState.documentRecommendationLetterHref
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download>
                                        Descargarlo Aquí
                                      </a>
                                    ) : (
                                      ''
                                    )
                                  }
                                  type="file"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={loadingPostulationPersonalGraph}
                                  req={false}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  register={register}
                                  errors={errors.recommendationLetter}
                                  // autoComplete="Carta de Presentación"
                                />
                              </BsCol>
                            </BsRow>
                            <div className="EditPostulation">
                              <SaveAction
                                messageType="add"
                                disabled={!activeSaveForm}
                                mutationLoading={loadingSubmitGraphPersonalData}
                                success={submitPersonalDataState}
                              />
                            </div>
                          </form>
                        </TabPanel>

                        {/* -------------->Formativa */}
                        <TabPanel value={valueTabState} index={2}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Typography variant="h6" gutterBottom>
                              Información Formativa
                            </Typography>
                            <Typography gutterBottom>
                              <b>Recuerde GUARDAR en cada pestaña.</b> En el escritorio puede ver los pasos del formulario que ya tiene completados.
                            </Typography>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="careerTitle"
                                  labeltext="Título Profesional"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  autoComplete="career"
                                  errors={errors.careerTitle}
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="yearDegree"
                                  labeltext="Año Titulación"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  minLength={4}
                                  maxLength={4}
                                  register={register}
                                  autoComplete="year"
                                  errors={errors.yearDegree}
                                />
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetText
                              name="certificateOfTitle"
                              labeltext="Certificado de título"
                              download={
                                trainingInformationDataState.documentProfessionCertificateHref &&
                                trainingInformationDataState.documentProfessionCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      trainingInformationDataState.documentProfessionCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              disabled={!Boolean(editPostulationState)}
                              loading={
                                loadingPostulationTrainingInformationGraph
                              }
                              type="file"
                              require={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.certificateOfTitle}
                            />
                            <AutoCompleteWidget
                              label="Universidad"
                              require={true}
                              data={universitiesDataState}
                              inicialValue="Seleccione Universidad..."
                              disabled={!Boolean(editPostulationState)}
                              loading={
                                loadingPostulationTrainingInformationGraph ||
                                loadinglistStudentUniversityGraph
                              }
                              valuecombo={universitySelectedState}
                              loadingComboState={false}
                              onSelectState={setUniversitySelectedState}
                              name="universityCombo"
                              register={register}
                              errors={errors.universityCombo}
                            />
                            <BsRow>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="otherUniversity"
                                  labeltext="Otra Universidad"
                                  req={
                                    universitySelectedState
                                      ? universitySelectedState.id === 0
                                        ? true
                                        : false
                                      : false
                                  }
                                  // disabled={!Boolean(editPostulationState)}
                                  disabled={disabledValidationOtherUniversity()}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  errors={errors.otherUniversity}
                                  autoComplete="university"
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <AutoCompleteWidget
                                  label="País Universidad"
                                  require={true}
                                  disabled={!Boolean(editPostulationState)}
                                  data={universityCountryDataState}
                                  inicialValue="Seleccione País..."
                                  loading={
                                    loadingPostulationTrainingInformationGraph ||
                                    loadingListCountryGraph
                                  }
                                  valuecombo={universityCountrySelectedState}
                                  loadingComboState={false}
                                  onSelectState={
                                    setUniversityCountrySelectedState
                                  }
                                  name="universityCountryCombo"
                                  register={register}
                                  errors={errors.universityCountryCombo}
                                />
                              </BsCol>
                            </BsRow>
                            <Typography variant="h6" gutterBottom>
                              Notas
                            </Typography>
                            <BsRow>
                              <BsCol class="col-12 col-md-6 col-xl-3">
                                <TextFieldWidgetText
                                  name="pediatricInternship"
                                  labeltext="Internado Pediatría"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  inputProps={{
                                    step: '.1'
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  minLength={1}
                                  maxLength={3}
                                  typenote={true}
                                  register={register}
                                  errors={errors.pediatricInternship}
                                />
                              </BsCol>
                              <BsCol class="col-12 col-md-6 col-xl-3">
                                <TextFieldWidgetText
                                  name="gynecologyInternship"
                                  labeltext="Internado Ginecología"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  inputProps={{
                                    step: '.1'
                                  }}
                                  type="number"
                                  minLength={1}
                                  maxLength={3}
                                  typenote={true}
                                  register={register}
                                  errors={errors.gynecologyInternship}
                                />
                              </BsCol>
                              <BsCol class="col-12 col-md-6 col-xl-3">
                                <TextFieldWidgetText
                                  name="surgeryInternship"
                                  labeltext="Internado Cirugía"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  inputProps={{
                                    step: '.1'
                                  }}
                                  minLength={1}
                                  maxLength={3}
                                  typenote={true}
                                  register={register}
                                  errors={errors.surgeryInternship}
                                />
                              </BsCol>
                              <BsCol class="col-12 col-md-6 col-xl-3">
                                <TextFieldWidgetText
                                  name="internalMedicineRating"
                                  labeltext="Medicina Interna"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  inputProps={{
                                    step: '.1'
                                  }}
                                  minLength={1}
                                  maxLength={3}
                                  typenote={true}
                                  register={register}
                                  errors={errors.internalMedicineRating}
                                />
                              </BsCol>
                              <BsCol class="col-12 col-md-6 col-xl-3">
                                <TextFieldWidgetText
                                  name="nationalMedicalRating"
                                  labeltext="Médica Nacional"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  inputProps={{
                                    step: '.1'
                                  }}
                                  minLength={1}
                                  maxLength={3}
                                  typenote={true}
                                  register={register}
                                  errors={errors.nationalMedicalRating}
                                />
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetText
                              name="documentNationalMedicalRating"
                              labeltext="Calificación Médica Nacional"
                              download={
                                trainingInformationDataState.documentNationalMedicalQualificationHref &&
                                trainingInformationDataState.documentNationalMedicalQualificationHref !==
                                  null ? (
                                  <a
                                    href={
                                      trainingInformationDataState.documentNationalMedicalQualificationHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={!Boolean(editPostulationState)}
                              loading={
                                loadingPostulationTrainingInformationGraph
                              }
                              req={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.documentNationalMedicalRating}
                              autoComplete="Calificación Médica Nacional"
                            />
                            <Typography variant="h6" gutterBottom>
                              EUNACOM
                            </Typography>
                            <BsRow>
                              <BsCol class="col-md-4">
                                <RadioGroupWidget
                                  name="eunacom"
                                  errors={errors.eunacom}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  checked={stateRadioButtons.eunacom}
                                  handleradiocheck={handleRadioCheck}>
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="1"
                                    disable={!Boolean(editPostulationState)}
                                    label="Aprobado"
                                  />
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="2"
                                    disable={!Boolean(editPostulationState)}
                                    label="No Aprobado"
                                  />
                                </RadioGroupWidget>
                              </BsCol>

                              <BsCol class="col-md-4">
                                <TextFieldWidgetText
                                  name="yearEunacom"
                                  labeltext="Año Rendición"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  minLength={4}
                                  maxLength={4}
                                  typenote={true}
                                  register={register}
                                  autoComplete="year"
                                  errors={errors.yearEunacom}
                                />
                              </BsCol>
                              <BsCol class="col-md-4">
                                <TextFieldWidgetText
                                  name="punctuationEunacom"
                                  labeltext="Puntuación"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationTrainingInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={require}
                                  type="number"
                                  inputProps={{
                                    step: '.01'
                                  }}
                                  minLength={1}
                                  maxLength={5}
                                  typenote={true}
                                  register={register}
                                  errors={errors.punctuationEunacom}
                                />
                              </BsCol>
                            </BsRow>

                            <TextFieldWidgetText
                              name="eunacomAproved"
                              labeltext="EUNACOM"
                              download={
                                trainingInformationDataState.documentEunacomCertificateHref &&
                                trainingInformationDataState.documentEunacomCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      trainingInformationDataState.documentEunacomCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={
                                !Boolean(editPostulationState) === true &&
                                stateRadioButtons.eunacom === '2'
                                  ? true
                                  : !Boolean(editPostulationState) === true &&
                                    stateRadioButtons.eunacom === '1'
                                  ? true
                                  : !Boolean(editPostulationState) ||
                                    stateRadioButtons.eunacom === '1'
                                  ? false
                                  : true
                              }
                              loading={
                                loadingPostulationTrainingInformationGraph
                              }
                              require={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.eunacomAproved}
                              autoComplete="EUNACOM"
                            />
                            <Typography variant="h6" gutterBottom>
                              Nivel de Inglés
                            </Typography>
                            <RadioGroupWidget
                              name="englishLevel"
                              errors={errors.englishLevel}
                              checked={stateRadioButtons.englishLevel}
                              handleradiocheck={handleRadioCheck}>
                              <RadioWidget
                                register={register}
                                req
                                value="1"
                                disable={!Boolean(editPostulationState)}
                                label="Básico"
                              />
                              <RadioWidget
                                register={register}
                                req
                                value="2"
                                disable={!Boolean(editPostulationState)}
                                label="Intermedio"
                              />
                              <RadioWidget
                                register={register}
                                req
                                value="3"
                                disable={!Boolean(editPostulationState)}
                                label="Avanzado"
                              />
                              <RadioWidget
                                register={register}
                                req
                                value="4"
                                disable={!Boolean(editPostulationState)}
                                label="Sin Conocimiento"
                              />
                            </RadioGroupWidget>
                            <div className="EditPostulation">
                              <SaveAction
                                messageType="add"
                                disabled={!activeSaveForm}
                                mutationLoading={
                                  loadingSubmitGraphTrainingInformation
                                }
                                success={submitTrainingInformationState}
                              />
                            </div>
                          </form>
                        </TabPanel>

                        {/* -------------->Salud */}
                        <TabPanel value={valueTabState} index={3}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Typography variant="h6" gutterBottom>
                              Inscripción en Registro de Prestadores
                            </Typography>
                            <Typography gutterBottom>
                              <b>Recuerde GUARDAR en cada pestaña.</b> En el escritorio puede ver los pasos del formulario que ya tiene completados.
                            </Typography>
                            <BsRow>
                              <BsCol class="col-md-3">
                                <RadioGroupWidget
                                  name="superintendencyRegistration"
                                  errors={errors.superintendencyRegistration}
                                  checked={
                                    stateRadioButtons.superintendencyRegistration
                                  }
                                  handleradiocheck={handleRadioCheck}>
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="1"
                                    disable={!Boolean(editPostulationState)}
                                    label="Sí"
                                  />
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="2"
                                    disable={!Boolean(editPostulationState)}
                                    label="No"
                                  />
                                </RadioGroupWidget>
                              </BsCol>
                              <BsCol class="col-md-4">
                                {editPostulationState === false ? (
                                  <TextFieldWidgetText
                                    name="superintendencyRegistrationNumber"
                                    labeltext="N° de Registro"
                                    loading={
                                      loadingPostulationHealthInformationGraph
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    req={
                                      stateRadioButtons.superintendencyRegistration ===
                                      '1'
                                        ? true
                                        : false
                                    }
                                    type="number"
                                    disabled={true}
                                    minLength={1}
                                    maxLength={8}
                                    register={register}
                                    errors={
                                      errors.superintendencyRegistrationNumber
                                    }
                                  />
                                ) : (
                                  <TextFieldWidgetText
                                    name="superintendencyRegistrationNumber"
                                    labeltext="N° de Registro"
                                    loading={
                                      loadingPostulationHealthInformationGraph
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    req={
                                      stateRadioButtons.superintendencyRegistration ===
                                      '1'
                                        ? true
                                        : false
                                    }
                                    type="number"
                                    disabled={
                                      stateRadioButtons.superintendencyRegistration ===
                                      '1'
                                        ? false
                                        : true
                                    }
                                    minLength={1}
                                    maxLength={8}
                                    register={register}
                                    errors={
                                      errors.superintendencyRegistrationNumber
                                    }
                                  />
                                )}
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetText
                              name="superintendencyRegistrationCertificate"
                              labeltext="Certificado Inscripción Registro Prestadores"
                              download={
                                healthInformationDataState.documentSuperintendencyRegistrationCertificateHref &&
                                healthInformationDataState.documentSuperintendencyRegistrationCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentSuperintendencyRegistrationCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={
                                !Boolean(editPostulationState) === true &&
                                stateRadioButtons.superintendencyRegistration ===
                                  '2'
                                  ? true
                                  : !Boolean(editPostulationState) === true &&
                                    stateRadioButtons.superintendencyRegistration ===
                                      '1'
                                  ? true
                                  : !Boolean(editPostulationState) ||
                                    stateRadioButtons.superintendencyRegistration ===
                                      '1'
                                  ? false
                                  : true
                              }
                              loading={loadingPostulationHealthInformationGraph}
                              require={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={
                                errors.superintendencyRegistrationCertificate
                              }
                              autoComplete="Certificado Inscripción Superintendencia"
                            />
                            <Typography variant="h6" gutterBottom>
                              Vacuna contra la influenza
                            </Typography>
                            <RadioGroupWidget
                              name="influenzaVaccine"
                              errors={errors.influenzaVaccine}
                              checked={stateRadioButtons.influenzaVaccine}
                              handleradiocheck={handleRadioCheck}>
                              <RadioWidget
                                register={register}
                                req
                                value="1"
                                disable={!Boolean(editPostulationState)}
                                label="Sí"
                              />
                              <RadioWidget
                                register={register}
                                req
                                value="2"
                                disable={!Boolean(editPostulationState)}
                                label="No"
                              />
                            </RadioGroupWidget>
                            <TextFieldWidgetText
                              name="influenzaCertificate"
                              labeltext="Certificado Vacuna Influenza"
                              download={
                                healthInformationDataState.documentInfluenzaCertificateHref &&
                                healthInformationDataState.documentInfluenzaCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentInfluenzaCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={
                                !Boolean(editPostulationState) === true &&
                                stateRadioButtons.influenzaVaccine === '2'
                                  ? true
                                  : !Boolean(editPostulationState) !== false &&
                                    stateRadioButtons.influenzaVaccine === '1'
                                  ? true
                                  : !Boolean(editPostulationState) ||
                                    stateRadioButtons.influenzaVaccine === '1'
                                  ? false
                                  : true
                              }
                              loading={loadingPostulationHealthInformationGraph}
                              require={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.influenzaCertificate}
                              // autoComplete="Certificado Hepatitis"
                            />

                            <Typography variant="h6" gutterBottom>
                              Vacuna contra Hepatitis B
                            </Typography>
                            <BsRow>
                              <BsCol class="col-md-6">
                                <RadioGroupWidget
                                  name="hepatitisBVaccine"
                                  errors={errors.hepatitisBVaccine}
                                  checked={stateRadioButtons.hepatitisBVaccine}
                                  handleradiocheck={handleRadioCheck}>
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="1"
                                    disable={!Boolean(editPostulationState)}
                                    label="Sí"
                                  />
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="2"
                                    disable={!Boolean(editPostulationState)}
                                    label="No"
                                  />
                                </RadioGroupWidget>
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="hepatitisBVaccineDose"
                                  labeltext="N° Dosis Recibidas"
                                  disabled={
                                    !Boolean(editPostulationState) === true &&
                                    stateRadioButtons.hepatitisBVaccine === '1'
                                      ? true
                                      : !Boolean(editPostulationState) ===
                                          true &&
                                        stateRadioButtons.hepatitisBVaccine ===
                                          '2'
                                      ? true
                                      : !Boolean(editPostulationState) ||
                                        stateRadioButtons.hepatitisBVaccine ===
                                          '1'
                                      ? false
                                      : true
                                  }
                                  loading={
                                    loadingPostulationHealthInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={
                                    stateRadioButtons.hepatitisBVaccine === '1'
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  minLength={1}
                                  maxLength={3}
                                  register={register}
                                  errors={errors.hepatitisBVaccineDose}
                                />
                              </BsCol>
                            </BsRow>

                            <TextFieldWidgetText
                              name="hepatitisCertificate"
                              labeltext="Certificado Hepatitis"
                              download={
                                healthInformationDataState.documentHepatitisCertificateHref &&
                                healthInformationDataState.documentHepatitisCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentHepatitisCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={
                                !Boolean(editPostulationState) === true &&
                                stateRadioButtons.hepatitisBVaccine === '2'
                                  ? true
                                  : !Boolean(editPostulationState) !== false &&
                                    stateRadioButtons.hepatitisBVaccine === '1'
                                  ? true
                                  : !Boolean(editPostulationState) ||
                                    stateRadioButtons.hepatitisBVaccine === '1'
                                  ? false
                                  : true
                              }
                              loading={loadingPostulationHealthInformationGraph}
                              require={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.hepatitisCertificate}
                              autoComplete="Certificado Hepatitis"
                            />

                            <Typography variant="h6" gutterBottom>
                              Póliza de Seguros de Responsabilidad Civil
                            </Typography>
                            <BsRow>
                              <BsCol class="col-12 col-md-3">
                                <p
                                  style={{
                                    lineHeight: '34px'
                                  }}>
                                  ¿Tiene los datos del Seguro?
                                </p>
                              </BsCol>
                              <BsCol class="col-12 col-md-6">
                                <RadioGroupWidget
                                  name="insuranceActive"
                                  errors={errors.insuranceActive}
                                  checked={insuranceActiveState}
                                  handleradiocheck={
                                    handleRadioActiveInsuranceCheck
                                  }>
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="1"
                                    disable={!Boolean(editPostulationState)}
                                    label="Sí"
                                  />
                                  <RadioWidget
                                    register={register}
                                    req
                                    value="2"
                                    disable={!Boolean(editPostulationState)}
                                    label="No"
                                  />
                                </RadioGroupWidget>
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="responsibilityPolicyNumber"
                                  labeltext="Número"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationHealthInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={
                                    insuranceActiveState === '1' ? true : false
                                  }
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  errors={errors.responsibilityPolicyNumber}
                                />
                              </BsCol>
                              <BsCol class="col-md-6">
                                <TextFieldWidgetText
                                  name="insuranceName"
                                  labeltext="Nombre Aseguradora"
                                  disabled={!Boolean(editPostulationState)}
                                  loading={
                                    loadingPostulationHealthInformationGraph
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  req={
                                    insuranceActiveState === '1' ? true : false
                                  }
                                  minLength={3}
                                  maxLength={100}
                                  register={register}
                                  errors={errors.insuranceName}
                                />
                              </BsCol>
                            </BsRow>
                            <TextFieldWidgetText
                              name="secureAffiliationCertificate"
                              labeltext="Certificado Afiliación Seguro"
                              download={
                                healthInformationDataState.documentSecureAffiliationCertificateHref &&
                                healthInformationDataState.documentSecureAffiliationCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentSecureAffiliationCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={!Boolean(editPostulationState)}
                              loading={loadingPostulationHealthInformationGraph}
                              req={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.secureAffiliationCertificate}
                              autoComplete="Certificado Afiliación Seguro"
                            />

                            <TextFieldWidgetText
                              name="healthAffiliationCertificate"
                              labeltext="Certificado Afiliación Isapre / Fonasa"
                              download={
                                healthInformationDataState.documentHealthAffiliationCertificateHref &&
                                healthInformationDataState.documentHealthAffiliationCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentHealthAffiliationCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={!Boolean(editPostulationState)}
                              loading={loadingPostulationHealthInformationGraph}
                              req={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.healthAffiliationCertificate}
                              // autoComplete="Certificado Afiliación Seguro"
                            />

                            <TextFieldWidgetText
                              name="mobilityPassCertificate"
                              labeltext="Pase de Movilidad"
                              download={
                                healthInformationDataState.documentMobilityPassCertificateHref &&
                                healthInformationDataState.documentMobilityPassCertificateHref !==
                                  null ? (
                                  <a
                                    href={
                                      healthInformationDataState.documentMobilityPassCertificateHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descargarlo Aquí
                                  </a>
                                ) : (
                                  ''
                                )
                              }
                              type="file"
                              disabled={!Boolean(editPostulationState)}
                              loading={loadingPostulationHealthInformationGraph}
                              req={false}
                              InputLabelProps={{
                                shrink: true
                              }}
                              register={register}
                              errors={errors.mobilityPassCertificate}
                              // autoComplete="Certificado Afiliación Seguro"
                            />
                            <div className="EditPostulation">
                              <SaveAction
                                messageType="add"
                                disabled={!activeSaveForm}
                                mutationLoading={
                                  loadingSubmitGraphHealthInformation
                                }
                                success={submitHealthInformationState}
                              />
                            </div>
                          </form>
                        </TabPanel>

                        {/* -------------->Adicional */}
                        <TabPanel value={valueTabState} index={4}>
                          <Typography variant="h6" gutterBottom>
                            Formación Adicional
                          </Typography>

                          <AddRegisterPanelAC
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            registeredItems={
                              postulationCurricularBackgroundDataState
                            }
                            loadingAC={loadingCurricularBackgroundGraph}
                          />
                          <AddRegisterPanelSP
                            registeredItems={postulationPublicationDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingSP={loadingPostulationPublicationGraph}
                          />
                          <AddRegisterPanelCP
                            registeredItems={postulationPresentationDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingCP={loadingPostulationPresentationGraph}
                          />
                          <AddRegisterPanelSA
                            registeredItems={postulationAssistantShipDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingSA={loadingPostulationAssistantShipGraph}
                          />
                          <AddRegisterPanelGR
                            registeredItems={postulationDiplomatDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingGR={loadingPostulationDiplomatGraph}
                          />
                          <AddRegisterPanelJP
                            registeredItems={postulationPerformanceDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingJP={loadingPostulationPerformanceGraph}
                          />
                          <AddRegisterPanelAR
                            registeredItems={postulationDistinctionDataState}
                            disableForm={!Boolean(editPostulationState) || !activeSaveForm}
                            loadingAR={loadingPostulationDistinctionGraph}
                          />
                        </TabPanel>
                      </SimpleTabs>
                    )}
                  </BsCol>
                  ) : 
                  (<BsCol class="col">
                    <p style={{textAlign: 'center'}}>Cargando...</p>
                  </BsCol>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
      <DialogConfirm
        openDialog={openDialogConfirmState}
        rowState={rowToHandleState}
        actionState={stateActionDialogHandler}
        variant="success"
        loading={loadingSendPostulationGraph}
        success={dataSendPostulationGraph}
        openState={setOpenDialogConfirmState}
      />
    </div>
  );
};
export default Postulation;
