import React, { useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import classes from './StepperDashTwo.module.scss';
import generalTexts from '../../../common/generalTexts.json';

const StepperDashTwo = props => {
  const [activeStep] = useState(-1);
  const steps = props.postulationUserStep;

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        classes={{
          root: classes.stepperRoot
        }}>
        {steps && steps.length > 0 ? (
          steps.map((step, index) => (
            <Step
              key={step.step}
              completed={step.finished}
              classes={{
                completed: classes.stepCompleted
              }}>
              <StepLabel
                StepIconProps={{
                  classes: { active: classes.stepActive }
                }}>
                {step.name}
              </StepLabel>
            </Step>
          ))
        ) : (
          <div></div>
        )}
      </Stepper>
      <div>
        {steps && activeStep === steps.length ? (
          <div>

          </div>
        ) : (
          <div>
						<p className="text-center" style={{ whiteSpace: "pre-line" }}>{generalTexts.dashboard.postulante.stepper2}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepperDashTwo;
