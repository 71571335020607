import gql from 'graphql-tag';

export const listCompetitions = gql`
  query($filter: CompetitionFilterInput, $sort: CompetitionSortingInput) {
    listCompetitions(filter: $filter, sort: $sort) {
      id
      name
      code
      since
      until
      description
      dayStart
      specialty {
        id
        name
      }
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const getCompetition = gql`
  query($id: Int!) {
    getCompetition(id: $id) {
      id
      name
      code
      since
      until
      description
      dayStart
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const listCompetitionsComplex = gql`
  query {
    listCompetitionsComplex {
      allCompetition {
        id
        name
        code
        since
        until
        description
        dayStart
        state {
          id
          name
        }
        delete
        edit
      }
      activeCompetition {
        id
        name
        code
        since
        until
        description
        dayStart
        state {
          id
          name
        }
      }
      inactiveCompetition {
        id
        name
        code
        since
        until
        description
        dayStart
        state {
          id
          name
        }
      }
    }
  }
`;
