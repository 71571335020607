import gql from 'graphql-tag';

export const createCourse = gql`
  mutation($type: Int!, $name: String!, $code: String!) {
    createCourse(courseInput: { type: $type, name: $name, code: $code })
  }
`;

export const updateCourse = gql`
  mutation($id: Int!, $type: Int!, $name: String!, $code: String!) {
    updateCourse(
      courseInput: { id: $id, type: $type, name: $name, code: $code }
    ) {
      id
      name
      code
      type {
        id
        name
      }
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const deleteCourse = gql`
  mutation($id: Int!) {
    deleteCourse(id: $id)
  }
`;

export const updateStateCourse = gql`
  mutation($id: Int!) {
    updateStateCourse(id: $id)
  }
`;
