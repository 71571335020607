import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Card, CardContent, Divider } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import LOGO from '../../../../assets/images/logo-certificado.jpg';
import { BsContainer, BsRow, BsCol } from '../../../../layouts/components';
import classes from './QualificationReport.module.scss';
import { printOrder } from '../../../../helpers';

const QualificationReport = props => {
  console.log(props.location, '<-----llego');

  // const { fullname, rut, competition, specialty, boss } = props.location.state;

  let fullname = "LILIBET ANA GUZMÁN GOITÍA";
  let rut = "26.014.409-k";
  let competition = "CONCURSO CONE APS DEL MINISTERIO DE SALUD";
  let specialty = "MEDICINA INTERNA";
  let boss = "DRA. NADIA A. MUÑOZ NAVARRO";
  let approve = false;
  let dateStart = "2 de mayo de 2022";

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth();
  let monthName = ['enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'];
  let year = newDate.getFullYear();

  // let query = useQueryRoute();
  // let postulationIdQuery = null;

  // // Cuando le llega un ID por get
  // postulationIdQuery = query.get('idPostulation');
  // useEffect(() => {
  //   if (postulationIdQuery) {
  // 		console.log(postulationIdQuery,"<---");
  //     // setUserIdSelectedState(postulationIdQuery);
  //   }
  // }, [postulationIdQuery]);

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <div>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => printOrder('ifmcontentstoprint')}>
                    Imprimir
                  </Button>
                </div>
              </CardActions>

              <Divider />
              <CardContent
                className={classes.cardContent}
                id="ifmcontentstoprint">
                <div className={classes.qualificationContainer} style={{textAlign: 'justify'}}>
                  <div style={{ textAlign: 'center'}}>
                    <img src={LOGO} alt="" className='img-fluid' width="320" />
                  </div>
                  <div style={{height: 80}}></div>
                  <div>
                    <h3>Certificado de {approve ? 'Habilitación' : 'No Habilitación'}</h3>
                    <h5>{competition.toUpperCase()}</h5>
                    <p>
                      Por medio de la presente, certifico que el/la Doctor/a:{' '}
                      <b>
                        {fullname} RUT: {rut}
                      </b>
                      , se encuentra {approve ? <b>HABILITADO</b> : <b>NO HABILITADA</b>}, para efectura el
                      Programa de Especialización en{' '}
                      <b>{specialty.toUpperCase()}</b>, que realiza la
                      Universidad Católica del Norte {approve ? `y que inicia el ${dateStart}` : ''}.
                    </p>
                    <p>
                      Se extiende el presente certificado, para ser presentado
                      en el "
                      <b>{competition.toUpperCase()} DEL MINISTERIO DE SALUD</b>
                      ".
                    </p>
                    <p>Sin otro particular, lo saluda atentamente,</p>
                  </div>
                  <div style={{height: 60}}></div>
                  <div className={classes.signatureStyle}>
                    <div>
                      {boss} <br />
                      JEFE DE POSTGRADO Y POSTÍTULO <br />
                      FACULTAD DE MEDICINA
                    </div>
                  </div>
                  <div>
                    Coquimbo, {date} de {monthName[month]} de {year}
                  </div>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default QualificationReport;
