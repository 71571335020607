import React, { useState, useEffect } from 'react';
import {
  SearchBasicTable,
  ModalWidget,
  DataTableWidget,
  DialogDelete,
  DialogState,
  IconActionTableDelete,
  IconActionTableState,
  IconActionTableEdit,
  IconActionTableDetail,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget,
  TextFieldWidgetSelect,
  FilterAction
} from '../../../components';
import useForm from 'react-hook-form';
import { listUsers, listUsersCount, listUsersProfile } from '../../../graphql/queries/users';
import { deleteUser, updateStateUser } from '../../../graphql/mutation/users';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  handleActionDetail,
  handleActionState,
  handleActionEdit,
  handleActionDelete,
  concatObjectConfigDatatable
} from '../../../helpers';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/list.scss';
import UserEdit from '../UserEdit';

const UserList = () => {
  const [
    listUsersHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listUsers, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });
  const [
    deleteUserHandler,
    { loading: loadingDeleteGraph, data: dataDeleteGraph }
  ] = useMutation(deleteUser, { errorPolicy: 'all' });
  const [
    stateUserHandler,
    { loading: loadingChangeStateGraph, data: dataChangeStateGraph }
  ] = useMutation(updateStateUser, { errorPolicy: 'all' });

  const { loading: loadingProfileGraph, data: dataProfileGraph } = useQuery(
    listUsersProfile,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const { handleSubmit, register, errors } = useForm();
  

  const [usersDataState, setUsersDataState] = useState([]);
  const [filterTextRutState, setFilterTextRutState] = useState('');
  const [filterTextPaternalLastState, setFilterTextPaternalLastState] = useState('');
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [openDialogStateState, setOpenDialogStateState] = useState(false);
  const [profileDataState, setProfileDataState] = useState([]);
  const [profileSelectedState, setProfileSelectedState] = useState('');
  const [limitState, setLimitState] = useState(10);
  const [sortState, setSortState] = useState({ name: 'asc' });
  const [pageState, setPageState] = useState(1);
  const [countState, setCountState] = useState(0);
  const [profileState, setProfileState] = useState(null);

  let countFilter = {};
  if(filterTextRutState) {
    countFilter = { 
      filter: {
        rut:  {
          contains: filterTextRutState
        }
      }
    }
  }

  if(filterTextPaternalLastState) {
    countFilter = { 
      filter: {
        paternalLastname:  {
          contains: filterTextPaternalLastState
        }
      }
    }
  }

  if(profileState) {
    countFilter = { 
      filter: {
        profile:  {
          eq: parseInt(profileState)
        }
      }
    }
  }

  const { loading: loadingCountGraph, data: dataCountGraph } = useQuery(
    listUsersCount,
    {
      variables: countFilter,
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    }
  );


  const [editionState, setEditionState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [rowToEditState, setRowToEditState] = useState('');
  const [modalState, setModalState] = useState({
    openDetail: false,
    detailModal: ''
  });

  useEffect(() => {
    if (dataListGraph && dataListGraph.listUsers) {
      setUsersDataState(dataListGraph.listUsers);
    }
  }, [dataListGraph]);

  useEffect(() => {
    if (dataProfileGraph && dataProfileGraph.listUsersProfile) {
      setProfileDataState(dataProfileGraph.listUsersProfile);
    }
  }, [dataProfileGraph]);

  useEffect(() => {
    let filterQuery;
    if(profileState){
      filterQuery = {
        filter: {
          profile:  {
            eq: parseInt(profileState)
          }
        },
        limit: limitState, 
        sort: sortState, 
        page: pageState 
      }
    } else {
      filterQuery = {
        limit: limitState, 
        sort: sortState, 
        page: pageState 
      }
    }
    async function queryData() {
      try {
        await listUsersHandler({
          variables: filterQuery
        });
      } catch (err) {}
    }
    queryData();
  }, [listUsersHandler, limitState, sortState, pageState, profileState]);

  useEffect(() => {
    if (dataCountGraph && dataCountGraph.listUsersCount) {
      setCountState(dataCountGraph.listUsersCount);
    }
  }, [dataCountGraph]);

  const searchFunc = async (filterText, type) => {
    let filter = {};
    if (filterText !== '' && type === "rut") {
      filter = {
        filter: {
          rut: {
            contains: filterText
          }
        }
      };
    }
    if (filterText !== '' && type === "apellido_paterno") {
      filter = {
        filter: {
          paternalLastname: {
            contains: filterText
          }
        }
      };
    }
    try {
      await listUsersHandler({
        variables: filter
      });
    } catch (err) {}
  };

  const subHeaderComponentMemo = (
   
    <>
      <div className='mr-md-4'>
        <SearchBasicTable
          resetFunc={() => {
            searchFunc('');
            setFilterTextPaternalLastState('');
          }}
          id="apellido"
          valuetext={filterTextPaternalLastState}
          placeholder="Buscar Apellido Paterno"
          filterFunc={() => searchFunc(filterTextPaternalLastState, "apellido_paterno")}
          onFilter={value => setFilterTextPaternalLastState(value)}
        />
      </div>
      <SearchBasicTable
        resetFunc={() => {
          searchFunc('');
          setFilterTextRutState('');
        }}
        valuetext={filterTextRutState}
        placeholder="Buscar Rut"
        filterFunc={() => searchFunc(filterTextRutState, "rut")}
        onFilter={value => setFilterTextRutState(value)}
      />
    </>
  );

  const columnsConfig = [
    {
      name: 'Rut',
      selector: 'rut',
      sortable: true
    },
    {
      name: 'Nombres',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Apellidos',
      selector: 'paternalLastname',
      cell: row => (
        <span>
          {row.paternalLastname} {row.maternalLastname}
        </span>
      ),
      sortable: true
    },
    {
      name: 'E-mail',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Perfil',
      selector: 'profile.name',
      sortable: true
    },
    {
      name: 'Estado',
      selector: 'state.name',
      sortable: true
    }
  ];

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '190px',
      cell: row => (
        <div className="actionColumn">
          <IconActionTableDetail
            handleActionDetail={() => {
              const html = handleActionDetail(row);
              setModalState({
                ...modalState,
                openDetail: true,
                detailModal: html
              });
            }}
          />
          {row.edit && (
            <IconActionTableEdit
              handleActionEdit={() => {
                setRowToEditState(row);
                setEditionState(true);
              }}
            />
          )}
          <IconActionTableState
            rowState={row.state.id}
            handleActionState={() => DialogStateHandler(row)}
          />
          {row.delete && (
            <IconActionTableDelete
              handleActionDelete={() => DialogDeleteHandler(row)}
            />
          )}
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setEditionState(false);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  const deleteActionDialogHandler = async () => {
    try {
      await deleteUserHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    setCountState(countState - 1);
    const newUsers = handleActionDelete(rowToHandleState, usersDataState);
    setUsersDataState(newUsers);
  };

  const DialogStateHandler = row => {
    setEditionState(false);
    setRowToHandleState(row);
    setOpenDialogStateState(!openDialogStateState);
  };

  const stateActionDialogHandler = async row => {
    try {
      await stateUserHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newUsers = handleActionState(row, usersDataState);
    setUsersDataState(newUsers);
  };

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = newObject => {
    const newUsers = handleActionEdit(newObject, usersDataState);
    // console.log(newUsers,"----------------newUsers",newObject)
    setUsersDataState(newUsers);
  };

  //const searchTool = false;

  const handleSort = async (column, sortDirection) => {
    let nameColumn = column.selector;
    if (column.selector === 'profile.name') {
      nameColumn = 'profile';
    } else if (column.selector === 'state.name') {
      nameColumn = 'state';
    }
    await setUsersDataState([]);
    let obj = {};
    Object.defineProperty(obj, nameColumn, {
      value: sortDirection,
      writable: true,
      enumerable: true,
      configurable: true
    });
    setSortState(obj);
  };

  const customSort = () => {
    return usersDataState;
  };

  const handleChangePagination = (page, totalRows) => {
    setPageState(page);
  };

  const handleChangeRows = (currentRowsPerPage, currentPage) => {
    setLimitState(currentRowsPerPage);
  };

  // console.log(usersDataState,"la data");

  const onSubmit = async values => {
    // console.log(values, "<---form")
    setPageState(1);
    setProfileState(values.profile);
  };

  return (
    <div className="list-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <UserEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card>
            
              <CardHeaderWidget
                title="Listar Usuarios"
                subheader={
                  <BreadCrumbs subTitle="Listado de todos los usuarios del sistema." />
                }
              />
              <Divider />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                <BsRow>
                <BsCol class="col-md-5">
                  <TextFieldWidgetSelect
                    options={profileDataState}
                    name="profile"
                    labeltext="Perfil"
                    shortLabel={true}
                    loading={loadingProfileGraph}
                    // req
                    InputLabelProps={{ shrink: true }}
                    selected={profileSelectedState}
                    onChange={e => setProfileSelectedState(e)}
                    register={register}
                    withDefault={true}
                    errors={errors.profile}
                  />
                </BsCol>
                <BsCol class="col-md-3">
                  <FilterAction mutationLoading={false} success={false} />
                </BsCol>
              </BsRow>
              </form>
                <div>
                  <DataTableWidget
                    totalRows={countState}
                    handleSort={handleSort}
                    sortFunction={customSort}
                    changePagination={handleChangePagination}
                    rowsChange={handleChangeRows}
                    columns={columns}
                    sortField="name"
                    filteredItems={usersDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingListGraph}
                    loadingCount={loadingCountGraph}
                    moduleName="Usuarios"
                  />
                </div>
                <ModalWidget
                  size="modal-50"
                  openDetail={modalState.openDetail}
                  detailModal={modalState.detailModal}
                  moduleName="Usuarios"
                />
                <DialogState
                  openDialog={openDialogStateState}
                  rowState={rowToHandleState}
                  actionState={stateActionDialogHandler}
                  variant="success"
                  loading={loadingChangeStateGraph}
                  success={dataChangeStateGraph}
                  openState={setOpenDialogStateState}
                />
                <DialogDelete
                  openDialog={openDialogDeleteState}
                  rowDelete={rowToHandleState}
                  actionDelete={deleteActionDialogHandler}
                  variant="success"
                  loading={loadingDeleteGraph}
                  success={dataDeleteGraph}
                  openState={setOpenDialogDeleteState}
                />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default UserList;
