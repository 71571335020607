import gql from 'graphql-tag';

export const listAbsences = gql`
  query(
    $filter: AbsenceFilterInput
    $limit: Int
    $page: Int
    $sort: AbsenceSortingInput
  ) {
    listAbsences(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      id
      absenceReason {
        id
        name
        document
        attendance
      }
      absenceDetail {
        id
        postulation
        student
        specialty
      }
      state {
        id
        name
      }
      document
      days
      since
      until
      delete
      edit
    }
  }
`;

export const listAbsencesCount = gql`
  query($filter: AbsenceFilterInput) {
    listAbsencesCount(filter: $filter)
  }
`;

export const getAbsence = gql`
  query($id: Int!) {
    getAbsence(id: $id) {
      id
      absenceReason {
        id
        name
        document
        attendance
      }
      absenceDetail {
        id
        postulation
        student
        specialty
      }
      state {
        id
        name
      }
      document
      days
      since
      until
      delete
      edit
    }
  }
`;
