import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { updateAbsenceReason } from '../../../graphql/mutation/absenceReason';
import {
  SaveAction,
  CardHeaderWidget,
  RadioGroupWidget,
  RadioWidget,
  TextFieldWidgetText
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import '../../../assets/scss/form.scss';

let initialRadioState = {
  document: '',
  attendance: ''
};

const AbsenceReasonEdit = props => {
  const require = true;

  const [stateRadioButtons, setStateRadioButtons] = useState(initialRadioState);

  const { id, name, document, attendance } = props.rowEdit;
  const { editFunc } = props;
  const { handleSubmit, register, errors, reset } = useForm();

  const [
    updateAbsenceReasonHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateAbsenceReason);

  useEffect(() => {
    reset({
      id: parseInt(id),
      name: name
    });
    initialRadioState = {
      document: document ? '1' : '2',
      attendance: attendance ? '1' : '2'
    };
    setStateRadioButtons(initialRadioState);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, name, document, attendance]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateAbsenceReason) {
        const newObj = dataSubmitGraph.updateAbsenceReason;
        console.log(newObj);
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async ({ id, name, document, attendance }) => {
    let valueDocument = true;
    let valueAttendance = true;
    if (document !== '1') {
      valueDocument = false;
    }
    if (attendance !== '1') {
      valueAttendance = false;
    }
    const query = {
      id: parseInt(id),
      name: name,
      document: valueDocument,
      attendance: valueAttendance
    };

    try {
      await updateAbsenceReasonHandler({
        variables: query
      });
    } catch (err) {}
    // props.editFunc(query);
  };

  const handleRadioCheck = value => {
    setStateRadioButtons({
      ...stateRadioButtons,
      [value.name]: value.value
    });
  };

  return (
    <Card>
      <CardHeaderWidget title={`Editar Motivo de Inasistencia: ${name} `} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="name"
                labeltext="Nombre"
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="nombre"
              />
              <TextField
                name="id"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />
              <BsRow class="mt-5">
                <BsCol class="col-md-6">
                  Tiene adjunto
                  <RadioGroupWidget
                    name="document"
                    errors={errors.document}
                    checked={stateRadioButtons.document}
                    handleradiocheck={handleRadioCheck}>
                    <RadioWidget register={register} req value="1" label="Sí" />
                    <RadioWidget register={register} req value="2" label="No" />
                  </RadioGroupWidget>
                </BsCol>
                <BsCol class="col-md-6">
                  Afecta Asistencia
                  <RadioGroupWidget
                    name="attendance"
                    errors={errors.attendance}
                    checked={stateRadioButtons.attendance}
                    handleradiocheck={handleRadioCheck}>
                    <RadioWidget register={register} req value="1" label="Sí" />
                    <RadioWidget register={register} req value="2" label="No" />
                  </RadioGroupWidget>
                </BsCol>
              </BsRow>
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default AbsenceReasonEdit;
