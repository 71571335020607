import gql from 'graphql-tag';

export const getReportBySpecialty = gql`
  query($filter: ReportFilterInput!) {
    getReportBySpecialty(filter: $filter) {
      id
      name
      postulationTotal
      postulationApproved
      postulationPendingValidation
      postulationPendingEvaluation
      postulationPendingInstitution
      postulationEnrollment
    }
  }
`;
