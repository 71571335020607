import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import FormatListNumberedRtlOutlinedIcon from '@material-ui/icons/FormatListNumberedRtlOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import ViewListIcon from '@material-ui/icons/ViewList';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DoneAll from '@material-ui/icons/DoneAll';
/* import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings'; */
import BallotIcon from '@material-ui/icons/Ballot';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import classes from './Sidebar.module.scss';
import { Context } from '../../../../context/Context';

import { Profile, SidebarNav } from './components';

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const context = useContext(Context);
  const moduleUser = context.moduleUser;
  let icon;

  const modules = [
    {
      id: 100,
      title: 'Inicio',
      href: '/dashboard',
      icon: <DashboardIcon />
    }
  ];
  moduleUser.forEach(module => {
    if (module.assigned) {
      if (module.subMenu) {
        const submenu = [];
        module.process.forEach(process => {
          if (process.assigned) {
            const proceso = {
              id: process.id,
              title: process.name,
              href: '/' + process.href
            };
            submenu.push(proceso);
          }
        });
        switch (module.icon) {
          case '<PersonIcon />':
            icon = <PersonIcon />;
            break;
          case '<SettingsOutlinedIcon />':
            icon = <SettingsOutlinedIcon />;
            break;
          case '<FormatAlignLeftIcon />':
            icon = <FormatAlignLeftIcon />;
            break;
          case '<AccountBoxIcon />':
            icon = <AccountBoxIcon />;
            break;
          case '<BallotIcon />':
            icon = <BallotIcon />;
            break;
          case '<EventAvailableIcon />':
            icon = <EventAvailableIcon />;
            break;
          case '<DateRangeIcon />':
            icon = <DateRangeIcon />;
            break;
          case '<LocalHospitalIcon />':
            icon = <LocalHospitalIcon />;
            break;
          case '<LibraryBooksIcon />':
            icon = <LibraryBooksIcon />;
            break;
          case '<GridOnOutlinedIcon />':
            icon = <GridOnOutlinedIcon />;
            break;
          case '<FormatListNumberedRtlOutlinedIcon />':
            icon = <FormatListNumberedRtlOutlinedIcon />;
            break;
          case '<DoneAll />':
            icon = <DoneAll />;
            break;
          default:
            break;
        }
        let hrefValue = module.href;
        if (hrefValue === 'false') {
          hrefValue = false;
        }
        const item = {
          id: module.id,
          title: module.name,
          href: hrefValue,
          icon: icon,
          submenu: submenu
        };
        modules.push(item);
      } else {
        console.log();
        switch (module.icon) {
          case '<PersonIcon />':
            icon = <PersonIcon />;
            break;
          case '<SettingsOutlinedIcon />':
            icon = <SettingsOutlinedIcon />;
            break;
          case '<FormatAlignLeftIcon />':
            icon = <FormatAlignLeftIcon />;
            break;
          case '<AccountBoxIcon />':
            icon = <AccountBoxIcon />;
            break;
          case '<BallotIcon />':
            icon = <BallotIcon />;
            break;
          case '<EventAvailableIcon />':
            icon = <EventAvailableIcon />;
            break;
          case '<DateRangeIcon />':
            icon = <DateRangeIcon />;
            break;
          case '<LocalHospitalIcon />':
            icon = <LocalHospitalIcon />;
            break;
          case '<LibraryBooksIcon />':
            icon = <LibraryBooksIcon />;
            break;
          case '<GridOnOutlinedIcon />':
            icon = <GridOnOutlinedIcon />;
            break;
          case '<FormatListNumberedRtlOutlinedIcon />':
            icon = <FormatListNumberedRtlOutlinedIcon />;
            break;
          case '<DoneAll />':
            icon = <DoneAll />;
            break;
          case '<InsertDriveFileOutlinedIcon />':
            icon = <DescriptionIcon />;
            break;
          default:
            break;
        }

        const item = {
          id: module.id,
          title: module.name,
          href: module.href,
          icon: icon,
          submenu: module.subMenu
        };
        modules.push(item);
      }
    }
  });

  // const menuAdmin = [
  //   {
  //     id: 100,
  //     title: 'Dashboard',
  //     href: '/dashboard',
  //     icon: <DashboardIcon />
  //   },
  //   {
  //     id: 200,
  //     title: 'Motivo Inasistencia',
  //     href: false,
  //     icon: <BallotIcon />,
  //     submenu: [
  //       {
  //         id: 201,
  //         title: 'Agregar',
  //         href: '/absence-reason-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 202,
  //         title: 'Listar',
  //         href: '/absence-reason-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 300,
  //     title: 'Inasistencia',
  //     href: false,
  //     icon: <EventAvailableIcon />,
  //     submenu: [
  //       {
  //         id: 301,
  //         title: 'Agregar',
  //         href: '/absence-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 302,
  //         title: 'Listar',
  //         href: '/absence-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 400,
  //     title: 'Período Académico',
  //     href: false,
  //     icon: <DateRangeIcon />,
  //     submenu: [
  //       {
  //         id: 401,
  //         title: 'Agregar',
  //         href: '/period-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 402,
  //         title: 'Listar',
  //         href: '/period-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 500,
  //     title: 'Especialidad',
  //     href: false,
  //     icon: <LocalHospitalIcon />,
  //     submenu: [
  //       {
  //         id: 501,
  //         title: 'Agregar',
  //         href: '/specialty-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 502,
  //         title: 'Listar',
  //         href: '/specialty-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 600,
  //     title: 'Asignatura',
  //     href: false,
  //     icon: <LibraryBooksIcon />,
  //     submenu: [
  //       {
  //         id: 601,
  //         title: 'Agregar',
  //         href: '/course-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 602,
  //         title: 'Listar',
  //         href: '/course-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 700,
  //     title: 'Malla Curricular',
  //     href: false,
  //     icon: <GridOnOutlinedIcon />,
  //     submenu: [
  //       {
  //         id: 701,
  //         title: 'Agregar',
  //         href: '/curriculum-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 702,
  //         title: 'Listar',
  //         href: '/curriculum-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 800,
  //     title: 'Nota Final',
  //     href: false,
  //     icon: <FormatListNumberedRtlOutlinedIcon />,
  //     submenu: [
  //       {
  //         id: 801,
  //         title: 'Agregar',
  //         href: '/note-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 802,
  //         title: 'Listar',
  //         href: '/note-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 1000,
  //     title: 'Usuario',
  //     href: false,
  //     icon: <PersonIcon />,
  //     submenu: [
  //       {
  //         id: 1001,
  //         title: 'Agregar',
  //         href: '/user-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 1002,
  //         title: 'Listar',
  //         href: '/user-list',
  //         icon: <ViewListIcon />
  //       },
  //       {
  //         id: 1003,
  //         title: 'Privilegios',
  //         href: '/user-privilege',
  //         icon: <PlaylistAddCheckIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 1600,
  //     title: 'Concurso',
  //     href: false,
  //     icon: <DoneAll />,
  //     submenu: [
  //       {
  //         id: 1601,
  //         title: 'Agregar',
  //         href: '/competition-add',
  //         icon: <AddCircleIcon />
  //       },
  //       {
  //         id: 1602,
  //         title: 'Listar',
  //         href: '/competition-list',
  //         icon: <ViewListIcon />
  //       }
  //     ]
  //   },
  //   {
  //     id: 1500,
  //     title: 'Cuenta',
  //     href: '/account',
  //     icon: <AccountBoxIcon />
  //   },
  //   {
  //     id: 900,
  //     title: 'Parámetros',
  //     href: '/parameters-edit',
  //     icon: <SettingsOutlinedIcon />
  //     // submenu: [
  //     //   {
  //     //     id: 901,
  //     //     title: 'Modificar',
  //     //     href: '/parameters-edit',
  //     //     icon: <AddCircleIcon />
  //     //   },
  //     //   {
  //     //     id: 902,
  //     //     title: 'Listar',
  //     //     href: '/parameters-list',
  //     //     icon: <ViewListIcon />
  //     //   }
  //     // ]
  //   },
  //   {
  //     id: 1100,
  //     title: 'Formulario Postulación',
  //     href: '/postulation-form',
  //     icon: <FormatAlignLeftIcon />
  //   }
  // ];

  //  const menuPostulante = [
  //       {
  //           id: 100,
  //           title: 'Dashboard',
  //           href: '/dashboard',
  //           icon: <DashboardIcon />

  //       },
  //       {
  //           id: 1100,
  //           title: 'Formulario Postulación',
  //           href: '/postulation-form',
  //           icon: <BallotIcon />
  //       },
  //       {
  //           id: 1200,
  //           title: 'Documentos Postulación',
  //           href: '/postulation-docs',
  //           icon: <InsertDriveFileOutlinedIcon />
  //       },
  //       {
  //           id: 1300,
  //           title: 'Mi Cuenta',
  //           href: '/user-edit',
  //           icon: <SettingsOutlinedIcon />
  //       }
  //   ];

  /* const pages = [
        {
            id: 100,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />

        },
        {
            id: 200,
            title: 'Usuarios',
            href: false,
            icon: <PeopleIcon />,
            submenu: [
                {
                    id: 201,
                    title: 'Agregar',
                    href: '/user-add',
                    icon: <AddCircleIcon />
                },
                {
                    id: 202,
                    title: 'Listar',
                    href: '/user-list',
                    icon: <ViewListIcon />
                }
            ]
        },
        {
            id: 300,
            title: 'Eventos',
            href: false,
            icon: <EventIcon />,
            submenu: [
                {
                    id: 301,
                    title: 'Agregar',
                    href: '/event-add',
                    icon: <AddCircleIcon />
                },
                {
                    id: 302,
                    title: 'Listar',
                    href: '/event-list',
                    icon: <ViewListIcon />
                }
            ]
		},
        {
            id: 400,
            title: 'Account',
            href: '/account',
            icon: <AccountBoxIcon />
        },
        {
            id: 500,
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />
		},
		{
            id: 600,
            title: 'Moldelo Form y Pruebas',
            href: false,
            icon: <SettingsIcon />,
            submenu: [
                {
                    id: 601,
                    title: 'Agregar',
                    href: '/test-add',
                    icon: <AddCircleIcon />
                },
                {
                    id: 602,
                    title: 'Listar',
                    href: '/test-list',
                    icon: <ViewListIcon />
                }
            ]
        }
    ]; */

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={modules} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
