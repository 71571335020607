import React, { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { IsDesktopHandler } from '../../../helpers';
import classes from './TextFieldWidgetDate.module.scss';
import errorMessage from '../../../common/errorMessages.json';

const TextFieldWidgetDate = props => {
  const {
    register,
    errors,
    success,
    labeltext,
    name,
    loading,
		disabled,
		req,
    ...others
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  const esLocaleDate = esLocale;
  // const [selectedDate, setSelectedDate] = useState('');
  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };
  useEffect(() => {
    if (errors && errors.message) {
      setStateMessage(errors.message);
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  // useEffect(() => {
  //   if (success) {
  //     setSelectedDate('');
  //   }
  // }, [success]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  return (
    <div className={classes.inputContainerStyle}>
      <div className={classes.labelDesktopStyle}>{labeltext}</div>
      {props.loading && <p>cargando</p>}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocaleDate}>
        <DatePicker
          name={name}
					format="dd-MM-yyyy"
          label={labelText}
          variant="outlined"
          inputVariant="outlined"
          disabled={disabled}
          {...others}
          fullWidth
          InputLabelProps={{ shrink: true }}
          invalidDateMessage="Fecha no válida"
          margin="dense"
          // onChange={handleDateChange}
          inputRef={register({
            required: errorMessage.message[name]
          })}
          error={stateError}
          helperText={stateMessage}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default TextFieldWidgetDate;
