import gql from 'graphql-tag';

export const updateParameter = gql`
  mutation(
    $parameterInput: [ParameterInputCreate!]!
  ) {
    updateParameter(
      parameterInput: $parameterInput
    )
  }
`;
