import gql from 'graphql-tag';

export const signUp = gql`
  mutation($email: String!, $password: String!) {
    signUp(authInput: { email: $email, password: $password }) {
      id
      email
      rut
      name
      paternalLastname
      maternalLastname
      phone
      profile {
        id
        name
      }
      state {
        id
        name
      }
      moduleUser {
        id
        name
      }
      processUser {
        id
        name
        module {
          id
          name
        }
      }
      password
      delete
      edit
    }
  }
`;
