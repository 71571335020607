import gql from 'graphql-tag';

export const createCompetition = gql`
  mutation(
    $name: String!
    $code: String!
    $since: String!
    $until: String!
    $description: String!
    $dayStart: String!
    $specialty: [Int]!
  ) {
    createCompetition(
      competitionInput: {
        name: $name
        code: $code
        since: $since
        until: $until
        description: $description
        dayStart: $dayStart
        specialty: $specialty
      }
    )
  }
`;

export const updateCompetition = gql`
  mutation(
    $id: Int!
    $name: String!
    $code: String!
    $since: String!
    $until: String!
    $description: String!
    $dayStart: String!
    $specialty: [Int]!
  ) {
    updateCompetition(
      competitionInput: {
        id: $id
        name: $name
        code: $code
        since: $since
        until: $until
        description: $description
        dayStart: $dayStart
        specialty: $specialty
      }
    ) {
      id
      name
      code
      since
      until
      description
      dayStart
      specialty {
        id
        name
      }
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const deleteCompetition = gql`
  mutation($id: Int!) {
    deleteCompetition(id: $id)
  }
`;

export const updateStateCompetition = gql`
  mutation($id: Int!) {
    updateStateCompetition(id: $id)
  }
`;
