import gql from 'graphql-tag';

export const listAbsenceReasons = gql`
  query($sort: AbsenceReasonSortingInput) {
    listAbsenceReasons(sort: $sort) {
      id
      name
      document
      documentSee
      attendance
      attendanceSee
      delete
      edit
    }
  }
`;
