import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Divider, TextField } from '@material-ui/core';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  DocumentsDetailTable
} from '../../../../components';
import { BsRow, BsCol } from '../../../../layouts/components';
import classes from './StageUpdate.module.scss';
import '../../../../assets/scss/form.scss';

const StageUpdate = React.memo(props => {
  console.log('las props en Update', props);
  const [titleAreaState, setTitleAreaState] = useState('');
  const [typeFormState, setTypeFormState] = useState(null);

  const require = true;

  const {
    actionStage,
    closeFunc,
    editFunc,
    successSubmitPostulationDocument
  } = props;

  const { handleSubmit, register, errors } = useForm();


  useEffect(() => {
    if (actionStage === 1) {
      setTitleAreaState('Subir Documentos para Aprobación');
      setTypeFormState(actionStage);
    } else if (actionStage === 2) {
      setTitleAreaState('Ingrese Observación para Rechazar Postulación');
      setTypeFormState(actionStage);
    } else {
      setTitleAreaState('Ingrese Observación de Psicología');
      setTypeFormState(actionStage);
    }
  }, [actionStage]);

  // console.log(successSubmitPostulationDocument, "-------successSubmitPostulationDocument")

  // useEffect(() => {
  // 	if (dataSubmitGraph) {
  // 		if(dataSubmitGraph.updateAbsenceReason) {
  // 			const newObj = dataSubmitGraph.updateAbsenceReason;
  // 			console.log(newObj);
  // 			props.editFunc(newObj);
  // 		}
  // 	}
  // }, [dataSubmitGraph]);


  const renderFormType = type => {
    if (type === 2) {
      return (
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <TextFieldWidgetText
            InputLabelProps={{ shrink: true }}
            name="rejectionObservation"
            labeltext="Observación del Rechazo"
            req={require}
            minRows="3"
            multiline
            minLength={3}
            maxLength={500}
            register={register}
            errors={errors.rejectionObservation}
          />
          <SaveAction
            messageType="edit"
            disabled={Boolean(props.successSubmitPostulationDocument)}
            mutationLoading={props.loadingSubmitPostulationDocument}
            success={successSubmitPostulationDocument}
            actionClose={closeFunc}></SaveAction>
        </form>
      );
    } else if (type === 1) {
      return (
        <BsRow>
          <BsCol class="col-md-6">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <BsRow>
                <BsCol class="col-md-12">
                  <TextFieldWidgetText
                    name="requireDocToApprove"
                    labeltext="Documento Requerido"
                    type="file"
                    disabled={false}
                    req={require}
                    InputLabelProps={{ shrink: true }}
                    register={register}
                    errors={errors.requireDocToApprove}
                  />
                  <TextField
                    value={props.userProfile === 'Psicólogo' ? 7 : 8}
                    name="documentType"
                    inputRef={register}
                    type="number"
                    style={{ display: 'none' }}
                  />
                </BsCol>
              </BsRow>
              <div className="EditPostulation">
                <SaveAction
                  messageType="add"
                  mutationLoading={props.loadingSubmitPostulationDocument}
                  success={successSubmitPostulationDocument}
                  actionClose={props.actionAfterSuccess}
                />
              </div>
            </form>
          </BsCol>
          <BsCol class="col-md-6">
            <DocumentsDetailTable
              documents={props.documentList}
              loading={props.loadingDocs}
            />
            {/* {successDocState ? (
              <div
                style={{ textAlign: 'center', marginLeft: '14px' }}
                className="alert alert-success"
                role="alert">
                Documento Subido, ahora puede <b>Aprobar/Rechazar</b> la
                postulación.
              </div>
            ) : null} */}
          </BsCol>
        </BsRow>
      );
    } else {
      return (
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <TextFieldWidgetText
            InputLabelProps={{ shrink: true }}
            name="psychologyObservation"
            labeltext="Observación de Psicólogo/a"
            req={require}
            minRows="3"
            multiline
            minLength={3}
            maxLength={500}
            register={register}
            errors={errors.psychologyObservation}
          />
          <SaveAction
            messageType="edit"
            disabled={Boolean(props.successSubmitPostulationDocument)}
            mutationLoading={props.loadingSubmitPostulationDocument}
            success={successSubmitPostulationDocument}
            actionClose={closeFunc}></SaveAction>
        </form>
      );
    }
  };

  const onSubmit = async values => {
    console.log(values, 'enviado en el componente');
    await editFunc(values);
  };

  return (
    <div className={classes.StageContainer}>
      <CardHeaderWidget title={titleAreaState} />
      <IconButton
        className={classes.CloseButton}
        size="medium"
        onClick={closeFunc}
        aria-label="cerrar"
        component="span">
        <CloseIcon fontSize="small" />
      </IconButton>
      <Divider />
      <div>
        <BsRow>
          <BsCol class="col-md-12">{renderFormType(typeFormState)}</BsCol>
        </BsRow>
      </div>
    </div>
  );
});

export default StageUpdate;
