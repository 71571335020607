import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import useForm from 'react-hook-form';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';
import { Card, CardContent, Divider, ButtonGroup } from '@material-ui/core';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { Context } from '../../../context/Context';
import CardActions from '@material-ui/core/CardActions';
import classes from './ProfileReport.module.scss';
import {
  FilterAction,
  AutoCompleteWidget,
  DialogApplicationStages,
  EditionAreaWidget
} from '../../../components';
import CircularProgressComponent from '../../../components/Progress/CircularProgressComponent';
import { decimalAdjust, getExt, printOrder, alterArrayGetIds } from '../../../helpers';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { listUsers } from '../../../graphql/queries/users';
import { listPostulationStudents } from '../../../graphql/queries/student';
import { getPostulationStudent } from '../../../graphql/queries/PostulationStudent';
import { getPostulationStateOptionButtons } from '../../../graphql/queries/postulationState';
import { createAndUpdatePostulationDocumentApprobation } from '../../../graphql/mutation/postulationState';
import { getPostulationDocumentApprobation } from '../../../graphql/queries/postulationState';
import { getParameter } from '../../../graphql/queries/parameters';
import { createAndUpdateDateMeetingStudent } from '../../../graphql/mutation/dateMeetingStudent';

import StageUpdate from './StageUpdate';
import MeetingStudent from './MeetingStudent';

// mutations
import { updateApprobationAndRejectPostulationState } from '../../../graphql/mutation/postulationState';
import { useActions } from 'store/actions';

function useQueryRoute() {
  return new URLSearchParams(useLocation().search);
}

const ProfileReport = props => {
  const context = useContext(Context);
  const { userId, userProfile, userIdProfile, specialtyUser } = context;

  const { year: yearReducer } = useActions();

  const history = useHistory();

  const [autoSubmitState, setAutoSubmitState] = useState(false);

  const [usersDataState, setUsersDataState] = useState([]);
  const [userSelectedState, setUserSelectedState] = useState(null);//va cambiando en cada select
  const [userIdSelectedState, setUserIdSelectedState] = useState(null);
  const [openDialogStageState, setOpenDialogStageState] = useState(false);
  const [stageToHandleState, setStageToHandleState] = useState('');
  const [actionTypeState, setActionTypeState] = useState('confirm');

  const [stageUpdateState, setStageUpdateState] = useState(false);
  const [actionStageState, setActionStageState] = useState();
  const [dataStudentState, setDataStudentState] = useState('');
  const [dataPhotoStudentState, setDataPhotoStudentState] = useState(null);
  const [dataPhotoDocStudentState, setDataPhotoDocStudentState] = useState(null);

  const [successUploadDocumentState, setSuccessUploadDocumentState] = useState(false);
  const [successObservationState, setSuccessObservationState] = useState(false);

  const [meetingStudentState, setMeetingStudentState] = useState(false);
  const [actionMeetingStudentState, setActionMeetingStudentState] = useState();

  const [limitState] = useState(200);
  const [sortState] = useState({
    paternalLastname: 'asc',
    maternalLastname: 'asc'
  });

  const [optionButtonsState, setOptionButtonsStateState] = useState(null);

  const [documentsState, setDocumentsState] = useState('');
  const [valuesDocState, setValuesDocState] = useState(false);

  const [queryParamsState, setQueryParamsState] = useState(null);
  const [
    queryParamsGraduateBossState,
    setQueryParamsGraduateBossState
  ] = useState(null);

  const { handleSubmit, register, errors } = useForm();

  const { loading: loadingParameterGraph, data: dataParameterGraph } = useQuery(
    getParameter,
    {
      variables: {
        id: 2
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const { loading: loadingUsersGraph, data: dataUsersGraph } = useQuery(
    listUsers,
    {
      variables: {
        filter: {
          profile: {
            in: [3, 4]
          }
        },
        limit: limitState,
        sort: sortState
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  // nuevo 2022
  // aquí hace el filtro, y para el caso comisión 6
  // le agrega el array de especialties
  let filterStudents = {
          year: { eq: parseInt(yearReducer) },
              // agregar solo enviadas
          statePostulation: { gt: 0 },
          
        }
  
  if(userIdProfile === "6" || userIdProfile === 6){

    filterStudents = {
      ...filterStudents,
      specialty: { in: alterArrayGetIds(specialtyUser) }
    }
  }

  const { loading: loadingStudentsGraph, data: dataStudentsGraph } = useQuery(
    listPostulationStudents,
    {
      variables: {
        filter: filterStudents,
        limit: limitState,
        sort: sortState
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );
  

  const [
    GetPostulationStudentHandler,
    {
      loading: loadingGetPostulationStudentGraph,
      data: dataGetPostulationStudentGraph
    }
  ] = useLazyQuery(getPostulationStudent, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // buttons
  const [
    getPostulationStateOptionButtonsHandler,
    {
      loading: loadingGetPostulationStateOptionButtonsGraph,
      data: dataGetPostulationStateOptionButtonsGraph
    }
  ] = useLazyQuery(getPostulationStateOptionButtons, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // buscar documentos de aprobación
  const [
    getPostulationDocumentApprobationHandler,
    {
      loading: loadingGetPostulationDocumentApprobationGraph,
      data: dataGetPostulationDocumentApprobationGraph
    }
  ] = useLazyQuery(getPostulationDocumentApprobation, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  // mutations
  const [
    updateApprobationAndRejectPostulationHandler,
    {
      loading: loadingSubmitUpdateApprobationAndRejectPostulationGraph,
      data: dataSubmitUpdateApprobationAndRejectPostulationGraph
    }
  ] = useMutation(updateApprobationAndRejectPostulationState);

  // carga de docs aprobación
  const [
    createAndUpdatePostulationDocumentApprobationHandler,
    {
      loading: loadingSubmitCreateAndUpdatePostulationDocumentApprobationGraph,
      data: dataSubmitCreateAndUpdatePostulationDocumentApprobationGraph,
    }
  ] = useMutation(createAndUpdatePostulationDocumentApprobation);

  const [
    createAndUpdateDateMeetingStudentHandler,
    {
      loading: loadingSubmitCreateAndUpdateDateMeetingStudentGraph,
      data: dataSubmitCreateAndUpdateDateMeetingStudentGraph
    }
  ] = useMutation(createAndUpdateDateMeetingStudent);

  // una vez que ejecutó la actualización de estado
  useEffect(() => {
    if (dataSubmitUpdateApprobationAndRejectPostulationGraph) {
      setSuccessObservationState(true);
      async function queryDataButtons(idGet) {
        const filter = {
          id: parseInt(userId),
          idUserFilter: parseInt(idGet),
          idPostulationActive: parseInt(userSelectedState.idPostulation),
        };
        try {
          await getPostulationStateOptionButtonsHandler({
            variables: filter
          });
        } catch (err) {}
      }
      async function queryDataGet(idGet) {
        const filter = {
          id: parseInt(idGet),
          idPostulationActive: parseInt(userSelectedState.idPostulation),
        };

        console.log(filter, "<-----GetPostulationStudentHandler-----------");
        try {
          await GetPostulationStudentHandler({
            variables: filter
          });
        } catch (err) {}
      }
      if (userIdSelectedState && userSelectedState) {
        queryDataButtons(userIdSelectedState);
        queryDataGet(userIdSelectedState);
      } else {
        setDataStudentState('');
        setDataPhotoStudentState(null);
        setDataPhotoDocStudentState(null);
      }
    }
  }, [
    dataSubmitUpdateApprobationAndRejectPostulationGraph,
    getPostulationStateOptionButtonsHandler,
    GetPostulationStudentHandler,
    userId,
    userIdSelectedState
    // userSelectedState
  ]);

  // cuando le llegan los docs de aprobaciones
  useEffect(() => {
    if (
      dataGetPostulationDocumentApprobationGraph &&
      dataGetPostulationDocumentApprobationGraph.getPostulationDocumentApprobation
    ) {
      console.log(dataGetPostulationDocumentApprobationGraph, "llegan los docs");
      setDocumentsState(
        dataGetPostulationDocumentApprobationGraph.getPostulationDocumentApprobation
      );
    }
  }, [dataGetPostulationDocumentApprobationGraph]);

  // cuando llega la data del postulante
  useEffect(() => {
    if (
      dataGetPostulationStudentGraph &&
      dataGetPostulationStudentGraph.getPostulationStudent
    ) {
      console.log('llega la data ------->', dataGetPostulationStudentGraph);
      setDataStudentState(dataGetPostulationStudentGraph.getPostulationStudent);
    }
  }, [dataGetPostulationStudentGraph]);

  // Cuando llegan los buttons
  useEffect(() => {
    if (
      dataGetPostulationStateOptionButtonsGraph &&
      dataGetPostulationStateOptionButtonsGraph.getPostulationStateOptionButtons
    ) {
      setOptionButtonsStateState(
        dataGetPostulationStateOptionButtonsGraph.getPostulationStateOptionButtons
      );
    }
  }, [dataGetPostulationStateOptionButtonsGraph]);

  useEffect(() => {
    if (dataStudentState) {
      // console.log('DATOS: ', dataStudentState);
      const photoProfile = dataStudentState.postulationDocument.filter(ele => ele.id === 18);

      // console.log(photoProfile, "foto llega")

      if(photoProfile[0].documentHref) {
        let filename =getExt(photoProfile[0].documentHref);

        // console.log("la foto", filename.split('?')[0]);

        if(filename.split('?')[0] === 'jpg' || filename.split('?')[0] === 'jpeg' || filename.split('?')[0] === 'png') {
        setDataPhotoStudentState(photoProfile[0]);
        } else {
          setDataPhotoDocStudentState(photoProfile[0]);
        } 
      } else {
        setDataPhotoStudentState(null);
        setDataPhotoDocStudentState(null);
      }

      
    }
  }, [dataStudentState]);

  // Cuando se selecciona el postulante o al cargar docs
  useEffect(() => {
    async function queryDataButtons(idGet) {
      const filter = {
        id: parseInt(userId),
        idUserFilter: parseInt(idGet),
        idPostulationActive: parseInt(userSelectedState.idPostulation),
      };
      try {
        await getPostulationStateOptionButtonsHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if ((userIdSelectedState && userSelectedState) || valuesDocState) {
      queryDataButtons(userIdSelectedState);
    } else {
      setDataStudentState('');
      setDataPhotoStudentState(null);
      setDataPhotoDocStudentState(null);
    }
  }, [
    getPostulationStateOptionButtonsHandler,
    userIdSelectedState,
    valuesDocState,
    userId
    // userSelectedState
  ]);

  // Cuando recibe el listado de postulantes
  // useEffect(() => {
  //   if (dataUsersGraph && dataUsersGraph.listUsers) {
  //     const usersFiltered = dataUsersGraph.listUsers.filter(
  //       student => !student.delete
  //     );
  //     // delete false filtrar array

  //     setUsersDataState(usersFiltered);
  //   }
  // }, [dataUsersGraph]);

  // 2022 - Cuando recibe el listado de postulantes
  useEffect(() => {
    if (dataStudentsGraph && dataStudentsGraph.listPostulationStudents) {

      // console.log(dataStudentsGraph,"<-------dataStudentsGraph")

      // const usersFiltered = dataStudentsGraph.listPostulationStudents.filter(
      //   student => !student.delete
      // );
      // delete false filtrar array

      setUsersDataState(dataStudentsGraph.listPostulationStudents);
    }
  }, [dataStudentsGraph]);

  // Ejecuta la búsqueda de los docs de aprobación
  useEffect(() => {
    async function queryData(userSelected) {
      try {
        await ({
          variables: {
            idUser: parseInt(userId),
            idUserFilter: parseInt(userSelected),
            idPostulationActive: parseInt(userSelectedState.idPostulation),
          }
        });
      } catch (err) {}
    }
    if (valuesDocState && userIdSelectedState && userSelectedState) {
      queryData(userIdSelectedState);
      setValuesDocState(false);
    } else {
      if (userIdSelectedState) {
        // console.log("se ejecuta la query de docs aprove", userSelectedState)
        queryData(userIdSelectedState);
      }
    }
  }, [
    getPostulationDocumentApprobationHandler,
    valuesDocState,
    userId,
    userIdSelectedState
    // userSelectedState
  ]);

  // console.log(dataPhotoStudentState, "LA FOTO");

  // Ejecuta Carga la data del postulante
  useEffect(() => {
    async function queryDataGet(idGet) {
      const filter = {
        id: parseInt(idGet),
        idPostulationActive: parseInt(userSelectedState.idPostulation),
      };
      // console.log(filter, "<-----");
      try {
        await GetPostulationStudentHandler({
          variables: filter
        });
      } catch (err) {}
    }

    // console.log("422, antes de entrar",userIdSelectedState,userSelectedState)

    if (userIdSelectedState && userSelectedState) {
      // console.log("-------------------BSUCA LA DATA 422")
      queryDataGet(userIdSelectedState);
    } else {
      setDataStudentState('');
      setDataPhotoStudentState(null);
      setDataPhotoDocStudentState(null);
    }
  }, [GetPostulationStudentHandler, 
    userIdSelectedState
    // userSelectedState
  ]);

  let query = useQueryRoute();
  let userIdQuery = null;
  // let postulationIdQuery = null;
  let idQuery = null;

  // Cuando le llega un ID por get
  userIdQuery = query.get('idUser');
  // postulationIdQuery = query.get('idPostulation');
  idQuery = query.get('id');
  // Recibe y selecciona el postulante del report
  useEffect(() => {
    
    if (userIdQuery && idQuery) {
      if(usersDataState) {
        const result = usersDataState.filter(ele => ele.idPostulation === parseInt(idQuery));

        // console.log('result----------', result[0])

        setUserSelectedState(result[0]);
      }
      // console.log(userIdQuery, "-------------userId")
      setUserIdSelectedState(userIdQuery);
      setAutoSubmitState(true);
    }
  }, [userIdQuery, usersDataState]);

  // se ejecuta despues de subir el doc de postulación
  useEffect(() => {
    if (dataSubmitCreateAndUpdatePostulationDocumentApprobationGraph) {
      setSuccessUploadDocumentState(true);
      async function queryDataGet(idGet) {
        const filter = {
          id: parseInt(idGet),
          idPostulationActive: parseInt(userSelectedState.idPostulation),
        };
        try {
          await GetPostulationStudentHandler({
            variables: filter
          });
          // setSuccessUploadDocumentState(false);
        } catch (err) {}
      }
      async function queryDataButtons(idGet) {
        const filter = {
          id: parseInt(userId),
          idUserFilter: parseInt(idGet),
          idPostulationActive: parseInt(userSelectedState.idPostulation),
        };
        try {
          await getPostulationStateOptionButtonsHandler({
            variables: filter
          });
        } catch (err) {}
      }
      if (userIdSelectedState && userSelectedState) {
        queryDataGet(userIdSelectedState);
      }

      if (userIdSelectedState && userSelectedState) {
        queryDataButtons(userIdSelectedState);
      }
      
    }
  }, [
    dataSubmitCreateAndUpdatePostulationDocumentApprobationGraph,
    userId,
    userIdSelectedState,
    GetPostulationStudentHandler,
    getPostulationStateOptionButtonsHandler
  ]);

  // cuando se setean datos usuario para armar params

  useEffect(() => {
    if (dataParameterGraph && dataParameterGraph.getParameter) {
      setQueryParamsGraduateBossState(dataParameterGraph.getParameter.value);
    }
  }, [dataParameterGraph]);

  let queryParams = {};
  useEffect(() => {
    if (
      dataStudentState &&
      dataStudentState.fullname &&
      queryParamsGraduateBossState
    ) {
      queryParams = {
        fullname: dataStudentState.fullname,
        rut: dataStudentState.rut,
        competition: dataStudentState.competition.name,
        specialty: dataStudentState.specialty.name,
        boss: queryParamsGraduateBossState
      };
      setQueryParamsState(queryParams);
    }
  }, [dataStudentState, queryParamsGraduateBossState]);

  const stageActionDialogHandler = async (stage, actionType) => {
    const query = {
      idUser: parseInt(userId),
      idUserFilter: parseInt(userIdSelectedState),
      option: 'Approve',
      message: null,
      idPostulationActive: parseInt(userSelectedState.idPostulation),
    };
    try {
      await updateApprobationAndRejectPostulationHandler({
        variables: query
      });
    } catch (err) {}
  };

  const DialogActionStageHandler = (stage, type) => {
    setStageToHandleState(stage);
    setOpenDialogStageState(!openDialogStageState);
    setActionTypeState(type);
  };

  const closeStageUpdateAreaHandler = () => {
    setStageUpdateState(false);
    setSuccessUploadDocumentState(false);
    setSuccessObservationState(false);
  };

  const closeMeetingStudentAreaHandler = () => {
    setMeetingStudentState(false);
  };

  const updateStageActionHandler = async values => {
    const query = {
      idUser: parseInt(userId),
      idUserFilter: parseInt(userIdSelectedState),
      option: 'Reject',
      message: values.rejectionObservation,
      idPostulationActive: parseInt(userSelectedState.idPostulation),
    };
    try {
      await updateApprobationAndRejectPostulationHandler({
        variables: query
      });
    } catch (err) {}
  };

  // nuevo observación
  const updateObservationStageActionHandler = async values => {
    const query = {
      idUser: parseInt(userId),
      idUserFilter: parseInt(userIdSelectedState),
      option: 'Observation',
      message: values.psychologyObservation,
      idPostulationActive: parseInt(userSelectedState.idPostulation),
    };
    try {
      await updateApprobationAndRejectPostulationHandler({
        variables: query
      });
    } catch (err) {}
  };

  const PostulationDocumentApprobationActionHandler = async values => {
    const query = {
      idUser: parseInt(userId),
      idUserFilter: parseInt(userIdSelectedState),
      documentRequired: values.requireDocToApprove,
      documentType: parseInt(values.documentType),
      idPostulationActive: parseInt(userSelectedState.idPostulation),
    };

    try {
      await createAndUpdatePostulationDocumentApprobationHandler({
        variables: query
      });
      setValuesDocState(true);
    } catch (err) {}
  };

  const PostulationDateMeetingStudentHandler = async values => {
    const query = {
      idUser: parseInt(userId),
      date: values.meetingDate,
      time: values.meetingTime
    };
    // console.log('PRUEBA QUERY: ', query);
    try {
      await createAndUpdateDateMeetingStudentHandler({
        variables: query
      });
    } catch (err) {}
  };

  const onSubmit = async values => {
    // revisar
    // console.log(userSelectedState, "------userSelectedState")
    // console.log(values, "-<<<<<<<-----values")
    setUserIdSelectedState(userSelectedState.idUser);
  };

  const onClear = () => {
    setUserIdSelectedState(null);
    setOptionButtonsStateState(null);
    history.replace('profile-report');
  }

  useEffect(() => {
    if(autoSubmitState && userSelectedState){
      // console.log("se enviará submit")
      handleSubmit(onSubmit)();
      setAutoSubmitState(false);
    }
  }, [autoSubmitState, userSelectedState]);

  // console.log("el perfil del usuario---->", userSelectedState);

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12 mb-4">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.push('/dashboard')}>
                  <HomeIcon fontSize="small" /> Ir al Escritorio
                </Button>
                <div>
                  {/* <Button size="small" color="primary">
										Excel
									</Button> */}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => printOrder('ifmcontentstoprint')}>
                    Imprimir
                  </Button>
                </div>
              </CardActions>
              <div className={classes.buttonContainer}>
                <ButtonGroup
                  color="default"
                  classes={{
                    root: classes.rootGroup
                  }}
                  aria-label="outlined primary button group">
                  <Button
                    onClick={() => props.history.push('/application-report')}>
                    Estado Postulaciones por Especialidad
                  </Button>
                  <Button
                    onClick={() =>
                      props.history.push('/specialty-application-report')
                    }>
                    Postulantes por Especialidad
                  </Button>
                  <Button
                    color="primary"
                    classes={{ outlinedPrimary: classes.outlinedPrimary }}>
                    Ficha Perfil
                  </Button>
                </ButtonGroup>
              </div>
              <Divider />
              <CardContent className={classes.cardContent}>
                {/* componente edit */}
                {stageUpdateState && (
                  <EditionAreaWidget
                    component={
                      <StageUpdate
                        actionStage={actionStageState}
                        loadingDocs={
                          actionStageState === 1
                            ? loadingGetPostulationDocumentApprobationGraph
                            : false
                        }
                        documentList={
                          actionStageState === 1 ? documentsState.length ? documentsState : dataStudentState.postulationDocumentState : false
                        }
                        loadingSubmitPostulationDocument={
                          actionStageState === 1
                            ? loadingSubmitCreateAndUpdatePostulationDocumentApprobationGraph
                            : loadingSubmitUpdateApprobationAndRejectPostulationGraph
                        }
                        successSubmitPostulationDocument={
                          actionStageState === 1
                            ? successUploadDocumentState
                            : successObservationState
                        }
                        closeFunc={closeStageUpdateAreaHandler}
                        userProfile={userProfile}
                        editFunc={
                          actionStageState === 1
                            ? PostulationDocumentApprobationActionHandler
                          : actionStageState === 2
                            ? updateStageActionHandler
                          : updateObservationStageActionHandler
                        }
                        actionAfterSuccess={() => {
                          setStageUpdateState(false);
                          setSuccessUploadDocumentState(false);
                           setSuccessObservationState(false);
                        }}
                      />
                    }
                    visible={stageUpdateState}
                  />
                )}
                {meetingStudentState && (
                  <EditionAreaWidget
                    component={
                      <MeetingStudent
                        actionStage={actionMeetingStudentState}
                        meetingDate={
                          dataStudentState.meetingDate
                            ? dataStudentState.meetingDate
                            : null
                        }
                        meetingTime={
                          dataStudentState.meetingTime
                            ? dataStudentState.meetingTime
                            : null
                        }
                        loadingSubmitPostulationMeeting={
                          loadingSubmitCreateAndUpdateDateMeetingStudentGraph
                        }
                        successSubmitPostulationMeeting={
                          dataSubmitCreateAndUpdateDateMeetingStudentGraph
                        }
                        closeFunc={closeMeetingStudentAreaHandler}
                        editFunc={PostulationDateMeetingStudentHandler}
                        actionAfterSuccess={() => {
                          setMeetingStudentState(false);
                        }}
                      />
                    }
                    visible={meetingStudentState}
                  />
                )}
                <div>
                  <BsRow>
                    <BsCol class="col-md-12">
                      <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                        <BsRow>
                          <BsCol class="col-md-7">
                            <AutoCompleteWidget
                              label="Postulante / Alumno"
                              require={true}
                              data={usersDataState}
                              dataOptionLabel="fullname"
                              inicialValue="Seleccione Perfil..."
                              valuecombo={userSelectedState}
                              checkId
                              loadingComboState={loadingUsersGraph}
                              onSelectState={setUserSelectedState}
                              onClear={onClear}
                              name="usercombo"
                              register={register}
                              errors={errors.usercombo}
                            />
                          </BsCol>
                          <BsCol class="col-md-5">
                            <FilterAction
                              mutationLoading={false}
                              success={false}
                            />
                          </BsCol>
                        </BsRow>
                      </form>
                    </BsCol>
                    <BsCol
                      class={clsx('col-md-12', classes.buttonsActionsContainer)}>
                      <div
                        className={clsx(
                          optionButtonsState &&
                            optionButtonsState.message &&
                            classes.buttonsActions
                        )}>
                        <div className={classes.actualStageContainer}>
                          <span className={classes.actualStage}>
                            {optionButtonsState
                                ? 'Estado Actual: ' 
                                  + optionButtonsState.message === "Postulación no enviada" && dataStudentState.datePostulation 
                                    ? 'Postulación Rechazada, puede volver a enviarla' 
                                    : optionButtonsState.message
                                : 'seleccione alumno / postulante'}
                          </span>
                        </div>
                        {dataStudentState.meetingDate &&
                          dataStudentState.meetingTime && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setActionMeetingStudentState(1);
                                setMeetingStudentState(true);
                              }}>
                              Reunión
                            </Button>
                          )}
                        {optionButtonsState &&
                          optionButtonsState.buttonDocuments && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setActionStageState(1);
                                setStageUpdateState(true);
                              }}>
                              Documentos
                            </Button>
                          )}

                        {optionButtonsState && optionButtonsState.buttonReject && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setActionStageState(2);
                              setStageUpdateState(true);
                            }}>
                            Rechazar
                          </Button>
                        )}

                        {optionButtonsState && optionButtonsState.buttonObservation && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setActionStageState(3);
                              setStageUpdateState(true);
                            }}>
                            Observación
                          </Button>
                        )}

                        {optionButtonsState &&
                        optionButtonsState.buttonApprove ? (
                          optionButtonsState.buttonReject ? (
                            <Button
                              variant="contained"
                              color="primary"
                              classes={{
                                containedPrimary: classes.buttonApprove
                              }}
                              onClick={() => {
                                setActionStageState(3);
                                setStageUpdateState(false);
                                DialogActionStageHandler(
                                  optionButtonsState.messageApprove,
                                  'confirm'
                                );
                              }}>
                              Aprobar
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              classes={{
                                containedPrimary: classes.buttonApprove
                              }}
                              onClick={() => {
                                setActionStageState(4);
                                setStageUpdateState(false);
                                DialogActionStageHandler(
                                  userProfile,
                                  'confirm'
                                );
                              }}>
                              Matricular
                            </Button>
                          )
                        ) : loadingGetPostulationStateOptionButtonsGraph ? (
                          <p>Cargando...</p>
                        ) : null}
                      </div>
                    </BsCol>
                  </BsRow>
                  <div id="ifmcontentstoprint">
                    {loadingGetPostulationStudentGraph && (
                      <BsRow class="mt-4">
                        <BsCol class="col-md-12">
                          <CircularProgressComponent />
                        </BsCol>
                      </BsRow>
                    )}
                    {userIdSelectedState && (
                      <React.Fragment>
                        <BsRow class="mt-4">
                          <BsCol class="col-md-4">
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" /> Datos
                                    Personales
                                  </td>
                                </tr>
                                <tr>
                                  <td>RUT</td>

                                  {dataStudentState.rut ? (
                                    <td>{dataStudentState.rut}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nombre Completo</td>
                                  {dataStudentState.fullname ? (
                                    <td>{dataStudentState.fullname}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Fecha Nacimiento</td>
                                  {dataStudentState.birthDate ? (
                                    <td>{dataStudentState.birthDate}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nacionalidad</td>
                                  {dataStudentState.nationality ? (
                                    <td>{dataStudentState.nationality.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Región / Comuna</td>
                                  {dataStudentState.region &&
                                  dataStudentState.commune ? (
                                    <td>
                                      {dataStudentState.region.name} /
                                      {dataStudentState.commune.name}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Dirección</td>
                                  {dataStudentState.address ? (
                                    <td>{dataStudentState.address}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  {dataStudentState.email ? (
                                    <td>{dataStudentState.email}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Teléfono</td>
                                  {dataStudentState.phone ? (
                                    <td>{dataStudentState.phone}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Fecha reunión</td>
                                  {dataStudentState.meetingDate ? (
                                    <td>{dataStudentState.meetingDate}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Hora reunión</td>
                                  {dataStudentState.meetingTime ? (
                                    <td>{dataStudentState.meetingTime} hrs</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol class="col-md-4">
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Especialidad
                                  </td>
                                </tr>
                                <tr>
                                  <td>Especialidad</td>
                                  {dataStudentState.specialty ? (
                                    <td>{dataStudentState.specialty.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Concurso</td>
                                  {dataStudentState.competition ? (
                                    <td>{dataStudentState.competition.name}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Fecha Envío Postulación</td>
                                  {dataStudentState.datePostulation ? (
                                    <td>{dataStudentState.datePostulation}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Información de Salud
                                  </td>
                                </tr>
                                <tr>
                                  <td>Inscripción en Registro de Prestadores</td>
                                  {dataStudentState.healthSuperintendenceRegistration &&
                                  dataStudentState.healthSuperintendenceRegistration ===
                                    1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.healthSuperintendenceRegistration ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Vacuna contra Influenza</td>
                                  {dataStudentState.influenzaVaccine &&
                                  dataStudentState.influenzaVaccine === 1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.influenzaVaccine ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Vacuna contra Hepatitis B</td>
                                  {dataStudentState.hepatitisbVaccine &&
                                  dataStudentState.hepatitisbVaccine === 1 ? (
                                    <td>Si</td>
                                  ) : dataStudentState.hepatitisbVaccine ===
                                    2 ? (
                                    <td>No</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>
                                    Póliza de Seguros de Responsabilidad
                                    Civil
                                  </td>
                                  {dataStudentState.policyProfessionalLiabilityNumber ? (
                                    <td>
                                      {
                                        dataStudentState.policyProfessionalLiabilityNumber
                                      }
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol class="col-md-4">
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Información Formativa
                                  </td>
                                </tr>
                                <tr>
                                  <td>Título Profesional</td>
                                  {dataStudentState.jobTitle ? (
                                    <td>{dataStudentState.jobTitle}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Año Titulación</td>
                                  {dataStudentState.yearTitle ? (
                                    <td>{dataStudentState.yearTitle}</td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Universidad</td>
                                  {dataStudentState.studentUniversity ? (
                                    <td>
                                      {dataStudentState.studentUniversity.name}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Pediatría</td>
                                  {dataStudentState.noteInternPediatrics ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternPediatrics,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Ginecología</td>
                                  {dataStudentState.noteInternGynecology ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternGynecology,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Int. Cirugía</td>
                                  {dataStudentState.noteInternSurgery ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternSurgery,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Medicina Interna</td>
                                  {dataStudentState.noteInternalMedicine ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteInternalMedicine,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nota Médico Nacional</td>
                                  {dataStudentState.noteNationalMedical ? (
                                    <td>
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.noteNationalMedical,
                                        -1
                                      )}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>EUNACOM</td>
                                  {dataStudentState.eunacomScore ? (
                                    <td>
                                      Nota:{' '}
                                      {decimalAdjust(
                                        'round',
                                        dataStudentState.eunacomScore,
                                        -1
                                      )}{' '}
                                      | Año: {dataStudentState.eunacomYear}
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                                <tr>
                                  <td>Nivel Inglés</td>
                                  {dataStudentState.studentLanguageLevel ? (
                                    <td>
                                      {
                                        dataStudentState.studentLanguageLevel
                                          .name
                                      }
                                    </td>
                                  ) : (
                                    <td>....</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </BsCol>
                        </BsRow>
                        <BsRow>
                          {/* <BsCol class={clsx('col-md-4', classes.picCol)}>
                            <div
                              style={{
                                width: '50%',
                                height: 'auto',
                                backgroundColor: 'white'
                              }}>
                                {dataPhotoStudentState && dataPhotoStudentState.documentHref && (
                                    <img
                                    alt="foto-perfil"
                                    style={{width: '100%', height: 'auto'}}
                                    src={dataPhotoStudentState.documentHref}
                                  />
                                )}
                                {dataPhotoDocStudentState && dataPhotoDocStudentState.documentHref && (
                                  <a
                                    href={
                                      dataPhotoDocStudentState.documentHref
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download>
                                    Descarga la foto Aquí
                                  </a>
                                )}
                              </div>
                          </BsCol> */}
                          <BsCol class={clsx('col-md-4', classes.docCol)}>
                            <h6>
                              <InsertDriveFileIcon fontSize="small" />{' '}
                              Documentos Adjuntos
                            </h6>
                            <ul className="list-group">
                              {dataStudentState.postulationDocument &&
                              dataStudentState.postulationDocument.length > 0
                                ? dataStudentState.postulationDocument.map(
                                    documentItem => {
                                      if (documentItem.id !== 18)
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={documentItem.id}>
                                          <span>{documentItem.name}</span>{' '}
                                          { documentItem.documentHref && 
                                          (<a
                                            href={documentItem.documentHref}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-light">
                                            <GetAppIcon
                                              style={{ fontSize: 20 }}
                                            />
                                          </a>)
                                          }
                                        </li>
                                      );
                                    }
                                  )
                                : null}
                            </ul>
                          </BsCol>
                          <BsCol class={clsx('col-md-4', classes.docCol)}>
                            <h6>
                              <InsertDriveFileIcon fontSize="small" />{' '}
                              Documentos Del Proceso de Postulación
                            </h6>
                            <ul className="list-group">
                              {dataStudentState.postulationDocumentState &&
                              dataStudentState.postulationDocumentState.length >
                                0
                                ? dataStudentState.postulationDocumentState.map(
                                    documentItem => {
                                      {/* console.log(documentItem, "los items de docs que llegan"); */}
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={documentItem.id}>
                                          <span>{documentItem.name}</span>{' '}
                                          <a
                                            href={documentItem.documentHref}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-light">
                                            <GetAppIcon
                                              style={{ fontSize: 20 }}
                                            />
                                          </a>
                                          {
                                            (documentItem.name==="Aprobación Psicólogo(a)" && userProfile === "Psicólogo") ? (
                                              <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => {
                                                    setActionStageState(1);
                                                    setStageUpdateState(true);
                                                  }}>
                                                  reemplazar Psic.
                                                </Button>
                                            ) : ""
                                          }
                                          {
                                            (documentItem.name==="Aprobación Comisión" && userProfile === "Comisión") ? (
                                              <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => {
                                                    setActionStageState(1);
                                                    setStageUpdateState(true);
                                                  }}>
                                                  reemplazar Comis.
                                                </Button>
                                            ) : ""
                                          }
                                        </li>
                                      );
                                    }
                                  )
                                : null}
                            </ul>
                          </BsCol>
                        </BsRow>
                        <BsRow class="mt-3">
                          <BsCol class="col-md-12">
                            <table
                              className={clsx(
                                'table',
                                'table-sm',
                                classes.tableRoot
                              )}>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="2"
                                    className={classes.titleTable}>
                                    <AssignmentIcon fontSize="small" />{' '}
                                    Formación Adicional
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Antecedentes
                              Curriculares
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Nombre Estudio</th>
                                  <th>Fecha/Período</th>
                                  <th>Calificación</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationCurricularBackground &&
                                dataStudentState.postulationCurricularBackground
                                  .length > 0 ? (
                                  dataStudentState.postulationCurricularBackground.map(
                                    cb => {
                                      return (
                                        <tr key={cb.id}>
                                          <td>{cb.name}</td>
                                          <td>{cb.date}</td>
                                          <td>{cb.qualification}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      No hay antecedentes curriculares.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Publicaciones
                              Científicas de forma individual
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Publicación</th>
                                  <th>Autores</th>
                                  <th>Revista</th>
                                  <th>Fecha/Período</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationPublication &&
                                dataStudentState.postulationPublication.length >
                                  0 ? (
                                  dataStudentState.postulationPublication.map(
                                    publication => {
                                      return (
                                        <tr key={publication.id}>
                                          <td>{publication.name}</td>
                                          <td>{publication.author}</td>
                                          <td>{publication.magazine}</td>
                                          <td>{publication.date}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No hay publicaciones.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Presentaciones
                              en Congresos de forma individual
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Presentación</th>
                                  <th>Autor</th>
                                  <th>Congreso</th>
                                  <th>Fecha/Período</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationPresentation &&
                                dataStudentState.postulationPresentation
                                  .length > 0 ? (
                                  dataStudentState.postulationPresentation.map(
                                    presentation => {
                                      return (
                                        <tr key={presentation.id}>
                                          <td>{presentation.name}</td>
                                          <td>{presentation.author}</td>
                                          <td>{presentation.congress}</td>
                                          <td>{presentation.date}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No hay presentaciones.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Ayudantías a
                              Alumnos
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Asignatura</th>
                                  <th>Universidad</th>
                                  <th>País</th>
                                  <th>Año</th>
                                  <th>Horas</th>
                                  <th>Descargar</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationAssistantShip &&
                                dataStudentState.postulationAssistantShip
                                  .length > 0 ? (
                                  dataStudentState.postulationAssistantShip.map(
                                    as => {
                                      return (
                                        <tr key={as.id}>
                                          <td>{as.course}</td>
                                          <td>{as.university}</td>
                                          <td>
                                            {as.country ? as.country.name : ''}
                                          </td>
                                          <td>{as.year}</td>
                                          <td>{as.hour}</td>
                                          <td>
                                            <a
                                              href={as.certificate}
                                              target="_blank"
                                              title="descargar certificado"
                                              rel="noopener noreferrer"
                                              className="btn btn-light">
                                              <GetAppIcon
                                                style={{ fontSize: 15 }}
                                              />
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      No hay ayudantías a alumnos.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Diplomados
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Diplomado</th>
                                  <th>Universidad</th>
                                  <th>País</th>
                                  <th>Fecha/Período</th>
                                  <th>Horas</th>
                                  <th>Calificación</th>
                                  <th>Descargar</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationDiplomat &&
                                dataStudentState.postulationDiplomat.length >
                                  0 ? (
                                  dataStudentState.postulationDiplomat.map(
                                    diplomat => {
                                      {/* console.log(diplomat, "<-----diplomat") */}
                                      return (
                                        <tr key={diplomat.id}>
                                          <td>{diplomat.name}</td>
                                          <td>{diplomat.university}</td>
                                          <td>{diplomat.country.name}</td>
                                          <td>{diplomat.date}</td>
                                          <td>{diplomat.hour}</td>
                                          <td>{diplomat.qualification}</td>
                                          <td>
                                            <a
                                              href={diplomat.certificate}
                                              target="_blank"
                                              title="descargar certificado"
                                              rel="noopener noreferrer"
                                              className="btn btn-light">
                                              <GetAppIcon
                                                style={{ fontSize: 15 }}
                                              />
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No hay diplomados.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Desempeño
                              Laboral
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>Lugar</th>
                                  <th>Horas</th>
                                  <th>Fecha/Período</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationPerformance &&
                                dataStudentState.postulationPerformance.length >
                                  0 ? (
                                  dataStudentState.postulationPerformance.map(
                                    performance => {
                                      return (
                                        <tr key={performance.id}>
                                          <td>{performance.name}</td>
                                          <td>{performance.place}</td>
                                          <td>{performance.hour}</td>
                                          <td>{performance.date}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      No hay registros de su desempeño laboral.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                          <BsCol
                            class={clsx('col-md-6', classes.tableRegisters)}>
                            <h6>
                              <ArrowRightIcon fontSize="small" /> Distinciones
                            </h6>
                            <table>
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>Institución</th>
                                  <th>Fecha/Período</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationDistinction &&
                                dataStudentState.postulationDistinction.length >
                                  0 ? (
                                  dataStudentState.postulationDistinction.map(
                                    distinction => {
                                      return (
                                        <tr key={distinction.id}>
                                          <td>{distinction.name}</td>
                                          <td>{distinction.institution}</td>
                                          <td>{distinction.date}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No hay distinciones.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </BsCol>
                        </BsRow>
                        <BsRow class="mt-4">
                          <BsCol class="col-md-12">
                            <h6>Eventos de la Postulación</h6>

                            <table
                              className={clsx(
                                'table',
                                'table-striped',
                                classes.tableRoot
                              )}>
                              <thead>
                                <tr>
                                  <th scope="col">Responsable</th>
                                  <th scope="col">Comentario</th>
                                  <th scope="col">Fecha</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataStudentState.postulationActivity &&
                                  dataStudentState.postulationActivity.map(
                                    (activity, i) => {
                                      let certificate_doc = null;
                                      {/* console.log(activity, "------") */}
                                      if (activity.state.id === 1 && activity.user.id === 6) {
                                        certificate_doc = (
                                          <RouterLink
                                            to={{
                                              pathname: '/qualification-report',
                                              state: queryParamsState
                                                ? queryParamsState
                                                : loadingParameterGraph && (
                                                    <p>Cargando...</p>
                                                  )
                                            }}>
                                            Ir al Certificado
                                          </RouterLink>
                                        );
                                      }
                                      return (
                                        <tr key={i}>
                                          <td>{activity.user.name}</td>
                                          <td>
                                            {activity.commentary}{' '}
                                            {certificate_doc}
                                            {activity.state.id === 3 && '(Rechazo)'}
                                          </td>
                                          <td>{activity.date}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </BsCol>
                        </BsRow>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <DialogApplicationStages
                  openDialog={openDialogStageState}
                  stageState={stageToHandleState}
                  actionState={stageActionDialogHandler}
                  variant="success"
                  actionType={actionTypeState}
                  loading={loadingSubmitUpdateApprobationAndRejectPostulationGraph}
                  success={undefined}
                  openState={setOpenDialogStageState}
                />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(ProfileReport);
