import gql from 'graphql-tag';

export const createNotification = gql`
  mutation($idUser: Int!, $name: String!, $message: String!) {
    createNotification(
      notificationInput: { idUser: $idUser, name: $name, message: $message }
    )
  }
`;

export const updateNotification = gql`
  mutation($id: Int!) {
    updateNotification(notificationInput: { id: $id }) {
      id
      state {
        id
        name
      }
      user {
        id
        email
        rut
        name
        paternalLastname
        maternalLastname
        fullname
      }
      date
      name
      message
    }
  }
`;
