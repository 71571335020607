import gql from 'graphql-tag';

export const createAcademicPeriod = gql`
  mutation(
    $specialty: Int!
    $name: String!
    $since: String!
    $until: String!
    $year: Int!
    $businessDays: Int!
  ) {
    createAcademicPeriod(
      academicPeriodInput: {
        specialty: $specialty
        name: $name
        since: $since
        until: $until
        year: $year
        businessDays: $businessDays
      }
    )
  }
`;

export const updateAcademicPeriod = gql`
  mutation(
    $id: Int!
    $specialty: Int!
    $name: String!
    $since: String!
    $until: String!
    $year: Int!
    $businessDays: Int!
  ) {
    updateAcademicPeriod(
      academicPeriodInput: {
        id: $id
        specialty: $specialty
        name: $name
        since: $since
        until: $until
        year: $year
        businessDays: $businessDays
      }
    ) {
      id
      specialty {
        id
        name
      }
      state {
        id
        name
      }
      name
      since
      until
      year
      businessDays
      delete
      edit
    }
  }
`;

export const deleteAcademicPeriod = gql`
  mutation($id: Int!) {
    deleteAcademicPeriod(id: $id)
  }
`;

export const updateStateAcademicPeriod = gql`
  mutation($id: Int!) {
    updateStateAcademicPeriod(id: $id)
  }
`;

export const createAndUpdateAcademicPeriods = gql`
  mutation($idAcademicPeriod: Int!, $idCourse: Int!, $block: Int!) {
    createAndUpdateAcademicPeriods(
      courseInput: {
        idAcademicPeriod: $idAcademicPeriod
        idCourse: $idCourse
        block: $block
      }
    ) {
      id
      course {
        id
        name
        code
        type {
          id
          name
        }
        state {
          id
          name
        }
        delete
        edit
      }
      block
    }
  }
`;
