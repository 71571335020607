import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import classes from './LinkOfInterest.module.scss';

const LinkOfInterest = props => {
  const { className, ...rest } = props;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <h3 className={classes.title}>Links de interés</h3>
        <p>www.link.cl</p>
      </CardContent>
    </Card>
  );
};

LinkOfInterest.propTypes = {
  className: PropTypes.string
};

export default LinkOfInterest;
