import React, { useState, useEffect } from 'react';
import {
  SearchBasicTable,
  DataTableWidget,
  DialogDelete,
  DialogState,
  IconActionTableDelete,
  IconActionTableState,
  IconActionTableEdit,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget
} from '../../../components';
import { listCourses, listCoursesCount } from '../../../graphql/queries/course';
import {
  deleteCourse,
  updateStateCourse
} from '../../../graphql/mutation/course';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  handleActionState,
  handleActionEditObj,
  handleActionDelete,
  concatObjectConfigDatatable
} from '../../../helpers';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/list.scss';
import CourseEdit from '../CourseEdit';

const CourseList = () => {
  const [
    listCoursesHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listCourses, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });
  const [
    deleteCourseHandler,
    { loading: loadingDeleteGraph, data: dataDeleteGraph }
  ] = useMutation(deleteCourse, { errorPolicy: 'all' });
  const [
    stateCourseHandler,
    { loading: loadingChangeStateGraph, data: dataChangeStateGraph }
  ] = useMutation(updateStateCourse, { errorPolicy: 'all' });
  const { loading: loadingCountGraph, data: dataCountGraph } = useQuery(
    listCoursesCount,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    }
  );

  const [coursesDataState, setCoursesDataState] = useState([]);
  const [filterTextState, setFilterTextState] = useState('');
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [openDialogStateState, setOpenDialogStateState] = useState(false);

  const [limitState, setLimitState] = useState(10);
  const [sortState, setSortState] = useState({ name: 'asc' });
  const [pageState, setPageState] = useState(1);
  const [countState, setCountState] = useState(0);

  const [editionState, setEditionState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [rowToEditState, setRowToEditState] = useState('');

  useEffect(() => {
    if (dataListGraph && dataListGraph.listCourses) {
      setCoursesDataState(dataListGraph.listCourses);
    }
  }, [dataListGraph]);

  useEffect(() => {
    async function queryData() {
      try {
        await listCoursesHandler({
          variables: { limit: limitState, sort: sortState, page: pageState }
        });
      } catch (err) {}
    }
    queryData();
  }, [listCoursesHandler, limitState, sortState, pageState]);

  useEffect(() => {
    if (dataCountGraph && dataCountGraph.listCoursesCount) {
      setCountState(dataCountGraph.listCoursesCount);
    }
  }, [dataCountGraph]);

  const searchFunc = async filterText => {
    let filter = {};
    if (filterText !== '') {
      filter = {
        filter: {
          name: {
            contains: filterText
          }
        }
      };
    }
    try {
      await listCoursesHandler({
        variables: filter
      });
    } catch (err) {}
  };

  const subHeaderComponentMemo = (
    <SearchBasicTable
      resetFunc={() => {
        searchFunc('');
        setFilterTextState('');
      }}
      valuetext={filterTextState}
      placeholder="Buscar Nombre"
      filterFunc={() => searchFunc(filterTextState)}
      onFilter={value => setFilterTextState(value)}
    />
  );

  const columnsConfig = [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Tipo',
      selector: 'type.name',
      sortable: true
    },
    {
      name: 'Código',
      selector: 'code',
      sortable: true
    },
    {
      name: 'Estado',
      selector: 'state.name',
      sortable: true
    }
  ];

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '190px',
      cell: row => (
        <div className="actionColumn">
          {row.edit && (
            <IconActionTableEdit
              handleActionEdit={() => {
                setRowToEditState(row);
                setEditionState(true);
              }}
            />
          )}
          <IconActionTableState
            rowState={row.state.id}
            handleActionState={() => DialogStateHandler(row)}
          />
          {row.delete && (
            <IconActionTableDelete
              handleActionDelete={() => DialogDeleteHandler(row)}
            />
          )}
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setEditionState(false);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  const deleteActionDialogHandler = async () => {
    try {
      await deleteCourseHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    setCountState(countState - 1);
    const newData = handleActionDelete(rowToHandleState, coursesDataState);
    setCoursesDataState(newData);
  };

  const DialogStateHandler = row => {
    setEditionState(false);
    setRowToHandleState(row);
    setOpenDialogStateState(!openDialogStateState);
  };

  const stateActionDialogHandler = async row => {
    try {
      await stateCourseHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionState(row, coursesDataState);
    setCoursesDataState(newData);
  };

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = newObject => {
    const newData = handleActionEditObj(newObject, coursesDataState);
    setCoursesDataState(newData);
  };

  //const searchTool = false;

  const handleSort = async (column, sortDirection) => {
    let nameColumn = column.selector;
    if (column.selector === 'type.name') {
      nameColumn = 'type';
    } else if (column.selector === 'state.name') {
      nameColumn = 'state';
    }
    await setCoursesDataState([]);
    let obj = {};
    Object.defineProperty(obj, nameColumn, {
      value: sortDirection,
      writable: true,
      enumerable: true,
      configurable: true
    });
    setSortState(obj);
  };

  const customSort = () => {
    return coursesDataState;
  };

  const handleChangePagination = (page, totalRows) => {
    setPageState(page);
  };

  const handleChangeRows = (currentRowsPerPage, currentPage) => {
    setLimitState(currentRowsPerPage);
  };
  return (
    <div className="list-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <CourseEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card>
              <CardHeaderWidget
                title="Listar Asignaturas"
                subheader={
                  <BreadCrumbs subTitle="Listado de todas las asignaturas del sistema." />
                }
              />
              <Divider />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <div>
                  <DataTableWidget
                    totalRows={countState}
                    handleSort={handleSort}
                    sortFunction={customSort}
                    changePagination={handleChangePagination}
                    rowsChange={handleChangeRows}
                    columns={columns}
                    sortField="name"
                    filteredItems={coursesDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingListGraph}
                    loadingCount={loadingCountGraph}
                    moduleName="Eventos"
                  />
                </div>
                <DialogState
                  openDialog={openDialogStateState}
                  rowState={rowToHandleState}
                  actionState={stateActionDialogHandler}
                  variant="success"
                  loading={loadingChangeStateGraph}
                  success={dataChangeStateGraph}
                  openState={setOpenDialogStateState}
                />
                <DialogDelete
                  openDialog={openDialogDeleteState}
                  rowDelete={rowToHandleState}
                  actionDelete={deleteActionDialogHandler}
                  variant="success"
                  loading={loadingDeleteGraph}
                  success={dataDeleteGraph}
                  openState={setOpenDialogDeleteState}
                />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default CourseList;
