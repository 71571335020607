import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Context } from './../../../context/Context';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgressComponent } from '../../Progress';

import { TextFieldWidgetText, DialogDelete } from '../../../components';
import { BsRow, BsCol } from '../../../layouts/components';
import classes from '../AddRegisterPanels.module.scss';

import { handleActionDelete } from '../../../helpers';

import {
  createPostulationPresentation,
  deletePostulationPresentation
} from '../../../graphql/mutation/postulationPresentation';

import { getPostulationPresentationList } from '../../../graphql/queries/postulationPresentation';

import { Context as ActivePostulationContext } from '../../../context/ActivePostulationContext';

const AddRegisterPanelCP = props => {
  const { registeredItems, disableForm, loadingCP } = props;
  const context = useContext(Context);
  const userId = context.userId;

  const [fieldsUncompletedState, setFieldsUncompletedState] = useState(null);
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [
    postulationPresentationState,
    setPostulationPresentationState
  ] = useState('');
  const [values, setValues] = useState(false);

  const {
		state: { activePostulation }
	} = useContext(ActivePostulationContext);

  const [
    getPostulationPresentationHandler,
    {
      loading: loadingPostulationPresentationGraph,
      data: dataPostulationPresentationGraph
    }
  ] = useLazyQuery(getPostulationPresentationList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationPresentationHandler({
          variables: { id: parseInt(userId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (values) {
      queryData();
      setValues(false);
    }
  }, [getPostulationPresentationHandler, values, userId]);

  const [
    deletePostulationPresentationHandler,
    {
      loading: loadingDeletePostulationPresentationGraph,
      data: dataDeletePostulationPresentationGraph
    }
  ] = useMutation(deletePostulationPresentation, {
    errorPolicy: 'all'
  });

  const deleteActionDialogHandler = async () => {
    try {
      await deletePostulationPresentationHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(
      rowToHandleState,
      postulationPresentationState
    );
    setPostulationPresentationState(newData);
  };

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  useEffect(() => {
    if (registeredItems) {
      setPostulationPresentationState(registeredItems);
    }
  }, [registeredItems]);

  const [
    createPostulationPresentationHandler,
    { loading: loadingSubmitGraph }
  ] = useMutation(createPostulationPresentation);

  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    reset: resetItem,
    watch
  } = useForm();

  let watchAllFields = watch();

  useEffect(() => {
    if (
      watchAllFields.nameCPIndividual === '' &&
      watchAllFields.authorsCPIndividual === '' &&
      watchAllFields.congressCPIndividual === '' &&
      watchAllFields.periodCPIndividual === ''
    ) {
      setFieldsUncompletedState(true);
    } else if (
      watchAllFields.nameCPIndividual === '' ||
      watchAllFields.authorsCPIndividual === '' ||
      watchAllFields.congressCPIndividual === '' ||
      watchAllFields.periodCPIndividual === ''
    ) {
      setFieldsUncompletedState('Debe llenar todos los campos');
    } else {
      setFieldsUncompletedState(null);
    }
  }, [watchAllFields]);

  const onSubmitItem = async values => {
    const query = {
      id: parseInt(userId),
      name: values.nameCPIndividual,
      author: values.authorsCPIndividual,
      congress: values.congressCPIndividual,
      date: values.periodCPIndividual,
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await createPostulationPresentationHandler({
        variables: query
      });
      if (
        values &&
        values.nameCPIndividual &&
        values.authorsCPIndividual &&
        values.congressCPIndividual &&
        values.periodCPIndividual
      ) {
        setValues(true);
        setFieldsUncompletedState(null);
        resetItem();
        // setPostulationPresentationState(
        //   dataPostulationPresentationGraph.getPostulationPresentationList
        // );
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (disableForm && disableForm !== true) {
      const elems = document.getElementsByClassName('EditPostulation');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].style.display = 'none';
      }
    }
  });

  useEffect(() => {
    // console.log(dataPostulationPublicationGraph, "<-----------llega la data")
   if(dataPostulationPresentationGraph && dataPostulationPresentationGraph.getPostulationPresentationList) {
     setPostulationPresentationState(
          dataPostulationPresentationGraph.getPostulationPresentationList
        );
   }
  }, [dataPostulationPresentationGraph]);

  return (
    <form onSubmit={handleSubmitItem(onSubmitItem)}>
      <div className={classes.addRegisterContainer}>
        <div className={classes.addTitleAdditional}>
          <AddIcon /> Registre sus Presentaciones en Congresos de forma
          individual
        </div>
        <BsRow class="align-items-center">
          <BsCol class="col-md-10">
            <BsRow>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="nameCPIndividual"
                  labeltext="Nombre Presentación"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={200}
                  errors={errorsItem.nameCPIndividual}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="authorsCPIndividual"
                  labeltext="Autor"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  placeholder="Nombre Autor"
                  maxLength={300}
                  errors={errorsItem.authorsCPIndividual}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="congressCPIndividual"
                  labeltext="Congreso"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={100}
                  errors={errorsItem.congressCPIndividual}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="periodCPIndividual"
                  labeltext="Fecha / Período"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={100}
                  errors={errorsItem.periodCPIndividual}
                />
              </BsCol>
            </BsRow>
          </BsCol>
          <BsCol class="col-md-2">
            <div className={clsx(classes.wrapper, 'EditPostulation')}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={Boolean(fieldsUncompletedState)}
                size="small"
                className={classes.buttonAdd}
                startIcon={<AddIcon />}>
                Agregar
              </Button>
              {fieldsUncompletedState && (
                <p className={classes.errorField}>{fieldsUncompletedState}</p>
              )}
              {loadingSubmitGraph && <CircularProgressComponent />}
            </div>
          </BsCol>
        </BsRow>
        {/* listado agregados */}
        <div className={classes.registerAddedContainer}>
          <div className={classes.addTitleAdditional}>
            Presentaciones en Congresos Agregadas
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Nombre Presentación</th>
                <th scope="col">Autor</th>
                <th scope="col">Congreso</th>
                <th scope="col">Fecha</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
            {postulationPresentationState &&
            postulationPresentationState.length > 0 ? (
              postulationPresentationState.map(row => (
                <tr key={row.name}>
                  <td>{row.name}</td>
                  <td>{row.author}</td>
                  <td>{row.congress}</td>
                  <td>{row.date}</td>
                  <td>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      disabled={disableForm}
                      onClick={() => DialogDeleteHandler(row)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : loadingPostulationPresentationGraph || loadingCP ? (
              <tr>
                <td colSpan={6}><CircularProgressComponent /></td>
              </tr>
            ) : (
             
              <tr>
                <td colSpan={6}> <p> No hay presentaciones en congresos de forma individual.</p></td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <DialogDelete
          openDialog={openDialogDeleteState}
          rowDelete={rowToHandleState}
          actionDelete={deleteActionDialogHandler}
          variant="success"
          loading={loadingDeletePostulationPresentationGraph}
          success={dataDeletePostulationPresentationGraph}
          openState={setOpenDialogDeleteState}
        />
      </div>
    </form>
  );
};

AddRegisterPanelCP.propTypes = {
  registeredItems: PropTypes.array.isRequired
};

export default AddRegisterPanelCP;
