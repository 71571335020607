import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { createAcademicPeriod } from '../../../graphql/mutation/academicPeriod';
import { listSpecialities } from '../../../graphql/queries/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  TextFieldWidgetDate,
  BreadCrumbs
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';

const require = true;
const pattern = /^\d*$/;
const AcademicPeriodAdd = () => {
  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  const [
    createPeriodHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createAcademicPeriod);

  const onSubmit = async values => {
    const query = {
      specialty: +values.specialty,
      name: values.name,
      since: values.since,
      until: values.until,
      year: parseInt(values.year),
      businessDays: parseInt(values.businessDays)
    };

    try {
      await createPeriodHandler({
        variables: query
      });
      setSinceSelectedState(null);
      setUntilSelectedState(null);
      setSpecialtySelectedState('');
      reset();
    } catch (err) {}
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Nuevo Periodo Académico"
                subheader={
                  <BreadCrumbs subTitle="Módulo para crear nuevo periodo académico." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetSelect
                        options={specialtyDataState}
                        name="specialty"
                        req={require}
                        loading={loadingSpecialitiesGraph}
                        withDefault={true}
                        selected={specialtySelectedState}
                        onChange={setSpecialtySelectedState}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Especialidad"
                        register={register}
                        errors={errors.specialty}
                      />
                      <TextFieldWidgetText
                        name="name"
                        labeltext="Nombre"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                        autoComplete="nombre"
                      />
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetDate
                            name="since"
                            labeltext="Inicio"
                            value={sinceSelectedState}
                            onChange={date => setSinceSelectedState(date)}
                            register={register}
                            errors={errors.since}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetDate
                            name="until"
                            labeltext="Fin"
                            value={untilSelectedState}
                            onChange={date => setUntilSelectedState(date)}
                            req={require}
                            register={register}
                            errors={errors.until}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="year"
                            labeltext="Año"
                            type="number"
                            req={require}
                            InputLabelProps={{ shrink: true }}
                            minLength={4}
                            maxLength={4}
                            register={register}
                            errors={errors.year}
                            autoComplete="año"
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="businessDays"
                            labeltext="Días hábiles"
                            type="number"
                            req={require}
                            pattern={pattern}
                            InputLabelProps={{ shrink: true }}
                            minLength={1}
                            register={register}
                            errors={errors.businessDays}
                            autoComplete="dias"
                          />
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default AcademicPeriodAdd;
