import gql from 'graphql-tag';

export const createSpecialty = gql`
  mutation(
    $name: String!
    $code: String!
    $description: String!
    $rut: String!
    $nameuser: String!
    $paternalLastname: String!
    $maternalLastname: String!
  ) {
    createSpecialty(
      specialtyInput: {
        name: $name
        code: $code
        description: $description
        rut: $rut
        nameuser: $nameuser
        paternalLastname: $paternalLastname
        maternalLastname: $maternalLastname
      }
    )
  }
`;

export const updateSpecialty = gql`
  mutation(
    $id: Int!
    $iduser: Int!
    $name: String!
    $code: String!
    $description: String!
    $rut: String!
    $nameuser: String!
    $paternalLastname: String!
    $maternalLastname: String!
  ) {
    updateSpecialty(
      specialtyInput: {
        id: $id
        iduser: $iduser
        name: $name
        code: $code
        description: $description
        rut: $rut
        nameuser: $nameuser
        paternalLastname: $paternalLastname
        maternalLastname: $maternalLastname
      }
    ) {
      id
      user {
        id
        rut
        name
        paternalLastname
        maternalLastname
        fullname
      }
      name
      code
      description
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const deleteSpecialty = gql`
  mutation($id: Int!) {
    deleteSpecialty(id: $id)
  }
`;

export const updateStateSpecialty = gql`
  mutation($id: Int!) {
    updateStateSpecialty(id: $id)
  }
`;
