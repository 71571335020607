import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Context } from './../../../context/Context';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  TextFieldWidgetText,
  DialogDelete,
  AutoCompleteWidget
} from '../../../components';
import { BsRow, BsCol } from '../../../layouts/components';
import classes from '../AddRegisterPanels.module.scss';
import { CircularProgressComponent } from '../../Progress';

import { handleActionDelete } from '../../../helpers';
import { listCountry } from '../../../graphql/queries/country';

import {
  createPostulationAssistantShip,
  deletePostulationAssistantShip
} from '../../../graphql/mutation/postulationAssistantShip';

import { getPostulationAssistantShipList } from '../../../graphql/queries/postulationAssistantShip';

import { Context as ActivePostulationContext } from '../../../context/ActivePostulationContext';

const AddRegisterPanelSA = React.memo(props => {
  const { registeredItems, disableForm, loadingSA } = props;
  const context = useContext(Context);
  const userId = context.userId;

  const [fieldsUncompletedState, setFieldsUncompletedState] = useState(null);
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [
    postulationAssistantShipState,
    setPostulationAssistantShipState
  ] = useState('');

  const [countryDataState, setCountrDataState] = useState([]);

  const [countrySelectedState, setCountrySelectedState] = useState(null);

  const [values, setValues] = useState(false);

  const {
		state: { activePostulation }
	} = useContext(ActivePostulationContext);

  const {
    loading: loadingListCountryGraph,
    data: dataListCountryGraph
  } = useQuery(listCountry, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [
    getPostulationAssistantShipHandler,
    {
      loading: loadingPostulationAssistantShipGraph,
      data: dataPostulationAssistantShipGraph
    }
  ] = useLazyQuery(getPostulationAssistantShipList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await getPostulationAssistantShipHandler({
          variables: { id: parseInt(userId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (values) {
      queryData();
      setValues(false);
    }
  }, [getPostulationAssistantShipHandler, values, userId]);

  const [
    deletePostulationAssistantShipHandler,
    {
      loading: loadingDeletePostulationAssistantShipGraph,
      data: dataDeletePostulationAssistantShipGraph
    }
  ] = useMutation(deletePostulationAssistantShip, {
    errorPolicy: 'all'
  });

  const deleteActionDialogHandler = async () => {
    try {
      await deletePostulationAssistantShipHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(
      rowToHandleState,
      postulationAssistantShipState
    );
    setPostulationAssistantShipState(newData);
  };

  const [
    createPostulationAssistantShipHandler,
    { loading: loadingSubmitGraph }
  ] = useMutation(createPostulationAssistantShip);

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  useEffect(() => {
    if (dataListCountryGraph && dataListCountryGraph.listCountry) {
      setCountrDataState(dataListCountryGraph.listCountry);
    }
  }, [dataListCountryGraph]);

  useEffect(() => {
    if (registeredItems) {
      setPostulationAssistantShipState(registeredItems);
    }
  }, [registeredItems]);

  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    reset: resetItem,
    watch: watchItem
  } = useForm();

  let watchAllFields = watchItem([
    'courseSAssistantship',
    'universitySAssistantship',
    'periodSAssistantship',
    'hoursSAssistantship',
    'certificateSAssistantship'
  ]);

  useEffect(() => {
    if (
      watchAllFields.courseSAssistantship === '' &&
      watchAllFields.universitySAssistantship === '' &&
      watchAllFields.periodSAssistantship === '' &&
      watchAllFields.hoursSAssistantship === '' &&
      watchAllFields.certificateSAssistantship &&
      watchAllFields.certificateSAssistantship.length === 0 &&
      countrySelectedState === null
    ) {
      setFieldsUncompletedState(true);
    } else if (
      watchAllFields.courseSAssistantship === '' ||
      watchAllFields.universitySAssistantship === '' ||
      watchAllFields.periodSAssistantship === '' ||
      watchAllFields.hoursSAssistantship === '' ||
      (watchAllFields.certificateSAssistantship &&
        watchAllFields.certificateSAssistantship.length === 0) ||
      countrySelectedState === null
    ) {
      setFieldsUncompletedState('Debe llenar todos los campos');
    } else {
      setFieldsUncompletedState(null);
    }
  }, [watchAllFields, countrySelectedState]);

  const onSubmitItem = async values => {
    const query = {
      id: parseInt(userId),
      idCountry: countrySelectedState.id,
      course: values.courseSAssistantship,
      hour: values.hoursSAssistantship,
      year: values.periodSAssistantship,
      university: values.universitySAssistantship,
      certificate: values.certificateSAssistantship,
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await createPostulationAssistantShipHandler({
        variables: query
      });
      if (
        values &&
        values.courseSAssistantship &&
        values.universitySAssistantship &&
        values.countrySAssistantship &&
        values.periodSAssistantship &&
        values.hoursSAssistantship &&
        values.certificateSAssistantship
      ) {
        setValues(true);
        setFieldsUncompletedState(null);
        resetItem();
        // setPostulationAssistantShipState(
        //   dataPostulationAssistantShipGraph.getPostulationAssistantShipList
        // );
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (disableForm && disableForm !== true) {
      const elems = document.getElementsByClassName('EditPostulation');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].style.display = 'none';
      }
    }
  });

  useEffect(() => {
    // console.log(dataPostulationPublicationGraph, "<-----------llega la data")
   if(dataPostulationAssistantShipGraph && dataPostulationAssistantShipGraph.getPostulationAssistantShipList) {
     setPostulationAssistantShipState(
          dataPostulationAssistantShipGraph.getPostulationAssistantShipList
        );
   }
  }, [dataPostulationAssistantShipGraph]);

  return (
    <form onSubmit={handleSubmitItem(onSubmitItem)}>
      <div className={classes.addRegisterContainer}>
        <div className={classes.addTitleAdditional}>
          <AddIcon /> Registre sus Ayudantías a Alumnos
        </div>
        <BsRow class="align-items-center">
          <BsCol class="col-md-10">
            <BsRow>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="courseSAssistantship"
                  labeltext="Asignatura"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={200}
                  errors={errorsItem.courseSAssistantship}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="universitySAssistantship"
                  labeltext="Universidad"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={300}
                  errors={errorsItem.universitySAssistantship}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="certificateSAssistantship"
                  labeltext="Certificado"
                  disabled={disableForm}
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  errors={errorsItem.certificateSAssistantship}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <AutoCompleteWidget
                  label="País"
                  require={true}
                  data={countryDataState}
                  disabled={disableForm}
                  inicialValue="Seleccione País..."
                  valuecombo={countrySelectedState}
                  loadingComboState={false}
                  onSelectState={setCountrySelectedState}
                  name="countrySAssistantship"
                  register={registerItem}
                  errors={errorsItem.countrySAssistantship}
                  loading={loadingListCountryGraph}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="periodSAssistantship"
                  labeltext="Año"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={100}
                  errors={errorsItem.periodSAssistantship}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="hoursSAssistantship"
                  labeltext="Horas"
                  type="number"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={6}
                  errors={errorsItem.hoursSAssistantship}
                />
              </BsCol>
            </BsRow>
          </BsCol>
          <BsCol class="col-md-2">
            <div className={clsx(classes.wrapper, 'EditPostulation')}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={Boolean(fieldsUncompletedState)}
                size="small"
                className={classes.buttonAdd}
                startIcon={<AddIcon />}>
                Agregar
              </Button>
              {fieldsUncompletedState && (
                <p className={classes.errorField}>{fieldsUncompletedState}</p>
              )}
              {loadingSubmitGraph && <CircularProgressComponent />}
            </div>
          </BsCol>
        </BsRow>
        {/* listado agregados */}
        <div className={classes.registerAddedContainer}>
          <div className={classes.addTitleAdditional}>
            Ayudantías a alumnos Registradas
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Asignatura</th>
                <th scope="col">Universidad</th>
                <th scope="col">Certificado</th>
                <th scope="col">País</th>
                <th scope="col">Año</th>
                <th scope="col">Horas</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
            {postulationAssistantShipState &&
            postulationAssistantShipState.length > 0 ? (
              postulationAssistantShipState.map(row => (
                <tr key={row.course}>
                  <td>{row.course}</td>
                  <td>{row.university}</td>
                  <td>
                    <a
                      href={row.certificate}
                      target="_blank"
                      rel="noopener noreferrer">
                      Ver Aquí
                    </a>
                  </td>
                  <td>{row.country.name}</td>
                  <td>{row.year}</td>
                  <td>{row.hour}</td>
                  <td>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      disabled={disableForm}
                      onClick={() => DialogDeleteHandler(row)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : loadingPostulationAssistantShipGraph || loadingSA ? (
              <tr>
                <td colSpan={6}><CircularProgressComponent /></td>
              </tr>
            ) : (
              
               <tr>
                <td colSpan={6}> <p> No hay Ayudantías a alumnos.</p></td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <DialogDelete
          openDialog={openDialogDeleteState}
          rowDelete={rowToHandleState}
          actionDelete={deleteActionDialogHandler}
          variant="success"
          loading={loadingDeletePostulationAssistantShipGraph}
          success={dataDeletePostulationAssistantShipGraph}
          openState={setOpenDialogDeleteState}
        />
      </div>
    </form>
  );
});

AddRegisterPanelSA.propTypes = {
  registeredItems: PropTypes.array.isRequired
};

export default AddRegisterPanelSA;
