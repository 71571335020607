import React, { useContext, useState, useEffect } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, InputLabel, FormControl, Select } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classes from './Dashboard.module.scss';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Context } from '../../context/Context';
import { Context as ActivePostulationContext } from '../../context/ActivePostulationContext';
import { getDashBoard } from '../../graphql/queries/dashboard';
import { listCompetitions } from '../../graphql/queries/competition';
import { sendPostulation } from '../../graphql/mutation/sendPostulation';
import { listPostulationSpecialty } from '../../graphql/queries/postulationSpecialty';
import CircularProgressComponent from '../../components/Progress/CircularProgressComponent';
import generalTexts from '../../common/generalTexts.json';
import { useActions } from '../../store/actions';

import {
  ButtonBox,
  StepperDash,
  StepperDashTwo,
  Stages,
  PieChart,
  KpiBox,
  DocumentationRequested,
  ContestInformation,
  LinkOfInterest
} from '../../components/Dashboard';

import { DialogConfirm } from '../../components';

import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const context = useContext(Context);
  const { userId, userIdProfile, userFullname, userProfile } = context;
  const { year: yearReducer, competition: competitionReducer } = useActions();

  let userCadidateId = userId;

  // console.log(competitionReducer, "<.............competitionReducer")

  const classesUI = useStyles();

  const {
		state: { activePostulation },
    changeActive,
    addFeature
	} = useContext(ActivePostulationContext);

  const [dataActivePostulationsState, setDataActivePostulationsState] = useState([]);
  // selector de postulación
  const [activePostulationState, setActivePostulationState] = useState(1);

  const [dashboardDataState, setDashboardDataState] = useState([]);
  const [competitionDataState, setCompetitionDataState] = useState([]);
  const [postulationByStateState, setPostulationByStateState] = useState('');
  const [
    postulationByStateStateSpecific,
    setPostulationByStateStateSpecific
  ] = useState('');
  const [
    activeButtonSendPostulation,
    setActiveButtonSendPostulation
  ] = useState(true);

  const [openDialogConfirmState, setOpenDialogConfirmState] = useState(false);
  const [rowToHandleState] = useState('');
  const [newGetDashboardState, setNewGetDashboardState] = useState(false);
  const [
    getDashBoardHandler,
    { loading: loadingDashboardGraph, data: dataDashboardGraph }
  ] = useLazyQuery(getDashBoard, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listPostulationSpecialtyHandler,
    { data: dataListPostulationSpecialtyGraph, loading: loadingdataListPostulationSpecialtyGraph, }
  ] = useLazyQuery(listPostulationSpecialty, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await getDashBoardHandler({
          variables: {
            filter: {
              id: parseInt(userId),
              profile: parseInt(userIdProfile),
              competition: parseInt(competitionReducer),
              year: parseInt(yearReducer),
              idPostulationActive: parseInt(activePostulation.idPostulationActive)
            }
          }
        });
      } catch (err) {}
    }
    // queryData();
    // console.log("----------------->",activePostulation, "dentro de ufe getDash")
    if(activePostulation.idPostulationActive !== null && activePostulation.idPostulationActive !== "" && userProfile === 'Postulante') {
      queryData();
    }
    if(userProfile !== 'Postulante') {
      console.log(userIdProfile, userId, activePostulation.idPostulationActive)
      queryData();
    }
    if (newGetDashboardState && activePostulation.idPostulationActive) {
      queryData();
      setNewGetDashboardState(false);
    }
  }, [
    getDashBoardHandler,
    newGetDashboardState,
    yearReducer,
    competitionReducer,
    userId,
    userIdProfile,
    activePostulation
  ]);

  useEffect(() => {
    // para traer postulaciones activas
    async function queryData() {
      try {
        await listPostulationSpecialtyHandler({
          variables: { id: parseInt(userCadidateId) }
        });
      } catch (err) {}
    }
    if (userCadidateId) {
      queryData();
    }
  }, []);

  const [
    listCompetitionsHandler,
    { loading: loadingCompetitionsGraph, data: dataCompetitionsGraph }
  ] = useLazyQuery(listCompetitions, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    sendPostulationHandler,
    { loading: loadingSendPostulationGraph, data: dataSendPostulationGraph }
  ] = useMutation(sendPostulation);

  useEffect(() => {
    async function queryData() {
      try {
        await listCompetitionsHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listCompetitionsHandler]);

  //STATE DE DASHBOARD
  useEffect(() => {
    if (dataDashboardGraph && dataDashboardGraph.getDashBoard) {
      setDashboardDataState(dataDashboardGraph.getDashBoard);
      console.log(dataDashboardGraph.getDashBoard, '<- DASHBOARD----------comisión');
    }
  }, [dataDashboardGraph]);

  useEffect(() => {
    if (dataCompetitionsGraph && dataCompetitionsGraph.listCompetitions) {
      setCompetitionDataState(dataCompetitionsGraph.listCompetitions);
    }
  }, [dataCompetitionsGraph]);

  useEffect(() => {
    if (dashboardDataState && dashboardDataState.postulationByState) {
      setPostulationByStateState(dashboardDataState.postulationByState);
    }
  }, [dashboardDataState]);

  useEffect(() => {
    if (dashboardDataState && dashboardDataState.postulationByStateSpecific) {
      setPostulationByStateStateSpecific(
        dashboardDataState.postulationByStateSpecific
      );
    }
  }, [dashboardDataState]);

  //DESAPARECE BOTON ENVIAR POSTULACION
  // useEffect(() => {
  //   if (dashboardDataState.postulationUserSend === false) {
  //     const elems = document.getElementsByClassName('postulationSend');
  //     for (let i = 0; i < elems.length; i += 1) {
  //       elems[i].style.display = 'none';
  //     }
  //   }
  // }, [dashboardDataState]);

  // //DESAPARECE BOTON ENVIAR POSTULACION
  // useEffect(() => {
  //   if (dashboardDataState.postulationSendSee === false) {
  //     const elems = document.getElementsByClassName('postulationSend');
  //     for (let i = 0; i < elems.length; i += 1) {
  //       elems[i].style.display = 'none';
  //     }
  //   }
  // }, [dashboardDataState]);

  useEffect(() => {
    if (dataSendPostulationGraph) {
      // console.log("--------------------------dataSendPostulationGraph", dataSendPostulationGraph);
      setActiveButtonSendPostulation(dataSendPostulationGraph.sendPostulation);
    }
  }, [dataSendPostulationGraph]);

  const submitPostulationHandler = async () => {
    setOpenDialogConfirmState(!openDialogConfirmState);
  };

  // console.log(activeButtonSendPostulation, "<----------activeButtonSendPostulation");

  const stateActionDialogHandler = async () => {
    let variable;
    variable = {
      idUser: parseInt(userId),
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await sendPostulationHandler({
        variables: variable
      });
      setNewGetDashboardState(true);
    } catch (err) {}
  };

  const fullnameRedirect = () => {
    if (
      !userFullname ||
      userFullname === 'null' ||
      userFullname.trim() === ''
    ) {
      return <Redirect to="/account" />;
    } else {
      return null;
    }
  };


  const handleChangePostulation = (e) => {
    let post = e.target.value;
    // console.log(post, "<---------onchange")
    setActivePostulationState(post);
  }

  const postulationHandler = () => {
    // console.log("seleccionó--->", activePostulationState)
    let activeValue = activePostulationState;
    // changeActive(activeValue);
    let activePostulation = [];
    if(activeValue){
      activePostulation = dataActivePostulationsState.filter(ele => ele.idPostulation === parseInt(activeValue));
    }
    // changeActive(activeValue);
    addFeature({
          idPostulationActive: activeValue,
          nameCompetition: activePostulation[0]?.nameCompetition,
	        nameSpecialty: activePostulation[0]?.nameSpecialty,
        });
    // necesario para recargar en cuaqluier pestaña
    // window.location.reload(false)
  }

  // cuando se setea el context, selecciona el select
  useEffect(() => {
    setActivePostulationState(activePostulation.idPostulationActive ? activePostulation.idPostulationActive : '');
    // console.log("..............>", activePostulation.idPostulationActive)
  }, [activePostulation]);


  useEffect(() => {
    if (
      dataListPostulationSpecialtyGraph &&
      dataListPostulationSpecialtyGraph.listPostulationSpecialty
    ) {
      // console.log(dataListPostulationSpecialtyGraph, "<<<<<---------")
      setDataActivePostulationsState(dataListPostulationSpecialtyGraph.listPostulationSpecialty);
      if(dataListPostulationSpecialtyGraph.listPostulationSpecialty.length === 1){
        // changeActive(dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].idPostulation);
        addFeature({
          idPostulationActive: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].idPostulation,
          nameCompetition: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].nameCompetition,
	        nameSpecialty: dataListPostulationSpecialtyGraph.listPostulationSpecialty[0].nameSpecialty,
        });
      }
    }
  }, [dataListPostulationSpecialtyGraph]);

  const PostulationSelector = () => {
    if (userProfile !== 'Postulante') {
      return null;
    }
    // console.log("-------------->dataActivePostulationsState", dataActivePostulationsState, activePostulationState)
    if(dataActivePostulationsState.length > 0) {
      return (
        <div style={{marginBottom: 30}}>
          <BsRow class="mt-4">
            <BsCol class="col-md-12">
              <Typography variant="h5" gutterBottom>
                Selecciona aquí la postulación activa
              </Typography>
            </BsCol>
            {!loadingdataListPostulationSpecialtyGraph && (<BsCol class="col-md-6">
              <FormControl className={classesUI.formControl}>
                <InputLabel htmlFor="postulation-native-simple" style={{fontSize: 20}}>Postulación</InputLabel>
                <Select
                  native
                  value={activePostulationState}
                  onChange={handleChangePostulation}
                  inputProps={{
                    name: 'postulation',
                    id: 'postulation-native-simple',
                  }}
                >
                  {
                    activePostulationState === '' && <option>Seleccione...</option>
                  }
                  {
                    dataActivePostulationsState.length && dataActivePostulationsState.map((postulation, index) => (
                      <option key={index} value={postulation.idPostulation}>Post. {index + 1} ({postulation.nameCompetition}/{postulation.nameSpecialty})</option>
                    ))
                  }
                </Select>
              </FormControl>
            </BsCol>)}
            <BsCol class="col-md-2">
              <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={postulationHandler}
              disabled={loadingdataListPostulationSpecialtyGraph}>
                Seleccionar
              </Button>
            </BsCol>
          </BsRow>
        </div>
      )
    }

    return (
      <div style={{minHeight: '60vh'}}>
        <BsRow class="mt-4">
          <BsCol class="col-md-12">
            {/* <p>Bienvenido a la plataforma de postulación a Especialidades médicas.</p> */}
            <p>Aún no tiene postulaciones ingresadas, puede postular en el menú de la izquierda.</p>
          </BsCol>
        </BsRow>
      </div>
    )
  }

  // console.log(activeButtonSendPostulation, "----------------------activeButtonSendPostulation")

  return (
    <div className={classes.root}>
      {fullnameRedirect()}
      {dashboardDataState ? (
        <BsContainer fluid class="px-md-0">
          <BsRow class="justify-content-md-center align-items-center">
            <BsCol class="col-md-9">
              <Typography variant="h3" gutterBottom>
                {generalTexts.dashboard.postulante.title}
              </Typography>

              <Typography variant="h5">Bienvenido a la plataforma de postulación de Especialidades Médicas de la UCN.</Typography>
              
              <PostulationSelector />

              {dashboardDataState.postulationMessageWelcomeSee === true ? (
                <p style={{ whiteSpace: 'pre-line' }}>
                  {dashboardDataState.postulationMessageWelcome}
                </p>
              ) : (
                ''
              )}
              {/* SI MUESTRE STEPPER DE 5 */}
              {dashboardDataState &&
              dashboardDataState.postulationDataLoadSee === true ? (
                <StepperDash
                  postulationUserDataLoad={
                    dashboardDataState.postulationUserDataLoad
                  }
                />
              ) : null}
              {/* SI MUESTRA STEPPER DE 3 */}
              {dashboardDataState.postulationStepSee && (
                <div className="stepperDashTwo">
                  <StepperDashTwo
                    postulationUserStep={dashboardDataState.postulationUserStep}
                  />
                </div>
              )}
            </BsCol>
            <BsCol class="col-md-3">
             {dashboardDataState.postulationUserSend === true && dashboardDataState.postulationSendSee === true && ( <div className="postulationSend">
              {/* REVISAR */}
                {activeButtonSendPostulation && (
                  <Button
                    onClick={() => submitPostulationHandler()}
                    variant="contained"
                    size="large"
                    color="primary">
                    Enviar Tu Postulación
                  </Button>
                )}
              </div>)}
            </BsCol>
          </BsRow>
          {/* <BsRow class="mt-2">
          <BsCol class="col-md-12">
            <Summary />
          </BsCol>
        </BsRow> */}
          {/* <p>Aca va el año {year}</p> */}
          <BsRow class="mt-3">
            {dashboardDataState.postulationByStateSee &&
            postulationByStateState &&
            postulationByStateState.length > 0
              ? postulationByStateState.map(postulationByState => {
                  return (
                    <BsCol
                      class="col-6 col-md pb-2 px-2"
                      key={postulationByState.id}>
                      <KpiBox
                        idState={postulationByState.id}
                        value={postulationByState.total}
                        title={postulationByState.name}
                        type={1}
                      />
                    </BsCol>
                  );
                })
              : null}
          </BsRow>
          <BsRow class="mt-1 align-items-center pt-2">
            {dashboardDataState &&
            dashboardDataState.postulationReportSee === true ? (
              <BsCol class="col-md-4 col-lg-1 pb-2">
                <AccountCircleIcon />
                <h6>Mi Perfil</h6>
              </BsCol>
            ) : null}
            {dashboardDataState.postulationByStateSpecificSee &&
            postulationByStateStateSpecific &&
            postulationByStateStateSpecific.length > 0
              ? postulationByStateStateSpecific.map(
                  postulationStateStateSpecific => {
                    if (postulationStateStateSpecific.id) {
                      return (
                        <BsCol
                          class="col-md-6 col-lg pb-2 px-2"
                          key={postulationStateStateSpecific.id}>
                          <KpiBox
                            idState={postulationStateStateSpecific.id}
                            value={postulationStateStateSpecific.total}
                            title={postulationStateStateSpecific.name}
                            type={2}
                          />
                        </BsCol>
                      );
                    }
                    return null;
                  }
                )
              : null}
          </BsRow>
          <BsRow>
            <BsCol class="col-md-6 col-lg-5 p-md-3">
              {dashboardDataState &&
              dashboardDataState.postulationReportSee === true ? (
                <ButtonBox
                  title="Informe Registrados y Aprobaciones"
                  link="/profile-report"
                />
              ) : null}
              {dashboardDataState &&
              dashboardDataState.postulationStateValidationSee === true ? (
                <Stages
                  className="mt-3"
                  detailApplications={dashboardDataState.postulationStateByStep}
                />
              ) : null}

              {dashboardDataState &&
              dashboardDataState.postulationDocumentLoadSee === true ? (
                <div>
                  <DocumentationRequested
                    postulationUserDocument={
                      dashboardDataState.postulationUserDocument
                    }
                  />
                </div>
              ) : null}
            </BsCol>
            <BsCol class="col-md-6 col-lg-7 p-md-3">
              {dashboardDataState &&
              dashboardDataState.postulationBySpecialtySee === true ? (
                <PieChart
                  postulationBySpecialty={
                    dashboardDataState.postulationBySpecialty
                  }
                />
              ) : null}

              {dashboardDataState &&
              dashboardDataState.postulationCompetitionSee === true ? (
                <div>
                  <ContestInformation
                    competitions={competitionDataState}
                    loading={loadingCompetitionsGraph}
                  />
                </div>
              ) : null}
              {dashboardDataState &&
              dashboardDataState.postulationLinksSee === true ? (
                <div className="link">
                  <LinkOfInterest />
                </div>
              ) : null}

              <BsRow>
                {dashboardDataState &&
                dashboardDataState.postulationReportSee === true ? (
                  <BsCol class="col-md-4 pt-3">
                    <ButtonBox
                      title="Reportes Postulaciones"
                      link="/application-report"
                    />
                  </BsCol>
                ) : null}
                {dashboardDataState &&
                dashboardDataState.postulationReportSee === true ? (
                  <BsCol class="col-md-4 pt-3">
                    <ButtonBox
                      title="Postulaciones por Especialidad"
                      link="/specialty-application-report"
                    />
                  </BsCol>
                ) : null}
                {dashboardDataState &&
                dashboardDataState.postulationReportSee === true ? (
                  <BsCol class="col-md-4 pt-3">
                    <ButtonBox title="Reporte Cohorte" link="/cohorte-report" />
                  </BsCol>
                ) : null}
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      ) : (
        loadingDashboardGraph && <CircularProgressComponent />
      )}

      {/* <DialogDelete
        openDialog={openDialogDeleteState}
        rowDelete={rowToHandleState}
        actionDelete={deleteActionDialogHandler}
        variant="success"
        loading={loadingDeletePostulationDistinctionGraph}
        success={dataDeletePostulationDistinctionGraph}
        openState={setOpenDialogDeleteState}
      /> */}

      <DialogConfirm
        openDialog={openDialogConfirmState}
        rowState={rowToHandleState}
        actionState={stateActionDialogHandler}
        variant="success"
        loading={loadingSendPostulationGraph}
        success={dataSendPostulationGraph}
        openState={setOpenDialogConfirmState}
      />
    </div>
  );
};

export default Dashboard;
