import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetText.module.scss';
import { validateRut, IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';

const TextFieldWidgetText = props => {
  const {
    register,
    errors,
    req,
    loading,
    disabled,
    typenote,
    ...others
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (errors) {
      console.log(errors, '<<---');
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        case 'pattern':
          setStateMessage(errorMessage.message[errors.ref.name].pattern);
          break;
        case 'maxLength':
          setStateMessage(errorMessage.message[errors.ref.name].maxLength);
          break;
        case 'minLength':
          setStateMessage(errorMessage.message[errors.ref.name].minLength);
          break;
        case 'menorQue':
          setStateMessage(errorMessage.message[errors.ref.name].menorQue);
          break;
        case 'mayorQue':
          setStateMessage(errorMessage.message[errors.ref.name].mayorQue);
          break;
        case 'rutValido':
          setStateMessage(errorMessage.message[errors.ref.name].rutValido);
          break;
        case 'matchesPreviousPassword':
          setStateMessage(
            errorMessage.message[errors.ref.name].matchesPreviousPassword
          );
          break;
        case 'sizeFileValidate':
          setStateMessage('error en el doc, de peso');
          break;
        case 'typeFileValidate':
          setStateMessage('error en el doc, de tipo');
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  //   validaciones
  let pattern = null;
  let validate = null;
  if (props.name === 'email') {
    pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  }

  if (props.name === 'rut') {
    validate = {
      rutValido: value => validateRut(value)
    };
  }

  if (
    props.name === 'CNIsimpleCopy' ||
    props.name === 'updatedCurriculumVitae' ||
    props.name === 'presentationLetter' ||
    props.name === 'documentNationalMedicalRating' ||
    props.name === 'nationalMedicalExam' ||
    props.name === 'eunacomAproved' ||
    props.name === 'certificateOfTitle' ||
    props.name === 'superintendencyRegistrationCertificate' ||
    props.name === 'hepatitisCertificate' ||
    props.name === 'secureAffiliationCertificate'
  ) {
    validate = {
      sizeFileValidate(value) {
        if (!value[0]) {
          return true;
        } else {
          return value[0].size < 3145728;
        }
      },
      typeFileValidate(value) {
        if (!value[0]) {
          return true;
        } else {
          return (
            value[0].type === 'application/pdf' ||
            value[0].type === 'image/jpeg' ||
            value[0].type === 'image/png'
          );
        }
      }
    };
  }

  if (
    props.name === 'pediatricInternship' ||
    props.name === 'gynecologyInternship' ||
    props.name === 'surgeryInternship' ||
    props.name === 'nationalMedicalRating'
    // props.name === 'punctuationEunacom'
  ) {
    pattern = /^(10|\d([.,]\d{1,2})?)$/;
  }

  if (props.name === 'password2' && props.equalto) {
    validate = {
      matchesPreviousPassword: value => props.equalto === value
    };
  }

  if (props.name === 'punctuationEunacom') {
    // pattern = /^[0-9][0-9]?$|^100$/;
    pattern = /^([0-9]{1,2}){1}(\.[0-9]{1,2})?$/;
  }

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  const isDesktopMd = IsDesktopHandler('md');

  // console.log('es md', isDesktopMd);

  return (
    <div className={classes.inputContainerStyle}>
      <div
        className={clsx(
          classes.labelDesktopStyle,
          typenote && isDesktopMd ? classes.class_noteLabelStyle : ''
        )}>
        {props.labeltext} {props.download}
      </div>
      {props.loading && <p>cargando</p>}
      <TextField
        variant="outlined"
        {...others}
        margin="dense"
        fullWidth
        label={labelText}
        disabled={disabled}
        onChange={props.onChange}
        inputRef={register({
          required: props.req,
          maxLength: props.maxLength,
          minLength: props.minLength,
          pattern: props.pattern || pattern,
          validate: validate
        })}
        error={stateError}
        helperText={stateMessage}
      />
    </div>
  );
};

TextFieldWidgetText.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  labeltext: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

export default TextFieldWidgetText;
