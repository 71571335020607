import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, IconButton, Link, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useForm from 'react-hook-form';
import { signValidation } from '../../graphql/mutation/validateAccount';
import { useMutation } from '@apollo/react-hooks';
import { TextFieldWidgetTextBig, CheckboxWidget } from '../../components';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import SnackBarsComponent from '../../components/SnackBarsComponent';
import snackBarsMessages from '../../common/snackBarsMessages.json';
import classes from './ValidateAccount.module.scss';

const ValidateAccount = props => {
  const { history } = props;
  const [loadingState, setLoadingState] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const { handleSubmit, register, errors } = useForm();
  const snackBarMessage = snackBarsMessages.message['signValidation'];

  const [signValidationHandler, { loading, error, data }] = useMutation(
    signValidation
  );

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      setLoadingState(false);
    }
    if (data) {
      setSuccessState(true);
    }
  }, [loading, error, data]);

  const handleBack = () => {
    history.goBack();
  };

  const onSubmit = async values => {
    setLoadingState(true);
    try {
      await signValidationHandler({
        variables: {
          email: values.email,
          validationCode: values.validationCode
        }
      });
    } catch (err) {}
  };

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid class={classes.grid}>
        <BsRow class={classes.grid}>
          <BsCol class={['col-lg-5 px-md-0', classes.quoteContainer].join(' ')}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Especialidades Médicas
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    UCN | Facultad de Medicina
                  </Typography>
                  {/* <Typography className={classes.bio} variant="body2">
                    Manager at inVision
                  </Typography> */}
                </div>
              </div>
            </div>
          </BsCol>
          <BsCol class={['col-lg-7', classes.content].join(' ')}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  autoComplete="on"
                  onSubmit={handleSubmit(onSubmit)}>
                  <Typography className={classes.title} variant="h2">
                    Validar cuenta
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Ingresa tu email y código de validación
                  </Typography>
                  <TextFieldWidgetTextBig
                    name="email"
                    className="mt-3"
                    label="E-mail"
                    req
                    register={register}
                    errors={errors.email}
                    autoComplete="email"
                  />
                  <TextFieldWidgetTextBig
                    name="validationCode"
                    req
                    minLength={4}
                    maxLength={10}
                    label="código de validación"
                    className="mt-3"
                    register={register}
                    errors={errors.validationCode}
                  />

                  <div className={classes.wrapperButton}>
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={loadingState}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained">
                      Validar
                    </Button>
                    {loadingState && (
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                      />
                    )}
                  </div>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    style={{ marginTop: '10px' }}>
                    Ya tienes una cuenta?{' '}
                    <Link component={RouterLink} to="/sign-in" variant="h6">
                      Ingresar
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successState}
        autoHideDuration={6000}>
        <SnackBarsComponent
          variant="success"
          message={snackBarMessage}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </div>
  );
};

ValidateAccount.propTypes = {
  history: PropTypes.object
};

export default withRouter(ValidateAccount);
