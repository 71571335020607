import gql from 'graphql-tag';

export const listCurriculumMesh = gql`
  query($filter: CurriculumMeshFilterInput) {
    listCurriculumMesh(filter: $filter) {
      rowBlock {
        blockNumber
        periods {
          idAcademicPeriod
          course {
            id
            name
            code
          }
        }
      }
      meshHeader {
        name
      }
    }
  }
`;
