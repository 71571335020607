import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import classes from './Profile.module.scss';
import { Context } from '../../../../../../context/Context';

const Profile = props => {
  const { className, ...rest } = props;
  const context = useContext(Context);
  const user = {
    name: context.userFullname,
    bio: context.userProfile
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography className={classes.name} variant="h5">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
