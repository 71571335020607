import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { updateCourse } from '../../../graphql/mutation/course';
import { listCoursesType } from '../../../graphql/queries/course';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import '../../../assets/scss/form.scss';

const CourseEdit = props => {
  const require = true;
  const { id, type, name, code } = props.rowEdit;
  const { editFunc } = props;

  const [courseTypeDataState, setCourseTypeDataState] = useState([]);

  const [typeSelectedState, setTypeSelectedState] = useState('');

  const { handleSubmit, register, errors, reset } = useForm();

  const {
    loading: loadingCourseTypeGraph,
    data: dataCourseTypeGraph
  } = useQuery(listCoursesType, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const [
    updateCourseHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateCourse);

  useEffect(() => {
    if (dataCourseTypeGraph && dataCourseTypeGraph.listCoursesType) {
      setCourseTypeDataState(dataCourseTypeGraph.listCoursesType);
    }
  }, [dataCourseTypeGraph]);

  useEffect(() => {
    reset({
      id: parseInt(id),
      name: name,
      code: code
    });
    setTypeSelectedState(type.id);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, type, name, code]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateCourse) {
        const newObj = dataSubmitGraph.updateCourse;
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async values => {
    const query = {
      id: parseInt(values.id),
      type: parseInt(values.type),
      name: values.name,
      code: values.code
    };
    try {
      await updateCourseHandler({
        variables: query
      });
    } catch (err) {}
  };

  return (
    <Card>
      <CardHeaderWidget title={`Editar asignatura: ${name}`} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetSelect
                loading={loadingCourseTypeGraph}
                options={courseTypeDataState}
                name="type"
                selected={typeSelectedState}
                onChange={setTypeSelectedState}
                labeltext="Tipo de Asignatura"
                register={register}
                errors={errors.type}
              />
              <TextField
                name="id"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />
              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="name"
                labeltext="Nombre"
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="nombre"
              />

              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="code"
                labeltext="Código"
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.code}
                autoComplete="codigo"
              />

              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default CourseEdit;
