import gql from 'graphql-tag';

export const createPostulationDiplomat = gql`
  mutation(
    $id: Int!
    $idCountry: Int!
    $name: String!
    $hour: String!
    $date: String!
    $university: String!
    $qualification: String!
    $certificate: Upload
    $idPostulationActive: Int
  ) {
    createPostulationDiplomat(
      formInput: {
        id: $id
        idCountry: $idCountry
        name: $name
        hour: $hour
        date: $date
        university: $university
        qualification: $qualification
        certificate: $certificate
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      country {
        id
        name
      }
      name
      hour
      date
      university
      qualification
      certificate
    }
  }
`;

export const deletePostulationDiplomat = gql`
  mutation($id: Int!) {
    deletePostulationDiplomat(id: $id)
  }
`;
