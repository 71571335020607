import gql from 'graphql-tag';

export const listPostulationDocument = gql`
  query(
    $id: Int!
    $idPostulationActive: Int!
    ) {
    listPostulationDocument(id: $id, idPostulationActive: $idPostulationActive) {
      id
      name
      documentHref
      documentLoad
      delete
      edit
    }
  }
`;
