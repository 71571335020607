import gql from 'graphql-tag';

export const createPostulationCurricularBackground = gql`
  mutation(
    $id: Int!
    $name: String!
    $qualification: String!
    $hours: String!
    $date: String!
    $idPostulationActive: Int
  ) {
    createPostulationCurricularBackground(
      formInput: {
        id: $id
        name: $name
        qualification: $qualification
        hours: $hours
        date: $date
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      name
      qualification
      hour
      date
    }
  }
`;

export const deletePostulationCurricularBackground = gql`
  mutation($id: Int!) {
    deletePostulationCurricularBackground(id: $id)
  }
`;
