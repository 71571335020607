import gql from 'graphql-tag';

export const getPostulationPresentationList = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationPresentationList(id: $id, idPostulationActive: $idPostulationActive) {
      id
      name
      author
      congress
      date
    }
  }
`;
