import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextFieldWidgetText } from '../../../components';

const AutoCompleteWidget = React.memo(props => {
  return (
    <Autocomplete
      options={props.data}
      getOptionLabel={option =>
        props.dataOptionLabel ? option[props.dataOptionLabel] : option.name
      }
      renderOption={props.name === "usercombo" && props.type !== "user" ? (option) => {
        // console.log(option, "<<------");
        return (
        <React.Fragment>
          <span id={option.id}>{option.fullname} / {option.competition.name} | {option.specialty.name}</span>
        </React.Fragment>
      )
      } : null}
      disableClearable={props.disableClearable ? true : false}
      getOptionSelected={
        props.checkId
          ? (option, value) => option.id === value.id
          : (option, value) => option === value
      }
      disabled={props.disabled}
      value={props.valuecombo ? props.valuecombo : null}
      noOptionsText={props.inicialValue}
      clearText="Limpiar"
      fullWidth
      onChange={(event, value, reason) => {
        // console.log(reason, "<----------");
        if (reason === 'clear') {
          props.onClear();
        }
        props.onSelectState(value)
      }}
      // loadingText={"cargando"}
      renderInput={params => {
        return (
          <TextFieldWidgetText
            {...params}
            loading={props.loading}
            name={props.name}
						placeholder={props.inicialValue}
            labeltext={props.label}
            register={props.register}
            errors={props.errors}
            req={props.require}
          />
        );
      }}
    />
  );
});

export default AutoCompleteWidget;
