import React from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { createSpecialty } from '../../../graphql/mutation/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  BreadCrumbs
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { formatRut, formatOnChangeRut } from '../../../helpers';
import '../../../assets/scss/form.scss';

const require = true;

const SpecialtyAdd = () => {
  const { handleSubmit, register, errors, reset } = useForm();

  const [
    createSpecialtyHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createSpecialty);

  const onSubmit = async values => {
    const query = {
      name: values.name,
      code: values.code,
      description: values.description,
      rut: formatRut(values.rut),
      nameuser: values.nameUser,
      paternalLastname: values.paternalLastname,
      maternalLastname: values.maternalLastname
    };
    try {
      await createSpecialtyHandler({
        variables: query
      });
      reset();
    } catch (err) {}
  };

  // toma el valor password

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Agregar Especialidades"
                subheader={
                  <BreadCrumbs subTitle="Módulo para crear Especialidades." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetText
                        name="name"
                        labeltext="Nombre"
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                        autoComplete="name"
                      />

                      <TextFieldWidgetText
                        name="code"
                        labeltext="Código"
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.code}
                        autoComplete="code"
                      />

                      <TextFieldWidgetText
                        name="description"
                        labeltext="Descripción"
                        InputLabelProps={{ shrink: true }}
                        req={require}
												rows="3"
												multiline
                        minLength={10}
                        maxLength={500}
                        register={register}
                        errors={errors.description}
                        autoComplete="description"
                      />

                      <BsRow>
                        <BsCol class="col-md-12">
                          <TextFieldWidgetText
                            name="rut"
                            autoComplete="rut"
                            labeltext="Rut"
                            placeholder="11.111.111-1"
                            onChange={formatOnChangeRut}
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            register={register}
                            errors={errors.rut}
                          />
                          <TextFieldWidgetText
                            name="nameUser"
                            labeltext="Nombres"
                            req={require}
                            InputLabelProps={{ shrink: true }}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            errors={errors.nameUser}
                            autoComplete="nombre"
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="paternalLastname"
                            labeltext="Apellido Paterno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="apellido"
                            errors={errors.paternalLastname}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="maternalLastname"
                            labeltext="Apellido Materno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="apellido"
                            errors={errors.maternalLastname}
                          />
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default SpecialtyAdd;
