import React, { createContext, useState, useReducer, useContext } from 'react';
import { initialState, reducer } from '../store/reducers';
export const Context = createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState(window.sessionStorage.getItem('token'));
  const [userId, setUserId] = useState(window.sessionStorage.getItem('userId'));
  const [userFullname, setUserFullname] = useState(
    window.sessionStorage.getItem('userFullname')
  );
  const [userProfile, setUserProfile] = useState(
    window.sessionStorage.getItem('userProfile')
  );
  const [userIdProfile, setUserIdProfile] = useState(
    window.sessionStorage.getItem('userIdProfile')
  );
  const [moduleUser, setModuleUser] = useState(
    JSON.parse(localStorage.getItem('moduleUser'))
  );

  const [specialtyUser, setSpecialtyUser] = useState(
    JSON.parse(localStorage.getItem('specialtyUser'))
  );

  const setUpdateUserFullname = fullname => {
    setUserFullname(fullname);
    window.sessionStorage.setItem('userFullname', userFullname);
  };

  const value = {
    token: token,
    userId: userId,
    userFullname: userFullname,
    userProfile: userProfile,
    userIdProfile: userIdProfile,
    moduleUser: moduleUser,
    specialtyUser: specialtyUser,
    login: (
      token,
      userId,
      userFullname,
      userProfile,
      tokenExpiration,
      userIdProfile,
      moduleUser,
      specialtyUser
    ) => {
      setToken(token);
      setUserId(userId);
      setUserFullname(userFullname);
      setUserProfile(userProfile);
      setModuleUser(moduleUser);
      setUserIdProfile(userIdProfile);
      setSpecialtyUser(specialtyUser);
      window.sessionStorage.setItem('token', token);
      window.sessionStorage.setItem('userId', userId);
      window.sessionStorage.setItem('userFullname', userFullname);
      window.sessionStorage.setItem('userProfile', userProfile);
      window.sessionStorage.setItem('userIdProfile', userIdProfile);
      localStorage.setItem('moduleUser', JSON.stringify(moduleUser));
      localStorage.setItem('specialtyUser', JSON.stringify(specialtyUser));
    },
    logout: () => {
      setToken(null);
      setUserId(null);
      setUserFullname(null);
      setUserProfile(null);
      setUserIdProfile(null);
      setModuleUser(null);
      setSpecialtyUser(null);
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('userId');
      window.sessionStorage.removeItem('userFullname');
      window.sessionStorage.removeItem('userProfile');
      window.sessionStorage.removeItem('userIdProfile');
      localStorage.removeItem('moduleUser');
      localStorage.removeItem('specialtyUser');
    },
    setUserFullname: setUpdateUserFullname,
    state,
    dispatch
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const useStore = () => useContext(Context);
export default {
  Provider,
  Consumer: Context.Consumer
};
