import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackBarsComponent from '../../SnackBarsComponent';
import CircularProgressComponent from '../../Progress/CircularProgressComponent';
import classes from './SaveAction.module.scss';
import snackBarsMessages from '../../../common/snackBarsMessages.json';
import {
  CardActions,
  Button
} from '@material-ui/core';

const SaveAction = props => {
  const { mutationLoading, success, messageType, disabled } = props;

const snackBarMessage = snackBarsMessages.message[messageType];

  const [stateSuccess, setStateSuccess] = useState(false);
  const handleCloseSnackBarsComponent = () => {
	setStateSuccess(false);
	const closeAction = props.actionClose ? props.actionClose : () => {};
	closeAction();
  };

  useEffect(() => {
    if (success) {
      setStateSuccess(true);
    }
    else {
      setStateSuccess(false);
    }
  }, [success]);
  return (
    <CardActions className={classes.cardActions}>
      <div className={classes.wrapper}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={disabled ? disabled : mutationLoading}
        >
          Guardar
        </Button>
        {mutationLoading && <CircularProgressComponent />}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={stateSuccess}
				onClose={handleCloseSnackBarsComponent}
        autoHideDuration={4500}
				key={Math.random()}
      >
        <SnackBarsComponent variant="success" message={snackBarMessage} handleClose={handleCloseSnackBarsComponent} />
      </Snackbar>
    </CardActions>
  );
};

export default SaveAction;