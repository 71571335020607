import gql from 'graphql-tag';

export const signIn = gql`
  query($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      userId
      token
      tokenExpiration
      fullname
      profile {
        id
        name
      }
      moduleUser {
        id
        name
        assigned
        href
        subMenu
        icon
        process {
          id
          name
          assigned
          href
        }
      }
      processUser {
        id
        name
        module {
          id
          name
        }
      }
      specialty {
        id
      }
    }
  }
`;
