import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import '../style.scss';
const IconActionTableDelete = (props) => {
  const { handleActionDelete } = props;
  //<IconButton aria-label="delete" onClick={() => handleActionDelete(row)}>
  return (
    <Tooltip title="Eliminar">
      <IconButton aria-label="delete" onClick={handleActionDelete}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default IconActionTableDelete;