import gql from 'graphql-tag';

export const createPostulationPresentation = gql`
  mutation(
    $id: Int!
    $name: String!
    $author: String!
    $congress: String!
    $date: String!
    $idPostulationActive: Int
  ) {
    createPostulationPresentation(
      formInput: {
        id: $id
        name: $name
        author: $author
        congress: $congress
        date: $date
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      name
      author
      congress
      date
    }
  }
`;

export const deletePostulationPresentation = gql`
  mutation($id: Int!) {
    deletePostulationPresentation(id: $id)
  }
`;
