import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CheckIcon from '@material-ui/icons/Check';
import SnackBarsComponent from '../SnackBarsComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IsDesktopHandler } from '../../helpers';
import dialogMessages from '../../common/dialogMessages.json';
import classes from './DialogConfirm.module.scss';

const DialogConfirm = props => {
  const {
    openDialog,
    rowState,
    actionState,
    success,
    loading,
    openState
  } = props;
  const [valueState, setValueState] = useState(false);
  const [disableState, setDisableState] = useState(loading);
  const [successState, setSuccessState] = useState(false);

  const isDesktop = IsDesktopHandler('md');
  useEffect(() => {
    if (openDialog) {
      setValueState(true);
      setDisableState(false);
    }
  }, [openDialog]);

  useEffect(() => {
    setDisableState(loading);
  }, [loading]);

  useEffect(() => {
    if (success !== undefined) {
      setSuccessState(true);
    }
  }, [success]);

  const handleClick = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setValueState(false);
    openState(false);
  };

  const handleClickConfirm = async () => {
    await actionState(rowState);
    openState(false);
    setValueState(false); //cierra el dialo
  };

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={!isDesktop}
        open={valueState}
        classes={{
          paper: classes.paperStyle
        }}
        onClose={handleClick}
        // disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title" className={classes.title}>
          {dialogMessages.dashboard.title}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText component="div" className={classes.description}>
            <p className="mb-0">{dialogMessages.message.state.subtitle}</p>
            <p style={{ fontWeight: 'bold' }}>
              {rowState.name} {rowState.lastname}
            </p>
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button autoFocus onClick={handleClick} disabled={disableState}>
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleClickConfirm}
              variant="contained"
              color="primary"
              autoFocus
              disabled={disableState}
              startIcon={<CheckIcon />}>
              Confirmar
            </Button>
            {disableState && (
              <CircularProgress
                style={{ color: 'white' }}
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successState}
        autoHideDuration={6000}>
        <SnackBarsComponent
          variant={props.variant}
          message={dialogMessages.dashboard.confirmation}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </React.Fragment>
  );
};

export default DialogConfirm;
