import gql from 'graphql-tag';

export const createPostulationDistinction = gql`
  mutation($id: Int!, $name: String!, $institution: String!, $date: String!, $idPostulationActive: Int) {
    createPostulationDistinction(
      formInput: {
        id: $id
        name: $name
        institution: $institution
        date: $date
        idPostulationActive: $idPostulationActive
      }
    ) {
      id
      name
      institution
      date
    }
  }
`;

export const deletePostulationDistinction = gql`
  mutation($id: Int!) {
    deletePostulationDistinction(id: $id)
  }
`;
