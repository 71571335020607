import React from 'react';
import classes from './AddFinalNoteTable.module.scss';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import { CircularProgressComponent } from './../Progress';

const DocumentDetailTable = props => {
  let state;

  const {documents} = props;

  // console.log(documents, "asi llega");

  
  const uploadedDocs = documents ? documents.filter(ele => ele.documentLoad ) : [];

  return (
    <div className={classes.registerAddedContainer}>
      <div className={classes.addTitleAdditional}>Detalle Documentos</div>
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col">Estado</th>
            <th scope="col">Tipo</th>
            <th scope="col">Nombre Archivo</th>
          </tr>
        </thead>
        <tbody>
          {uploadedDocs && uploadedDocs.length > 0 ? (
            uploadedDocs.map(documentFile => {
              if (documentFile.documentLoad) {
                state = <CheckSharpIcon className={classes.loaded} />;
              } else {
                state = <HighlightOffSharpIcon className={classes.empty} />;
              }

              return (
                <tr key={documentFile.id}>
                  <td>{state}</td>
                  <td>{documentFile.name}</td>
                  <td>
                    <a
                      href={documentFile.documentHref}
                      target="_blank"
                      rel="noopener noreferrer"
                      download>
                      Descargarlo Aquí
                    </a>
                  </td>
                </tr>
              );
            })
          ) : props.loading ? (
            <tr>
              <td>
                <CircularProgressComponent />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={3}>No hay documentos.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentDetailTable;
