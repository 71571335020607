import gql from 'graphql-tag';

export const listAcademicPeriods = gql`
  query($filter: AcademicPeriodFilterInput, $sort: AcademicPeriodSortingInput) {
    listAcademicPeriods(filter: $filter, sort: $sort) {
      id
      specialty {
        id
        name
      }
      state {
        id
        name
      }
      name
      since
      until
      year
      businessDays
      delete
      edit
    }
  }
`;

export const getAcademicPeriod = gql`
  query($id: Int!) {
    getAcademicPeriod(id: $id) {
      id
      specialty {
        id
        name
      }
      state {
        id
        name
      }
      name
      since
      until
      year
      businessDays
      delete
      edit
    }
  }
`;
