import { useStore } from '../../context/Context';
import { CHANGE_YEAR, CHANGE_COMPETITION } from './actionTypes';
export const useActions = () => {
  const {
    state: { year, competition },
    dispatch
  } = useStore();
  return {
    year,
    competition,
    changeYear: year => {
      dispatch({ type: CHANGE_YEAR, year });
    },
    changeCompetition: competition => {
      dispatch({ type: CHANGE_COMPETITION, competition });
    }
  };
};
