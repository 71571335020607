import gql from 'graphql-tag';

export const getPostulationDistinctionList = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationDistinctionList(id: $id, idPostulationActive: $idPostulationActive) {
      id
      name
      institution
      date
    }
  }
`;
