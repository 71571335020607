import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { listSpecialities } from '../../../graphql/queries/specialty';
import { listPostulationStudents } from '../../../graphql/queries/student';
import { createFinalNote } from '../../../graphql/mutation/finalNote';
import { Context } from '../../../context/Context';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  AddFinalNoteTable
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/form.scss';

const require = true;
// const usersData = [
//   { id: 1, name: 'Pedro', lastname: 'Rojas', idSpecialty: 1 },
//   { id: 2, name: 'Juan', lastname: 'Orellana', idSpecialty: 1 },
//   { id: 3, name: 'Alberto', lastname: 'Parra', idSpecialty: 3 }
// ];

const FinalNoteAdd = () => {
  const context = useContext(Context);
  const { year } = context.state;

  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const [studentsDataState, setStudentsDataState] = useState(null);
  const [limitState, setLimitState] = useState(200);
  const [sortState, setSortState] = useState({
    paternalLastname: 'asc',
    maternalLastname: 'asc'
  });
  const [pageState, setPageState] = useState(1);

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listStudentsHandler,
    { loading: loadingListStudentsGraph, data: dataListStudentsGraph }
  ] = useLazyQuery(listPostulationStudents, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    createFinalNoteHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createFinalNote);

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  useEffect(() => {
    if (
      dataListStudentsGraph &&
      dataListStudentsGraph.listPostulationStudents
    ) {
      setStudentsDataState(dataListStudentsGraph.listPostulationStudents);
    }
  }, [dataListStudentsGraph]);

  useEffect(() => {
    console.log('datos: ', studentsDataState);
  }, [studentsDataState]);

  useEffect(() => {
    // setStudentsDataState(null);
    setSpecialtySelectedState('');
  }, [dataSubmitGraph]);

  // aquí enviará el filtro para traer alumnos segun especialty
  useEffect(() => {
    // setCheckboxesStundentsState(null);
    async function queryData() {
      const filter = {
        filter: {
          specialty: {
            eq: parseInt(specialtySelectedState)
          },
          statePostulation: {
            eq: 5
          }
        },
        year: year,
        limit: limitState,
        sort: sortState,
        page: pageState
      };
      try {
        await listStudentsHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (specialtySelectedState) {
      queryData();
    } else {
      setStudentsDataState(null);
    }
  }, [listStudentsHandler, specialtySelectedState]);

  const onSubmit = async values => {
    const arrayToSend = studentsDataState.map(student => {
      if (values[`note_${student.id}`] !== '') {
        return {
          idPostulation: student.idPostulation,
          finalNote: parseFloat(values[`note_${student.id}`])
        };
      }
    });

    // elimino undefinded
    let arrayToSendClean = _.compact(arrayToSend);

    const query = {
      idSpecialty: +values.specialty,
      year: year,
      inputArray: arrayToSendClean
    };

    try {
      await createFinalNoteHandler({
        variables: {
          finalNoteInput: query
        }
      });
    } catch (err) {}
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget title="Nota Final" />
              <Divider />
              <CardContent>
                <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                  <BsRow>
                    <BsCol class="col-md-6">
                      <TextFieldWidgetSelect
                        options={specialtyDataState}
                        name="specialty"
                        req={require}
                        loading={loadingSpecialitiesGraph}
                        withDefault={true}
                        selected={specialtySelectedState}
                        onChange={setSpecialtySelectedState}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Especialidad"
                        register={register}
                        errors={errors.specialty}
                      />
                    </BsCol>
                    {loadingListStudentsGraph && <div>CARGANDO...</div>}
                    {studentsDataState && (
                      <React.Fragment>
                        <BsCol class="col-md-12 mt-3">
                          <AddFinalNoteTable
                            students={studentsDataState.filter(
                              student => student.finalNote === null
                            )}
                            register={register}
                            errors={errors}
                          />
                        </BsCol>
                      </React.Fragment>
                    )}
                    <BsCol class="col-md-12">
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                        disabled={
                          !Boolean(
                            studentsDataState && studentsDataState.length > 0
                          )
                        }
                      />
                    </BsCol>
                  </BsRow>
                </form>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default FinalNoteAdd;
