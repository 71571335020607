import gql from 'graphql-tag';

export const listYears = gql`
  query {
    listYears {
      year
      defaultValue
    }
  }
`;
