import gql from 'graphql-tag';

export const listCourses = gql`
  query(
    $filter: CourseFilterInput
    $limit: Int
    $page: Int
    $sort: CourseSortingInput
  ) {
    listCourses(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      id
      name
      code
      type {
        id
        name
      }
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;

export const listCoursesCount = gql`
  query($filter: CourseFilterInput) {
    listCoursesCount(filter: $filter)
  }
`;

export const listCoursesType = gql`
  query {
    listCoursesType {
      id
      name
    }
  }
`;
