import gql from 'graphql-tag';

export const getPostulationAssistantShipList = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationAssistantShipList(id: $id, idPostulationActive: $idPostulationActive) {
      id
      country {
        id
        name
      }
      course
      hour
      year
      university
      certificate
    }
  }
`;
