import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classes from './DocumentationRequested.module.scss';

const itemTrue = (name, value, color) => {
  return (
    <div className={classes.box} key={name}>
      <label className={classes.label}>{name}</label>
    </div>
  );
};

const itemFalse = name => {
  return (
    <div className={classes.box} key={name}>
      <label className={classes.labelFalse}>
        <WarningIcon className={classes.alertStyle} /> {name}
      </label>
    </div>
  );
};

const DocumentationRequested = props => {
  const { className, postulationUserDocument, ...rest } = props;

  let bgStyleDocs = '';

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className, classes[bgStyleDocs])}>
      <CardContent className={classes.content}>
        <h3 className={classes.title}>Documentación Solicitada</h3>
        {postulationUserDocument && postulationUserDocument.length > 0 ? (
          postulationUserDocument.map(detail => {
            if (detail.load === true) {
              return itemTrue(detail.name);
            } else {
              return itemFalse(detail.name);
            }
          })
        ) : (
          <p>No existe documentación solicitada.</p>
        )}
      </CardContent>
    </Card>
  );
};

DocumentationRequested.propTypes = {
  className: PropTypes.string
};

export default DocumentationRequested;
