import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { updateSpecialty } from '../../../graphql/mutation/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import { formatRut, formatOnChangeRut } from '../../../helpers';
import '../../../assets/scss/form.scss';

const SpecialtyEdit = props => {
  const require = true;
  const { id, name, code, description, user } = props.rowEdit;
  const { editFunc } = props;

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    updateSpecialtyHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateSpecialty);

  useEffect(() => {
    reset({
      id: parseInt(id),
      name: name,
      code: code,
      description: description,
      rut: user.rut,
      nameuser: user.name,
      paternalLastname: user.paternalLastname,
      maternalLastname: user.maternalLastname
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, name, code, description, user]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateSpecialty) {
        const newObj = dataSubmitGraph.updateSpecialty;
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async values => {
    const query = {
      id: parseInt(values.id),
      iduser: user.id,
      name: values.name,
      code: values.code,
      description: values.description,
      rut: formatRut(values.rut),
      nameuser: values.nameuser,
      paternalLastname: values.paternalLastname,
      maternalLastname: values.maternalLastname
    };
    try {
      await updateSpecialtyHandler({
        variables: query
      });
    } catch (err) {}
  };

  return (
    <Card>
      <CardHeaderWidget title={`Editar Espacialidad: ${name} - ${code}`} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetText
                name="name"
                labeltext="Nombre"
                InputLabelProps={{ shrink: true }}
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="name"
              />

              <TextFieldWidgetText
                name="code"
                labeltext="Código"
                InputLabelProps={{ shrink: true }}
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.code}
                autoComplete="code"
              />

              <TextFieldWidgetText
                name="description"
                labeltext="Descripción"
                InputLabelProps={{ shrink: true }}
                req={require}
                minLength={3}
                rows="3"
                multiline
                maxLength={100}
                register={register}
                errors={errors.description}
                autoComplete="description"
              />
              <TextField
                name="id"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />

              <BsRow>
                <BsCol class="col-md-12">
                  <TextFieldWidgetText
                    name="rut"
                    autoComplete="rut"
                    labeltext="Rut"
                    placeholder="11.111.111-1"
                    onChange={formatOnChangeRut}
                    InputLabelProps={{ shrink: true }}
                    req={require}
                    register={register}
                    errors={errors.rut}
                  />
                  <TextFieldWidgetText
                    name="nameuser"
                    labeltext="Nombres"
                    req={require}
                    InputLabelProps={{ shrink: true }}
                    minLength={3}
                    maxLength={100}
                    register={register}
                    errors={errors.nameUser}
                    autoComplete="nombre"
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="paternalLastname"
                    labeltext="Apellido Paterno"
                    InputLabelProps={{ shrink: true }}
                    req={require}
                    minLength={3}
                    maxLength={100}
                    register={register}
                    autoComplete="apellido"
                    errors={errors.paternalLastname}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="maternalLastname"
                    labeltext="Apellido Materno"
                    InputLabelProps={{ shrink: true }}
                    req={require}
                    minLength={3}
                    maxLength={100}
                    register={register}
                    autoComplete="apellido"
                    errors={errors.maternalLastname}
                  />
                </BsCol>
              </BsRow>
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default SpecialtyEdit;
