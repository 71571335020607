import React, { useState, useEffect } from 'react';
import {
  SearchBasicTable,
  ModalWidget,
  DataTableWidgetNotLazy,
  IconActionTableDelete,
  IconActionTableState,
  IconActionTableEdit,
  IconActionTableDetail,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget
} from '../../../components';
import { listPostulationStudents } from '../../../graphql/queries/student';
import { updateFinalNote } from '../../../graphql/mutation/finalNote';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  handleActionEditObj,
  concatObjectConfigDatatable
} from '../../../helpers';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import '../../../assets/scss/list.scss';
import FinalNoteEdit from '../FinalNoteEdit';
import { decimalAdjust } from '../../../helpers';

const FinalNoteList = () => {
  const [
    listStudentsHandler,
    { loading: loadingListStudentsGraph, data: dataListStudentsGraph }
  ] = useLazyQuery(listPostulationStudents, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [studentsDataState, setStudentsDataState] = useState([]);
  const [onFilterDataState, setOnFilterDataState] = useState([]);
  const [filterTextState, setFilterTextState] = useState('');

  const [sortState] = useState({ name: 'asc' });
  const [pageState, setPageState] = useState(1);

  const [editionState, setEditionState] = useState(false);
  const [rowToEditState, setRowToEditState] = useState('');

  useEffect(() => {
    setOnFilterDataState(studentsDataState);
  }, [studentsDataState]);

  useEffect(() => {
    console.log('----->', dataListStudentsGraph);
    if (
      dataListStudentsGraph &&
      dataListStudentsGraph.listPostulationStudents
    ) {
      setStudentsDataState(dataListStudentsGraph.listPostulationStudents);
    }
  }, [dataListStudentsGraph]);

  useEffect(() => {
    // setCheckboxesStundentsState(null);
    async function queryData() {
      const filter = {
        filter: {
          statePostulation: {
            eq: 5
          }
          // ,
          // finalNote: {
          //   exists: 'NOT_NULL'
          // }
        },
        sort: sortState,
        page: pageState
      };
      try {
        await listStudentsHandler({
          variables: filter
        });
      } catch (err) {}
    }
    queryData();
  }, [listStudentsHandler, sortState]);

  const searchFunc = async filterText => {
    let filter = {};
    if (filterText !== '') {
      filter = {
        filter: {
          rut: {
            contains: filterText
          },
          statePostulation: {
            eq: 5
          },
          finalNote: {
            exists: 'NOT_NULL'
          }
        }
      };
    } else {
      filter = {
        filter: {
          statePostulation: {
            eq: 5
          },
          finalNote: {
            exists: 'NOT_NULL'
          }
        }
      };
    }
    try {
      await listStudentsHandler({
        variables: filter
      });
    } catch (err) {}
  };

  const subHeaderComponentMemo = (
    <SearchBasicTable
      resetFunc={() => {
        searchFunc('');
        setFilterTextState('');
      }}
      valuetext={filterTextState}
      placeholder="Buscar Rut"
      filterFunc={() => searchFunc(filterTextState)}
      onFilter={value => setFilterTextState(value)}
    />
  );

  // {decimalAdjust(
  // 	'round',
  // 	dataStudentState.noteInternPediatrics,
  // 	-1
  // )}

  const columnsConfig = [
    {
      name: 'Especialidad',
      selector: 'specialty.name',
      sortable: true
    },
    {
      name: 'Nombres',
      selector: 'fullname',
      sortable: true
    },
    {
      name: 'Rut',
      selector: 'rut',
      sortable: true
    },
    {
      name: 'Nota Final',
      selector: 'finalNote',
      cell: row => decimalAdjust('round', row.finalNote, -1),
      sortable: true
    }
  ];

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '190px',
      cell: row => (
        <div className="actionColumn">
          {row.edit && (
            <IconActionTableEdit
              handleActionEdit={() => {
                setRowToEditState(row);
                setEditionState(true);
              }}
            />
          )}
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = newObject => {
    const newData = handleActionEditObj(newObject, studentsDataState);
    setStudentsDataState(newData);
  };

  return (
    <div className="list-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <FinalNoteEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card>
              <CardHeaderWidget
                title="Listado de Notas Finales"
                subheader={
                  <BreadCrumbs subTitle="Notas finales de especialidades ingresadas al sistema." />
                }
              />
              <Divider />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <div>
                  <DataTableWidgetNotLazy
                    columns={columns}
                    sortField="name"
                    filteredItems={onFilterDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingListStudentsGraph}
                  />
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default FinalNoteList;
