import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateCompetition } from '../../../graphql/mutation/competition';
import { listSpecialities } from '../../../graphql/queries/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetDate,
  CheckboxWidget
} from '../../../components';
import { Card, CardContent, Divider, Typography, FormGroup } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import classes from './CompetitionEdit.module.scss';
import '../../../assets/scss/form.scss';
import { alterArrayForSpecialtyAssign, alterArrayForSpecialtyAssigned, dateConvert } from '../../../helpers';

const CompetitionEdit = props => {
  const require = true;
  const { id, name, code, since, until, description, specialty, dayStart } = props.rowEdit;
  const { editFunc } = props;

  // console.log(props, "-----props")

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [checkboxesState, setCheckboxesState] = useState([]);

  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    updateCompetitionHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateCompetition);

  const { loading: loadingSpecialtiesGraph, data: dataSpecialtiesGraph } = useQuery(
    listSpecialities,
    {
      variables: {
        filter: {
          id: {
            gt: 0
          }
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  useEffect(() => {
    if (dataSpecialtiesGraph && dataSpecialtiesGraph.listSpecialities) {
      console.log(dataSpecialtiesGraph.listSpecialities, "----data specialty")
      setSpecialtyDataState(dataSpecialtiesGraph.listSpecialities);
    }
  }, [dataSpecialtiesGraph]);

  useEffect(() => {
    let formatSince = dateConvert(since);
    let formatUntil = dateConvert(until);
    reset({
      id: parseInt(id),
      name: name,
      code: code,
      description: description,
      daystart: dayStart
    });
    setSinceSelectedState(formatSince);
    setUntilSelectedState(formatUntil);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, name, code, since, until, description, dayStart]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateCompetition) {
        console.log(dataSubmitGraph.updateCompetition, "-----")
        const newObj = dataSubmitGraph.updateCompetition;
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async values => {

    let arrayQuery = [];

    if(values.specialtyUser) {
      arrayQuery = values.specialtyUser.map((id) => {
        return parseInt(id);
      });
    }

    const query = {
      id: parseInt(id),
      name: values.name,
      code: values.code,
      since: values.since,
      until: values.until,
      description: values.description,
      dayStart: values.daystart,
      specialty: arrayQuery
    };

    // console.log(query)
    try {
      await updateCompetitionHandler({
        variables: query
      });
    } catch (err) {}
  };

  const handleChange = e => {
    const newArrayEdit = checkboxesState.map(check => {
      if (check.id !== parseInt(e.target.value)) {
        return check;
      } else {
        const checkNew = { ...check, assigned: e.target.checked };
        return checkNew;
      }
    });
    setCheckboxesState(newArrayEdit);
  };

  useEffect(() => {
    if(specialty.length){
      // console.log("-------------1")
      setCheckboxesState(alterArrayForSpecialtyAssigned(specialtyDataState, specialty))
    } else {
      // console.log("-------------2")
      setCheckboxesState(alterArrayForSpecialtyAssign(specialtyDataState))
    }
  }, [specialtyDataState, specialty]);

  const renderCheckboxes = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: '50px' }}>
          Seleccione Perfil y usuario para cargar módulos.
        </p>
      );
    }

    return checkboxesState.map((aCheckbox, index) => {
      // console.log("--------------------------_>", aCheckbox)
      return (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidget
            name="specialtyUser"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleChange}
            checked={aCheckbox.assigned}
            register={register}
            errors="specialtyUser"
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.name}
          </Typography>
        </label>
      );
    });
  };

  return (
    <Card>
      <CardHeaderWidget title={`Editar Concurso: ${name} | ${code}`} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetText
                name="name"
                labeltext="Nombre"
                req={require}
                InputLabelProps={{ shrink: true }}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="nombre"
              />
              <TextFieldWidgetText
                name="code"
                labeltext="Codigo"
                req={require}
                InputLabelProps={{ shrink: true }}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.code}
                autoComplete="codigo"
              />
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetDate
                    name="since"
                    labeltext="Desde"
                    value={sinceSelectedState}
                    onChange={date => setSinceSelectedState(date)}
                    register={register}
                    errors={errors.since}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetDate
                    name="until"
                    labeltext="Hasta"
                    value={untilSelectedState}
                    onChange={date => setUntilSelectedState(date)}
                    req={require}
                    register={register}
                    errors={errors.until}
                  />
                </BsCol>
              </BsRow>
              {(<BsRow class="mt-4">
                <BsCol class="col-md-12">
                  <Typography variant="h6" gutterBottom>
                    Seleccione especialidades del concurso
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormGroup className={classes.groupLabel}>
                      {renderCheckboxes()}
                    </FormGroup>
                  </div>
                </BsCol>
              </BsRow>)}
              <TextFieldWidgetText
                name="description"
                labeltext="Descripción"
                req={require}
                InputLabelProps={{ shrink: true }}
                minRows="3"
                multiline
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.description}
              />
              <TextFieldWidgetText
                name="daystart"
                labeltext="Fecha inicio (para certificado)"
                req={require}
                InputLabelProps={{ shrink: true }}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.daystart}
              />
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default CompetitionEdit;
