import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useForm from 'react-hook-form';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { MuiPickersUtilsProvider, useUtils } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { updateAbsence } from '../../../graphql/mutation/absence';
import { listPostulationStudents } from '../../../graphql/queries/student';
import { listAbsenceReasons } from '../../../graphql/queries/absenceReason';
import { listSpecialities } from '../../../graphql/queries/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetSelect,
  TextFieldWidgetDateRange,
  TextFieldWidgetText,
  CheckboxWidget
} from '../../../components';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import errorMessage from '../../../common/errorMessages.json';
import '../../../assets/scss/form.scss';
import classes from '../AbsenceAdd/AbsenceAdd.module.scss';

const AbsenceEdit = props => {
  const require = true;

  const {
    id,
    since,
    document,
    absenceReason,
    until,
    days,
    absenceDetail
  } = props.rowEdit;
  const { editFunc } = props;
  console.log('DETAIL', props.rowEdit, ' ');

  const [studentsDataState, setStudentsDataState] = useState([]);
  const [
    checkboxesCheckedStundentsState,
    setCheckboxesCheckedStundentsState
  ] = useState(null);
  const [checkboxesStundentsState, setCheckboxesStundentsState] = useState(
    null
  );
  const [reasonDataState, setReasonDataState] = useState([]);
  const [reasonSelectedState, setReasonSelectedState] = useState('');
  const [specialtyDataState, setSpecialtyDataState] = useState([]);
  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [documentState, setDocumentState] = useState(null);

  const [beginRangeState, setBeginRangeState] = useState(false);
  const [endRangeState, setEndRangeState] = useState(false);
  const [clearRangeState, setClearRangeState] = useState(false);

  const [withDocState, setWithDocState] = useState(false);

  const [checkedGroupState, setCheckedGroupState] = useState([]);

  const [limitState] = useState(200);
  const [sortState] = useState({
    paternalLastname: 'asc',
    maternalLastname: 'asc'
  });
  const [pageState] = useState(1);

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    updateAbsenceHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateAbsence);

  const [
    listStudentsHandler,
    { loading: loadingListStudentsGraph, data: dataListStudentsGraph }
  ] = useLazyQuery(listPostulationStudents, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const {
    loading: loadingAbsenceReasonsGraph,
    data: dataAbsenceReasonsGraph
  } = useQuery(listAbsenceReasons, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    if (dataAbsenceReasonsGraph && dataAbsenceReasonsGraph.listAbsenceReasons) {
      setReasonDataState(dataAbsenceReasonsGraph.listAbsenceReasons);
    }
  }, [dataAbsenceReasonsGraph]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  // aca recibe data de los alumnos
  useEffect(() => {
    if (
      dataListStudentsGraph &&
      dataListStudentsGraph.listPostulationStudents
    ) {
      setStudentsDataState(dataListStudentsGraph.listPostulationStudents);
      // aca armar el array con los que llegan
      const NewArrayStudentsChecks = dataListStudentsGraph.listPostulationStudents.map(
        studentCheck => {
          let CheckedStudent = _.find(checkboxesCheckedStundentsState, function(
            o
          ) {
            return o.student === studentCheck.id;
          });
          const checkNew = {
            ...studentCheck,
            checked: Boolean(CheckedStudent)
          };
          return checkNew;
        }
      );
      setCheckedGroupState(NewArrayStudentsChecks);
    }
  }, [dataListStudentsGraph]);

  useEffect(() => {
    async function queryData() {
      const filter = {
        filter: {
          specialty: {
            eq: parseInt(specialtySelectedState)
          },
          statePostulation: {
            eq: 5
          }
        },
        limit: limitState,
        sort: sortState,
        page: pageState
      };
      try {
        await listStudentsHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (specialtySelectedState) {
      queryData(specialtySelectedState);
    } else {
      setCheckboxesStundentsState(null);
    }
  }, [
    listStudentsHandler,
    specialtySelectedState,
    limitState,
    pageState,
    sortState
  ]);

  useEffect(() => {
    reset({
      id: parseInt(id),
      absenceDays: days
    });
    setReasonSelectedState(absenceReason.id);
    setSpecialtySelectedState(absenceDetail[0].specialty);
    setBeginRangeState(since);
    setEndRangeState(until);
    setDocumentState(document);
    setCheckboxesCheckedStundentsState(absenceDetail);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, since, document, absenceReason, until, days, absenceDetail]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateAbsenceReason) {
        const newObj = dataSubmitGraph.updateAbsenceReason;
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  useEffect(() => {
    if (specialtySelectedState) {
      setCheckboxesStundentsState(studentsDataState);
    }
  }, [specialtySelectedState, studentsDataState]);

  const handleSelectStudents = e => {
    const newArrayEdit = checkedGroupState.map(check => {
      if (check.id !== parseInt(e.target.value)) {
        return check;
      } else {
        const checkNew = { ...check, checked: e.target.checked };
        return checkNew;
      }
    });
    setCheckedGroupState(newArrayEdit);
  };

  // cada cambio de motivo
  useEffect(() => {
    const reasonSelArray = reasonDataState.filter(
      reson => reson.id === parseInt(reasonSelectedState)
    );

    if (reasonSelArray[0] && reasonSelArray[0].document) {
      setWithDocState(true);
    } else {
      setWithDocState(false);
    }
  }, [reasonSelectedState, reasonDataState]);

  const renderCheckboxes = () => {
    if (!checkedGroupState) {
      return (
        <p style={{ marginTop: '15px' }}>
          Seleccione Especialidad para cargar Alumnos.
        </p>
      );
    } else if (checkedGroupState && checkedGroupState.length === 0) {
      return <p style={{ marginTop: '15px' }}>Especialidad sin Alumnos.</p>;
    } else {
      return checkedGroupState.map(aCheckbox => {
        return (
          <label key={aCheckbox.id} className={classes.labels}>
            <CheckboxWidget
              name="absenceUsers"
              value={aCheckbox.id}
              color="primary"
              onChangeHandler={handleSelectStudents}
              checked={aCheckbox.checked}
              register={register}
              req={true}
              loading={loadingListStudentsGraph}
            />
            <Typography color="textSecondary" variant="body1">
              {aCheckbox.fullname}
            </Typography>
          </label>
        );
      });
    }
  };

  const renderStudents = () => {
    return (
      <div className={classes.inputContainerStyle}>
        <div className={classes.labelDesktopStyle}>Alumnos</div>
        <div>
          <div className={classes.groupLabel}>{renderCheckboxes()}</div>
          {errors && errors.absenceUsers && (
            <FormHelperText className={classes.errorText} error>
              {errorMessage.message['absenceUsers']}
            </FormHelperText>
          )}
        </div>
      </div>
    );
  };

  const renderAttachment = () =>
    withDocState && (
      <TextFieldWidgetText
        name="attached"
        labeltext="Documento Adjunto"
        download={
          documentState && documentState !== null ? (
            <a
              href={documentState}
              target="_blank"
              rel="noopener noreferrer"
              download>
              Descargarlo Aquí
            </a>
          ) : (
            ''
          )
        }
        type="file"
        disabled={false}
        req={require}
        InputLabelProps={{ shrink: true }}
        register={register}
        errors={errors.attached}
      />
    );

  const onSubmit = async values => {
    const sinceUntil = values.period.split('-');
    const sinceDay = sinceUntil[0].trim();
    const untilDay = sinceUntil[1].trim();
    let arrayStudent = values.absenceUsers;
    if (typeof values.absenceUsers === 'string') {
      arrayStudent = Array.from(values.absenceUsers);
    }
    let documentSend = '';
    if (values.attached) {
      documentSend = values.attached;
    }

    const newArrayStudent = arrayStudent.map(student => {
      const newStudents = checkboxesStundentsState.map(dataStu => {
        if (parseInt(student) === parseInt(dataStu.id)) {
          const studentWithAbsence = {
            postulation: dataStu.idPostulation,
            student: parseInt(student),
            specialty: dataStu.specialty.id
          };
          return studentWithAbsence;
        } else {
          return null;
        }
      });
      return newStudents;
    });
    // elimino un nivel y elementos null
    let cleanArrayStudent1 = _.flatten(newArrayStudent);
    let cleanArrayStudent2 = _.compact(cleanArrayStudent1);

    const query = {
      id: parseInt(id),
      absenceReason: +values.reason,
      absenceDetail: cleanArrayStudent2,
      document: documentSend,
      days: +values.absenceDays,
      since: sinceDay,
      until: untilDay
    };

    try {
      await updateAbsenceHandler({
        variables: {
          absenceInput: query
        }
      });
    } catch (err) {}
  };

  console.log(documentState, '<-------------------');

  return (
    <Card>
      <CardHeaderWidget
        title={`Editar Inasistencia: ${absenceReason.name} | ${since} - ${until} `}
      />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetSelect
                options={reasonDataState}
                name="reason"
                req={require}
                selected={reasonSelectedState}
                onChange={e => setReasonSelectedState(e)}
                loading={loadingAbsenceReasonsGraph}
                withDefault={true}
                InputLabelProps={{ shrink: true }}
                labeltext="Motivo"
                register={register}
                errors={errors.reason}
              />
              <TextFieldWidgetSelect
                options={specialtyDataState}
                name="specialty"
                req={require}
                selected={specialtySelectedState}
                onChange={e => setSpecialtySelectedState(e)}
                withDefault={true}
                InputLabelProps={{ shrink: true }}
                labeltext="Especialidad"
                register={register}
                errors={errors.specialty}
                loading={loadingSpecialitiesGraph}
              />
              {renderStudents()}
              {renderAttachment()}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TextFieldWidgetDateRange
                  value={[]}
                  name="period"
                  emptyLabel={clearRangeState}
                  beginSinceDate={beginRangeState}
                  beginUntilDate={endRangeState}
                  labeltext="Período Inasistencia"
                  register={register}
                  errors={errors.period}
                  placeholder="Seleccione rango de fechas"
                  format="dd/MM/yyyy"
                  onChange={values => {
                    setBeginRangeState(values.begin);
                    setEndRangeState(values.end);
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextFieldWidgetText
                name="absenceDays"
                type="number"
                labeltext="Cant. de días académicos de inasistencia"
                placeholder="1"
                InputLabelProps={{ shrink: true }}
                req={require}
                register={register}
                autoComplete="days"
                errors={errors.absenceDays}
              />
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default AbsenceEdit;
