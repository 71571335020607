import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import classes from './AddFinalNoteTable.module.scss';
import errorMessage from '../../common/errorMessages.json';

const AddFinalNoteTable = props => {
  const { errors, register, students } = props;

  const [hayError, setHayError] = useState({});

  useEffect(() => {
    if (errors) {
      setHayError(errors);
    }
  }, [errors, students]);

  let pattern = /^(10|\d([.,]\d{1,2})?)$/;

  return (
    <div className={classes.registerAddedContainer}>
      <div className={classes.addTitleAdditional}>
        Ingrese nota final de cada estudiante
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre Estudiante</th>
            <th scope="col">Promedio</th>
          </tr>
        </thead>
        <tbody>
          {students && students.length > 0 ? (
            students.map(student => {
              let fieldName = 'note_' + student.id;
              return (
                <tr key={student.id}>
                  <td
                    className="py-0"
                    style={{ width: '70%', verticalAlign: 'middle' }}>
                    {student.fullname}
                  </td>
                  <td className="py-0">
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      classes={{
                        root: classes.inputRoot
                      }}
                      inputProps={{step:'.1'}}
                      name={fieldName}
											type="number"
                      onChange={props.onChange}
                      inputRef={register({
                        maxLength: {
                          value: 4,
                          message: errorMessage.message['finalNote'].maxLength
                        },
                        pattern: {
                          value: pattern,
                          message: errorMessage.message['finalNote'].pattern
                        }
                      })}
                      error={Boolean(hayError[fieldName])}
                      helperText={
                        hayError[fieldName] && hayError[fieldName].message
                      }
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2}>No hay estudiantes.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AddFinalNoteTable;
