import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateParameter } from '../../graphql/mutation/parameters';
import { listParameter } from '../../graphql/queries/parameters';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetDate
} from '../../components';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsRow, BsCol } from '../../layouts/components';
import '../../assets/scss/form.scss';

const Parameters = props => {
  const require = true;
  const [parametersDataState, setParametersDataState] = useState([]);

  const { handleSubmit, register, errors } = useForm();

  const { data: dataParametersGraph } = useQuery(listParameter, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    updateParametersHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateParameter);

  useEffect(() => {
    if (dataParametersGraph && dataParametersGraph.listParameter) {
      setParametersDataState(dataParametersGraph.listParameter);
    }
  }, [dataParametersGraph]);

  const onChange = (e, id) => {
    const val = e.target.value;
    const newParams = parametersDataState.map(param => {
      if (param.id === id) {
        const updatedParam = {
          ...param,
          value: val
        };
        return updatedParam;
      }
      return param;
    });
    setParametersDataState(newParams);
  };

  const parametro = parametersDataState.map(parameter => {
    if (parameter.type !== 'date') {
      return (
        <TextFieldWidgetText
          InputLabelProps={{ shrink: true }}
          name={parameter.nameInput}
          type={parameter.type}
          labeltext={parameter.name}
          req={require}
          key={parameter.name}
          defaultValue={parameter.value}
          onChange={e => onChange(e, parameter.id)}
          register={register}
          placeholder={`Ingrese ${parameter.name}`}
          autoComplete={parameter.name}
          // errors={`errors.${parameter.nameInput}`}
          errors={errors[parameter.nameInput]}
        />
      );
    }
    if (parameter.type === 'date') {
      return (
        <TextFieldWidgetDate
          name="date"
          label="Fecha"
          register={register}
          errors={errors.date}
        />
      );
    }
    return parameter;
  });

  const onSubmit = async values => {
    const query = parametersDataState.map(param => {
      if (param.id) {
        const updatedParam = {
          id: param.id,
          value: param.value
        };
        return updatedParam;
      }
      return param;
    });

    const variable = {
      parameterInput: query
    };
    try {
      await updateParametersHandler({
        variables: variable
      });
    } catch (err) {}
  };

  return (
    <Card>
      <CardHeaderWidget title={`Modificar parametros`} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <BsRow>
                <BsCol class="col-md-12">{parametro}</BsCol>
              </BsRow>
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};
export default Parameters;
