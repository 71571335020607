import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { updateUser } from '../../../graphql/mutation/users';
import { listUsersProfile } from '../../../graphql/queries/users';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  CheckboxWidget
} from '../../../components';
import { Card, CardContent, Divider, TextField, Typography, FormGroup } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { listSpecialities } from '../../../graphql/queries/specialty';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import { formatRut, formatOnChangeRut, alterArrayForSpecialtyAssign, alterArrayForSpecialtyAssigned } from '../../../helpers';
import classes from './UserEdit.module.scss';
import '../../../assets/scss/form.scss';

const UserEdit = props => {
  const require = true;
  const {
    id,
    email,
    rut,
    name,
    paternalLastname,
    maternalLastname,
    profile,
    phone,
    specialty
  } = props.rowEdit;
  const { editFunc } = props;
  const [profileListDataState, setProfileListDataState] = useState([]);

  const [checkboxesState, setCheckboxesState] = useState([]);

  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const { handleSubmit, register, errors, getValues, reset } = useForm();

  const { loading: loadingProfileGraph, data: dataProfileGraph } = useQuery(
    listUsersProfile,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const { loading: loadingSpecialtiesGraph, data: dataSpecialtiesGraph } = useQuery(
    listSpecialities,
    {
      variables: {
        filter: {
          id: {
            gt: 0
          }
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const [
    updateUserHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateUser);

  useEffect(() => {
    if (dataProfileGraph && dataProfileGraph.listUsersProfile) {
      setProfileListDataState(dataProfileGraph.listUsersProfile);
    }
  }, [dataProfileGraph]);

  useEffect(() => {
    if (dataSpecialtiesGraph && dataSpecialtiesGraph.listSpecialities) {
      console.log(dataSpecialtiesGraph.listSpecialities, "----data specialty")
      setSpecialtyDataState(dataSpecialtiesGraph.listSpecialities);
    }
  }, [dataSpecialtiesGraph]);

  // console.log("-------------------", props)

  useEffect(() => {
    reset({
      id: parseInt(id),
      profile: parseInt(profile.id),
      rut: rut,
      name: name,
      paternalLastname: paternalLastname,
      maternalLastname: maternalLastname,
      email: email,
      phone: phone
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [
    id,
    email,
    rut,
    name,
    paternalLastname,
    maternalLastname,
    profile,
    phone,
    reset
  ]);

  // useEffect(() => {
  //   if(specialty.length){
  //     reset({
  //       specialtyUser: specialty
  //     });
  //   }
     
  // }, [specialty]);

  useEffect(() => {
    if (dataSubmitGraph) {
      if (dataSubmitGraph.updateUser) {
        // console.log(dataSubmitGraph.updateUser, "-----------ASI VUELVE DE LA QUERY")
        const newObj = dataSubmitGraph.updateUser;
        editFunc(newObj);
      }
    }
  }, [dataSubmitGraph]);

  const onSubmit = async values => {
    // console.log(values, "<--------------")

    // const arraySpecialties = checkboxesState.map(({ id, assigned }) => {
    //   return { id: id, assigned: assigned };
    // });

    let arrayQuery = [];

    if(values.specialtyUser) {
      arrayQuery = values.specialtyUser.map((id) => {
        return parseInt(id);
      });
    }
    

    console.log(arrayQuery,"---arraySpecialties")

    const query = {
      id: parseInt(values.id),
      email: values.email,
      rut: formatRut(values.rut),
      name: values.name,
      paternalLastname: values.paternalLastname,
      maternalLastname: values.maternalLastname,
      phone: values.phone,
      password: values.password,
      specialty: arrayQuery
    };
    try {
      await updateUserHandler({
        variables: query
      });
    } catch (err) {}
  };

  // TOMA EL VALOR DE PASSWORD
  const { password } = getValues();

  const handleChange = e => {
    const newArrayEdit = checkboxesState.map(check => {
      if (check.id !== parseInt(e.target.value)) {
        return check;
      } else {
        const checkNew = { ...check, assigned: e.target.checked };
        return checkNew;
      }
    });
    setCheckboxesState(newArrayEdit);
  };

  useEffect(() => {
    if(specialty.length){
      // console.log("-------------1")
      setCheckboxesState(alterArrayForSpecialtyAssigned(specialtyDataState, specialty))
    } else {
      // console.log("-------------2")
      setCheckboxesState(alterArrayForSpecialtyAssign(specialtyDataState))
    }
  }, [specialtyDataState, specialty]);

  const renderCheckboxes = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: '50px' }}>
          Seleccione Perfil y usuario para cargar módulos.
        </p>
      );
    }

    return checkboxesState.map((aCheckbox, index) => {
      // console.log("--------------------------_>", aCheckbox)
      return (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidget
            name="specialtyUser"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleChange}
            checked={aCheckbox.assigned}
            register={register}
            errors="specialtyUser"
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.name}
          </Typography>
        </label>
      );
    });
  };

  console.log("------------------")

  return (
    <Card>
      <CardHeaderWidget title={`Editar Usuario: ${name} ${paternalLastname}`} />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWidgetSelect
                loading={loadingProfileGraph}
                selected={profile.id}
                options={profileListDataState}
                name="profile"
                labeltext="Tipo de Perfil"
                register={register}
                errors={errors.profile}
                disabled
              />
              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="rut"
                autoComplete="rut"
                onChange={formatOnChangeRut}
                labeltext="Rut"
                req={require}
                register={register}
                placeholder="11.111.111-1"
                errors={errors.rut}
              />
              <TextField
                name="id"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />
              <TextFieldWidgetText
                InputLabelProps={{ shrink: true }}
                name="name"
                labeltext="Nombres"
                req={require}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="nombres"
              />
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="paternalLastname"
                    labeltext="Apellido paterno"
                    InputLabelProps={{ shrink: true }}
                    req={require}
                    minLength={3}
                    maxLength={100}
                    register={register}
                    autoComplete="apellido"
                    errors={errors.paternalLastname}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="maternalLastname"
                    labeltext="Apellido materno"
                    InputLabelProps={{ shrink: true }}
                    req={require}
                    minLength={3}
                    maxLength={100}
                    register={register}
                    autoComplete="apellido materno"
                    errors={errors.maternalLastname}
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{ shrink: true }}
                    name="email"
                    labeltext="E-mail"
                    req={require}
                    register={register}
                    errors={errors.email}
                    autoComplete="email"
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{ shrink: true }}
                    name="phone"
                    type="number"
                    labeltext="Teléfono"
                    req={require}
                    minLength={8}
                    maxLength={10}
                    register={register}
                    placeholder="56999999999"
                    autoComplete="phone"
                    errors={errors.phone}
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{ shrink: true }}
                    name="institutionalMail"
                    labeltext="Correo Institucional"
                    req={false}
                    register={register}
                    errors={errors.institutionalMail}
                    autoComplete="institutionalMail"
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  {/* <TextFieldWidgetText
                    InputLabelProps={{ shrink: true }}
                    name="photo"
                    type="file"
                    labeltext="Foto"
                    req={require}
                    minLength={8}
                    maxLength={10}
                    register={register}
                    errors={errors.photo}
                  /> */}
                </BsCol>
              </BsRow>
              {(profile.id === 5 || profile.id === 6) && (<BsRow class="mt-4">
                <BsCol class="col-md-12">
                  <Typography variant="h6" gutterBottom>
                    Seleccione los Procesos de cada Módulo
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormGroup className={classes.groupLabel}>
                      {renderCheckboxes()}
                    </FormGroup>
                  </div>
                </BsCol>
              </BsRow>)}
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    minLength={6}
                    maxLength={10}
                    labeltext="Contraseña"
                    register={register}
                    errors={errors.password}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="password2"
                    type="password"
                    equalto={password}
                    autoComplete="new-password"
                    minLength={6}
                    maxLength={10}
                    labeltext="Confirmar Contraseña"
                    register={register}
                    errors={errors.password2}
                  />
                </BsCol>
              </BsRow>
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}></SaveAction>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default UserEdit;
