import gql from 'graphql-tag';

export const createUser = gql`
  mutation(
    $profile: Int!
    $email: String!
    $rut: String!
    $name: String!
    $paternalLastname: String!
    $maternalLastname: String!
    $phone: String!
    $password: String!
  ) {
    createUser(
      userInput: {
        profile: $profile
        email: $email
        rut: $rut
        name: $name
        paternalLastname: $paternalLastname
        maternalLastname: $maternalLastname
        phone: $phone
        password: $password
      }
    )
  }
`;

export const updateUser = gql`
  mutation(
    $id: Int!
    $email: String!
    $rut: String!
    $name: String!
    $paternalLastname: String!
    $maternalLastname: String!
    $phone: String!
    $password: String!
    $specialty: [Int]!
  ) {
    updateUser(
      userInput: {
        id: $id
        email: $email
        rut: $rut
        name: $name
        paternalLastname: $paternalLastname
        maternalLastname: $maternalLastname
        phone: $phone
        password: $password
        specialty: $specialty
      }
    ) {
      id
      email
      rut
      name
      paternalLastname
      maternalLastname
      phone
      profile {
        id
        name
      }
      state {
        id
        name
      }
      moduleUser {
        id
        name
      }
      processUser {
        id
        name
        module {
          id
          name
        }
      }
      password
      delete
      edit
    }
  }
`;

export const deleteUser = gql`
  mutation($id: Int!) {
    deleteUser(id: $id)
  }
`;

export const updateStateUser = gql`
  mutation($id: Int!) {
    updateStateUser(id: $id)
  }
`;

export const updatePrivilegeUser = gql`
  mutation(
    $id: Int!
    $userInputModule: [UserInputModule!]!
    $userInputProcess: [UserInputProcess!]!
  ) {
    updatePrivilegeUser(
      userInputModule: $userInputModule
      userInputProcess: $userInputProcess
      id: $id
    )
  }
`;
