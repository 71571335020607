import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Card, CardContent, Divider } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createUser } from '../../../graphql/mutation/users';
import { listUsersProfile } from '../../../graphql/queries/users';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  BreadCrumbs
} from '../../../components';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { formatRut, formatOnChangeRut } from '../../../helpers';
import '../../../assets/scss/form.scss';

const require = true;

const UserAdd = () => {
  const [profileDataState, setProfileDataState] = useState([]);

  const [profileSelectedState, setProfileSelectedState] = useState('');

  const { handleSubmit, register, errors, getValues, reset } = useForm();
  const { loading: loadingProfileGraph, data: dataProfileGraph } = useQuery(
    listUsersProfile,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const [
    createUserHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(createUser);

  useEffect(() => {
    if (dataProfileGraph && dataProfileGraph.listUsersProfile) {
      setProfileDataState(dataProfileGraph.listUsersProfile);
    }
  }, [dataProfileGraph]);

  const onSubmit = async values => {
    const query = {
      profile: +values.profile,
      email: values.email,
      rut: formatRut(values.rut),
      name: values.name,
      paternalLastname: values.paternalLastname,
      maternalLastname: values.maternalLastname,
      phone: values.phone,
      password: values.password
    };
    try {
      await createUserHandler({
        variables: query
      });
      setProfileSelectedState(null);
      reset();
    } catch (err) {}
  };

  // toma el valor password
  const { password } = getValues();

  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Agregar Usuarios"
                subheader={
                  <BreadCrumbs subTitle="Módulo para crear Usuarios." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <TextFieldWidgetSelect
                        options={profileDataState}
                        name="profile"
                        req={require}
                        loading={loadingProfileGraph}
                        withDefault={true}
                        selected={profileSelectedState}
                        onChange={e => setProfileSelectedState(e)}
                        InputLabelProps={{ shrink: true }}
                        labeltext="Tipo de Perfil"
                        register={register}
                        errors={errors.profile}
                      />
                      <TextFieldWidgetText
                        name="rut"
                        autoComplete="rut"
                        labeltext="Rut"
                        placeholder="11.111.111-1"
                        onChange={formatOnChangeRut}
                        InputLabelProps={{ shrink: true }}
                        req={require}
                        register={register}
                        errors={errors.rut}
                      />
                      <TextFieldWidgetText
                        name="name"
                        labeltext="Nombres"
                        req={require}
                        InputLabelProps={{ shrink: true }}
                        minLength={3}
                        maxLength={100}
                        register={register}
                        errors={errors.name}
                        autoComplete="nombre"
                      />
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="paternalLastname"
                            labeltext="Apellido paterno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="apellido"
                            errors={errors.paternalLastname}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="maternalLastname"
                            labeltext="Apellido materno"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={3}
                            maxLength={100}
                            register={register}
                            autoComplete="apellido materno"
                            errors={errors.maternalLastname}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="email"
                            labeltext="E-mail"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            register={register}
                            errors={errors.email}
                            autoComplete="email"
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="phone"
                            type="number"
                            labeltext="Teléfono"
                            placeholder="56999999999"
                            InputLabelProps={{ shrink: true }}
                            req={require}
                            minLength={8}
                            maxLength={10}
                            register={register}
                            autoComplete="phone"
                            errors={errors.phone}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="institutionalMail"
                            labeltext="Correo Institucional"
                            req={false}
                            register={register}
                            errors={errors.institutionalMail}
                            autoComplete="institutionalMail"
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          {/* <TextFieldWidgetText
                            InputLabelProps={{ shrink: true }}
                            name="photo"
                            type="file"
                            labeltext="Foto"
                            req={require}
                            minLength={8}
                            maxLength={10}
                            register={register}
                            errors={errors.photo}
                          /> */}
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="password"
                            req={require}
                            minLength={6}
                            maxLength={10}
                            autoComplete="new-password"
                            type="password"
                            labeltext="Contraseña"
                            InputLabelProps={{ shrink: true }}
                            register={register}
                            errors={errors.password}
                          />
                        </BsCol>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            name="password2"
                            equalto={password}
                            req={require}
                            type="password"
                            autoComplete="new-password"
                            minLength={6}
                            maxLength={10}
                            labeltext="Confirmar Contraseña"
                            InputLabelProps={{ shrink: true }}
                            register={register}
                            errors={errors.password2}
                          />
                        </BsCol>
                      </BsRow>
                      <SaveAction
                        messageType="add"
                        mutationLoading={loadingSubmitGraph}
                        success={dataSubmitGraph}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default UserAdd;
