import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { Context } from './../../context/Context';
import classes from './PostulationHeader.module.scss';

const PostulationHeader = props => {
  const { userId, submitHandler, buttonPostulationState } = props;

  const history = useHistory();

  const context = useContext(Context);

  const userProfile = context.userProfile;

  const onClickFunc = () => {
    let path = `/curriculum-report?idUser=${userId}`;
    history.push(path);
  };

  // console.log(buttonPostulationState, "<----")

  return (
    <div className={classes.headerContainer}>
      {buttonPostulationState && userProfile !== 'Secretaria' && (
        <div style={{ marginRight: '20px' }}>
          <Button
            onClick={submitHandler}
            variant="contained"
            size="large"
            color="primary">
            Enviar Tu Postulación
          </Button>
        </div>
      )}
      {
        userProfile === 'Secretaria' && props.userCadidateId && buttonPostulationState && (
           <div style={{ marginRight: '20px' }}>
            <Button
              onClick={submitHandler}
              variant="contained"
              size="large"
              color="primary">
              Enviar Postulación Secretaria
            </Button>
          </div>
        )
      }
      <div>
        <Button
          variant="contained"
          color={props.disableExport ? 'primary' : 'default'}
          size="medium"
          disabled={!props.disableExport}
          className={classes.button}
          startIcon={<PictureAsPdfOutlinedIcon />}
          onClick={onClickFunc}>
          Exportar Postulación
        </Button>
        {!props.disableExport && (
          <Typography variant="caption" display="block" className="mt-2">
            Se habilita cuando completes tu información.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default PostulationHeader;
