import gql from 'graphql-tag';

export const listUsers = gql`
  query(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $sort: UserSortingInput
  ) {
    listUsers(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      id
      email
      rut
      name
      paternalLastname
      maternalLastname
      fullname
      phone
      profile {
        id
        name
      }
      state {
        id
        name
      }
      specialty {
        id
        name
      }
      moduleUser {
        id
        name
        assigned
        process {
          id
          name
          assigned
        }
      }
      processUser {
        id
        name
        module {
          id
          name
        }
      }
      password
      delete
      edit
    }
  }
`;

export const listUsersCount = gql`
  query($filter: UserFilterInput) {
    listUsersCount(filter: $filter)
  }
`;

export const getUser = gql`
  query($id: Int!) {
    getUser(id: $id) {
      id
      email
      rut
      name
      paternalLastname
      maternalLastname
      fullname
      phone
      profile {
        id
        name
      }
      state {
        id
        name
      }
      moduleUser {
        id
        name
      }
      processUser {
        id
        name
        module {
          id
          name
        }
      }
      password
      delete
      edit
    }
  }
`;

export const listUsersProfile = gql`
  query {
    listUsersProfile {
      id
      name
    }
  }
`;

export const listUsersState = gql`
  query {
    listUsersState {
      id
      name
    }
  }
`;

export const listModules = gql`
  query {
    listModules {
      id
      name
    }
  }
`;

export const listProcess = gql`
  query {
    listProcess {
      id
      name
      module {
        id
        name
      }
    }
  }
`;

export const listModulesProcess = gql`
  query($filter: [Int]) {
    listModulesProcess(filter: $filter) {
      id
      name
      module {
        id
        name
      }
    }
  }
`;
