import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import {
  Card,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormGroup
} from '@material-ui/core';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { listUsersProfile, listUsers } from '../../../graphql/queries/users';
import { updatePrivilegeUser } from '../../../graphql/mutation/users';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetSelect,
  BreadCrumbs,
  AutoCompleteWidget,
  CheckboxWidget
} from '../../../components';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import classes from './UserPrivilege.module.scss';

const UserPrivilege = () => {
  const [profileDataState, setProfileDataState] = useState([]);
  const [usersDataState, setUsersDataState] = useState([]);
  const [profileSelectedState, setProfileSelectedState] = useState(null);
  const [userSelectedState, setUserSelectedState] = useState(null);
  const [checkboxesState, setCheckboxesState] = useState([]);
  const [valueTabState, setValueTabState] = useState(0);

  const tabItems = ['Módulos', 'Procesos'];

  const { handleSubmit, register, errors } = useForm();
  const { loading: loadingProfileGraph, data: dataProfileGraph } = useQuery(
    listUsersProfile,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
    }
  );

  const [
    listUsersHandler,
    { loading: loadingUsersGraph, data: dataUsersGraph }
  ] = useLazyQuery(listUsers, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [
    updatePrivilegeUserHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updatePrivilegeUser);

  useEffect(() => {
    if (dataUsersGraph && dataUsersGraph.listUsers) {
      console.log("----------", dataUsersGraph.listUsers)
      setUsersDataState(dataUsersGraph.listUsers);
    }
  }, [dataUsersGraph]);

  useEffect(() => {
    if (dataProfileGraph && dataProfileGraph.listUsersProfile) {
      setProfileDataState(dataProfileGraph.listUsersProfile);
      console.log("-------", dataProfileGraph.listUsersProfile)
    }
  }, [dataProfileGraph]);

  useEffect(() => {
    setUserSelectedState(null);
    setCheckboxesState([]);
    async function queryData() {
      const filter = {
        filter: {
          profile: {
            eq: parseInt(profileSelectedState)
          }
        }
      };
      try {
        await listUsersHandler({
          variables: filter
        });
      } catch (err) {}
    }
    if (profileSelectedState) {
      console.log("------")
      queryData();
    } else {
      setUsersDataState([]);
    }
  }, [listUsersHandler, profileSelectedState]);

  useEffect(() => {
    if (userSelectedState) {
      setCheckboxesState(userSelectedState.moduleUser);
    }
  }, [userSelectedState]);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const onSubmit = async values => {
    const arrayModule = checkboxesState.map(({ id, assigned }) => {
      return { id: id, assigned: assigned };
    });

    const arrayProcess = checkboxesState.map(({ process }) => {
      const newProcess = process.map(pro => {
        return { id: pro.id, assigned: pro.assigned };
      });
      return newProcess;
    });

    const variable = {
      userInputModule: arrayModule,
      userInputProcess: _.flatten(arrayProcess),
      id: parseInt(values.userId)
    };

    try {
      await updatePrivilegeUserHandler({
        variables: variable
      });
      setProfileSelectedState(0);
      setUserSelectedState(null);
      setCheckboxesState([]);
    } catch (err) {}
  };

  const handleChange = e => {
    const newArrayEdit = checkboxesState.map(check => {
      if (check.id !== parseInt(e.target.value)) {
        return check;
      } else {
        const checkNew = { ...check, assigned: e.target.checked };
        return checkNew;
      }
    });
    setCheckboxesState(newArrayEdit);
  };

  const handleChangeProcess = e => {
    const newArrayEditProcess = checkboxesState.map(check => {
      const newProcess = check.process.map(pro => {
        if (pro.id !== parseInt(e.target.value)) {
          return pro;
        } else {
          const proNew = { ...pro, assigned: e.target.checked };
          return proNew;
        }
      });
      return { ...check, process: newProcess };
    });
    setCheckboxesState(newArrayEditProcess);
  };

  const renderCheckboxes = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: '50px' }}>
          Seleccione Perfil y usuario para cargar módulos.
        </p>
      );
    }

    return checkboxesState.map((aCheckbox, index) => {
      return (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidget
            name="modulesUser"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleChange}
            checked={aCheckbox.assigned}
            register={register}
            errors="modulesUser"
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.name}
          </Typography>
        </label>
      );
    });
  };

  const renderCheckboxesProcess = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: '50px' }}>
          Seleccione Perfil y usuario para cargar procesos.
        </p>
      );
    }
    const checkboxes = checkboxesState.map(aCheckbox => {
      if (aCheckbox.assigned) {
        return (
          <div className={classes.processBox} key={aCheckbox.id}>
            <div style={{ fontWeight: 'bold' }}>{aCheckbox.name}</div>
            {aCheckbox.process.map(pros => {
              return (
                <label key={pros.id} className={classes.labels}>
                  <CheckboxWidget
                    name="processUser"
                    value={pros.id}
                    color="primary"
                    onChangeHandler={handleChangeProcess}
                    checked={pros.assigned}
                    register={register}
                    errors="processUser"
                  />
                  <Typography color="textSecondary" variant="body1">
                    {pros.name}
                  </Typography>
                </label>
              );
            })}
          </div>
        );
      } else {
        return null;
      }
    });
    return checkboxes;
  };
  return (
    <div className="form-style">
      <BsContainer fluid class="px-md-0">
        <BsRow>
          <BsCol class="col-md-12">
            <Card>
              <CardHeaderWidget
                title="Privilegios de Usuarios"
                subheader={
                  <BreadCrumbs subTitle="Defina aquí los privilegios de cada usuario." />
                }
              />
              <Divider />
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-5">
                    <TextFieldWidgetSelect
                      options={profileDataState}
                      name="profile"
                      labeltext="Perfil"
                      loading={loadingProfileGraph}
                      req
                      InputLabelProps={{ shrink: true }}
                      selected={profileSelectedState}
                      onChange={e => setProfileSelectedState(e)}
                      register={register}
                      withDefault={true}
                      errors={errors.profile}
                    />
                  </BsCol>
                  <BsCol class="col-md-5">
                    <AutoCompleteWidget
                      label="Usuario"
                      require={true}
                      type="user"
                      data={usersDataState}
                      dataOptionLabel="fullname"
                      inicialValue="Seleccione Perfil..."
                      valuecombo={userSelectedState}
                      loadingComboState={loadingUsersGraph}
                      onSelectState={setUserSelectedState}
                      name="usercombo"
                      register={register}
                      errors={errors.usercombo}
                    />
                  </BsCol>
                </BsRow>
                <BsRow>
                  <BsCol class="col-md-12 pt-3">
                    <SimpleTabs
                      tabitems={tabItems}
                      value={valueTabState}
                      toggler={handleChangeTab}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                          name="userId"
                          value={userSelectedState ? userSelectedState.id : ''}
                          inputRef={register}
                          type="text"
                          style={{ display: 'none' }}
                        />
                        <TabPanel value={valueTabState} index={0}>
                          <Typography variant="h6" gutterBottom>
                            Seleccione los Módulos del Usuario
                          </Typography>
                          <div style={{ display: 'flex' }}>
                            <FormGroup className={classes.groupLabel}>
                              {renderCheckboxes()}
                            </FormGroup>
                          </div>
                        </TabPanel>
                        <TabPanel value={valueTabState} index={1}>
                          <Typography variant="h6" gutterBottom>
                            Seleccione los Procesos de cada Módulo
                          </Typography>
                          <div style={{ display: 'flex' }}>
                            <FormGroup className={classes.groupLabel}>
                              {renderCheckboxesProcess()}
                            </FormGroup>
                          </div>
                          {checkboxesState.length === 0 ? <hr /> : null}
                        </TabPanel>
                        <SaveAction
                          messageType="add"
                          mutationLoading={loadingSubmitGraph}
                          success={dataSubmitGraph}
                        />
                      </form>
                    </SimpleTabs>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default UserPrivilege;
