import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { IconButton, Tooltip } from '@material-ui/core';

const IconActionTableStateOn = props => {
  const { handleActionState, rowState } = props;

  return (
    <Tooltip title="Cambio de estado">
      <IconButton aria-label="state" onClick={handleActionState}>
        {(rowState === 1 && <CheckBoxIcon fontSize="small" />) ||
          (rowState === 2 && <CheckBoxOutlineBlankIcon fontSize="small" />)}
      </IconButton>
    </Tooltip>
  );
};

export default IconActionTableStateOn;
