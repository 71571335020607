import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import classes from './Stages.module.scss';

const itemBox = (name, value, color) => {
  return (
    <div className={classes.box} key={name}>
      <div className={classes.number}>{value}</div>
      <label className={classes.label}>{name}</label>
    </div>
  );
};

const Stages = props => {
  const { className, detailApplications, ...rest } = props;
  // const detailApplications = props.detailApplications;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <h3 className={classes.title}>Detalle Postulaciones por Etapa</h3>
        {detailApplications && detailApplications.length > 0 ? (
          detailApplications.map(detail => {
            return itemBox(detail.name, detail.total);
          })
        ) : (
          <p>No hay detalle postulaciones.</p>
        )}
      </CardContent>
    </Card>
  );
};

Stages.propTypes = {
  className: PropTypes.string
};

export default Stages;
