import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const paginationOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de'
};
const DataTableWidgetNotLazy = props => {
  const {
    columns,
    filteredItems,
    subHeaderComponentMemo,
    loading,
    sortField
  } = props;
  const [perPageOptions] = useState([10, 15, 20, 25, 30]);
  const [data, setData] = useState(filteredItems);

  useEffect(() => {
    setData(filteredItems);
  }, [filteredItems]);

  return (
    <DataTable
      noHeader
      columns={columns}
      data={data}
      subHeader
      defaultSortField={sortField}
      subHeaderComponent={subHeaderComponentMemo}
      paginationComponentOptions={paginationOptions}
      pagination
      paginationRowsPerPageOptions={perPageOptions}
      progressPending={loading}
      noDataComponent="No hay registros."
    />
  );
};

export default DataTableWidgetNotLazy;
