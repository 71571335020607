import gql from 'graphql-tag';

export const getDashBoard = gql`
  query($filter: DashBoardFilterInput!) {
    getDashBoard(filter: $filter) {
      postulationByState {
        id
        name
        total
      }
      postulationByStateSpecific {
        id
        name
        total
      }
      postulationBySpecialty {
        id
        name
        percentage
      }
      postulationStateByStep {
        name
        total
      }
      postulationUserDataLoad {
        finished
        name
        step
      }
      postulationUserStep {
        finished
        name
        step
      }
      postulationUserSend
      postulationUserDocument {
        name
        load
      }
      postulationUserDocumentPercent
      postulationMessageWelcome
      postulationByStateSee
      postulationByStateSpecificSee
      postulationBySpecialtySee
      postulationStateValidationSee
      postulationStateEvaluationSee
      postulationStateInstitutionSee
      postulationReportSee
      postulationDataLoadSee
      postulationStepSee
      postulationSendSee
      postulationDocumentLoadSee
      postulationCompetitionSee
      postulationLinksSee
      postulationMessageWelcomeSee
    }
  }
`;

export const getDashboardUserPostulation = gql`
  query($id: Int!) {
    getDashboardUserPostulationList(id: $id) {
      finished
      name
      step
    }
  }
`;
