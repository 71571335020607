import React, { useReducer, createContext, useEffect } from 'react';

// recibe al final el nombre del cookie que creará para mantener la sesión
export default (reducer, actions, initialState, sessionName) => {
	const Context = createContext();

	const Provider = ({ children }) => {
		const [state, dispatch] = useReducer(reducer, initialState, () => {
			// const localState = localStorage.getItem('@session'); //se mantiene aunque cierre el navegador
			const localState = window.sessionStorage.getItem(sessionName); // se limpia cuando cierra nevagador
			return localState ? JSON.parse(localState) : initialState;
		});

		// actions === { addBlogPost: (dispatch) => { return () => {} } }
		const boundActions = {};
		for (let key in actions) {
			boundActions[key] = actions[key](dispatch);
		}

		useEffect(() => {
			// localStorage.setItem('@session', JSON.stringify(state));
			window.sessionStorage.setItem(sessionName, JSON.stringify(state));
		}, [state]);

		return (
			<Context.Provider value={{ state, ...boundActions }}>
				{children}
			</Context.Provider>
		);
	};

	return { Context, Provider };
};
