import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import '../../Minimal.scss';
import { Context } from '../../../../context/Context';
import classes from './Topbar.module.scss';

const Topbar = props => {
    const { className, ...rest } = props;

    const context = useContext(Context);

    return (
        <AppBar
            {...rest}
						classes={{
							colorPrimary: classes.colorprimary
						}}
            className={clsx('toolbar_root', className)}
            position="fixed"
        >
            <Toolbar>
                <RouterLink to="/">
									<img
											className={classes.logoTop}
											alt="Logo"
											src="/images/logos/logo-top-w.png"
									/>
                </RouterLink>
                <div className={classes.flexGrow} />
                <IconButton
                    className={classes.signOutButton}
                    color="inherit"
                    onClick={context.logout}>
                    <InputIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string
};

export default Topbar;
