import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classes from './SearchBasicTable.module.scss';

const SearchBasicTable = ({
  onFilter,
  filterFunc,
  placeholder,
  valuetext,
  resetFunc,
  id = "search"
}) => (
  <Paper component="form" className={classes.root}>
    <InputBase
      id={id}
      type="text"
      role="search"
      value={valuetext}
      placeholder={placeholder}
      onChange={e => onFilter(e.target.value)}
      inputProps={{ 'aria-label': 'Buscar' }}
      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    />
    <IconButton
      onClick={resetFunc}
      className={classes.iconButton}
      color="default"
      aria-label="reset">
      <ClearIcon />
    </IconButton>
    <Divider className={classes.divider} orientation="vertical" />
    <IconButton
      onClick={filterFunc}
      className={classes.iconButton}
      color="primary"
      aria-label="buscar">
      <SearchIcon />
    </IconButton>
  </Paper>
);
export default SearchBasicTable;
