import gql from 'graphql-tag';

export const getPostulationTrainingInformation = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationTrainingInformation(id: $id, idPostulationActive: $idPostulationActive) {
      id
      jobTitle
      yearTitle
      studentUniversity {
        id
        name
      }
      otherUniversity
      otherUniversityCountry {
        id
        name
      }
      noteInternPediatrics
      noteInternGynecology
      noteInternSurgery
      noteNationalMedical
      eunacomApproved
      eunacomYear
      eunacomScore
      studentLanguageLevel {
        id
        name
      }
      documentProfessionCertificateHref
      documentNationalMedicalQualificationHref
      documentEunacomCertificateHref
      noteInternalMedicine
    }
  }
`;
