import gql from 'graphql-tag';

export const getPostulationStudent = gql`
  query(
      $id: Int!
      $idPostulationActive: Int!
    ) {
    getPostulationStudent(id: $id, idPostulationActive: $idPostulationActive) {
      id
      idUser
      rut
      name
      paternalLastname
      maternalLastname
      fullname
      birthDate
      address
      email
      phone
      datePostulation
      region {
        id
        name
      }
      commune {
        id
        name
      }
      nationality {
        id
        name
      }
      competition {
        id
        name
        code
        since
        until
        description
        state {
          id
          name
        }
        delete
        edit
      }
      specialty {
        id
        user {
          id
          rut
          name
          paternalLastname
          maternalLastname
          fullname
        }
        name
        code
        description
        state {
          id
          name
        }
        delete
        edit
      }
      jobTitle
      yearTitle
      studentUniversity {
        id
        name
      }
      otherUniversity
      otherUniversityCountry {
        id
        name
      }
      noteInternPediatrics
      noteInternGynecology
      noteInternSurgery
      noteNationalMedical
      noteInternalMedicine
      eunacomApproved
      eunacomYear
      eunacomScore
      studentLanguageLevel {
        id
        name
      }
      healthSuperintendenceRegistration
      registerNumber
      influenzaVaccine
      hepatitisbVaccine
      doseHepatitisb
      policyProfessionalLiabilityNumber
      policyProfessionalLiabilityName
      meetingDate
      meetingTime
      postulationCurricularBackground {
        id
        name
        qualification
        hour
        date
      }
      postulationPublication {
        id
        name
        author
        magazine
        date
      }
      postulationPresentation {
        id
        name
        author
        congress
        date
      }
      postulationAssistantShip {
        id
        country {
          id
          name
        }
        course
        hour
        year
        university
        certificate
      }
      postulationDiplomat {
        id
        country {
          id
          name
        }
        name
        hour
        date
        university
        qualification
        certificate
      }
      postulationPerformance {
        id
        name
        place
        hour
        date
      }
      postulationDistinction {
        id
        name
        institution
        date
      }
      postulationDocument {
        id
        name
        documentHref
        documentLoad
        delete
        edit
      }
      postulationDocumentState {
        id
        name
        documentHref
        documentLoad
        delete
        edit
      }
      statePostulation {
        id
        name
      }
      stateEvaluationPostulation {
        id
        name
      }
      stateInstitutionPostulation {
        id
        name
      }
      stateValidationPostulation {
        id
        name
      }
      postulationActivity {
        id
        idPostulation
        state {
          id
          name
        }
        date,
        commentary,
        user {
          id
          email
          rut
          name
          paternalLastname
          maternalLastname
          fullname
          phone
          profile {
            id
            name
          }
          state {
            id
            name
          }
          moduleUser {
            id
            name
            assigned
            href
            subMenu
            icon
            process {
              id
              name
              assigned
              href
            }
          }
          processUser {
            id
            name
            module {
              id
              name
            }
          }
          password
          delete
          edit
        }
      }
    }
  }
`;
