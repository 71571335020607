import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { AutoCompleteWidget } from '../';
import classes from './CurriculumCourseTable.module.scss';
import { listCourses } from '../../graphql/queries/course';

// const optionsCourse = [
// 	{id: '1', name: 'Asignatura 1', code: 'AA1', __typename:"CurriculumMeshCourse"},
// 	{id: '2', name: 'Asignatura 2', code: 'AA2', __typename:"CurriculumMeshCourse"},
// 	{id: '3', name: 'Asignatura 3', code: 'AA3', __typename:"CurriculumMeshCourse"},
// 	{id: '4', name: 'Asignatura 4', code: 'AA4', __typename:"CurriculumMeshCourse"},
// 	{id: '5', name: 'Asignatura 5', code: 'AA5', __typename:"CurriculumMeshCourse"}
// ];

const CurriculumCourseTable = props => {
  const {
    blocksData,
    tableHeaders,
    actionSelect,
    register,
    errors,
    resetFunc
  } = props;

  const [
    listCoursesHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listCourses, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const [coursesDataState, setCoursesDataState] = useState('');
  const [tableHeadersState, setTableHeadersState] = useState(null);

  useEffect(() => {
    setTableHeadersState(tableHeaders);
  }, [tableHeaders]);

  useEffect(() => {
    if (dataListGraph && dataListGraph.listCourses) {
      setCoursesDataState(dataListGraph.listCourses);
    }
  }, [dataListGraph]);

  useEffect(() => {
    async function queryData() {
      try {
        await listCoursesHandler();
      } catch (err) {}
    }
    queryData();
  }, [listCoursesHandler]);

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Per. / Línea</th>
          {tableHeadersState &&
            tableHeadersState.map((per, i) => <th key={i}>{per.name}</th>)}
        </tr>
      </thead>
      <tbody>
        {blocksData &&
          blocksData.map(block => {
            return (
              <tr key={block.blockNumber}>
                <td>{block.blockNumber}</td>
                {block.periods.map(per => {
                  return (
                    <td key={per.idAcademicPeriod}>
                      <div className={classes.containerCourse}>
                        {coursesDataState ? (
                          <AutoCompleteWidget
                            label="Asignatura"
                            require={false}
                            checkId
                            disableClearable
                            data={coursesDataState}
                            inicialValue="Seleccione Asignatura..."
                            valuecombo={
                              per.course
                                ? per.course.id
                                  ? per.course
                                  : null
                                : null
                            }
                            loadingComboState={false}
                            onSelectState={e =>
                              actionSelect(
                                e,
                                block.blockNumber,
                                per.idAcademicPeriod
                              )
                            }
                            name={`usercombo${block.blockNumber}_${per.idAcademicPeriod}`}
                            register={register}
                            errors={errors.usercombo}
                            loading={loadingListGraph}
                          />
                        ) : (
                          '--'
                        )}
                        <IconButton
                          onClick={() =>
                            resetFunc(block.blockNumber, per.idAcademicPeriod)
                          }
                          className={classes.iconButton}
                          color="default"
                          size="small"
                          aria-label="reset">
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default CurriculumCourseTable;
