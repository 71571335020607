import gql from 'graphql-tag';

export const createAndUpdatePostulationTrainingInformation = gql`
  mutation(
    $id: Int!
    $jobTitle: String!
    $yearTitle: Int
    $idStudentUniversity: Int!
    $otherUniversity: String
    $idOtherUniversityCountry: Int!
    $noteInternPediatrics: Float
    $noteInternGynecology: Float
    $noteInternSurgery: Float
    $noteNationalMedical: Float
    $eunacomApproved: Int!
    $eunacomYear: Int
    $eunacomScore: Float!
    $idStudentLanguageLevel: Int!
    $documentProfessionCertificate: Upload
    $documentNationalMedicalQualification: Upload
    $documentEunacomCertificate: Upload
    $noteInternalMedicine: Float
    $idPostulationActive: Int
  ) {
    createAndUpdatePostulationTrainingInformation(
      formInput: {
        id: $id
        jobTitle: $jobTitle
        yearTitle: $yearTitle
        idStudentUniversity: $idStudentUniversity
        otherUniversity: $otherUniversity
        idOtherUniversityCountry: $idOtherUniversityCountry
        noteInternPediatrics: $noteInternPediatrics
        noteInternGynecology: $noteInternGynecology
        noteInternSurgery: $noteInternSurgery
        noteNationalMedical: $noteNationalMedical
        eunacomApproved: $eunacomApproved
        eunacomYear: $eunacomYear
        eunacomScore: $eunacomScore
        idStudentLanguageLevel: $idStudentLanguageLevel
        documentProfessionCertificate: $documentProfessionCertificate
        documentNationalMedicalQualification: $documentNationalMedicalQualification
        documentEunacomCertificate: $documentEunacomCertificate
        noteInternalMedicine: $noteInternalMedicine
        idPostulationActive: $idPostulationActive
      }
    )
  }
`;
