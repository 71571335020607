import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { updateAcademicPeriod } from '../../../graphql/mutation/academicPeriod';
import { listSpecialities } from '../../../graphql/queries/specialty';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelect,
  TextFieldWidgetDate
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import { dateConvert } from '../../../helpers';
import '../../../assets/scss/form.scss';

const AcademicPeriodEdit = props => {
  const pattern = /^\d*$/;
  const require = true;
  const {
    id,
    specialty,
    name,
    since,
    until,
    year,
    businessDays
  } = props.rowEdit;
  const { editFunc } = props;

  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [specialtyDataState, setSpecialtyDataState] = useState([]);

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm();

  const [
    listSpecialitiesHandler,
    { loading: loadingSpecialitiesGraph, data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { filter: { id: { gt: 0 } } }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  const [
    updateAcademicPeriodHandler,
    { loading: loadingSubmitGraph, data: dataSubmitGraph }
  ] = useMutation(updateAcademicPeriod);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {
      setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
    }
  }, [dataSpecialitiesGraph]);

  useEffect(() => {
    let formatSince = dateConvert(since);
    let formatUntil = dateConvert(until);
    reset({
      id: parseInt(id),
      name: name,
      year: parseInt(year),
      businessDays: parseInt(businessDays)
    });
    setSpecialtySelectedState(specialty.id);
    setSinceSelectedState(formatSince);
    setUntilSelectedState(formatUntil);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id, specialty, name, since, until, year, businessDays]);

  useEffect(
    props => {
      if (dataSubmitGraph) {
        if (dataSubmitGraph.updateAcademicPeriod) {
          const newObj = dataSubmitGraph.updateAcademicPeriod;
          editFunc(newObj);
        }
      }
    },
    [dataSubmitGraph]
  );

  const onSubmit = async values => {
    const query = {
      id: parseInt(id),
      specialty: +values.specialty,
      name: values.name,
      since: values.since,
      until: values.until,
      year: parseInt(values.year),
      businessDays: parseInt(values.businessDays)
    };
    try {
      await updateAcademicPeriodHandler({
        variables: query
      });
    } catch (err) {}
  };

  return (
    <Card>
      <CardHeaderWidget title="Editar Periodo academico" />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="id"
                inputRef={register}
                type="number"
                style={{ display: 'none' }}
              />
              <TextFieldWidgetSelect
                options={specialtyDataState}
                name="specialty"
                req={require}
                loading={loadingSpecialitiesGraph}
                withDefault={true}
                selected={specialtySelectedState}
                onChange={setSpecialtySelectedState}
                InputLabelProps={{ shrink: true }}
                labeltext="Especialidad"
                register={register}
                errors={errors.specialty}
              />
              <TextFieldWidgetText
                name="name"
                labeltext="Nombre"
                req={require}
                InputLabelProps={{ shrink: true }}
                minLength={3}
                maxLength={100}
                register={register}
                errors={errors.name}
                autoComplete="nombre"
              />
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetDate
                    name="since"
                    labeltext="Inicio"
                    value={sinceSelectedState}
                    onChange={date => setSinceSelectedState(date)}
                    register={register}
                    errors={errors.since}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetDate
                    name="until"
                    labeltext="Fin"
                    value={untilSelectedState}
                    onChange={date => setUntilSelectedState(date)}
                    req={require}
                    register={register}
                    errors={errors.until}
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="year"
                    labeltext="Año"
                    type="number"
                    req={require}
                    InputLabelProps={{ shrink: true }}
                    minLength={4}
                    maxLength={4}
                    register={register}
                    errors={errors.year}
                    autoComplete="año"
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="businessDays"
                    labeltext="Días hábiles"
                    type="number"
                    req={require}
                    pattern={pattern}
                    InputLabelProps={{ shrink: true }}
                    minLength={1}
                    register={register}
                    errors={errors.businessDays}
                    autoComplete="dias"
                  />
                </BsCol>
              </BsRow>
              <SaveAction
                messageType="edit"
                mutationLoading={loadingSubmitGraph}
                success={dataSubmitGraph}
                actionClose={props.closeFunc}
              />
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default AcademicPeriodEdit;
