import React, { useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import classes from './StepperDash.module.scss';

const StepperDash = props => {
  const [activeStep] = useState(-1);
  const steps = props.postulationUserDataLoad;

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        classes={{
          root: classes.stepperRoot
        }}>
        {steps && steps.length > 0 ? (
          steps.map((step, index) => (
            <Step
              key={step.step}
              completed={step.finished}
              classes={{
                completed: classes.stepCompleted
              }}>
              <StepLabel
                StepIconProps={{
                  classes: { active: classes.stepActive }
                }}>
                {step.name}
              </StepLabel>
            </Step>
          ))
        ) : (
          <div></div>
        )}
      </Stepper>
      <div>
        {steps && activeStep === steps.length ? (
          <div>
            <div className={classes.instructions}>
              Has enviado tu Postulación
            </div>
            {/* <Button onClick={handleReset}>Limpiar</Button> */}
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>
              Completa las secciones del Formulario, las primeras 4 son
              obligatorias para enviar la Postulación.
            </div>
            <div>
              {/* <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}>
                Volver
              </Button> */}
              {/* <Button variant="contained" color="primary" onClick={handleNext}>
                {steps && activeStep === steps.length - 1
                  ? 'Finish'
                  : 'Siguiente'}
              </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepperDash;
