import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import useForm from 'react-hook-form';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Card, CardContent, Divider, ButtonGroup } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import CardActions from '@material-ui/core/CardActions';
import classes from './ReportApplication.module.scss';
import {
  SearchBasicTable,
  FilterAction,
  DataTableWidget,
  TextFieldWidgetSelect,
  IconActionTableProfile
} from '../../../components';
import { alterArrayForSelectYear, arrayIntersectionElements, concatObjectConfigDatatable, dateTimeParse, printOrder } from '../../../helpers';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { listUsersCount } from '../../../graphql/queries/users';
import { listPostulationStudents, listPostulationStudentsCount } from '../../../graphql/queries/student';
import { listSpecialities } from '../../../graphql/queries/specialty';
import { listYears } from '../../../graphql/queries/year';
import { listCompetitions } from '../../../graphql/queries/competition';
import { useActions } from '../../../store/actions';
import { Context } from '../../../context/Context';

function useQueryRoute() {
  return new URLSearchParams(useLocation().search);
}

const ApplicationLevelTwo = props => {
  const { year: yearReducer, competition: competitionReducer, changeYear, changeCompetition } = useActions();
  
  const context = useContext(Context);
  const { userId, userIdProfile, userFullname, userProfile, specialtyUser } = context;

  const [
    listPostulationStudentsHandler,
    { loading: loadingListGraph, data: dataListGraph }
  ] = useLazyQuery(listPostulationStudents, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const { loading: loadingCountGraph } = useQuery(listUsersCount, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

   const [
    listPostulationStudentsCountHandler,
    { loading: loadingStudentsCountGraph, data: dataCountPostulationStudentsGraph }
  ] = useLazyQuery(listPostulationStudentsCount, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const { loading: loadingListYearsGraph, data: dataListYearsGraph } = useQuery(
    listYears,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const { loading: loadingListCompetitionsGraph, data: dataListCompetitionsGraph } = useQuery(
    listCompetitions,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );


  const [usersPostulationDataState, setUsersPostulationDataState] = useState([]);
  const [filterTextState, setFilterTextState] = useState('');

  const [specialtyDataState, setSpecialtyDataState] = useState([]);
  const [specialtySelectedState, setSpecialtySelectedState] = useState('');
  const [
    specialtySelectedSubmitState,
    setSpecialtySelectedSubmitState
  ] = useState(null);

  const [yearDataState, setYearDataState] = useState([]);
  const [yearSelectedState, setYearSelectedState] = useState(yearReducer);

  const [competitionDataState, setCompetitionDataState] = useState([]);
  const [competitionSelectedState, setCompetitionSelectedState] = useState(competitionReducer);

  const [limitState, setLimitState] = useState(10);
  const [sortState, setSortState] = useState({ name: 'asc' });
  const [pageState, setPageState] = useState(1);
  const [countState, setCountState] = useState(0);

  const { handleSubmit, register, errors } = useForm();

  const [
    listSpecialitiesHandler,
    { data: dataSpecialitiesGraph }
  ] = useLazyQuery(listSpecialities, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await listSpecialitiesHandler({
          variables: { 
            sort: { name: 'asc'},
            filter: { id: { gt: 0 } } 
          }
        });
      } catch (err) {}
    }
    queryData();
  }, [listSpecialitiesHandler]);

  useEffect(() => {
    if (dataSpecialitiesGraph && dataSpecialitiesGraph.listSpecialities) {

      if(userIdProfile === "6" || userIdProfile === 6){

        console.log(dataSpecialitiesGraph.listSpecialities, "<-------------", specialtyUser)

        let newArray2 = arrayIntersectionElements(dataSpecialitiesGraph.listSpecialities, specialtyUser);
        setSpecialtyDataState(newArray2);
      } else {
        setSpecialtyDataState(dataSpecialitiesGraph.listSpecialities);
      }

      
    }
  }, [dataSpecialitiesGraph]);

  useEffect(() => {
		if (dataCountPostulationStudentsGraph && dataCountPostulationStudentsGraph.listPostulationStudentsCount) {
      // console.log(dataCountPostulationStudentsGraph.listPostulationStudentsCount, "<---count")
			setCountState(dataCountPostulationStudentsGraph.listPostulationStudentsCount);
		}
	}, [dataCountPostulationStudentsGraph]);

  useEffect(() => {
    console.log('llegaron los postulantes-------------', dataListGraph);
    if (dataListGraph && dataListGraph.listPostulationStudents) {
      setUsersPostulationDataState(dataListGraph.listPostulationStudents);
    }
  }, [dataListGraph]);

  useEffect(() => {
    if (dataListCompetitionsGraph && dataListCompetitionsGraph.listCompetitions) {
      setCompetitionDataState(dataListCompetitionsGraph.listCompetitions);
    }
  }, [dataListCompetitionsGraph]);


  useEffect(() => {
    // console.log('console: ', usersDataState);
  }, [usersPostulationDataState]);
  

  useEffect(() => {
    async function queryData(idSpecialty) {
      try {
        await listPostulationStudentsHandler({
          variables: {
            limit: limitState,
            sort: sortState,
            page: pageState,
            filter: {
              specialty: { eq: parseInt(idSpecialty) },
              competition: { eq: parseInt(competitionReducer)},
              year: { eq: parseInt(yearReducer) },
              // agregar solo enviadas
              statePostulation: { gt: 0 }
            }
          }
        });
      } catch (err) {}
    }

    async function queryDataCount(idSpecialty) {
      try {
        await listPostulationStudentsCountHandler({
          variables: {
            filter: {
              specialty: { eq: parseInt(idSpecialty) },
              year: { eq: parseInt(yearReducer) },
              competition: { eq: parseInt(competitionReducer)},
              // agregar solo enviadas
              statePostulation: { gt: 0 }
            }
          }
        });
      } catch (err) {}
    }

    if (specialtySelectedSubmitState) {
      queryData(specialtySelectedSubmitState);
      queryDataCount(specialtySelectedSubmitState);
    }
  }, [
    listPostulationStudentsHandler,
    limitState,
    sortState,
    pageState,
    specialtySelectedSubmitState,
    yearReducer,
    competitionReducer
  ]);

  let query = useQueryRoute();
  let specialtyQuery = null;
  specialtyQuery = query.get('idSpecialty');
  useEffect(() => {
    if (specialtyQuery) {
      setSpecialtySelectedState(specialtyQuery);
      setSpecialtySelectedSubmitState(specialtyQuery);
    }
  }, [specialtyQuery]);

  const searchFunc = async filterText => {
    let filter = {};
    if (filterText !== '') {
      filter = {
        filter: {
          specialty: { eq: parseInt(specialtySelectedSubmitState) },
          competition: { eq: parseInt(competitionReducer)},
          rut: { contains: filterText }
        }
      };
    }
    try {
      await listPostulationStudentsHandler({
        variables: filter
      });
    } catch (err) {}
  };

  const subHeaderComponentMemo = (
    <SearchBasicTable
      resetFunc={() => {
        searchFunc('');
        setFilterTextState('');
      }}
      valuetext={filterTextState}
      placeholder="Buscar Rut"
      filterFunc={() => searchFunc(filterTextState)}
      onFilter={value => setFilterTextState(value)}
    />
  );

  const columnsConfig = [
    {
      name: 'Rut',
      width: '200px',
      selector: 'rut',
      sortable: true
    },
    {
      name: 'Nombre Completo',
      selector: 'fullname',
      sortable: false
    },
    {
      name: 'Especialidad',
      selector: 'specialty.name',
      sortable: false
    },
    // {
    //   name: 'Apellidos',
    //   selector: 'paternalLastname',
    //   cell: row => (
    //     <span>
    //       {row.paternalLastname} {row.maternalLastname}
    //     </span>
    //   ),
    //   sortable: true
    // },
    {
      name: 'Concurso',
      selector: 'competition.name',
      sortable: false
    },
    {
      name: 'Fecha Envío',
      selector: 'postulationDate',
      cell: row => dateTimeParse(row.postulationDate),
      sortable: false
    },
    {
      name: 'Estado Actual',
      selector: 'state',
      grow: 3,
      cell: row => (
        <span>
          {row.state} <br /> {row.statePostulation.name}
        </span>
      ),
      sortable: false
    }
  ];

  const handleGoToProfile = row => {
    console.log(row, "esto manda");
    props.history.push('/profile-report?idUser=' + row.idUser + '&id=' + row.idPostulation);
  };

  const actionsConfig = [
    {
      name: 'Acciones',
      width: '100px',
      cell: row => (
        <div className="actionColumn">
          <IconActionTableProfile
            handleActionDetail={() => handleGoToProfile(row)}
          />
        </div>
      )
    }
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  const handleSort = async (column, sortDirection) => {
    let nameColumn = column.selector;
    if (column.selector === 'specialty.name') {
      nameColumn = 'specialty';
    } else if (column.selector === 'competition.name') {
      nameColumn = 'competition';
    }
    await setUsersPostulationDataState([]);
    let obj = {};
    Object.defineProperty(obj, nameColumn, {
      value: sortDirection,
      writable: true,
      enumerable: true,
      configurable: true
    });
    setSortState(obj);
  };

  const customSort = () => {
    return usersPostulationDataState;
  };

  const handleChangePagination = (page, totalRows) => {
    setPageState(page);
  };

  const handleChangeRows = (currentRowsPerPage, currentPage) => {
    setLimitState(currentRowsPerPage);
  };

  const onSubmit = async values => {
    setSpecialtySelectedSubmitState(values.specialty);
    changeCompetition(values.competition);
    changeYear(values.year);
  };

  // filters

  useEffect(() => {
    if (dataListYearsGraph && dataListYearsGraph.listYears) {
      console.log(dataListYearsGraph.listYears, "años")
      setYearDataState(alterArrayForSelectYear(dataListYearsGraph.listYears));
    }
  }, [dataListYearsGraph]);

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class="col-lg-12 mb-4">
            <Card className={classes.cardRoot}>
              <CardActions
                classes={{
                  root: classes.cardActionRoot
                }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.goBack()}>
                  <ArrowBackIosIcon fontSize="small" /> Volver
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => props.history.push('/dashboard')}>
                  <HomeIcon fontSize="small" /> Ir al Escritorio
                </Button>
                <div>
                  {/* <Button size="small" color="primary">
                    Excel
                  </Button> */}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => printOrder('ifmcontentstoprint')}>
                    Imprimir
                  </Button>
                </div>
              </CardActions>
              <div className={classes.buttonContainer}>
                <ButtonGroup
                  color="default"
                  classes={{
                    root: classes.rootGroup
                  }}
                  aria-label="outlined primary button group">
                  <Button
                    onClick={() => props.history.push('/application-report')}>
                    Estado Postulaciones por Especialidad
                  </Button>
                  <Button
                    color="primary"
                    classes={{ outlinedPrimary: classes.outlinedPrimary }}>
                    Postulantes por Especialidad
                  </Button>
                  <Button onClick={() => props.history.push('/profile-report')}>
                    Ficha Perfil
                  </Button>
                </ButtonGroup>
              </div>
              <Divider />
              <CardContent
                className={classes.cardContent}
                id="ifmcontentstoprint">
                <div>
                  <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                    <BsRow>
                      <BsCol class="col-md-3">
                        <TextFieldWidgetSelect
                          options={specialtyDataState}
                          name="specialty"
                          req={true}
                          shortLabel={true}
                          selected={specialtySelectedState}
                          onChange={e => {
                            console.log(e, "<-------");
                            return setSpecialtySelectedState(e)
                          }}
                          loading={false}
                          withDefault={true}
                          InputLabelProps={{ shrink: true }}
                          labeltext="Especialidad"
                          register={register}
                          errors={errors.specialty}
                        />
                      </BsCol>
                      <BsCol class="col-md-3">
                        <TextFieldWidgetSelect
                          options={yearDataState}
                          name="year"
                          req={false}
                          shortLabel={true}
                          selected={yearSelectedState}
                          onChange={e => setYearSelectedState(e)}
                          loading={loadingListYearsGraph}
                          withDefault={true}
                          InputLabelProps={{ shrink: true }}
                          labeltext="Año"
                          register={register}
                          errors={errors.year}
                        />
                      </BsCol>
                      <BsCol class="col-md-3">
                        <TextFieldWidgetSelect
                          options={competitionDataState}
                          name="competition"
                          req={false}
                          shortLabel={true}
                          loading={loadingListCompetitionsGraph}
                          withDefault={true}
                          selected={competitionSelectedState}
                          disabled={false}
                          onChange={e => setCompetitionSelectedState(e)}
                          InputLabelProps={{
                            shrink: true
                          }}
                          labeltext="Concurso"
                          register={register}
                          errors={errors.competition}
                        />
                      </BsCol>
                      <BsCol class="col-md-3">
                        <FilterAction mutationLoading={false} success={false} />
                      </BsCol>
                    </BsRow>
                  </form>
                  <div>
                    <DataTableWidget
                      totalRows={countState}
                      handleSort={handleSort}
                      sortFunction={customSort}
                      changePagination={handleChangePagination}
                      rowsChange={handleChangeRows}
                      columns={columns}
                      sortField="fullname"
                      filteredItems={usersPostulationDataState}
                      subHeaderComponentMemo={subHeaderComponentMemo}
                      loading={loadingListGraph}
                      loadingCount={loadingStudentsCountGraph}
                      moduleName="Postulantes"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(ApplicationLevelTwo);
