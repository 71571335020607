import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Sidebar, Topbar, Footer } from './components';
import { IsDesktopHandler } from '../../helpers';
import { useActions } from 'store/actions';

import './Main.scss';

const Main = props => {
  const { children } = props;

  const { changeYear, year: yearReducer } = useActions();

  const handleChangeYear = event => {
    changeYear(event.value);
  };

  const isDesktop = IsDesktopHandler('lg');

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        root: true,
        shiftContent: isDesktop
      })}>
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        onChangeYear={e => handleChangeYear(e)}
        yearSelected={yearReducer}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className="content">
        {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
