import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Context } from './../../../context/Context';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { TextFieldWidgetText, DialogDelete } from '../../../components';

import { BsRow, BsCol } from '../../../layouts/components';
import classes from '../AddRegisterPanels.module.scss';

import { handleActionDelete } from '../../../helpers';

import { CircularProgressComponent } from '../../Progress';

import {
  createPostulationCurricularBackground,
  deletePostulationCurricularBackground
} from '../../../graphql/mutation/postulationCurricularBackground';

import { getPostulationCurricularBackgroundList } from '../../../graphql/queries/postulationCurricularBackground';

import { Context as ActivePostulationContext } from '../../../context/ActivePostulationContext';

const AddRegisterPanelAC = props => {
  const { registeredItems, disableForm, loadingAC } = props;
  const context = useContext(Context);
  const userId = context.userId;

  const [fieldsUncompletedState, setFieldsUncompletedState] = useState(null);
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState('');
  const [curricularBackgroundState, setCurricularBackgroundState] = useState(
    ''
  );
  const [values, setValues] = useState(false);

  const {
		state: { activePostulation }
	} = useContext(ActivePostulationContext);

  const [
    createPostulationCurricularBackgroundHandler,
    { loading: loadingSubmitGraph }
  ] = useMutation(createPostulationCurricularBackground);

  const [
    getCurricularBackgroundHandler,
    {
      loading: loadingCurricularBackgroundGraph,
      data: dataCurricularBackgroundGraph
    }
  ] = useLazyQuery(getPostulationCurricularBackgroundList, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function queryData() {
      try {
        await getCurricularBackgroundHandler({
          variables: { id: parseInt(userId), idPostulationActive: parseInt(activePostulation.idPostulationActive) }
        });
      } catch (err) {}
    }
    if (values) {
      queryData();
      setValues(false);
    }
  }, [getCurricularBackgroundHandler, values, userId]);

  const [
    deletePostulationCurricularBackgroundHandler,
    {
      loading: loadingDeleteCurricularBackgroundGraph,
      data: dataDeleteCurricularBackgroundGraph
    }
  ] = useMutation(deletePostulationCurricularBackground, {
    errorPolicy: 'all'
  });

  const deleteActionDialogHandler = async () => {
    try {
      await deletePostulationCurricularBackgroundHandler({
        variables: { id: rowToHandleState.id }
      });
    } catch (err) {}
    const newData = handleActionDelete(
      rowToHandleState,
      curricularBackgroundState
    );
    setCurricularBackgroundState(newData);
  };

  const DialogDeleteHandler = row => {
    setRowToHandleState(row);
    setOpenDialogDeleteState(!openDialogDeleteState);
  };

  useEffect(() => {
    if (registeredItems) {
      setCurricularBackgroundState(registeredItems);
    }
  }, [registeredItems]);

  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    reset: resetItem,
    watch
  } = useForm();

  let watchAllFields = watch();

  useEffect(() => {
    if (
      watchAllFields.hoursCVAntecedent === '' &&
      watchAllFields.nameCVAntecedent === '' &&
      watchAllFields.periodCVAntecedent === '' &&
      watchAllFields.qualificationCVAntecedent === ''
    ) {
      setFieldsUncompletedState(true);
    } else if (
      watchAllFields.hoursCVAntecedent === '' ||
      watchAllFields.nameCVAntecedent === '' ||
      watchAllFields.periodCVAntecedent === '' ||
      watchAllFields.qualificationCVAntecedent === ''
    ) {
      setFieldsUncompletedState('Debe llenar todos los campos');
    } else {
      setFieldsUncompletedState(null);
    }
  }, [watchAllFields]);

  const onSubmitItem = async values => {
    const query = {
      id: parseInt(userId),
      name: values.nameCVAntecedent,
      qualification: values.qualificationCVAntecedent,
      hours: values.hoursCVAntecedent,
      date: values.periodCVAntecedent,
      idPostulationActive: parseInt(activePostulation.idPostulationActive)
    };
    try {
      await createPostulationCurricularBackgroundHandler({
        variables: query
      });
      if (
        values &&
        values.hoursCVAntecedent &&
        values.nameCVAntecedent &&
        values.periodCVAntecedent &&
        values.qualificationCVAntecedent
      ) {
        setValues(true);
        setFieldsUncompletedState(null);
        resetItem();
        // setCurricularBackgroundState(
        //   dataCurricularBackgroundGraph.getPostulationCurricularBackgroundList
        // );
      }
    } catch (err) {}
  };

  //DESAPARECE BOTON GUARDAR
  useEffect(() => {
    if (disableForm && disableForm !== true) {
      const elems = document.getElementsByClassName('EditPostulation');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].style.display = 'none';
      }
    }
  });

  useEffect(() => {
    // console.log(dataPostulationPublicationGraph, "<-----------llega la data")
   if(dataCurricularBackgroundGraph && dataCurricularBackgroundGraph.getPostulationCurricularBackgroundList) {
     setCurricularBackgroundState(
          dataCurricularBackgroundGraph.getPostulationCurricularBackgroundList
        );
   }
  }, [dataCurricularBackgroundGraph]);

  return (
    <form onSubmit={handleSubmitItem(onSubmitItem)}>
      <div className={classes.addRegisterContainer}>
        <div className={classes.addTitleAdditional}>
          <AddIcon /> Registre sus Antecedentes Curriculares por cada estudio
          realizado
        </div>
        <BsRow class="align-items-center">
          <BsCol class="col-md-10">
            <BsRow>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="nameCVAntecedent"
                  labeltext="Nombre Estudio"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={200}
                  errors={errorsItem.nameCVAntecedent}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="periodCVAntecedent"
                  labeltext="Fecha / Período"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={3}
                  maxLength={100}
                  errors={errorsItem.periodCVAntecedent}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="qualificationCVAntecedent"
                  labeltext="Calificación"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  minLength={1}
                  maxLength={20}
                  errors={errorsItem.qualificationCVAntecedent}
                />
              </BsCol>
              <BsCol class="col-md-6">
                <TextFieldWidgetText
                  name="hoursCVAntecedent"
                  labeltext="Horas"
                  disabled={disableForm}
                  InputLabelProps={{ shrink: true }}
                  register={registerItem}
                  type="number"
                  minLength={1}
                  maxLength={6}
                  errors={errorsItem.hoursCVAntecedent}
                />
              </BsCol>
            </BsRow>
          </BsCol>
          <BsCol class="col-md-2">
            <div className={clsx(classes.wrapper, 'EditPostulation')}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={Boolean(fieldsUncompletedState)}
                size="small"
                className={classes.buttonAdd}
                startIcon={<AddIcon />}>
                Agregar
              </Button>
              {fieldsUncompletedState && (
                <p className={classes.errorField}>{fieldsUncompletedState}</p>
              )}
              {loadingSubmitGraph && <CircularProgressComponent />}
            </div>
          </BsCol>
        </BsRow>
        {/* listado agregados */}
        <div className={classes.registerAddedContainer}>
          <div className={classes.addTitleAdditional}>
            Antecedentes Curriculares Agregados
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Calificación</th>
                <th scope="col">Horas</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
            {curricularBackgroundState &&
            curricularBackgroundState.length > 0 ? (
              curricularBackgroundState.map(row => (
                <tr key={row.name}>
                  <td>{row.name}</td>
                  <td>{row.qualification}</td>
                  <td>{row.hour}</td>
                  <td>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      disabled={disableForm}
                      onClick={() => DialogDeleteHandler(row)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : loadingCurricularBackgroundGraph || loadingAC ? (
              <tr>
                <td colSpan={6}><CircularProgressComponent /></td>
              </tr>
            ) : (
              
              <tr>
                <td colSpan={6}><p> No hay antecedentes curriculares.</p></td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <DialogDelete
          openDialog={openDialogDeleteState}
          rowDelete={rowToHandleState}
          actionDelete={deleteActionDialogHandler}
          variant="success"
          loading={loadingDeleteCurricularBackgroundGraph}
          success={dataDeleteCurricularBackgroundGraph}
          openState={setOpenDialogDeleteState}
        />
      </div>
    </form>
  );
};

AddRegisterPanelAC.propTypes = {
  registeredItems: PropTypes.array.isRequired
};

export default AddRegisterPanelAC;
