import gql from 'graphql-tag';

export const createFinalNote = gql`
  mutation($finalNoteInput: createFinalNoteInput!) {
    createFinalNote(finalNoteInput: $finalNoteInput)
  }
`;

export const updateFinalNote = gql`
  mutation($idPostulation: Int!, $finalNote: Float!) {
    updateFinalNote(
      finalNoteInput: { idPostulation: $idPostulation, finalNote: $finalNote }
    ) {
      id
      idUser
      rut
      name
      paternalLastname
      maternalLastname
      fullname
      finalNote
    }
  }
`;
