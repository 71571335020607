import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import useForm from 'react-hook-form';
import { Context } from '../../context/Context';
import { signIn } from '../../graphql/queries/auth';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Link, Typography } from '@material-ui/core';
import { TextFieldWidgetTextBig } from '../../components';
import classes from './SignIn.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';

const SignIn = props => {
  const context = useContext(Context);
  const [loadingState, setLoadingState] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const [signInHandler, { loading, error, data }] = useLazyQuery(signIn, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      setLoadingState(false);
    }
    if (data && data.signIn.token) {
      console.log(data, "<------")
      if(data.signIn.profile.id === 6) {
         context.login(
          data.signIn.token,
          data.signIn.userId,
          data.signIn.fullname,
          data.signIn.profile.name, //ejemplo
          data.signIn.tokenExpiration,
          data.signIn.profile.id,
          data.signIn.moduleUser,
          data.signIn.specialty
        );
      } else {
        context.login(
          data.signIn.token,
          data.signIn.userId,
          data.signIn.fullname,
          data.signIn.profile.name, //ejemplo
          data.signIn.tokenExpiration,
          data.signIn.profile.id,
          data.signIn.moduleUser,
          ''
        );
      }
    }
  }, [loading, error, data, context]);

  const onSubmit = async event => {
    const { email, password } = event;
    try {
      await signInHandler({ variables: { email, password } });
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid style={{ height: '100%' }}>
        <BsRow style={{ height: '100%' }}>
          <BsCol class={['col-lg-5 px-md-0', classes.quoteContainer].join(' ')}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Especialidades Médicas
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    UCN | Facultad de Medicina
                  </Typography>
                  {/* <Typography className={classes.bio} variant="body2">
                    Manager at inVision
                  </Typography> */}
                </div>
              </div>
            </div>
          </BsCol>
          <BsCol class={['col-lg-7', classes.content].join(' ')}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}>
                  <Typography className={classes.title} variant="h2">
                    Ingresar
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    Con su email registrado y su contraseña
                  </Typography>
                  <TextFieldWidgetTextBig
                    name="email"
                    className="mt-3"
                    label="E-mail"
                    req
                    register={register}
                    errors={errors.email}
                    autoComplete="email"
                  />
                  <TextFieldWidgetTextBig
                    name="password"
                    req
                    minLength={5}
                    maxLength={10}
                    type="password"
                    label="Contraseña"
                    className="mt-3"
                    register={register}
                    errors={errors.password}
                  />
                  <div className={classes.wrapper}>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      fullWidth
                      disabled={loadingState}
                      size="large"
                      type="submit"
                      variant="contained">
                      Ingresar
                    </Button>
                    {loadingState && (
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                      />
                    )}
                  </div>

                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className="mb-2">
                    No tienes una cuenta?{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h5">
                      Registrarse
                    </Link>
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className="mb-2">
                    Olvidaste tu contraseña?{' '}
                    <Link
                      component={RouterLink}
                      to="/recovery-password"
                      variant="h5">
                      Recuperar contraseña
                    </Link>
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    Quieres validar tu cuenta?{' '}
                    <Link
                      component={RouterLink}
                      to="/validate-account"
                      variant="h5">
                      Validar cuenta
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(SignIn);
