import gql from 'graphql-tag';

export const listSpecialities = gql`
  query($filter: SpecialtyFilterInput, $sort: SpecialtySortingInput) {
    listSpecialities(filter: $filter, sort: $sort) {
      id
      user {
        id
        rut
        name
        paternalLastname
        maternalLastname
        fullname
      }
      name
      code
      description
      state {
        id
        name
      }
      delete
      edit
    }
  }
`;
