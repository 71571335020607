import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Context } from './context/Context';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  UserAdd as UserAddView,
  UserPrivilege as UserPrivilegeView,
  AbsenceAdd as AbsenceAddView,
  AbsenceList as AbsenceListView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  RecoveryPassword as RecoveryPasswordView,
  ValidateAccount as ValidateAccountView,
  NotFound as NotFoundView,
  AbsenceReasonAdd as AbsenceReasonAddView,
  AbsenceReasonList as AbsenceReasonListView,
  CourseAdd as CourseAddView,
  CourseList as CourseListView,
  SpecialtyAdd as SpecialtyAddView,
  SpecialtyList as SpecialtyListView,
  Parameters as ParametersView,
  AcademicPeriodList as AcademicPeriodListView,
  AcademicPeriodAdd as AcademicPeriodAddView,
  CompetitionList as CompetitionListView,
  CompetitionAdd as CompetitionAddView,
  Postulation as PostulationView,
  ApplicationLevelOne as ApplicationLevelOneView,
  ApplicationLevelTwo as ApplicationLevelTwoView,
  ProfileReport as ProfileReportView,
  FinalNoteList as FinalNoteListView,
  FinalNoteAdd as FinalNoteAddView,
  PostulationDocsAdd as PostulationDocsAddView,
  CurriculumAdd as CurriculumAddView,
  CohorteReport as CohorteReportView,
  QualificationReport as QualificationReportView,
  CurriculumReport as CurriculumReportView
} from './views';

const Routes = () => {
  const { token } = useContext(Context);

  return (
    <Switch>
      {!token && <Redirect from="/" to="/sign-in" exact />}
      <Redirect from="/" to="/dashboard" exact />
      {!token && <Redirect from="/dashboard" to="/sign-in" exact />}
      {/* -----USER----- */}
      {!token && <Redirect from="/user-add" to="/sign-in" exact />}
      {!token && <Redirect from="/user-list" to="/sign-in" exact />}
      {!token && <Redirect from="/user-privilege" to="/sign-in" exact />}

      {/* -----ABSENCE----- */}
      {!token && <Redirect from="/absence-add" to="/sign-in" exact />}
      {!token && <Redirect from="/absence-list" to="/sign-in" exact />}
      {/* -----ACCOUNT----- */}
      {!token && <Redirect from="/account" to="/sign-in" exact />}
      {!token && <Redirect from="/settings" to="/sign-in" exact />}
      {token && <Redirect from="/sign-in" to="/dashboard" exact />}
      {/* -----ABSENCE-REASON----- */}
      {!token && <Redirect from="/absence-reason-add" to="/sign-in" exact />}
      {!token && <Redirect from="/absence-reason-list" to="/sign-in" exact />}

      {/* -----COURSE----- */}
      {!token && <Redirect from="/coure-add" to="/sign-in" exact />}
      {!token && <Redirect from="/coure-list" to="/sign-in" exact />}

      {/* -----SPECIALTY----- */}
      {!token && <Redirect from="/specialty-add" to="/sign-in" exact />}
      {!token && <Redirect from="/specialty-list" to="/sign-in" exact />}

      {/* -----PARAMETERS----- */}
      {!token && <Redirect from="/parameters-edit" to="/sign-in" exact />}

      {/* -----PARAMETERS----- */}
      {!token && <Redirect from="/postulation-docs" to="/sign-in" exact />}

      {/* -----ACADEMIC-PERIOD------ */}
      {!token && <Redirect from="/period-add" to="/sign-in" exact />}
      {!token && <Redirect from="/period-list" to="/sign-in" exact />}

      {/* -----COMPETITION------ */}
      {!token && <Redirect from="/competition-add" to="/sign-in" exact />}
      {!token && <Redirect from="/competition-list" to="/sign-in" exact />}

      {/* -----POSTULATION------ */}
      {!token && <Redirect from="/postulation-form" to="/sign-in" exact />}

      {/* -------REPORTS--------- */}
      {!token && <Redirect from="/application-report" to="/sign-in" exact />}
      {!token && (
        <Redirect from="/specialty-application-report" to="/sign-in" exact />
      )}
      {!token && <Redirect from="/profile-report" to="/sign-in" exact />}
      {!token && <Redirect from="/cohorte-report" to="/sign-in" exact />}

      {/* -----FINAL NOTE------ */}
      {!token && <Redirect from="/note-add" to="/sign-in" exact />}
      {!token && <Redirect from="/note-list" to="/sign-in" exact />}

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      {/* -----USER----- */}
      <RouteWithLayout
        component={UserAddView}
        exact
        layout={MainLayout}
        path="/user-add"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/user-list"
      />
      <RouteWithLayout
        component={UserPrivilegeView}
        exact
        layout={MainLayout}
        path="/user-privilege"
      />
      {/* -----ABSENCE REASON----- */}
      <RouteWithLayout
        component={AbsenceReasonAddView}
        exact
        layout={MainLayout}
        path="/absence-reason-add"
      />

      <RouteWithLayout
        component={AbsenceReasonListView}
        exact
        layout={MainLayout}
        path="/absence-reason-list"
      />

      {/* -----COURSE----- */}
      <RouteWithLayout
        component={CourseAddView}
        exact
        layout={MainLayout}
        path="/course-add"
      />

      <RouteWithLayout
        component={CourseListView}
        exact
        layout={MainLayout}
        path="/course-list"
      />

      {/* -----SPECIALTY----- */}
      <RouteWithLayout
        component={SpecialtyAddView}
        exact
        layout={MainLayout}
        path="/specialty-add"
      />

      <RouteWithLayout
        component={SpecialtyListView}
        exact
        layout={MainLayout}
        path="/specialty-list"
      />

      <RouteWithLayout
        component={AbsenceAddView}
        exact
        layout={MainLayout}
        path="/absence-add"
      />
      <RouteWithLayout
        component={AbsenceListView}
        exact
        layout={MainLayout}
        path="/absence-list"
      />

      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />

      <RouteWithLayout
        component={ParametersView}
        exact
        layout={MainLayout}
        path="/parameters-edit"
      />

      {/* -----POSTULATION-DOCS----- */}
      <RouteWithLayout
        component={PostulationDocsAddView}
        exact
        layout={MainLayout}
        path="/postulation-docs"
      />
      {/* -----ACADEMIC-PERIOD----- */}
      <RouteWithLayout
        component={AcademicPeriodAddView}
        exact
        layout={MainLayout}
        path="/period-add"
      />
      <RouteWithLayout
        component={AcademicPeriodListView}
        exact
        layout={MainLayout}
        path="/period-list"
      />
      {/* -----COMPETITION----- */}
      <RouteWithLayout
        component={CompetitionAddView}
        exact
        layout={MainLayout}
        path="/competition-add"
      />
      <RouteWithLayout
        component={CompetitionListView}
        exact
        layout={MainLayout}
        path="/competition-list"
      />
      {/* -----POSTULATION----- */}
      <RouteWithLayout
        component={PostulationView}
        exact
        layout={MainLayout}
        path="/postulation-form"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      {/* -----REPORTS-------- */}
      <RouteWithLayout
        component={ApplicationLevelOneView}
        exact
        layout={MinimalLayout}
        path="/application-report"
      />
      <RouteWithLayout
        component={ApplicationLevelTwoView}
        layout={MinimalLayout}
        path="/specialty-application-report"
      />
      <RouteWithLayout
        component={ProfileReportView}
        layout={MinimalLayout}
        path="/profile-report"
      />
      <RouteWithLayout
        component={CohorteReportView}
        layout={MinimalLayout}
        path="/cohorte-report"
      />
      <RouteWithLayout
        component={QualificationReportView}
        layout={MinimalLayout}
        path="/qualification-report"
      />
      <RouteWithLayout
        component={CurriculumReportView}
        layout={MinimalLayout}
        path="/curriculum-report"
      />
      {/* -----FINAL NOTE----- */}
      <RouteWithLayout
        component={FinalNoteAddView}
        exact
        layout={MainLayout}
        path="/note-add"
      />
      <RouteWithLayout
        component={FinalNoteListView}
        exact
        layout={MainLayout}
        path="/note-list"
      />

      {/* -----Curriculum view----- */}
      <RouteWithLayout
        component={CurriculumAddView}
        exact
        layout={MinimalLayout}
        path="/curriculum-add"
      />
      <RouteWithLayout
        component={FinalNoteListView}
        exact
        layout={MainLayout}
        path="/note-list"
      />

      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={RecoveryPasswordView}
        exact
        layout={MinimalLayout}
        path="/recovery-password"
      />

      <RouteWithLayout
        component={ValidateAccountView}
        exact
        layout={MinimalLayout}
        path="/validate-account"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
