import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

const RadioWidget = props => {
  const { register, req, value, label, disable } = props;

  return (
    <FormControlLabel
      inputRef={register({ required: req })}
      value={value}
      disabled={disable}
      control={<Radio color="primary" />}
      label={label}
    />
  );
};

RadioWidget.propTypes = {
  label: PropTypes.string.isRequired,
  req: PropTypes.bool,
  value: PropTypes.string.isRequired
};

export default RadioWidget;
