import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Link } from '@material-ui/core';
import classes from './Footer.module.scss';

const Footer = props => {
  const { className, ...rest } = props;

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
			<Typography variant="caption">
        Plataforma Especialidades Médicas | Facultad de Medicina | Universidad Católica del Norte
      </Typography>
      <Typography variant="body2">
        &copy;{' '}
        <Link
          component="a"
          href="https://proyectarse.com/"
          target="_blank"
        >
          Proyectarse.com
        </Link>
        . 2020
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
