import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import classes from './Tabs.module.scss';

function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
}
  

const SimpleTabs = (props) => {
	const tabList = props.tabitems.map((tKey,tid) => {
		return (
			<Tab 
				key={tid} 
				classes={{
					root: classes.rootTabs,
					selected: classes.TabsSelected,
					textColorPrimary: classes.TabsTextColor,
				}}
				label={tKey} 
				{...a11yProps({tid})} 
			/>
		);
	});

	return (
	  <div className={classes.root}>
		<Paper elevation={0} position="static">
			<Tabs 
				value={props.value}
				onChange={props.toggler}
				indicatorColor="primary" 
				classes={{ indicator: classes.indicator }}
				textColor="primary"
				aria-label="area de Tabs"
				variant="scrollable"
        scrollButtons="on"
			>
				{}
				{tabList}
			</Tabs>
		</Paper>
		{props.children}
	  </div>
	);
}

export default SimpleTabs;
