import gql from 'graphql-tag';

export const createAndUpdatePostulationDocument = gql`
  mutation(
    $idUser: Int!
    $documentNationalMedicalQualification: Upload
    $documentProfessionCertificate: Upload
    $documentPresentationLetter: Upload
    $documentSecureAffiliationCertificate: Upload
    $documentSimpleCopyCNI: Upload
    $documentUpdatedResume: Upload
    $documentEunacomCertificate: Upload
    $documentHepatitisCertificate: Upload
    $documentSuperintendencyRegistrationCertificate: Upload
    $documentProfilePhoto: Upload
    $documentRecommendationLetter: Upload
    $documentInfluenzaCertificate: Upload
    $documentHealthAffiliationCertificate: Upload
    $documentMobilityPassCertificate: Upload
    $documentBirthCertificate: Upload
    $documentDefinitiveResidence: Upload
    $idPostulationActive: Int
  ) {
    createAndUpdatePostulationDocument(
      postulationDocumentInput: {
        idUser: $idUser
        documentNationalMedicalQualification: $documentNationalMedicalQualification
        documentProfessionCertificate: $documentProfessionCertificate
        documentPresentationLetter: $documentPresentationLetter
        documentSecureAffiliationCertificate: $documentSecureAffiliationCertificate
        documentSimpleCopyCNI: $documentSimpleCopyCNI
        documentUpdatedResume: $documentUpdatedResume
        documentEunacomCertificate: $documentEunacomCertificate
        documentHepatitisCertificate: $documentHepatitisCertificate
        documentSuperintendencyRegistrationCertificate: $documentSuperintendencyRegistrationCertificate
        documentProfilePhoto: $documentProfilePhoto
        documentRecommendationLetter: $documentRecommendationLetter
        documentInfluenzaCertificate: $documentInfluenzaCertificate
        documentHealthAffiliationCertificate: $documentHealthAffiliationCertificate
        documentMobilityPassCertificate: $documentMobilityPassCertificate
        documentBirthCertificate: $documentBirthCertificate
        documentDefinitiveResidence: $documentDefinitiveResidence
        idPostulationActive: $idPostulationActive
      }
    )
  }
`;
