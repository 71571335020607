import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const paginationOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de'
};
const DataTableWidget = props => {
  const {
    columns,
    filteredItems,
    subHeaderComponentMemo,
    loading,
    sortField,
    handleSort,
    sortFunction,
    changePagination,
    totalRows,
    rowsChange,
    loadingCount
  } = props;
  const [perPageOptions] = useState([10, 15, 20, 25, 30]);
  const [totalRowState, setTotalRowState] = useState(totalRows);

  useEffect(() => {
    setTotalRowState(totalRows);
  }, [loadingCount, totalRows]);

  return (
    <DataTable
      noHeader
      columns={columns}
      data={filteredItems}
      subHeader
      onSort={handleSort}
      sortFunction={sortFunction}
      defaultSortField={sortField}
      subHeaderComponent={subHeaderComponentMemo}
      pagination
      paginationServer
      paginationComponentOptions={paginationOptions}
      paginationRowsPerPageOptions={perPageOptions}
      onChangeRowsPerPage={rowsChange}
      progressPending={loading}
      paginationTotalRows={totalRowState}
      onChangePage={changePagination}
      noDataComponent="No hay registros."
    />
  );
};

export default DataTableWidget;
