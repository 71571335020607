import gql from 'graphql-tag';

export const listComune = gql`
  query {
    listComune {
      id
      name
    }
  }
`;

export const listCommuneByRegion = gql`
  query($id: Int) {
    listCommuneByRegion(id: $id) {
      id
      name
    }
  }
`;
