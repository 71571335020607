import gql from 'graphql-tag';

export const getPostulationPersonalData = gql`
  query(
    $id: Int!
    $idPostulationActive: Int
    ) {
    getPostulationPersonalData(id: $id, idPostulationActive: $idPostulationActive) {
      id
      rut
      name
      paternalLastname
      maternalLastname
      birthDate
      address
      email
      phone
      region {
        id
        name
      }
      commune {
        id
        name
      }
      nationality {
        id
        name
      }
      documentSimpleCopyCNIHref
      documentUpdatedResumeHref
      documentPresentationLetterHref
      documentProfilePhotoHref
      documentRecommendationLetterHref
      documentBirthCertificateHref
      documentDefinitiveResidenceHref
    }
  }
`;
